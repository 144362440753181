import { BaseEntity } from "../BaseEntity";

export class CentroCusto extends BaseEntity {

    codigo!: string;
	nome!: string;
    descricao!: string;
    idFuncionario!: string;
    nmFuncionario!: string;
    departamento!: string;
    orcamento!: number;
}
