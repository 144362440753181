import { BaseEntity } from "../BaseEntity";
import { Documento } from "../suporte/documento";
import { DocumentoFiscal } from "../suporte/documentoFiscal";
import { Endereco } from "../suporte/endereco";

export class Contato extends BaseEntity {

	nome: string;
    email: string;
    telefone: string;
    genero: string;
    observacao: string;
    nascimento: Date;
    profissao: string;
    naturalidade: string;
    nomePai: string;
    nomeMae: string;
    cpfPai: string;
    cpfMae: string;
	endereco: Endereco;
	documento: Documento;
	documentoFiscal: DocumentoFiscal;
}
