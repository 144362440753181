import { BaseEntity } from '../BaseEntity';
import { PagamentoForma } from '../suporte/pagamentoForma';
import { PropostaItem } from '../suporte/propostaItem';
import { ProdutoAnexo } from '../suporte/produtoAnexo';
import { PropostaStatus } from '../suporte/propostaStatus';

export class PropostaComercial extends BaseEntity {
  public numero!: number;
  public dtProposta!: Date;
  public dtProxContato!: Date;
  public natureza!: string;
  public cuidados!: string;
  public descricao!: string;
  public validade!: number;
  public prazo!: string;
  public observacoes!: string;
  public valorTotal!: number;
  public valorDesconto!: number;
  public valorSubTotal!: number;
  public qteParcelas!: number;
  public idVendedor!: string;
  public nmVendedor!: string;
  public idCliente!: string;
  public nmCliente!: string;
  public idUsuario!: string;
  public nmUsuario!: string;
  public anotacoes!: string;
  public propostaStatus!: PropostaStatus;
  public pagamentoForma!: PagamentoForma;
  public propostaItens!: PropostaItem[];
  public anexos!: ProdutoAnexo[];
}
