<div class="bs-dialog-header d-flex justify-content-between">
  DETALHES PEDIDO #{{ ('0000000' + (data?.numeroPedido)).slice(-7) }}
  <span class="pull-right label label-secondary-oco" style="font-size: 10px;">{{data?.pedidoOrigem?.value}}</span>
</div>

<div class="bs-dialog-main">
  <div class="bs-dialog-content">

    <!-- Carregando informações... -->
    <div class="modal-overlay" *ngIf="loading">
      <div class="alert alert-light fade show m-b-0 text-center" style="color: #222;">
        <i class="fas fa-circle-notch fa-spin"></i>
      </div>
    </div>









    <div class="row" *ngIf="!loading">

      <div class="col-xl-8">

        <p-panel>
          <ng-template pTemplate="header">
              <span class="p-panel-title">
                <i class="fa fa-shopping-bag fa-lg m-r-10 text-gray text-opacity-50"></i>
              <span *ngIf="data.totalItens == 1">Produto</span>
              <span *ngIf="data.totalItens > 1">Produtos ({{data.totalItens}})</span>
              </span> 
          </ng-template>

          <div class="keras-scrollbar" style="max-height: 255px; padding: 0 15px;">
            <div class="row align-items-center" *ngFor="let d of data?.pedidoItens; let i = index">
              <div class="col-lg-6 d-flex align-items-center">
                <div class="h-65px w-65px d-flex align-items-center justify-content-center position-relative">
                  <img *ngIf="d.produtoImg != null" [src]="d.produtoImg" class="mw-100 mh-100" />
                  <img *ngIf="d.produtoImg == null" src="../../../assets/images/pos-background.jpeg" class="mw-100 mh-100" />
                </div>
                <div class="ps-3 flex-1">
                  <div class="text-dark">{{d.nomeProduto}}</div>
                  <div *ngIf="d.produtoSku != null" class="text-dark text-opacity-50 small fw-bold">
                    SKU: {{d.produtoSku}}
                  </div>
                </div>
              </div>
              <div class="col-lg-3 m-0 ps-lg-3 text-end">
                R$ {{d.valorUnitario | number:'1.2-2'}} x {{d.quantidade | number:'1.2-5'}}
              </div>
              <div class="col-lg-3 text-dark fw-bold m-0 text-end">
                R$ {{d.valorTotal | number:'1.2-2'}}
              </div>
            </div>
          </div>
        </p-panel>

        <p-panel styleClass="m-t-10">
          <ng-template pTemplate="header">
              <span class="p-panel-title">
                <i class="fa fa-credit-card fa-lg m-r-10 text-gray text-opacity-50"></i> Registro de Pagamento
              </span> 
          </ng-template>
        
          <table class="table table-borderless table-sm fw-bold m-0">
            <tbody>
              <tr>
                <td class="w-150px">Subtotal</td>
                <td *ngIf="data.totalItens == 1">1 item</td>
                <td *ngIf="data.totalItens > 1">{{data.totalItens}} itens</td>
                <td class="text-end">R$ {{data.valorSubTotal | number:'1.2-2'}}</td>
              </tr>
              <tr>
                <td>Desconto</td>
                <td>R$ {{data.valorDesconto | number:'1.2-2'}}</td>
                <td class="text-end">- R$ {{data.valorDesconto | number:'1.2-2'}}</td>
              </tr>
              <tr>
                <td>Taxa de envio</td>
                <td>
                  <!-- promo code: <u class="text-success">FREESHIPPING</u> R$ 120.00 -->
                </td>
                <td class="text-end">R$ 0.00</td>
              </tr>
              <tr>
                <td class="pb-2" colspan="2"><b>Total</b></td>
                <td class="text-end pb-2 text-decoration-underline"><b>R$ {{data.valorTotal | number:'1.2-2'}}</b>
                </td>
              </tr>
              <tr>
                <td colspan="3">
                  <hr class="m-0">
                </td>
              </tr>
              <tr>
                <td class="pt-2 pb-2" nowrap="">
                  Pago pelo cliente
                </td>
                <td class="pt-2 pb-2">
                  via <a href="#" class="text-primary text-decoration-none">
                    {{data.pagamentoForma.value}} {{data?.pagamentoForma?.code == 'cc' ? ' em (' + data?.qteParcelas +
                    'x)' : ''}}
                  </a>
                  <!-- (#IRU9589320) -->
                </td>
                <td class="pt-2 pb-2 text-end">R$ {{data.valorTotal | number:'1.2-2'}}</td>
              </tr>
            </tbody>
          </table>
        </p-panel>
        
      </div>


      <div class="col-4">
        <div class="panel-header-with-icon">
          <p-panel header="Anotações">
            <ng-template pTemplate="icons">
              <button *ngIf="editarAnotacaoPedido" class="p-panel-header-icon p-link" pTooltip="Salvar" tooltipPosition="bottom">
                  <span class="pi pi-save"></span>
              </button>
              <button class="p-panel-header-icon p-link" (click)="habilitarAnotacaoPedido(true)" pTooltip="Editar" tooltipPosition="bottom">
                  <span class="pi pi-user-edit"></span>
              </button>
            </ng-template>

            <div class="keras-scrollbar" *ngIf="data?.anotacoes && !editarAnotacaoPedido">
              {{data.anotacoes}}
            </div>
            <div class="keras-scrollbar" *ngIf="!data?.anotacoes && !editarAnotacaoPedido">
              Nenhuma anotação para este pedido.
            </div>
            <div class="keras-scrollbar" *ngIf="editarAnotacaoPedido">
              <textarea (blur)="salvarAnotacaoPedido($event)" [(ngModel)]="data.anotacoes" blur rows="3" class="form-control"></textarea>
            </div>
          </p-panel>
        </div>

        <p-panel header="Cliente" styleClass="m-t-10">
          <div *ngIf="data?.idCliente">
            <i class="fa fa-address-card fa-fw mr-2"></i> <a (click)="atualizarStatusCliente()" href="javascript:;"
              pTooltip="Carregar mais informações" tooltipPosition="bottom">{{data.nmCliente}}</a>
            <div *ngIf="cliente" class="d-flex flex-column">

              <div class="d-flex align-items-center m-b-10 m-t-10">
                <i class="fa fa-phone fa-fw mr-2"></i>
                <span>{{cliente.telefone}}</span>
              </div>
              <div class="d-flex align-items-center m-b-5">
                <span><a href="mailto:{{cliente.email}}" target="_blank">{{cliente.email}}</a></span>
              </div>
              <div class="d-flex align-items-center m-b-5">
                <span>{{cliente.endereco?.cidade}} - {{cliente.endereco?.estado}}</span>
              </div>
              <div class="d-flex align-items-center m-b-5">
                <span class="text-uppercase">{{cliente.documento?.tipo}} - {{cliente.documento?.valor}}</span>
              </div>
              <div class="d-flex align-items-center m-b-5">
                <span>{{cliente.observacao}}</span>
              </div>
            </div>
          </div>
          <div *ngIf="!data?.idCliente">
            Cliente não informado
          </div>
        </p-panel>

        <p-panel header="Informações de Entrega" styleClass="m-t-10">
          <i class="fa fa-phone fa-fw"></i> +916-663-4289<br><br>
            867 Highland View Drive<br>
            Newcastle, CA<br>
            California<br>
            95658<br>
        </p-panel>

        <p-panel header="Vendedor" styleClass="m-t-10">
          <div *ngIf="data?.idVendedor">
            <i class="fa fa-briefcase fa-fw mr-2"></i> <a (click)="atualizarStatusVendedor()" href="javascript:;"  pTooltip="Carregar mais informações" tooltipPosition="bottom">{{data.nmVendedor}}</a>
            
            <div *ngIf="vendedor" class="d-flex flex-column">
              <div class="d-flex align-items-center m-b-10 m-t-10">
                <i class="fa fa-phone fa-fw mr-2"></i> <span>{{vendedor.telefone}}</span>
              </div>
              <div class="d-flex align-items-center m-b-5">
                <span><a href="mailto:{{cliente.email}}" target="_blank">{{vendedor.email}}</a></span>
              </div>
              <div class="d-flex align-items-center m-b-5">
                <span>{{vendedor.endereco?.cidade}} - {{vendedor.endereco?.estado}}</span>
              </div>
            </div>
          </div>

          <div *ngIf="!data?.idVendedor">
            Vendedor não informado
          </div>
        </p-panel>
      </div>
    </div>










  </div>
</div>

<div class="bs-dialog-footer">
  <div class="bs-footer-action-close">
    <button (click)="close()" class="btn btn-white" pTooltip="Esc" tooltipPosition="bottom">Fechar</button>
  </div>
  <div class="bs-footer-action">
    <button (click)="openFaturaDialog()" [disabled]="recording" class="btn btn-primary">Imprimir Fatura</button>
  </div>
</div>