export class ContratoStatus {

	public static ATIVO: ContratoStatus = new ContratoStatus("ativo", "Ativo");
    public static INATIVO: ContratoStatus = new ContratoStatus("inativo", "Inativo");
    public static BAIXADO: ContratoStatus = new ContratoStatus("baixado", "Baixado");
    public static ENCERRADO: ContratoStatus = new ContratoStatus("encerrado", "Encerrado");

    public code: string;
    public value: string;

    constructor(code: string, value: string) {
        this.code = code;
        this.value = value;
    }
}
