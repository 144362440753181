<div class="bs-dialog-header">
  <i class="fa fa-file-invoice"></i> TÍTULOS A RECEBER <small> Gestão de Títulos a Receber </small>
</div>

<div class="bs-dialog-main">
  <div class="bs-dialog-content">

    <!-- Carregando informações... -->
    <div class="modal-overlay" *ngIf="loading">
      <div class="alert alert-light fade show m-b-0 text-center" style="color: #222;">
        <i class="fas fa-circle-notch fa-spin"></i> 
      </div>
    </div>

    <div class="be-form">

      <div class="row">
        <div class="col-3">
          <label>Status Pagamento:</label>
          <select [(ngModel)]="data.statusRecebimento.code" class="form-control">
            <option value="pendente">Pendente</option>
            <option value="autorizado">Autorizado</option>
            <option value="processando">Processando</option>
            <option value="aprovado">Aprovado</option>
            <option value="recusado">Recusado</option>
            <option value="estornado">Estornado</option>
            <option value="concluido">Concluído</option>
            <option value="falha">Falha</option>
          </select>
        </div>
        <div class="col-3">
          <label>Número Documento:</label>
          <p-inputNumber [(ngModel)]="data.numeroTitulo" mode="decimal" [minFractionDigits]="0" [maxFractionDigits]="0" maxlength="17"></p-inputNumber>
        </div>
      </div>

      <div class="row">
        <div class="col-6">
          <label>Descrição:</label>
          <input type="text" pInputText [(ngModel)]="data.identificador" class="form-control">
        </div>
      </div>

      <div class="row">
        <div class="col-6">
          <label>Cliente:</label>
          <div class="p-inputgroup">
            <p-autoComplete [(ngModel)]="cliente" (onSelect)="onSelectCliente()" field="nome" [suggestions]="autocompleteCliente" (completeMethod)="acFindCliente($event)" autoHighlight="true" forceSelection="true" emptyMessage="Cliente não encontrado" class="be-autocomplete width-full">
              <ng-template let-cliente pTemplate="item">
                {{cliente.nome}}
              </ng-template>
            </p-autoComplete>
            <button (click)="addCliente()" type="button" pButton  pTooltip="Adicionar Novo Cliente" tooltipPosition="bottom" icon="pi pi-plus-circle"></button>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-6">
          <label>Categoria:</label>
          <div class="p-inputgroup">
            <p-autoComplete [(ngModel)]="categoria" (onSelect)="onSelectCategoria()" field="descricao" [suggestions]="autocompleteCategoria" (completeMethod)="acFindCategoria($event)" autoHighlight="true" forceSelection="true" emptyMessage="Categoria não encontrada" class="be-autocomplete width-full">
              <ng-template let-categoria pTemplate="item">
                {{categoria.arvoreCategoriaTexto ? categoria.arvoreCategoriaTexto + ' > ' : ''}} {{categoria.descricao}}
              </ng-template>
            </p-autoComplete>
            <button (click)="addCategoria()" type="button" pButton  pTooltip="Adicionar Nova Categoria" tooltipPosition="bottom"icon="pi pi-plus-circle"></button>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <label>Centro de Custo:</label>
          <div class="p-inputgroup">
            <p-autoComplete [(ngModel)]="centroCusto" (onSelect)="onSelectCentroCusto()" field="nome" [suggestions]="autocompleteCentroCusto" (completeMethod)="acFindCentroCusto($event)" autoHighlight="true" forceSelection="true" emptyMessage="Centro de Custo não encontrado" class="be-autocomplete width-full">
              <ng-template let-centroCusto pTemplate="item">
                {{centroCusto.nome}}
              </ng-template>
            </p-autoComplete>
            <button (click)="addCentroCusto()" type="button" pButton  pTooltip="Adicionar Novo Centro de Custo" tooltipPosition="bottom"icon="pi pi-plus-circle"></button>
          </div>
        </div>
      </div>

      <div class="row">
       
        <div class="col-3">
          <label>Data Vencimento:</label>
          <input type="date" [(ngModel)]="data.dtVencimento" class="form-control" pattern="\d{4}-\d{2}-\d{2}" placeholder="dd/MM/yyyy">
        </div>
        <div class="col-3">
          <label>Data Pagamento:</label>
          <input type="date" [(ngModel)]="data.dtPagamento" class="form-control" pattern="\d{4}-\d{2}-\d{2}" placeholder="dd/MM/yyyy">
        </div>
      </div>

      <div class="row">
        <div class="col-3">
          <label>Valor Título:</label>
          <div class="p-inputgroup">
            <span class="p-inputgroup-addon">R$</span>
            <p-inputNumber [(ngModel)]="data.valorTitulo" mode="decimal" locale="pt-BR" [minFractionDigits]="2" [maxFractionDigits]="2" maxlength="17"></p-inputNumber>
          </div>
        </div>
        <div class="col-3">
          <label>Valor Desconto:</label>
          <div class="p-inputgroup">
            <span class="p-inputgroup-addon">R$</span>
            <p-inputNumber [(ngModel)]="data.valorDesconto" mode="decimal" locale="pt-BR" [minFractionDigits]="2" [maxFractionDigits]="2" maxlength="17"></p-inputNumber>
          </div>
        </div>
        <div class="col-3">
          <label>Valor Multa:</label>
          <div class="p-inputgroup">
            <span class="p-inputgroup-addon">R$</span>
            <p-inputNumber [(ngModel)]="data.valorMulta" mode="decimal" locale="pt-BR" [minFractionDigits]="2" [maxFractionDigits]="2" maxlength="17"></p-inputNumber>
          </div>
        </div>
        <div class="col-3">
          <label>Valor Total:</label>
          <div class="p-inputgroup">
            <span class="p-inputgroup-addon">R$</span>
            <p-inputNumber [(ngModel)]="data.valorTotal" mode="decimal" locale="pt-BR" [minFractionDigits]="2" [maxFractionDigits]="2" maxlength="17"></p-inputNumber>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <label>Observações:</label>
          <textarea [(ngModel)]="data.observacoes" rows="3" class="form-control" placeholder="Observações"></textarea>
        </div>
      </div>

    </div>    
  </div>
</div>

<div class="bs-dialog-footer">
  <div class="bs-footer-action-close">
    <button (click)="close()" class="btn btn-white" pTooltip="Esc" tooltipPosition="bottom">Fechar</button>
  </div>
  <div class="bs-footer-action">
    <button (click)="save()" [disabled]="recording" class="btn btn-white" pTooltip="Ctrl + Enter" tooltipPosition="bottom">Salvar e criar outro</button>
    <button (click)="saveClose()" [disabled]="recording" class="btn btn-primary" pTooltip="Ctrl + S" tooltipPosition="bottom">Salvar</button>
  </div>
</div>