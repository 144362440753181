export class PagamentoStatus {

	static PENDENTE: PagamentoStatus = new PagamentoStatus("pendente", "Pendente");
    static AUTORIZADO: PagamentoStatus = new PagamentoStatus("autorizado", "Autorizado");
    static PROCESSANDO: PagamentoStatus = new PagamentoStatus("processando", "Processando");
    static APROVADO: PagamentoStatus = new PagamentoStatus("aprovado", "Aprovado");
    static RECUSADO: PagamentoStatus = new PagamentoStatus("recusado", "Recusado");
    static ESTORNADO: PagamentoStatus = new PagamentoStatus("estornado", "Estornado");
    static CONCLUIDO: PagamentoStatus = new PagamentoStatus("concluido", "Concluído");
    static FALHA: PagamentoStatus = new PagamentoStatus("falha", "Falha");

    public code: string;
    public value: string;

    constructor(code: string, value: string) {
        this.code = code;
        this.value = value;
    }
}
