import { HttpClient } from '@angular/common/http';
import { Component, HostListener, OnInit } from '@angular/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { CalendarOptions, EventClickArg } from '@fullcalendar/core';
import { AgendaEventService } from './agenda.event.service';
import { ToastrService } from 'ngx-toastr';
import { BaseService } from 'src/app/_services/base.service';
import { LayoutPrivateComponent } from 'src/app/suporte/layout-private/layout-private.component';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng-lts/dynamicdialog';
import { TranslateService } from '@ngx-translate/core';
import { BaseServiceDialog } from 'src/app/_services/base.service.dialog';
import { Agenda } from 'src/app/_entity/home/agenda';
import { AuthenticationService } from 'src/app/_services';
import { environment } from '@environments/environment';
import { ConfirmationComponent } from 'src/app/suporte/confirmation/confirmation.component';
import { Confirmation } from 'src/app/_entity/confirmation';

@Component({
  selector: 'app-agenda',
  templateUrl: './agenda.component.html',
  styleUrls: ['./agenda.component.css'],
  providers: [DialogService]
})
export class AgendaComponent extends BaseService implements OnInit {

  public filter: string = null;
  public showWeekends: boolean = true;

  public events: any[];
  public calendarOptions: CalendarOptions;

  private start: string;
  private end: string;
  private clickCount: number = 0;

  constructor(
    public app: LayoutPrivateComponent,
    public http: HttpClient,
    public dialog: DialogService,
    public toastr: ToastrService,
    public translate: TranslateService,
    private eventService: AgendaEventService,
    private authenticationService: AuthenticationService) {

    super('agenda', app, http, dialog, toastr, translate);
  }
    
  ngOnInit(): void {
    this.updateCalendarOptions();
  }

  public toggleWeekends(): void {
    this.updateCalendarOptions();
  }
  public updateCalendarOptions(): void {
    this.calendarOptions = {
      plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
      locale: 'pt-br',
      timeZone: 'America/Sao_Paulo',
      headerToolbar: {
        left: 'prev,next,today',
        center: 'title',
        right: 'dayGridMonth,timeGridWeek,timeGridDay'
      },
      buttonText: {
        today:    'Hoje',
        month:    'Mês',
        week:     'Semana',
        day:      'Dia',
        list:     'Lista'
      },
      themeSystem: 'bootstrap',
      editable: true,
      weekends: this.showWeekends, // Oculta os fins de semana
      // eventClick: this.handleEventClick.bind(this),

      eventClick: (arg: EventClickArg) => {
        // let clickCount = 0;
        let singleClickTimer: any = null;
    
        arg.jsEvent.stopPropagation(); // Prevent bubbling to parent elements
    
        this.clickCount++;
    
        if (this.clickCount === 1) {
          singleClickTimer = setTimeout(() => {
            if (this.clickCount === 1) {
              // console.log('Single click on event:', arg.event.title);
              // arg.event.setProp('backgroudColor', 'red');
            }
            this.clickCount = 0; // Reset click count regardless of single or double click
          }, 400); // Adjust timeout as needed
        } else if (this.clickCount === 2) {
          clearTimeout(singleClickTimer);
          // Handle your double click logic here
          this.clickCount = 0; // Reset click count after double click
          this.handleEventClick(arg.event);
        }
      },

      dateClick: this.handleDateClick.bind(this),
      eventDrop: this.handleEventDrop.bind(this),
      eventResize: this.handleEventDrop.bind(this),
      events: (info, successCallback, failureCallback) => {
        // Fetch events from your service or API
        this.eventService.getEvents(info.startStr, info.endStr).subscribe(
          events => {
            this.end = info.endStr;
            this.start = info.startStr;
            successCallback(events);
          },
          error => {
            this.toastr.error('Não foi possível carregar os eventos do calendário');
            failureCallback(error);
          }
        );
      }
    };
  }

  public filterEvent(cat: string): void {
    this.filter = cat;
    this.eventService.getEvents(this.start, this.end, cat).subscribe(
      events => {
        this.events = events;
      },
      error => {
        this.toastr.error('Não foi possível carregar os eventos do calendário');
      }
    );
  }

  handleEventDrop(eventDropInfo: any) {
    // Implement logic to handle event drop
    const droppedEvent = eventDropInfo.event;
    const newStartDate = droppedEvent.start;
    const newEndDate = droppedEvent.end;

    let data: any = new Object;
    
    data._id = droppedEvent._def.extendedProps._id;
    data.account = droppedEvent._def.extendedProps.account;
    data.status = droppedEvent._def.extendedProps.status;
    data.created = droppedEvent._def.extendedProps.created;
    data.descricao = droppedEvent._def.extendedProps.descricao;
    data.title = droppedEvent._def.title;
    data.start = newStartDate;
    data.end = newEndDate;

    this.http.post<any>(`${environment.apiUrl}/${this.mapping}/save`, data).subscribe(
      data => {                    
        this.toastr.success('Evento atualizado com sucesso');
      },
      error => {
        this.toastr.error('Não foi possível atualizar o evento.');
        this.eventService.getEvents(this.start, this.end).subscribe(
          events => {
            this.events = events;
          },
          error => {
            this.toastr.error('Não foi possível carregar os eventos do calendário');
          }
        );
      }
  );
  }

  handleEventClick(arg) {
    // Aqui você pode implementar a lógica para edição ou inclusão do evento
    // Por exemplo, abrir um modal de edição ou inclusão com os detalhes do evento clicado
    let data: any = new Object;
    data.arg = arg;
    data.newEvent = false;

    const dialogRef = this.dialog.open(AgendaDialogComponent, {
      data: data,
      width: '600px',
      height: '390px',
      transitionOptions: '1ms',
      showHeader: false
    });

    dialogRef.onClose.subscribe(result => {
      if (data.reload) {
        this.eventService.getEvents(this.start, this.end).subscribe(
          events => {
            this.events = events;
          },
          error => {
            this.toastr.error('Não foi possível carregar os eventos do calendário');
          }
        );
      }
    });
  }

  handleDateClick(arg) {
    // Aqui você pode implementar a lógica para inserir um novo evento na data clicada
    // Por exemplo, abrir um modal de inclusão de evento com a data clicada
    let data: any = new Object;
    data.arg = arg;
    data.newEvent = true;

    const dialogRef = this.dialog.open(AgendaDialogComponent, {
      data: data,
      width: '600px',
      height: '390px',
      transitionOptions: '1ms',
      showHeader: false
    });

    dialogRef.onClose.subscribe(result => {
      if (data.reload) {
        this.eventService.getEvents(this.start, this.end).subscribe(
          events => {
            this.events = events;
          },
          error => {
            this.toastr.error('Não foi possível carregar os eventos do calendário');
          }
        );
      }
    });
  }

}

@Component({
  selector: 'app-agenda-dialog',
  templateUrl: 'agenda.dialog.component.html',
})
export class AgendaDialogComponent extends BaseServiceDialog implements OnInit {
  
  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public http: HttpClient,
    public toastr: ToastrService,
    public translate: TranslateService,
    public dialog: DialogService) {

    super('agenda', http, toastr, translate, ref, config.data);

    this.datas = config.data;
  }

  ngOnInit(): void {
    // Caso seja uma iniciativa ja criada para edicao, busca a ultima
    // versao no banco de dados e apos inicializa os autocompletes

    if (!this.datas.newEvent) {
      this.data = new Agenda;
      this.data.status = new Object;
      this.data.start = new Date;
      this.data.end = new Date;

      this.loading = true;
      this.http.get<any>(`${environment.apiUrl}/${this.mapping}/find/${this.datas.arg._def.extendedProps._id}`).subscribe(
          data => {
              this.data.origem = data.origem;
              this.data.origemId = data.origemId;
              this.data.userId = data.userId;
              this.data._id = data._id;
              this.data.account = data.account;
              this.data.status.code = data.status.code;
              this.data.status.value = data.status.value;
              this.data.created = data.created;
              this.data.descricao = data.descricao;
              this.data.title = data.title;
              this.data.start = new Date(data.start);
              this.data.end = new Date(data.end);
              this.data.strstart = formatDate(this.data.start);
              this.data.hrstart = formatTimeAddHour(this.data.start, 0);
              this.data.strend = formatDate(this.data.end);
              this.data.hrend = formatTimeAddHour(this.data.end, 0);
              
              this.loading = false;
          },
          error => {
              this.loading = false;
              this.toastr.error('Não foi possível carregar o registro');
          }
      );
    }
    // Caso seja uma nova apenas inicializa os autocompletes
    else {
      this.createData();
    }
  }

  public beforeSave(callback: (valid: boolean) => void): void {

    if (!this.datas.newEvent && this.data.origem != 'private') {
      this.toastr.error('Evento não pode ser editado.');
      callback(false);
      return;
    }

    this.data.start = new Date(this.data.strstart + ' ' + this.data.hrstart);
    // this.data.start.setHours(this.data.start.getHours() - 3);
    
    this.data.end = new Date(this.data.strend + ' ' + this.data.hrend);
    // this.data.end.setHours(this.data.end.getHours() - 3);
    

    if (!this.data || !this.data.title) {
      this.toastr.error('Campo Nome do Evento deve ser preenchido.');
      callback(false);
      return;
    }
    else if (!this.data.start) {
      this.toastr.error('Início do Evento deve ser preenchido.');
      callback(false);
      return;
    }
    else {
      callback(true);
    }
  }

  public delete(): void {

    const confirmation = this.dialog.open(ConfirmationComponent, {
        width: '400px',
        transitionOptions: '1ms',
        showHeader: false,
        data: new Confirmation('Confirmação', 'Confirma a exclusão do evento ' + this.data.title + '?')
    });

    confirmation.onClose.subscribe(dialogResult => {
        if (dialogResult) {
          try {
            this.http.delete<any>(`${environment.apiUrl}/${this.mapping}/delete/` + this.data._id).subscribe(
                data => {
                  this.toastr.success('Evento removido com sucesso.');
                  this.dialogRef.close();
                },
                error => {
                    this.toastr.error(error);
                }
            );
        }
        catch (e) {
            this.toastr.error('', e.message);
        }
      }
    });
  }

  public validateEndDate(): void {
    if (!this.data.strstart || this.data.strstart == '') {
      this.data.strstart = formatDate(new Date);
    }
    if (!this.data.hrstart || this.data.hrstart == '') {
      this.data.hrstart = '00:00';
    }
    if (!this.data.strend || this.data.strend == '') {
      this.data.strend = formatDate(new Date(this.data.strstart));
    }
    if (!this.data.hrend || this.data.hrend == '') {
      this.data.hrstart = '00:00';
    }

    let s = new Date(this.data.strstart + ' ' + this.data.hrstart);
    let e = new Date(this.data.strend + ' ' + this.data.hrend);

    if (e <= s) {
      this.data.strend = formatDate(s);
      this.data.hrend = formatTimeAddHour(s, 1);
    }
  }

  public createData(): void {
    if (this.datas.newEvent) {
      this.data = new Agenda;
      // this.data.start = this.datas.arg.date;
      // this.data.start.setHours(this.data.start.getHours() + 3);

      this.data.strstart = formatDate(this.datas.arg.date);
      this.data.hrstart = formatTime(this.datas.arg.date);

      this.data.strend = formatDate(this.datas.arg.date);
      this.data.hrend = formatTimeAddHour(this.datas.arg.date, 1);
    }
  }

  @HostListener('window:keydown.control.enter', ['$event'])
  keSave(event: KeyboardEvent) {
    if (this.datas.newEvent) {
      this.save();
    }
  }

  @HostListener('window:keydown.control.s', ['$event'])
  keSaveClose(event: KeyboardEvent) {
    this.saveClose();
  }

  @HostListener('window:keydown.esc', ['$event'])
  keClose(event: KeyboardEvent) {
    this.dialogRef.close();
  }
}

function formatDate(date: Date): string {
  date.setHours(date.getHours() + 3);
  // Obtém os componentes de data (ano, mês e dia)
  const year = date.getFullYear();
  const month = ('0' + (date.getMonth() + 1)).slice(-2); // Adiciona um zero à esquerda se o mês for menor que 10
  const day = ('0' + date.getDate()).slice(-2); // Adiciona um zero à esquerda se o dia for menor que 10

  // Retorna a data formatada no formato "yyyy-MM-dd"
  return `${year}-${month}-${day}`;
}

function formatTime(date: Date): string {
  // Obtém as horas e os minutos
  const hours = ('0' + date.getHours()).slice(-2); // Adiciona um zero à esquerda se for menor que 10
  const minutes = ('0' + date.getMinutes()).slice(-2); // Adiciona um zero à esquerda se for menor que 10
  
  // Retorna a hora formatada no formato "HH:mm"
  return `${hours}:${minutes}`;
}

function formatTimeAddHour(date: Date, plus: number): string {
  date.setHours(date.getHours() + plus - 3);
  // Obtém as horas e os minutos
  const hours = ('0' + date.getHours()).slice(-2); // Adiciona um zero à esquerda se for menor que 10
  const minutes = ('0' + date.getMinutes()).slice(-2); // Adiciona um zero à esquerda se for menor que 10

  // Retorna a hora formatada no formato "HH:mm"
  return `${hours}:${minutes}`;
}