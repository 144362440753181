import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { LayoutPublicComponent } from '../../suporte/layout-public/layout-public.component';
import { AuthenticationService, UserService } from '../../_services';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng-lts/dynamicdialog';

@Component({
  selector: 'app-cadastro',
  templateUrl: './cadastro.component.html',
  styleUrls: ['./cadastro.component.css'],
  providers: [DialogService]
})
export class CadastroComponent implements OnInit {

  registerForm: FormGroup;
  error = false;
  loading = false;
  submitted = false;
  time = new Date;
  errorMsg = '';

  public constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private userService: UserService,
    private app: LayoutPublicComponent,
    public dialogService: DialogService,
    private authenticationService: AuthenticationService) {

    // redirect to home if already logged in
    if (this.authenticationService.currentUserValue) {
      this.router.navigate(['/dashboard']);
    }
  }

  ngAfterViewInit() {
    this.app.setTitle('Criar ID BeSales | BeSales');
  }

  ngOnInit() {
    this.registerForm = this.formBuilder.group({
      nome: ['', Validators.required],
      email: ['', Validators.required],
      telefone: ['', Validators.required],
      password: ['', [Validators.required, Validators.minLength(6)]],
      repassword: ['', [Validators.required]]
    });
  }

  // convenience getter for easy access to form fields
  get f() { return this.registerForm.controls; }

  onSubmit() {

    this.error = false;
    this.errorMsg = '';
    this.submitted = true;

    // stop here if form is invalid
    if (this.registerForm.invalid) {
      return;
    }

    if (this.f.password.value != this.f.repassword.value) {
      this.error = true;
      this.errorMsg = 'Confirmação de senha não confere';
      return;
    }

    this.loading = true;
    
    this.userService.register(this.registerForm.value)
      .pipe(first())
      .subscribe(
        data => {
          this.authenticationService.register(data);
          this.router.navigate(['/configuracao']);
        },
        error => {
          this.errorMsg = error;
          this.loading = false;
          this.error = true;      
        });
  }

  
  openDialog(): void {
    const ref = this.dialogService.open(CadastroComponentDialog, {
      width: '850px',
      height: '80%',
      transitionOptions: '1ms',
      showHeader: false
    });
  }
}

@Component({
  selector: 'app-cadastro-dialog',
  templateUrl: 'cadastro.dialog.component.html',
})
export class CadastroComponentDialog {
  constructor(public ref: DynamicDialogRef, public config: DynamicDialogConfig) {
  }

  public close(): void {
    this.ref.close();
  }
}