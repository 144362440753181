import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { DialogService } from 'primeng-lts/dynamicdialog';
import { AuthenticationService } from 'src/app/_services';
import { BaseService } from 'src/app/_services/base.service';
import { LayoutPrivateComponent } from 'src/app/suporte/layout-private/layout-private.component';
import { ConcorrenteDialogComponent } from '../configuracao/concorrente.dialog.component';
import { environment } from '@environments/environment';
import { ConcorrenteDetalheDialogComponent } from './detalhe.dialog.component';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  providers: [DialogService]
})
export class ConcorrenteHomeComponent extends BaseService implements OnInit {

  public activeIndex: number = 0;
  public chtTotalVendas: any;
  public chtTicketMedio: any;
  public chtPedidoPorOrigem: any;
  public chtTop10Produtos: any;
  public chtHorarioVendas: any;
  public chtVendaDevolucao: any;

  constructor(
    public app: LayoutPrivateComponent,
    public http: HttpClient,
    public dialog: DialogService,
    public toastr: ToastrService,
    public translate: TranslateService,
    private authenticationService: AuthenticationService) {

    super('integracao/mercadolibre/concorrente', app, http, dialog, toastr, translate);

    this.chtTotalVendas = {
      labels: this.getDaysOfLastWeek(),
      datasets: [
        {
          label: '',
          backgroundColor: '#00a1e0',
          borderColor: '#018dc4',
          borderWidth: 1,
          data: [65321.43, 59213.12, 80032.56, 81000.34, 56098.78, 55000.00, 40002.22]
        }
      ]
    }

    this.chtTicketMedio = {
      labels: this.getDaysOfLastWeek(),
      datasets: [
        {
          label: '',
          backgroundColor: '#00a1e0',
          borderColor: '#018dc4',
          borderWidth: 1,
          data: [21.43, 13.12, 32.56, 20.34, 98.78, 50.00, 42.22]
        }
      ]
    }
    this.chtPedidoPorOrigem = {
      labels: this.getDaysOfLastWeek(),
      datasets: [
        {
          label: '',
          backgroundColor: '#00a1e0',
          borderColor: '#018dc4',
          borderWidth: 1,
          data: [21.43, 13.12, 32.56, 20.34, 98.78, 50.00, 42.22]
        }
      ]
    }
    this.chtTop10Produtos = {
      labels: ['Produto 01', 'Produto 02', 'Produto 03', 'Produto 04', 'Produto 05', 'Produto 06', 'Produto 07', 'Produto 08', 'Produto 09', 'Produto 10'],
      datasets: [
        {
          label: '',     
          backgroundColor: '#00a1e0',
          borderColor: '#018dc4',     
          borderWidth: 1,
          data: [21.43, 13.12, 32.56, 20.34, 98.78, 50.00, 42.22, 23, 33, 43]
        }
      ]
    }
    this.chtHorarioVendas = {
      labels: ['00:00', '01:00', '02:00', '03:00', '04:00', '05:00', '06:00', '07:00', '08:00', '09:00', '10:00', '11:00', '12:00',
             '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00'],
      datasets: [{
          label: 'Vendas por hora',
          data: [12, 8, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 55, 50, 45, 40, 35, 30, 25, 20, 15, 10],
          backgroundColor: '#00a1e0',
          borderColor: '#018dc4',
          borderWidth: 1 // Largura da borda das barras
      }]
    }
    this.chtVendaDevolucao = {
      labels: this.getDaysOfLastWeek(),
      datasets: [
        {
          label: 'Vendas',
          backgroundColor: '#00a1e0',
          borderColor: '#018dc4',
          borderWidth: 1,
          data: [21, 13, 32, 20, 98, 50, 42]
        },
        {
          label: 'Devoluções',
          backgroundColor: '#FF6384',
          borderColor: '#FF6384',
          borderWidth: 1,
          data: [3, 1, 6, 4, 8, 20, 12]
        }
      ]
    }
  }

  ngOnInit(): void {
    this.find();
    this.dialogClass = ConcorrenteDialogComponent;
    this.authService = this.authenticationService;

    this.dialogwidth = '800px';
    this.dialogheight = '480px';
    
    this.buildFilterItens();
  }

  public openDialogDetail(data: any): void {
    const dialogRef = this.dialog.open(ConcorrenteDetalheDialogComponent, {
        data: data,
        width: '70%',
        height: '600px',
        transitionOptions: '1ms',
        showHeader: false
    });

    dialogRef.onClose.subscribe((result: any) => {
        
    });
  }

  // public afterFind(): void {
  //   let index: number = 0;

  //   if (this.data != null && this.data.result != null) {
  //     for (let seller of this.data.result) {
  //       this.http.get<any>(`${environment.apiUrl}/${this.mapping}/listar-detalhes/${seller.id}`).subscribe(
  //         data => {
  //             if (data != null && data.seller != null) {
  //               this.data.result[index] = data.seller;
  //               console.log(seller);
  //             }
  //         },
  //         error => {
  //             this.toastr.error('Não foi possível carregar o registro');
  //         }
  //       );
  //       index++;
  //     }
  //   }
  // }

  public barChartOptions = {
    legend: {
      display: false
    },
    tooltips: {
      callbacks: {
        label: function(tooltipItem: any, data: any) {
          let value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
          return 'R$ ' + value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
        }
      }
    },
    scales: {
      xAxes: [{
        gridLines: {
          display: false
        }
      }],
      yAxes: [{
        gridLines: {
          display: true
        },
        ticks: {
          beginAtZero: true,
          callback: function(value: number) {
            return formatCurrency(value);
          }
        }
      }]
    }
  };

  public getDaysOfLastWeek(): string[] {
    const daysOfWeek: string[] = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'];
    const today = new Date();
    // Dia da semana atual (0 = Domingo, 1 = Segunda-feira, ..., 6 = Sábado)
    const dayOfWeek = today.getDay(); 

    const daysOfLastWeek: string[] = [];

    // Adiciona os dias da semana anterior ao array, começando pelo dia seguinte ao atual
    for (let i = 1; i <= 7; i++) {
      // Calcula o índice do dia da semana seguinte
      const index = (dayOfWeek + i) % 7; 
      daysOfLastWeek.push(daysOfWeek[index]);
    }

    return daysOfLastWeek;
  }
}

function formatCurrency(value: number): string {
  return 'R$ ' + value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
}

function randomColor() {
  return '#' + Math.floor(Math.random() * 16777215).toString(16);
}
