import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-responder',
  templateUrl: './responder.component.html',
  styleUrls: ['./responder.component.css']
})
export class ResponderComponent implements OnInit {

  public activeIndex: number = 0;

  constructor() { }

  ngOnInit(): void {
  }

}
