<div class="bs-dialog-header">
  <i class="fa fa-tools"></i> ORDEM DE SERVIÇO <small> Gestão de Ordens de Serviço </small>
</div>

<div class="bs-dialog-main">
  <div class="bs-dialog-content">

    <!-- Carregando informações... -->
    <div class="modal-overlay" *ngIf="loading">
      <div class="alert alert-light fade show m-b-0 text-center" style="color: #222;">
        <i class="fas fa-circle-notch fa-spin"></i> 
      </div>
    </div>

    <div class="be-form">

      <div class="row">
        <div class="col-6">
          <label>Descrição:</label>
          <input type="text" pInputText [(ngModel)]="data.descricao" class="form-control">
        </div>
        <div class="col-3">
          <label>Status:</label>
          <select [(ngModel)]="data.ordemStatus.code" class="form-control">
            <option value="aberto">Aberto</option>
            <option value="orcada">Orçada</option>
            <option value="aprovado">Aprovado</option>
            <option value="reprovado">Reprovado</option>
            <option value="em_andamento">Em Andamento</option>
            <option value="cancelada">Cancelada</option>
            <option value="concluido">Concluído</option>
          </select>
        </div>
      </div>

      <div class="row">
        <div class="col-6">
          <label>Cliente:</label>
          <p-autoComplete [(ngModel)]="contato" (onSelect)="onInputClienteSelect()" field="nome" [suggestions]="autocompleteCliente" (completeMethod)="acFind($event)" autoHighlight="true" forceSelection="true" emptyMessage="Cliente não encontrado" class="be-autocomplete">
            <ng-template let-contato pTemplate="item">
              {{contato.nome}}
            </ng-template>
          </p-autoComplete>
        </div>
      </div>
      
      <div class="row">
        <div class="col-12">
          <label>Observações:</label>
          <textarea [(ngModel)]="data.observacoes" rows="3" class="form-control" placeholder="Observações"></textarea>
        </div>
      </div>

      <div class="row">
        <div class="col-6">
          <label>Vendedor:</label>
          <p-autoComplete [(ngModel)]="vendedor" (onSelect)="onInputVendedorSelect()" field="nome" [suggestions]="autocompleteVendedor" (completeMethod)="acFindVendedor($event)" autoHighlight="true" forceSelection="true" emptyMessage="Vendedor não encontrado" class="be-autocomplete">
            <ng-template let-vendedor pTemplate="item">
              {{vendedor.nome}}
            </ng-template>
          </p-autoComplete>
        </div>
        <div class="col-3">
          <label>Data Início:</label>
          <input type="date" [(ngModel)]="data.dtInicio" class="form-control" pattern="\d{4}-\d{2}-\d{2}" placeholder="dd/MM/yyyy">
        </div>
        <div class="col-3">
          <label>Previsão de Término:</label>
          <input type="date" [(ngModel)]="data.dtPrevisao" class="form-control" pattern="\d{4}-\d{2}-\d{2}" placeholder="dd/MM/yyyy">
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <label>Técnico Responsável:</label>
          <input type="text" pInputText [(ngModel)]="data.nmTecnico" class="form-control">
        </div>
      </div>

      <hr class="m-t-30 m-b-30" />

      <h4>Itens da Ordem de Serviço</h4>

      <div class="m-t-20">
        <table class="table">
          <thead>
            <tr>
              <th>Nº</th>
              <th style="width: 35%;">Item</th>
              <th>Código (SKU)</th>
              <th style="width: 15%;">Quantidade</th>
              <th style="width: 15%;">Preço unitário</th>
              <th style="width: 20%;">Sub Total</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let d of data?.ordemItens; let i = index">
              <td>{{ i + 1 }}</td>
              <td>
                <p-autoComplete *ngIf="!d.nomeItem" [(ngModel)]="servicoOrdemItem" (onSelect)="onInputProdutoSelect(i)" field="nomeItem" [suggestions]="autocompleteProdutoServico" (completeMethod)="acFindProduto($event)" autoHighlight="true" forceSelection="true" emptyMessage="Produto ou Serviço não encontrado" class="be-autocomplete">
                  <ng-template let-item pTemplate="item">
                    {{item.nomeItem}}
                  </ng-template>
                </p-autoComplete>
                <input type="text" *ngIf="d.nomeItem" [disabled]="true" pInputText [(ngModel)]="d.nomeItem" class="form-control">
              </td>
              <td>
                <input type="text" [disabled]="true" pInputText [(ngModel)]="d.sku" class="form-control">
              </td>
              <td>
                <p-inputNumber [(ngModel)]="d.quantidade" (onBlur)="calculaSubTotal(i)" mode="decimal" locale="pt-BR" [minFractionDigits]="2" [maxFractionDigits]="2" maxlength="17"></p-inputNumber>
              </td>
              <td>
                <div class="p-inputgroup">
                  <span class="p-inputgroup-addon">R$</span>
                  <p-inputNumber [disabled]="true" [(ngModel)]="d.valorUnitario" mode="decimal" locale="pt-BR" [minFractionDigits]="2" [maxFractionDigits]="2" maxlength="17"></p-inputNumber>
                </div>
              </td>
              <td>
                <div class="p-inputgroup">
                  <span class="p-inputgroup-addon">R$</span>
                  <p-inputNumber [disabled]="true" [(ngModel)]="d.valorTotal" mode="decimal" locale="pt-BR" [minFractionDigits]="2" [maxFractionDigits]="2" maxlength="17"></p-inputNumber>
                </div>
              </td>
              <td>
                <a (click)="removeItem(i)" pTooltip="Remover Item" tooltipPosition="bottom" class="btn btn-danger btn-icon btn-circle btn-xs"><i class="fa fa-times"></i></a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <button (click)="addItem()" class="btn btn-primary btn-xs m-l-10">
        <i class="fa fa-plus-circle f-s-14"></i> Adicionar Item
      </button>

      <hr class="m-t-30 m-b-30" />

      <h4>Valor final</h4>

      <div class="row m-t-20">
        <div class="col-3">
          <label>Sub Total:</label>
          <div class="p-inputgroup">
            <span class="p-inputgroup-addon">R$</span>
            <p-inputNumber [disabled]="true" [(ngModel)]="data.valorSubTotal" mode="decimal" locale="pt-BR" [minFractionDigits]="2" [maxFractionDigits]="2" maxlength="17"></p-inputNumber>
          </div>
        </div>
        <div class="col-3">
          <label>Desconto:</label>
          <div class="p-inputgroup">
            <span class="p-inputgroup-addon">R$</span>
            <p-inputNumber [(ngModel)]="data.valorDesconto" (onBlur)="calculaTotal()" mode="decimal" locale="pt-BR" [minFractionDigits]="2" [maxFractionDigits]="2" maxlength="17"></p-inputNumber>
          </div>
        </div>
        <div class="col-3">
          <label>Valor total:</label>
          <div class="p-inputgroup">
            <span class="p-inputgroup-addon">R$</span>
            <p-inputNumber [disabled]="true" [(ngModel)]="data.valorTotal" mode="decimal" locale="pt-BR" [minFractionDigits]="2" [maxFractionDigits]="2" maxlength="17"></p-inputNumber>
          </div>
        </div>
      </div>
      
      <hr class="m-t-30 m-b-30" />

      <h4>Anexos</h4>
      
      <div class="alert alert-primary fade show m-b-10 m-t-20">
        Esta opção permite que você adicione arquivos relevantes à sua proposta comercial.
      </div>

      <p-fileUpload 
            name="files" [url]="upload_path_arquivo" (onUpload)="onUploadArquivo($event)" 
            [auto]="true" accept=".pdf,image/*" maxFileSize="1000000" multiple="multiple"
            chooseLabel="Selecionar arquivo">
          
          <ng-template pTemplate="content">
            <label>Arraste seus arquivos aqui.</label>
          </ng-template>

      </p-fileUpload>

      <hr *ngIf="data.anexos?.length" />

      <div class="widget-list widget-list-rounded m-b-30" *ngIf="data.anexos?.length">
        <div class="widget-list-item" *ngFor="let file of data.anexos; let i = index">
          <div class="widget-list-media">
            <i class="far fa-file-pdf"></i>
          </div>
          <div class="widget-list-content">
            <h4 class="widget-list-title">{{file.nome}}</h4>
            <p class="widget-list-desc">{{file.tamanho}} bytes</p>
          </div>
          <div class="widget-list-action">
            <button class="btn btn-link" (click)="removeAnexo(i)"><i class="fa fa-trash-alt f-s-14 m-l-10" pTooltip="Excluir" tooltipPosition="bottom"></i></button>
          </div>
        </div>
      </div>

    </div>    
  </div>
</div>

<div class="bs-dialog-footer">
  <div class="bs-footer-action-close">
    <button (click)="close()" class="btn btn-white" pTooltip="Esc" tooltipPosition="bottom">Fechar</button>
  </div>
  <div class="bs-footer-action">
    <button (click)="save()" [disabled]="recording" class="btn btn-white" pTooltip="Ctrl + Enter" tooltipPosition="bottom">Salvar e criar outro</button>
    <button (click)="saveClose()" [disabled]="recording" class="btn btn-primary" pTooltip="Ctrl + S" tooltipPosition="bottom">Salvar</button>
  </div>
</div>