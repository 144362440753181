import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AgendaEventService {

  constructor(private http: HttpClient) { }

  getEvents(start: string, end: string, categoria?: string): Observable<Event[]> {
    if (categoria == null || categoria == undefined) {
      return this.http.get<Event[]>(`${environment.apiUrl}/agenda/listarEventos`, { params: { start, end } });
    }
    else {
      // Make a GET request to your API endpoint, passing start and end dates as query parameters
      return this.http.get<Event[]>(`${environment.apiUrl}/agenda/listarEventos`, { params: { start, end, categoria } });
    }
  }

  // Método para adicionar um novo evento
  addEvent(event: any): Observable<any> {
    return this.http.post<any>('URL_API_PARA_ADICIONAR_EVENTO', event);
  }

  // Método para atualizar um evento existente
  updateEvent(event: any): Observable<any> {
    return this.http.put<any>('URL_API_PARA_ATUALIZAR_EVENTO', event);
  }

  // Método para excluir um evento
  deleteEvent(eventId: number): Observable<any> {
    return this.http.delete<any>(`URL_API_PARA_EXCLUIR_EVENTO/${eventId}`);
  }
}