import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, NavigationEnd } from '@angular/router';
import { environment } from '@environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from 'src/app/_services';
import { NotificacaoService } from 'src/app/_services/notificacao.service';

declare let gtag: Function;

@Component({
  selector: 'app-layout-private',
  templateUrl: './layout-private.component.html',
  styleUrls: ['./layout-private.component.css']
})
export class LayoutPrivateComponent implements OnInit {

  currentUser: any;
  langs = ['pt', 'es', 'en'];

  public notifNaoLida: number = 0;
  public notificacoes: any[] = [];

  public home: boolean = false;
  public crm: boolean = false;
  public cadastro: boolean = false;
  public venda: boolean = false;
  public servico: boolean = false;
  public pergunta: boolean = false;
  public concorrente: boolean = false;
  public precificacao: boolean = false;
  public financa: boolean = false;
  public configuracao: boolean = false;
  public relatorio: boolean = false;
  public store: boolean = false;
  public marketplace: boolean = false;
  public mercadolivre: boolean = false;

  sidebar = false;
  profile = false;

  public constructor(
    private titleService: Title,
    public router: Router,
    private http: HttpClient,
    private translateService: TranslateService,
    private authenticationService: AuthenticationService,
    private notificacaoService: NotificacaoService) {

    if (localStorage.getItem('lang') != null) {
      if (this.langs.indexOf(localStorage.getItem('lang')) > -1) {
        this.translateService.setDefaultLang(localStorage.getItem('lang'));
      }
    }

    if (this.translateService.defaultLang === undefined) {
      translateService.addLangs(this.langs);
      let browserlang = this.translateService.getBrowserLang();
      if (this.langs.indexOf(browserlang) > -1) {
        this.translateService.setDefaultLang(browserlang);
        localStorage.setItem('lang', browserlang);
      } else {
        this.translateService.setDefaultLang('en');
        localStorage.setItem('lang', 'en');
      }
    }

    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);

    this.router.events.subscribe(event => {

      if (event instanceof NavigationEnd) {
        gtag('config', 'UA-170995864-1',
          {
            'page_path': event.urlAfterRedirects
          }
        );
      }
    });
  }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.menu(this.router.url);
    this.submenu(this.router.url);
    this.submenu2(this.router.url);
    
    if (this.currentUser.status.code != 'pending') {
      this.http.get(`${environment.apiUrl}/mensagem/count`).subscribe(
        (data: number) => {
          this.notifNaoLida = data;
        }
      );

      this.notificacaoService.fetchNotifications().subscribe(
        (notifications: number) => {
          this.notifNaoLida = notifications;        
        },
        error => {
          console.error('Erro ao buscar notificações:', error);
        }
      );
    }
  }

  public last: boolean = false;
  public start: number = 0;
  @ViewChild('scrollableArea') scrollableArea: any;

  public carregarNotificacoes(): void {
    this.last = false;
    this.http.get(`${environment.apiUrl}/mensagem/listar?start=` + this.start).subscribe(
      (data: any[]) => {
        if (data.length > 0) {          
          data.forEach(notificacao => {
            this.notificacoes.push(notificacao);
          });
          this.http.get(`${environment.apiUrl}/mensagem/count`).subscribe(
            (data: number) => {
              this.notifNaoLida = data;
            }
          );
        }
        else {
          this.last = true;
        }
      }
    );
  }

  public abrirNotificacoes(): void {
    this.start = 0;
    this.notificacoes = [];
    this.carregarNotificacoes();
  }

  public carregarMaisNotificacoes(): void {
    this.start = this.start + 20;
    this.carregarNotificacoes();
    this.scrollableArea.nativeElement.scrollTop += 100;
  }

  sidebarToggled() {
    if(this.sidebar)
      this.sidebar = false;
    else 
      this.sidebar = true;
  }

  navProfile() {
    if(this.profile)
      this.profile = false;
    else 
      this.profile = true;
  }

  menu(from: string) {

    if (from.startsWith('/'))
      from = from.substring(1);

    if (from.indexOf('/') > 0)
      from = from.substring(0, from.indexOf('/'));

    this.crm = false;
    this.cadastro = false;
    this.venda = false;
    this.servico = false;
    this.pergunta = false;
    this.home = false;
    this.concorrente = false;
    this.precificacao = false;
    this.financa = false;
    this.configuracao = false;
    this.relatorio = false;
    this.store = false;
    this.marketplace = false;
    this.mercadolivre = false;

    switch (from) {
      case 'marketplace':
        this.marketplace = true;
        break;
      case 'crm':
        this.crm = true;
        break;
      case 'cadastro':
        this.cadastro = true;
        break;
      case 'venda':
        this.venda = true;
        break;
      case 'servico':
        this.servico = true;
        break;
      case 'pergunta':
        this.pergunta = true;
        break;
      case 'home':
        this.home = true;
        break;
      case 'concorrente':
        this.concorrente = true;
        break;
      case 'precificacao':
        this.precificacao = true;
        break;
      case 'financa':
        this.financa = true;
        break;
      case 'configuracao':
        this.configuracao = true;
        break;
      case 'relatorio':
        this.relatorio = true;
        break;
      case 'store':
        this.store = true;
        break;
    }
  }

  submenu(from: string) {
    if (from.startsWith('/')){
      from = from.substring(1);
      from = from.substring(from.indexOf('/'));
      from = from.substring(1);
    }
    
    if (from.indexOf('/') > 0)
      from = from.substring(0, from.indexOf('/'));
    
    this.pergunta = false;
    this.precificacao = false;
    this.mercadolivre = false;

    switch (from) {
      case 'mercadolivre':
        this.mercadolivre = true;
        break;
    }
  }
  
  submenu2(from: string) {
    if (from.startsWith('/')){
      from = from.substring(1);
      from = from.substring(from.indexOf('/'));
      from = from.substring(1);
      from = from.substring(from.indexOf('/'));
      from = from.substring(1);
    }

    if (from.indexOf('/') > 0)
      from = from.substring(0, from.indexOf('/'));

    this.pergunta = false;
    this.precificacao = false;

    switch (from) {
      case 'pergunta':
        this.pergunta = true;
        break;
      case 'precificacao':
        this.precificacao = true;
        break;
    }
  }

  public switchLang(lang: string): void {
    this.translateService.setDefaultLang(lang);
    localStorage.setItem('lang', lang);
  }

  public navTitle: string = '';

  public setTitle(newTitle: string) {
    this.titleService.setTitle(newTitle);
  }

  public setNavTitle(newTitle: string) {
    this.navTitle = newTitle;
  }

  logout() {
    this.authenticationService.logout();
    this.router.navigate(['/login']);
  }

}
