import { BaseEntity } from "../BaseEntity";
import { PagamentoStatus } from "../suporte/pagamentoStatus";

export class TituloReceber extends BaseEntity {

    numeroTitulo!: number;
    identificador!: string;
    idCliente!: string;
    nmCliente!: string;
    dtVencimento!: Date;
    valorTitulo!: number;
    valorDesconto!: number;
    valorMulta!: number;
    valorTotal!: number;
    statusRecebimento!: PagamentoStatus;
    dtPagamento!: Date;
    idCategoria!: string;
    idCentroCusto!: string;
    observacoes!: string;
}
