export class ServicoOrdemStatus {
    public static ABERTO: ServicoOrdemStatus = new ServicoOrdemStatus("aberto", "Aberto");
    public static ORCADA: ServicoOrdemStatus = new ServicoOrdemStatus("orcada", "Orçada");
    public static APROVADO: ServicoOrdemStatus = new ServicoOrdemStatus("aprovado", "Aprovado");
    public static REPROVADO: ServicoOrdemStatus = new ServicoOrdemStatus("reprovado", "Reprovado");
    public static EM_ANDAMENTO: ServicoOrdemStatus = new ServicoOrdemStatus("em_andamento", "Em Andamento");
    public static CANCELADA: ServicoOrdemStatus = new ServicoOrdemStatus("cancelada", "Cancelada");
    public static CONCLUIDO: ServicoOrdemStatus = new ServicoOrdemStatus("concluido", "Concluído");

    public code: string;
    public value: string;

    constructor(code: string, value: string) {
        this.code = code;
        this.value = value;
    }
}
