<div id="content" class="content content-full-width">

    <h1 class="page-header">Informações da minha conta BeSales</h1>
    
    <div class="vertical-box with-grid inbox bg-light">

        <div class="vertical-box-column width-200">
            <div class="vertical-box">

                <div class="wrapper d-none d-sm-none d-md-none d-lg-block">
                    <div class="d-flex align-items-center justify-content-center">
                        Menu
                    </div>
                </div>

                <div class="vertical-box-row d-lg-table-row collapse">
                    <div class="vertical-box-cell">
                        <div class="vertical-box-inner-cell">
                            <div class="keras-scrollbar">
                                <div class="wrapper p-0">
                                    <ul class="nav nav-inbox">
                                        <li [ngClass]="activeIndex == 0 ? 'active' : ''"><a (click)="activeIndex = 0"
                                                href="javascript:;">Geral</a></li>
                                        <li [ngClass]="activeIndex == 1 ? 'active' : ''"><a (click)="activeIndex = 1"
                                                href="javascript:;">Dados de Cobrança</a></li>                                        
                                        <li [ngClass]="activeIndex == 3 ? 'active' : ''"><a (click)="activeIndex = 3"
                                                href="javascript:;">Histórico de Pagamento</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

        <div class="vertical-box-column">
            <div class="vertical-box">
                <div class="vertical-box-row">
                    <div class="vertical-box-cell">
                        <div class="vertical-box-inner-cell bg-white">
                            <div class="keras-scrollbar">
                                <div class="bs-configuracao bs-dashboard">
                                    <div class="col-12">
                                        
                                        <p-tabView [(activeIndex)]="activeIndex">
                                            
                                            
                                            
                                            <p-tabPanel>
                                                
                                                <div *ngIf="data.organizacao.periodoTeste" class="alert alert-warning fade show m-b-30">
                                                    <strong>Importante</strong> você está atualmente no período de teste da plataforma BeSales. Seu período de teste vencerá em <strong>{{data?.organizacao?.vencimentoTeste ? (data?.organizacao?.vencimentoTeste | date:'dd/MM/yyyy') : 'Sem data'}}                                                    </strong>.
                                                </div>

                                                <h4 class="m-b-30">Utilização</h4>
                                                <div class="row">
                                                    <div class="col-xl-4 col-lg-6 col-md-12">
                                                        <div class="widget widget-stats" [class]="getClassForWidget(calcularPorcentagemUtilizacao(utilizacao?.totalSize / 1024 / 1024, plano?.armazDadoMb))">
                                                            <div class="stats-info">
                                                                <h4>ARMAZENAMENTO DE DADOS</h4>
                                                                <p><small>{{ (utilizacao?.totalSize / 1024 / 1024) | number:'1.3-3' }} MB</small> {{calcularPorcentagemUtilizacao(utilizacao?.totalSize / 1024 / 1024, plano?.armazDadoMb)}}% <small>de {{plano?.armazDadoMb | number}} MB</small></p>
                                                            </div>
                                                            <div class="stats-link">
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-xl-4 col-lg-6 col-md-12">
                                                        <div class="widget widget-stats" [class]="getClassForWidget(calcularPorcentagemUtilizacao(utilizacaoArquivo?.totalSize / 1024 / 1024, plano?.armazArquivoMb))">
                                                            <div class="stats-info">
                                                                <h4>ARMAZENAMENTO ARQUIVOS</h4>
                                                                <p><small>{{ (utilizacaoArquivo?.totalSize / 1024 / 1024) | number:'1.3-3' }} MB</small> {{calcularPorcentagemUtilizacao(utilizacaoArquivo?.totalSize / 1024 / 1024, plano?.armazArquivoMb)}}% <small>de {{plano?.armazArquivoMb | number}} MB</small></p>
                                                            </div>
                                                            <div class="stats-link">
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-xl-4 col-lg-6 col-md-12">
                                                        <div class="widget widget-stats" [class]="getClassForWidget(calcularPorcentagemUtilizacao(totalUser, plano?.totalUser))">
                                                            <div class="stats-info">
                                                                <h4>USUÁRIOS</h4>
                                                                <p>{{totalUser}} <small>de {{plano?.detalhes[0].valor}}</small></p>
                                                            </div>
                                                            <div class="stats-link">
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <!-- <div class="col-xl-6 col-md-6">
                                                        <div class="widget widget-stats bg-blue">
                                                            <div class="bs-widget-stats">
                                                                <p-chart type="doughnut" [data]="chart"
                                                                    [options]="chartOptions"></p-chart>
                                                            </div>
                                                            <div class="stats-info">
                                                                <h4>CONCORRENTES MONITORADOS</h4>
                                                                <p>0 <small>de 5 Concorrentes</small></p>
                                                            </div>
                                                            <div class="stats-link">
                                                            </div>
                                                        </div>
                                                    </div> -->
                                                </div>

                                                <h4 class="m-b-30 m-t-30">Meu plano</h4>
                                                <div class="row">

                                                    <div class="col-xl-4 col-md-6">
                                                        <div class="widget widget-stats bg-bs-white">
                                                            <div class="stats-icon"><i class="fa fa-desktop"></i></div>
                                                            <div class="stats-info">
                                                                <h4>PLANO ATUAL</h4>
                                                                <p>{{plano?.nome}}</p>
                                                            </div>
                                                            <div class="stats-link">
                                                                <a [routerLink]="" style="cursor: default;">&nbsp;</a>
                                                            </div>
                                                        </div>
                                                    </div>


                                                    <div class="col-xl-4 col-md-6">
                                                        <div class="widget widget-stats bg-bs-white">
                                                            <div class="stats-icon"><i class="fa fa-link"></i></div>
                                                            <div class="stats-info">
                                                                <h4>VALOR</h4>
                                                                <p *ngIf="data.organizacao.planoAnual">{{plano?.valorAnual | currency:'R$ '}}</p>
                                                                <p *ngIf="!data.organizacao.planoAnual">{{plano?.valorMensal | currency:'R$ '}}</p>
                                                            </div>
                                                            <div class="stats-link">
                                                                <a [routerLink]="" style="cursor: default;">&nbsp;</a>
                                                            </div>
                                                        </div>
                                                    </div>


                                                    <div class="col-xl-4 col-md-6">
                                                        <div class="widget widget-stats bg-bs-white">
                                                            <div class="stats-icon"><i class="fa fa-users"></i></div>
                                                            <div class="stats-info">
                                                                <h4>PERIODICIDADE</h4>
                                                                <p *ngIf="data.organizacao.planoAnual">Anual</p>
                                                                <p *ngIf="!data.organizacao.planoAnual">Mensal</p>
                                                            </div>
                                                            <div class="stats-link">
                                                                <a [routerLink]="" style="cursor: default;">&nbsp;</a>
                                                            </div>
                                                        </div>
                                                    </div>


                                                    <div class="col-xl-4 col-md-6">
                                                        <div class="widget widget-stats bg-bs-white">
                                                            <div class="stats-icon"><i class="fa fa-clock"></i></div>
                                                            <div class="stats-info">
                                                                <h4>SITUAÇÃO</h4>
                                                                <p *ngIf="data.organizacao.periodoTeste">Período de teste</p>
                                                                <p *ngIf="!data.organizacao.periodoTeste">{{data?.organizacao?.status?.value}}</p>
                                                            </div>
                                                            <div class="stats-link">
                                                                <a [routerLink]="" style="cursor: default;">&nbsp;</a>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <!-- <div class="col-xl-4 col-md-6">
                                                        <div class="widget widget-stats bg-bs-white">
                                                            <div class="stats-icon"><i class="fa fa-desktop"></i></div>
                                                            <div class="stats-info">
                                                                <h4>QTE DE ACESSOS</h4>
                                                                <p>922</p>
                                                            </div>
                                                            <div class="stats-link">
                                                                <a href="javascript:;">Ver Detalhes <i
                                                                        class="fa fa-arrow-alt-circle-right"></i></a>
                                                            </div>
                                                        </div>
                                                    </div>


                                                    <div class="col-xl-4 col-md-6">
                                                        <div class="widget widget-stats bg-bs-white">
                                                            <div class="stats-icon"><i class="fa fa-link"></i></div>
                                                            <div class="stats-info">
                                                                <h4>ÚLTIMO ACESSO</h4>
                                                                <p>10/10/2023 12:23</p>
                                                            </div>
                                                            <div class="stats-link">
                                                                <a href="javascript:;">Ver Detalhes <i
                                                                        class="fa fa-arrow-alt-circle-right"></i></a>
                                                            </div>
                                                        </div>
                                                    </div> -->

                                                    <div class="col-xl-4 col-md-6">
                                                        <div class="widget widget-stats bg-bs-white">
                                                            <div class="stats-icon">
                                                                <img src="../../../assets/images/pix.png" style="width: 50px; margin-bottom: 12px;" *ngIf="data.organizacao.formaCobranca == 'pix'">
                                                                <i class="fa fa-credit-card" *ngIf="data.organizacao.formaCobranca == 'cartao_credito'"></i>
                                                                <i class="fa fa-barcode" *ngIf="data.organizacao.formaCobranca == 'boleto'"></i>
                                                            </div>
                                                            <div class="stats-info">
                                                                <h4>FORMA DE PAGAMENTO</h4>
                                                                <p *ngIf="data.organizacao.formaCobranca == 'cartao_credito'">Cartão de Crédito</p>
                                                                <p *ngIf="data.organizacao.formaCobranca == 'pix'">Pix</p>
                                                                <p *ngIf="data.organizacao.formaCobranca == 'boleto'">Boleto</p>
                                                            </div>
                                                            <div class="stats-link">
                                                                <a [routerLink]="" (click)="openDialog()">Alterar <i class="fa fa-arrow-alt-circle-right"></i></a>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-xl-4 col-md-6">
                                                        <div class="widget widget-stats bg-bs-white">
                                                            <div class="stats-icon"><i class="fa fa-users"></i></div>
                                                            <div class="stats-info">
                                                                <h4>ÚLTIMO PAGAMENTO</h4>
                                                                <p>-</p>
                                                            </div>
                                                            <div class="stats-link">
                                                                <a [routerLink]="" style="cursor: default;">&nbsp;</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                </div>
                                            </p-tabPanel>

                                            <p-tabPanel>
                                                <h4 class="m-b-30">Informações de Cobrança</h4>

                                                <div class="row row-space-10">
                                                    <div class="col-md-3">
                                                      <label>Tipo de pessoa:</label>
                                                      <select class="form-control" [(ngModel)]="data.organizacao.documento.tipo" name="select">
                                                        <option value="cpf">Física</option>
                                                        <option value="cnpj">Jurídica</option>                    
                                                      </select>
                                                    </div>
                                                    <div class="col-md-4">                  
                                                      <label *ngIf="data.organizacao.documento.tipo == 'cpf'">CPF:</label>
                                                      <label *ngIf="data.organizacao.documento.tipo == 'cnpj'">CNPJ:</label>
                                                      <p-inputMask [(ngModel)]="data.organizacao.documento.valor" [mask]="data.organizacao.documento.tipo == 'cnpj' ? '99.999.999/9999-99' : '999.999.999-99'"></p-inputMask>
                                                      
                                                    </div>
                                                  </div>
                                              
                                                  <div class="row row-space-10 m-t-5">
                                                    <div class="col-md-7">
                                                      <label *ngIf="data.organizacao.documento.tipo == 'cnpj'">Nome fantasia:</label>
                                                      <label *ngIf="data.organizacao.documento.tipo != 'cnpj'">Nome:</label>
                                                      <input type="text" [(ngModel)]="data.organizacao.nome" name="organizacao.nome" class="form-control">
                                                    </div>
                                                  </div>
                                              
                                                  <div class="row row-space-10 m-t-5">
                                                    <div class="col-md-3">
                                                      <label>Telefone:</label>
                                                      <input type="text" [(ngModel)]="data.organizacao.telefone" mask="00 00000-0000" name="organizacao.telefone" class="form-control">
                                                    </div>       
                                                  </div>
                                              
                                                  <div *ngIf="data.organizacao.documento.tipo == 'cnpj'">
                                                    <hr />
                                              
                                                    <h4 class="m-t-30">Informações fiscais</h4>
                                              
                                                    <div class="row row-space-10">
                                                      <div class="col-md-8">
                                                        <label>Razão social:</label>
                                                        <input type="text" [(ngModel)]="data.organizacao.documentoFiscal.nome" name="legal.name" class="form-control">
                                                      </div>                  
                                                    </div>
                                              
                                                    <div class="row row-space-10 m-t-5">
                                              
                                                      <div class="col-md-4">
                                                        <label>Inscrição Estadual:</label>
                                                        <input type="text" [(ngModel)]="data.organizacao.documentoFiscal.ie" name="legal.ie" class="form-control">
                                                      </div>
                                                      <div class="col-md-4">
                                                        <label>Inscrição Municipal:</label>
                                                        <input type="text" [(ngModel)]="data.organizacao.documentoFiscal.im" name="legal.im" class="form-control">
                                                      </div>
                                                    </div>
                                                  </div>
                                              
                                                  <hr />

                                                <h4 class="m-t-30">Endereço</h4>
              
                                                <div class="row m-t-20">
                                                    <div class="col-2">
                                                      <label>CEP:</label>
                                                        <p-inputMask [(ngModel)]="data.organizacao.endereco.cep" mask="99999-999" (onBlur)="carregarEndereco()"></p-inputMask>
                                                    </div>
                                                    <div class="col-4">
                                                      <label>Endereço:</label>
                                                      <input type="text" pInputText [(ngModel)]="data.organizacao.endereco.logradouro" class="form-control" [readonly]="this.loadingAdd">
                                                    </div>
                                                    <div class="col-2">
                                                      <label>Número:</label>
                                                      <input type="text" pInputText [(ngModel)]="data.organizacao.endereco.numero" class="form-control">
                                                    </div>
                                                </div>
                                              
                                                <div class="row">
                                                    <div class="col-2">
                                                      <label>Complemento:</label>
                                                      <input type="text" pInputText [(ngModel)]="data.organizacao.endereco.complemento" class="form-control">
                                                    </div>
                                                    <div class="col-2">
                                                      <label>Bairro:</label>
                                                      <input type="text" pInputText [(ngModel)]="data.organizacao.endereco.bairro" [readonly]="this.loadingAdd" class="form-control">
                                                    </div>
                                                    <div class="col-3">
                                                      <label>Cidade:</label>
                                                      <input type="text" pInputText [(ngModel)]="data.organizacao.endereco.cidade" class="form-control" [readonly]="this.loadingAdd">
                                                    </div>
                                                    <div class="col-1">
                                                      <label>Estado:</label>
                                                      <input type="text" pInputText [(ngModel)]="data.organizacao.endereco.estado" class="form-control" [readonly]="this.loadingAdd">
                                                    </div>
                                                </div>
                                                <div class="row m-t-30">
                                                    <div class="col-8">
                                                        <button (click)="saveOrganizacao(data)" [disabled]="recording" class="btn btn-primary btn-lg float-right">
                                                            Atualizar dados <i class="fa fa-circle-o-notch fa-spin" *ngIf="recording"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </p-tabPanel>

                                            <p-tabPanel>
                                                <h4 class="m-b-30">Forma de Pagamento</h4>
                                                <div class="row">
                                                    <div class="col-xl-3 col-md-6">
                                                    </div>
                                                </div>
                                            </p-tabPanel>

                                            <p-tabPanel>
                                                <h4 class="m-b-30">Histórico de Pagamento</h4>
                                                <div class="row">
                                                    <div class="col-12 m-t-30">
                                                        <p class="text-center m-t-30">Nenhum pagamento efetuado até o momento</p>
                                                    </div>
                                                </div>
                                            </p-tabPanel>
                                        </p-tabView>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>