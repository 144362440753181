import { HttpClient } from '@angular/common/http';
import { Component, HostListener, OnInit } from '@angular/core';
import { environment } from '@environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng-lts/dynamicdialog';
import { Organizacao } from 'src/app/_entity/cadastro/organizacao';
import { Documento } from 'src/app/_entity/suporte/documento';
import { DocumentoFiscal } from 'src/app/_entity/suporte/documentoFiscal';
import { Endereco } from 'src/app/_entity/suporte/endereco';
import { AuthenticationService } from 'src/app/_services';
import { BaseServiceDialog } from 'src/app/_services/base.service.dialog';

@Component({
  selector: 'app-minhaconta',
  templateUrl: './minhaconta.component.html',
  styleUrls: ['./minhaconta.component.css'],
  providers: [DialogService]
})
export class MinhacontaComponent implements OnInit {

  public data: any;
  public plano: any;
  public totalUser: any;
  public utilizacao: any;
  public utilizacaoArquivo: any;
  public activeIndex: number = 0;
  
  public recording: boolean = false;

  public loading: boolean = false;
  public loadingAdd: boolean = false;

  constructor(
    public http: HttpClient,
    public dialog: DialogService,
    public toastr: ToastrService,
    public authenticationService: AuthenticationService) { }

  ngOnInit(): void {

    this.createData();
    this.data.user = this.authenticationService.currentUserValue;
    this.data.organizacao.nome = this.data.user.organizacao;

    try {
      this.loading = true;
      this.http.get<any>(`${environment.apiUrl}/organizacao/find`).subscribe(
        data => {
          this.data.organizacao = data;
          this.loading = false;
          
          this.http.get<any>(`${environment.apiUrl}/support/plano/${this.data.organizacao.plano}`).subscribe(
            plano => {
              this.plano = plano;

              this.http.get<any>(`${environment.apiUrl}/organizacao/account-size`).subscribe(
                data => {
                  this.utilizacao = data;
                }
              );
              this.http.get<any>(`${environment.apiUrl}/organizacao/account-size-data`).subscribe(
                data => {
                  this.utilizacaoArquivo = data;          
                }
              );
              this.http.get<any>(`${environment.apiUrl}/organizacao/total-user`).subscribe(
                data => {
                  this.totalUser = data;          
                }
              );
            },
            error => {
            }
          );
        },
        error => {
          this.loading = false;
        }
      );
    }
    catch (e) {
      this.loading = false;
    }
  }

  public openDialog() {
    const dialogRef = this.dialog.open(MinhacontaPgtoDialogComponent, {
      data: this.data.organizacao,
      width: '500px',
      height: '357px',
      transitionOptions: '1ms',
      showHeader: false
    });

    dialogRef.onClose.subscribe(result => {
      try {
        this.loading = true;
        this.http.get<any>(`${environment.apiUrl}/organizacao/find`).subscribe(
          data => {
            this.data.organizacao = data;
          },
            error => {
                this.recording = false;
            }
        );
      }
      catch (e) {
          this.recording = false;
          this.toastr.error('', e.message);
      }
    });
  }

  public saveOrganizacao(baseDoc: any): void {
    try {
      this.beforeSave(baseDoc.organizacao);

      this.http.post<any>(`${environment.apiUrl}/organizacao/save`, baseDoc.organizacao).subscribe(
          data => {
              this.afterSave(data.organizacao);
          },
          error => {
              this.recording = false;
          }
      );
    }
    catch (e) {
        this.recording = false;
        this.toastr.error('', e.message);
    }
  }

  public beforeSave(baseDoc: any): void {
      this.recording = true;
  }

  public afterSave(baseDoc: any): void {
      this.recording = false;
      this.toastr.success('Informações atualizadas com sucesso.');
  }

  public createData(){
    this.data = new Object;
    this.data.organizacao = new Organizacao;
    this.data.organizacao.documento = new Documento;
    this.data.organizacao.documento.tipo = 'cnpj';
    this.data.organizacao.endereco = new Endereco;    
    this.data.organizacao.documentoFiscal = new DocumentoFiscal;
  }

  public calcularPorcentagemUtilizacao(utilizacaoAtual: number, capacidadeTotalMB: number): number {
    try {
      // Converter utilização atual para bytes
      const utilizacaoBytes = utilizacaoAtual * 1024 * 1024;
      // Converter capacidade total para bytes
      const capacidadeTotalBytes = capacidadeTotalMB * 1024 * 1024;

      // Calcular a porcentagem de utilização
      const porcentagemUtilizacao = (utilizacaoBytes / capacidadeTotalBytes) * 100;

      // Arredondar para duas casas decimais
      return parseFloat(porcentagemUtilizacao.toFixed(2));
    }
    catch (e) {
      return 0;
    }
  }

  public getClassForWidget(x: number): string {
    if (x < 50) {
      return 'bg-blue';
    } else if (x >= 50 && x <= 75) {
      return 'bg-info';
    } else  {
      return 'bg-red';
    }
  }

  public carregarEndereco(): void {
    try {
      this.loadingAdd = true;
      this.http.get<any>(`${environment.apiUrl}/support/address/${this.data.organizacao.endereco.cep}`).subscribe(
        address => {
          this.data.organizacao.endereco.logradouro = address.logradouro;
          this.data.organizacao.endereco.bairro = address.bairro;
          this.data.organizacao.endereco.estado = address.estado;
          this.data.organizacao.endereco.cidade = address.cidade;

          this.loadingAdd = false;
        },
        error => {
          this.loadingAdd = false;
        }
      );
    }
    catch (e) {
      this.loadingAdd = false;
    }
  }
}

@Component({
  selector: 'app-minhaconta-pgto-dialog',
  templateUrl: 'minhaconta.pgto.dialog.component.html',
})
export class MinhacontaPgtoDialogComponent extends BaseServiceDialog implements OnInit {
  
  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public http: HttpClient,
    public toastr: ToastrService,
    public translate: TranslateService,
    public dialog: DialogService) {

    super('minhaconta', http, toastr, translate, ref, config.data);

    this.data = config.data;
  }

  ngOnInit(): void {
    // Caso seja uma iniciativa ja criada para edicao, busca a ultima
    // versao no banco de dados e apos inicializa os autocompletes
    if (this.data == null) {
      this.toastr.error('Não foi possível carregar a forma de pagamento');
    }
  }

  public updatePgto(): void {
    try {
      this.recording = true;

      this.http.put<any>(`${environment.apiUrl}/organizacao/update-pgto`, this.data).subscribe(
          data => {
            this.recording = false;
            this.toastr.success('Forma de pagamento atualizada com sucesso');
            this.close();
          },
          error => {
              this.recording = false;
          }
      );
    }
    catch (e) {
        this.recording = false;
        this.toastr.error('', e.message);
    }
  }

  public pgtoForma(data: string): void {
    this.data.formaCobranca = data;
  }

  @HostListener('window:keydown.control.s', ['$event'])
  keSaveClose(event: KeyboardEvent) {
    this.updatePgto();
  }

  @HostListener('window:keydown.esc', ['$event'])
  keClose(event: KeyboardEvent) {
    this.dialogRef.close();
  }
}