import { HttpClient } from '@angular/common/http';
import { Component, HostListener, Inject, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from 'src/app/_services';
import { BaseService } from 'src/app/_services/base.service';
import { BaseServiceDialog } from 'src/app/_services/base.service.dialog';
import { BaseServiceImportDialog, Upload } from 'src/app/_services/base.service.import.dialog';
import { LayoutPrivateComponent } from 'src/app/suporte/layout-private/layout-private.component';
import { Produto } from 'src/app/_entity/cadastro/produto';
import { KeyValue } from 'src/app/_entity/suporte/keyValue';
import { environment } from '@environments/environment';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng-lts/dynamicdialog';
import { ProdutoVariacaoParametro } from 'src/app/_entity/suporte/produtoVariacaoParametro';
import { ProdutoVariacao } from 'src/app/_entity/suporte/produtoVariacao';
import { Categoria } from 'src/app/_entity/cadastro/categoria';
import { Filters } from 'src/app/_entity/filters';
import { Filter } from 'src/app/_entity/filter';

@Component({
  selector: 'app-produto',
  templateUrl: './produto.component.html',
  styleUrls: ['./produto.component.css'],
  providers: [DialogService]
})
export class ProdutoComponent extends BaseService implements OnInit {

  public upload: Upload;

  constructor(
    public app: LayoutPrivateComponent,
    public http: HttpClient,
    public dialog: DialogService,
    public toastr: ToastrService,
    public translate: TranslateService,
    private authenticationService: AuthenticationService) {

    super('produto', app, http, dialog, toastr, translate);
  }

  ngOnInit(): void {
    this.find();
    this.dialogClass = ProdutoDialogComponent;
    this.authService = this.authenticationService;
    this.buildFilterItens();
  }

  public buildFilterItens(): void {
    this.filters.filters[0].att = 'nome';
    this.filters.filters[0].attAlias = 'Nome';

    this.filterItens = [{
          label: 'Nome',
          command: () => {
            this.filterChangeAtt('nome', 'Nome')
          }
      },
      {
          label: 'Marca',
          command: () => {
            this.filterChangeAtt('marca', 'Marca')
          }
      },
      {
          label: 'SKU',
          command: () => {
            this.filterChangeAtt('sku', 'SKU')
          }
      },
      {
          label: 'Código de barras',
          command: () => {
            this.filterChangeAtt('ean', 'Código de barras')
          }
      }];
  }

  public openDialogImportar() {
    this.upload = new Upload();
    this.upload.carregado = false;

    const dialogRef = this.dialog.open(ProdutoDialogImportarComponent, {
      data: this.upload,
      width: '80%',
      height: '500px',
      transitionOptions: '1ms',
      showHeader: false
    });

    dialogRef.onClose.subscribe(result => {
      if (this.upload != null && this.upload.carregado)
        this.find(null);
    });
  }

}

@Component({
  selector: 'app-produto-dialog',
  templateUrl: 'produto.dialog.component.html',
})
export class ProdutoDialogComponent extends BaseServiceDialog implements OnInit {

  public data: Produto = null;
  public upload_path_image: string;
  public upload_path_arquivo: string;
  
  public medidas: KeyValue[];
  public categorias: Categoria[] = [];

  constructor(
    public ref: DynamicDialogRef, 
    public config: DynamicDialogConfig,
    public http: HttpClient,
    public toastr: ToastrService,
    public translate: TranslateService) {
      
      super('produto', http, toastr, translate, ref, config.data);

      this.datas = config.data;
      this.data = this.datas.data;

      this.upload_path_image = `${environment.apiUrl}/produto/upload-imagem`;
      this.upload_path_arquivo = `${environment.apiUrl}/produto/upload-arquivo`;

    }

  ngOnInit(): void {
    this.medidas = [
      { "value": "Caixa", "code": "cx" },
      { "value": "Pacote", "code": "pac" },
      { "value": "Unidade", "code": "un" },
      { "value": "Dezena", "code": "dzn" },
      { "value": "Dúzia", "code": "dz" },
      { "value": "Miligrama", "code": "mg" },
      { "value": "Grama", "code": "gr" },
      { "value": "Quilograma", "code": "kg" },
      { "value": "Tonelada", "code": "ton" },
      { "value": "Litro", "code": "lt" },
      { "value": "Mililitro", "code": "ml" },
      { "value": "Milímetro", "code": "mm" },
      { "value": "Centímetro", "code": "cm" },
      { "value": "Metro", "code": "m" },
      { "value": "Metro Cúbico", "code": "m3" },
      { "value": "Metro Quadrado", "code": "m2" }
    ];

    // Caso seja uma iniciativa ja criada para edicao, busca a ultima
    // versao no banco de dados e apos inicializa os autocompletes
    if (this.data && this.data._id) {
      this.find(this.data._id);
    }
    // Caso seja uma nova apenas inicializa os autocompletes
    else {
      this.createData();
    }
  }

  public beforeSave(callback: (valid: boolean) => void): void {
    if (!this.data || !this.data.nome) {
      this.toastr.error('Campo Nome do produto deve ser preenchido.');
      callback(false);
    }
    else {
      callback(true);
    }
  }

  public onSelectCategoria(): void {
    this.data.idCategoria = this.data.categoria._id;
  }

  public onUploadImagem(event): void {
    if (event.originalEvent && event.originalEvent.body) {
      if (!this.data.imagens) {
        this.data.imagens = [];
      }
      this.data.imagens = this.data.imagens.concat(event.originalEvent.body);
      this.toastr.success('Operação realizada com sucesso', 'File Uploaded');
    }
    else {
      this.toastr.error('Ocorreu um problema ao enviar as imagens', 'Ops.');
    }
  }

  public onUploadArquivo(event): void {
    if (event.originalEvent && event.originalEvent.body) {
      if (!this.data.anexos) {
        this.data.anexos = [];
      }
      this.data.anexos = this.data.anexos.concat(event.originalEvent.body);
      this.toastr.success('Operação realizada com sucesso', 'File Uploaded');
    }
    else {
      this.toastr.error('Ocorreu um problema ao enviar os arquivos', 'Ops.');
    }
  }

  moveUp(index: number) {
    if (index > 0) {
      const temp = this.data.imagens[index];
      this.data.imagens[index] = this.data.imagens[index - 1];
      this.data.imagens[index - 1] = temp;
    }
  }

  moveDown(index: number) {
    if (index < this.data.imagens.length - 1) {
      const temp = this.data.imagens[index];
      this.data.imagens[index] = this.data.imagens[index + 1];
      this.data.imagens[index + 1] = temp;
    }
  }

  removeImage(index: number) {
    this.data.imagens.splice(index, 1);
  }
  removeAnexo(index: number) {
    this.data.anexos.splice(index, 1);
  }

  public createData(): void {
    if (this.data == null) {
      this.data = new Produto;
      this.data.origem = new KeyValue;
      this.data.categoria = new Categoria;
      this.data.unidadeMedida = new KeyValue;
      this.data.anexos = [];
      this.data.imagens = [];
      this.data.variacoes = [];
      this.data.variacaoParametros = [];
      this.data.unidadeMedida.code = 'un';
      this.data.unidadeMedida.value = 'Unidade';

      this.criarVariacao();
    }
  }

  public criarVariacao(): void {
    let variacao: ProdutoVariacao = new ProdutoVariacao;
    if (!this.data.variacoes) {
      this.data.variacoes = [];
    }
    this.data.variacoes.push(variacao);
  }

  public criarVariacaoParametro(): void {
    let novaVariacaoParametro: ProdutoVariacaoParametro[] = [];

    if (!this.data.variacaoParametros) {
      this.data.variacaoParametros = [];
    }

    for (const variacao of this.data.variacoes) {
      novaVariacaoParametro = this.criarVariacaoParametroCampos(variacao, novaVariacaoParametro);
    };

    this.data.variacaoParametros = novaVariacaoParametro;
  }

  public criarVariacaoParametroCampos(variacao: ProdutoVariacao, variacaoParametros: ProdutoVariacaoParametro[]): ProdutoVariacaoParametro[] {
    let keys: string[] = variacao.variacoes;
    if(!keys) {
      keys = [];
    }
    if (variacaoParametros.length === 0){
      for (const key of keys) {
        let parametro = new ProdutoVariacaoParametro();
        parametro.variacao = key;

        let parametroAntigo = this.data.variacaoParametros.find(p => p.variacao === parametro.variacao);

        if (parametroAntigo) {
          parametro.sku = parametroAntigo.sku;
          parametro.ean = parametroAntigo.ean;
          parametro.valorVenda = parametroAntigo.valorVenda;
          parametro.estoque = parametroAntigo.estoque;
        }

        variacaoParametros.push(parametro);
      };
      return variacaoParametros;
    }
    else {
      let novaVariacaoParametro: ProdutoVariacaoParametro[] = [];
      for (const parametro of variacaoParametros) {
        for (const key of keys) {
          let novoParametro = new ProdutoVariacaoParametro();
          novoParametro.variacao = parametro.variacao + ' - ' + key;
          
          let parametroAntigo = this.data.variacaoParametros.find(parametro => parametro.variacao === novoParametro.variacao);

          if (parametroAntigo) {
            novoParametro.sku = parametroAntigo.sku;
            novoParametro.ean = parametroAntigo.ean;
            novoParametro.valorVenda = parametroAntigo.valorVenda;
            novoParametro.estoque = parametroAntigo.estoque;
          }
          
          novaVariacaoParametro.push(novoParametro);
        };
      };

      return novaVariacaoParametro;
    }
  }

  public autocomplete(event): void {
    this.http.post<any>(`${environment.apiUrl}/categoria/lazylist`, this.createFilters(event.query)).subscribe(
      data => {
          this.categorias = data.result;
      },
      error => {
      }
    );
  }

  public createFilters(nome: string): Filters {
    const filters = new Filters();
    const filterName = new Filter('lk', 'descricao', this.clearSpecialCharacters(nome));
    const filterTipo = new Filter('eq', 'origem.code', 'produto');
  
    filters.offset = 0;
    filters.pageSize = 5;
    filters.orderField = "descricao";
    filters.orderDirection = 'asc';
    filters.filters = [filterName, filterTipo];
  
    return filters;
  }

  public replicarValorParametro(campo:string, valor: string): void {
    for (const parametro of this.data.variacaoParametros) {
      parametro[campo] = valor;
    }
  }

  public editChip(chip: string, variacao: ProdutoVariacao): void {
    const index = variacao.variacoes.indexOf(chip);
    const newLabel = prompt('Editar variação', chip);
    if (newLabel && newLabel.trim() !== '') {
        variacao.variacoes[index] = newLabel.trim();
    }
    this.criarVariacaoParametro();
  }

  @HostListener('window:keydown.control.enter', ['$event'])
  keSave(event: KeyboardEvent) {
    this.save();
  }

  @HostListener('window:keydown.control.s', ['$event'])
  keSaveClose(event: KeyboardEvent) {
    this.saveClose();
  }

  @HostListener('window:keydown.esc', ['$event'])
  keClose(event: KeyboardEvent) {
    this.dialogRef.close();
  }
}

@Component({
  selector: 'app-produto-dialog-importar',
  templateUrl: 'produto.dialog.importar.component.html',
})
export class ProdutoDialogImportarComponent extends BaseServiceImportDialog {
  constructor(
    public ref: DynamicDialogRef, 
    public config: DynamicDialogConfig,
    public http: HttpClient,
    public toastr: ToastrService,
    public translate: TranslateService,
    public authenticationService: AuthenticationService) {
    super('produto', http, toastr, translate, authenticationService, ref, config.data);
  }

}