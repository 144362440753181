import { HttpClient } from '@angular/common/http';
import { Component, HostListener } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from '@environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng-lts/dynamicdialog';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-mercadolivre.dialog',
  templateUrl: './mercadolivre.dialog.component.html'
})
export class MercadoLivreDialogComponent {
 
  public code: string;
  public mlUrl: string;

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public http: HttpClient,
    private activatedRoute: ActivatedRoute,
    public toastr: ToastrService,
    public translate: TranslateService) {

      this.mlUrl = environment.mlUrl;
  }

  ngOnInit() {

    this.activatedRoute.queryParams
      .subscribe(params => {
        this.code = params.code;
      }
    );

    if(this.code !== null && this.code != undefined) {

      this.http.get(`${environment.apiUrl}/api/store/ml/initialize/` + this.code)
        .pipe(first())
        .subscribe(
          data => {
            console.log(data);
          },
          error => {
            console.log(error);
        }
      );
    }
  }

  public close(): void {
    this.ref.close();
  }

  @HostListener('window:keydown.esc', ['$event'])
  keClose(event: KeyboardEvent) {
    this.ref.close();
  }
}