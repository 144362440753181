import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { LayoutPublicComponent } from '../../suporte/layout-public/layout-public.component';
import { AuthenticationService } from '../../_services';


@Component({
  selector: 'app-root-public',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  loading = false;
  submitted = false;
  error = false;
  returnUrl: string;
  registered = false;
  logout = 'false';
  time = new Date();

  bt = 'Entrar';
  errorMsg = '';

  public constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private app: LayoutPublicComponent,
    private authenticationService: AuthenticationService) {

    // redirect to home if already logged in
    if (this.authenticationService.currentUserValue) {
      this.router.navigate(['/home/dashboard']);
    }
  }

  ngAfterViewInit() {
    this.app.setTitle('Login | BeSales');
  }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      email: ['', Validators.required],
      password: ['', Validators.required]
    });


    this.registered = this.route.snapshot.queryParams['registered'];

    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'];

    if (this.returnUrl === null || this.returnUrl === undefined) {
      this.returnUrl = '/home/dashboard';
    }
  }

  // convenience getter for easy access to form fields
  get f() { return this.loginForm.controls; }

  onSubmit() {
    this.error = false;
    this.errorMsg = '';
    this.submitted = true;

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }

    this.loading = true;
    this.bt = 'Validando...';

    this.authenticationService.login(this.f.email.value, this.f.password.value)
      .pipe(first())
      .subscribe(
        data => {
        this.router.navigate([this.returnUrl]);
      },
        error => {
          this.errorMsg = error;
          this.loading = false;
          this.error = true;
          this.bt = 'Entrar';
      });
  }
}
