<div id="content" class="content content-full-width">

    <h1 class="page-header">Dashboard</h1>

    <div class="vertical-box with-grid inbox bg-light">

        <div class="vertical-box-column width-200">
            <div class="vertical-box">

                <div class="wrapper d-none d-sm-none d-md-none d-lg-block">
                    <div class="d-flex align-items-center justify-content-center">
                        Menu
                    </div>
                </div>

                <div class="vertical-box-row d-lg-table-row collapse">
                    <div class="vertical-box-cell">
                        <div class="vertical-box-inner-cell">
                            <div class="keras-scrollbar">
                                <div class="wrapper p-0">
                                    <ul class="nav nav-inbox">
                                        <li [ngClass]="activeIndex == 0 ? 'active' : ''"><a (click)="activeIndex = 0" href="javascript:;">Vendas</a></li>
                                        <!-- <li [ngClass]="activeIndex == 1 ? 'active' : ''"><a (click)="activeIndex = 1" href="javascript:;">Finanças</a></li> -->
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

        <div class="vertical-box-column">
            <div class="vertical-box">
                <div class="vertical-box-row">
                    <div class="vertical-box-cell">
                        <div class="vertical-box-inner-cell bg-white">
                            <div class="keras-scrollbar">
                                <div class="bs-configuracao bs-dashboard">
                                    <div class="col-12">
                                        <h4>{{ 'dashboard.welcome' | translate:{name: username.nome} }}</h4>
                                        <div class="d-sm-flex align-items-center mb-3">
                                            <div ngbDropdown container="body" class="m-r-10 m-t-20">
                                                <button pButton ngbDropdownToggle icon="far fa-lg fa-fw fa-calendar-alt" type="button" label="{{fRangeLabel}}" class="p-button-outlined p-button-info"></button>
                                                <div ngbDropdownMenu>
                                                    <button ngbDropdownItem (click)="changeRange('ma', 'Mês atual')">Mês atual</button>
                                                    <button ngbDropdownItem (click)="changeRange('da', 'Dia atual')">Dia atual</button>
                                                    <button ngbDropdownItem (click)="changeRange('u7d', 'Últimos 7 dias')">Últimos 7 dias</button>
                                                    <button ngbDropdownItem (click)="changeRange('u30d', 'Últimos 30 dias')">Últimos 30 dias</button>
                                                    <button ngbDropdownItem (click)="changeRange('u6m', 'Últimos 6 meses')">Últimos 6 meses</button>
                                                    <button ngbDropdownItem (click)="changeRange('ua', 'Último ano')">Último ano</button>
                                                </div>
                                            </div>
                                            <!-- <div ngbDropdown container="body" class="m-r-10 m-t-20">
                                                <button pButton ngbDropdownToggle icon="fas fa-lg fa-fw fa-filter" type="button" label="Filtro" class="p-button-outlined p-button-info"></button>
                                                <div ngbDropdownMenu>
                                                    <button ngbDropdownItem>Atualizar agora</button>
                                                    <button ngbDropdownItem>Atualizar a cada 10 segundos</button>
                                                    <button ngbDropdownItem>Atualizar a cada 30 segundos</button>
                                                    <button ngbDropdownItem>Atualizar a cada 60 segundos</button>
                                                </div>
                                            </div> -->
                                            <div ngbDropdown container="body" class="m-r-10 m-t-20">
                                                <button pButton ngbDropdownToggle icon="fas fa-lg fa-fw fa-history" type="button" label="Atualizando em {{textCall}}" class="p-button-outlined p-button-info"></button>
                                                <div ngbDropdownMenu>
                                                    <button ngbDropdownItem (click)="loadDashs()">Atualizar agora</button>
                                                    <button ngbDropdownItem (click)="updateDashs(30000)">Atualizar a cada 30 segundos</button>
                                                    <button ngbDropdownItem (click)="updateDashs(60000)">Atualizar a cada 60 segundos</button>
                                                    <button ngbDropdownItem (click)="updateDashs(60000 * 5)">Atualizar a cada 5 minutos</button>
                                                    <button ngbDropdownItem (click)="updateDashs(60000 * 15)">Atualizar a cada 15 minutos</button>
                                                    <button ngbDropdownItem (click)="updateDashs(60000 * 30)">Atualizar a cada 30 minutos</button>
                                                </div>
                                            </div>
                                        </div>
                                        <p-tabView [(activeIndex)]="activeIndex">
                                    
                                            <p-tabPanel>
                                                <div class="row">                                                    
                                                    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3">
                                                        <div class="widget widget-stats bg-gradient-orange m-b-10">
                                                            <div class="stats-icon stats-icon-lg"><i class="fa fa-file-alt fa-fw"></i></div>
                                                            <div class="stats-content">
                                                                <div class="stats-title">PEDIDOS <small>hoje</small></div>
                                                                <div class="stats-number" *ngIf="!pnPedido">{{countPedidos?.values[0]}}</div>
                                                                <div class="stats-number" *ngIf="pnPedido" style="height: 81px;text-align: center;padding-top: 22px;">
                                                                    <i class="fas fa-spinner fa-pulse"></i>
                                                                </div>
                                                                <div class="stats-progress progress" *ngIf="!pnPedido">
                                                                    <div class="progress-bar" [style.width.%]="(countPedidos?.total)"></div>
                                                                </div>
                                                                <div class="stats-desc" *ngIf="!pnPedido">{{lbCountPedidos}}</div>
                                                            </div>                                                            
                                                        </div>
                                                    </div>
                                                    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3">
                                                        <div class="widget widget-stats bg-gradient-blue m-b-10">
                                                            <div class="stats-icon stats-icon-lg"><i class="fa fa-globe fa-fw"></i>
                                                            </div>
                                                            <div class="stats-content">
                                                                <div class="stats-title">VENDAS ONLINE <small>hoje</small></div>
                                                                <div class="stats-number" *ngIf="!pnOnline">{{sumPedidosOnline?.values[0]}}</div>
                                                                <div class="stats-number" *ngIf="pnOnline" style="height: 81px;text-align: center;padding-top: 22px;">
                                                                    <i class="fas fa-spinner fa-pulse"></i>
                                                                </div>
                                                                <div class="stats-progress progress" *ngIf="!pnOnline">
                                                                    <div class="progress-bar" [style.width.%]="calcProgress(sumPedidosOnline?.total)"></div>
                                                                </div>
                                                                <div class="stats-desc" *ngIf="!pnOnline">{{lbsumPedidosOnline}}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3">
                                                        <div class="widget widget-stats bg-gradient-blue m-b-10">
                                                            <div class="stats-icon stats-icon-lg"><i class="fa fa-archive fa-fw"></i>
                                                            </div>
                                                            <div class="stats-content">
                                                                <div class="stats-title">VENDAS FÍSICAS <small>hoje</small></div>
                                                                <div class="stats-number" *ngIf="!pnFisica">{{sumPedidosLocal?.values[0]}}</div>
                                                                <div class="stats-number" *ngIf="pnFisica" style="height: 81px;text-align: center;padding-top: 22px;">
                                                                    <i class="fas fa-spinner fa-pulse"></i>
                                                                </div>
                                                                <div class="stats-progress progress" *ngIf="!pnFisica">
                                                                    <div class="progress-bar" [style.width.%]="calcProgress(sumPedidosLocal?.total)"></div>
                                                                </div>
                                                                <div class="stats-desc" *ngIf="!pnFisica">{{lbsumPedidosLocal}}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3">
                                                        <div class="widget widget-stats bg-gradient-purple m-b-10">
                                                            <div class="stats-icon stats-icon-lg"><i class="fa fa-dollar-sign fa-fw"></i>
                                                            </div>
                                                            <div class="stats-content">
                                                                <div class="stats-title">VALOR TOTAL <small>hoje</small></div>
                                                                <div class="stats-number" *ngIf="!pnTotal">{{sumPedidos?.values[0]}}</div>
                                                                <div class="stats-number" *ngIf="pnTotal" style="height: 81px;text-align: center;padding-top: 22px;">
                                                                    <i class="fas fa-spinner fa-pulse"></i>
                                                                </div>
                                                                <div class="stats-progress progress" *ngIf="!pnTotal">
                                                                    <div class="progress-bar" [style.width.%]="calcProgress(sumPedidos?.total)"></div>
                                                                </div>
                                                                <div class="stats-desc" *ngIf="!pnTotal">{{lbsumPedidos}}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 m-t-20">
                                                        
                                                        <!-- <p-panel header="Total das vendas" [ngClass]="tvTotal > 0 ? 'maximized-panel' : ''"> -->
                                                        <p-panel header="Total das vendas">
                                                            <h4 class="m-b-30 m-t-10" *ngIf="tvTotal > 0 && !ldDashVenda">R$ {{tvTotal | number:'1.2-2'}} <small>Total acomulado</small></h4>
                                                            <p-chart type="bar" [data]="chtTotalVendas" [options]="barChartOptions" *ngIf="tvTotal > 0 && !ldDashVenda"></p-chart>

                                                            <div class="row justify-content-center" style="height: 335px;" *ngIf="tvTotal == 0 && !ldDashVenda">
                                                                <div class="col-md-6 align-self-center text-center">
                                                                    <img class="img-fluid mx-auto d-block" src="../../assets/images/bg-dash.svg">
                                                                    <p class="m-t-30 font-weight-bold">Coletando informações.</p>
                                                                </div>
                                                            </div>

                                                            <div *ngIf="ldDashVenda" style="height: 285px;">
                                                            </div>
                                                            <div class="texto-sobre-imagem" *ngIf="ldDashVenda">
                                                                <i class="fas fa-spinner fa-pulse"></i>
                                                            </div>
                                                        </p-panel>
                                                    </div>
                                                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 m-t-20">
                                                        <p-panel header="Ticket médio">
                                                            <h4 class="m-b-30 m-t-10" *ngIf="tmTotal > 0 && !ldDashTm">R$ {{tmTotal  | number:'1.2-2' }} <small>Ticket médio dos itens</small></h4>
                                                            <p-chart type="bar" [data]="chtTicketMedio" [options]="barChartOptions" *ngIf="tmTotal > 0 && !ldDashTm"></p-chart>

                                                            <div class="row justify-content-center" style="height: 335px;" *ngIf="tmTotal == 0 && !ldDashTm">
                                                                <div class="col-md-6 align-self-center text-center">
                                                                    <img class="img-fluid mx-auto d-block" src="../../assets/images/bg-dash.svg">
                                                                    <p class="m-t-30 font-weight-bold">Coletando informações.</p>
                                                                </div>
                                                            </div>

                                                            <div *ngIf="ldDashTm" style="height: 285px;">
                                                            </div>
                                                            <div class="texto-sobre-imagem" *ngIf="ldDashTm">
                                                                <i class="fas fa-spinner fa-pulse"></i>
                                                            </div>
                                                        </p-panel>
                                                    </div>
                                                </div>                                                
                                                <div class="row">
                                                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 m-t-20">
                                                        <p-panel header="Pedido por origem">
                                                            <p-chart type="bar" [data]="chtPedidoPorOrigem" [options]="barChartOptions" *ngIf="poValue?.length > 0 && !ldDashPo"></p-chart>
                                                            
                                                            <div class="row justify-content-center" style="height: 224px;" *ngIf="(!poValue || poValue?.length == 0) && !ldDashPo">
                                                                <div class="col-md-6 align-self-center text-center">
                                                                    <img class="img-fluid mx-auto d-block" src="../../assets/images/bg-dash.svg">
                                                                    <p class="m-t-30 font-weight-bold">Coletando informações.</p>
                                                                </div>
                                                            </div>

                                                            <div *ngIf="ldDashPo" style="height: 224px;">
                                                            </div>
                                                            <div class="texto-sobre-imagem" *ngIf="ldDashPo">
                                                                <i class="fas fa-spinner fa-pulse"></i>
                                                            </div>
                                                        </p-panel>
                                                    </div>
                                                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 m-t-20">
                                                        <p-panel header="Top 10 Produtos mais vendidos">
                                                            <p-chart type="horizontalBar" [data]="chtTop10Produtos" *ngIf="topValue?.length > 0 && !ldDashTop"></p-chart>
                                                            
                                                            <div class="row justify-content-center" style="height: 224px;" *ngIf="(!topValue || topValue?.length == 0) && !ldDashTop">
                                                                <div class="col-md-6 align-self-center text-center">
                                                                    <img class="img-fluid mx-auto d-block" src="../../assets/images/bg-dash.svg">
                                                                    <p class="m-t-30 font-weight-bold">Coletando informações.</p>
                                                                </div>
                                                            </div>
                                                            
                                                            <div *ngIf="ldDashTop" style="height: 224px;">
                                                            </div>
                                                            <div class="texto-sobre-imagem" *ngIf="ldDashTop">
                                                                <i class="fas fa-spinner fa-pulse"></i>
                                                            </div>
                                                        </p-panel>
                                                    </div>
                                                </div>
                                                <div class="row m-b-30">
                                                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 m-t-20">
                                                        <p-panel header="Horários de maiores vendas">
                                                            <p-chart type="bar" [data]="chtHorarioVendas" *ngIf="phTotal > 0 && !ldDashPh"></p-chart>
                                                            
                                                            <div class="row justify-content-center" style="height: 224px;" *ngIf="phTotal == 0 && !ldDashPh">
                                                                <div class="col-md-6 align-self-center text-center">
                                                                    <img class="img-fluid mx-auto d-block" src="../../assets/images/bg-dash.svg">
                                                                    <p class="m-t-30 font-weight-bold">Coletando informações.</p>
                                                                </div>
                                                            </div>

                                                            <div *ngIf="ldDashPh" style="height: 224px;">
                                                            </div>
                                                            <div class="texto-sobre-imagem" *ngIf="ldDashPh">
                                                                <i class="fas fa-spinner fa-pulse"></i>
                                                            </div>
                                                        </p-panel>
                                                    </div>
                                                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 m-t-20">
                                                        <p-panel header="Vendas x Devoluções">
                                                            <p-chart type="bar" [data]="chtVendaDevolucao" *ngIf="vdTotal > 0 && !ldDashVd"></p-chart>
                                                            
                                                            <div class="row justify-content-center" style="height: 224px;" *ngIf="vdTotal == 0 && !ldDashVd">
                                                                <div class="col-md-6 align-self-center text-center">
                                                                    <img class="img-fluid mx-auto d-block" src="../../assets/images/bg-dash.svg">
                                                                    <p class="m-t-30 font-weight-bold">Coletando informações.</p>
                                                                </div>
                                                            </div>

                                                            <div *ngIf="ldDashVd" style="height: 224px;">
                                                            </div>
                                                            <div class="texto-sobre-imagem" *ngIf="ldDashVd">
                                                                <i class="fas fa-spinner fa-pulse"></i>
                                                            </div>
                                                        </p-panel>
                                                    </div>
                                                </div>
                                            </p-tabPanel>
                                            
                                
                                            <p-tabPanel>
                                                <div class="row">                                                    
                                                    <div class="col-3">
                                                        <div class="widget widget-stats bg-gradient-orange m-b-10">
                                                            <div class="stats-icon stats-icon-lg"><i class="fa fa-file-alt fa-fw"></i>
                                                            </div>
                                                            <div class="stats-content">
                                                                <div class="stats-title">SALDO ATUAL</div>
                                                                <div class="stats-number">7,842,900</div>
                                                                <div class="stats-progress progress">
                                                                    <div class="progress-bar" style="width: 70.1%;"></div>
                                                                </div>
                                                                <div class="stats-desc">Better than last week (70.1%)</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-3">
                                                        <div class="widget widget-stats bg-gradient-blue m-b-10">
                                                            <div class="stats-icon stats-icon-lg"><i class="fa fa-globe fa-fw"></i>
                                                            </div>
                                                            <div class="stats-content">
                                                                <div class="stats-title">CONTAS A PAGAR</div>
                                                                <div class="stats-number">7,842,900</div>
                                                                <div class="stats-progress progress">
                                                                    <div class="progress-bar" style="width: 70.1%;"></div>
                                                                </div>
                                                                <div class="stats-desc">Better than last week (70.1%)</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-3">
                                                        <div class="widget widget-stats bg-gradient-blue m-b-10">
                                                            <div class="stats-icon stats-icon-lg"><i class="fa fa-archive fa-fw"></i>
                                                            </div>
                                                            <div class="stats-content">
                                                                <div class="stats-title">CONTAS A RECEBER</div>
                                                                <div class="stats-number">7,842,900</div>
                                                                <div class="stats-progress progress">
                                                                    <div class="progress-bar" style="width: 0%;"></div>
                                                                </div>
                                                                <div class="stats-desc">Better than last week (70.1%)</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-3">
                                                        <div class="widget widget-stats bg-gradient-purple m-b-10">
                                                            <div class="stats-icon stats-icon-lg"><i class="fa fa-dollar-sign fa-fw"></i>
                                                            </div>
                                                            <div class="stats-content">
                                                                <div class="stats-title">CONTAS VENCENDO HOJE</div>
                                                                <div class="stats-number">7,842,900</div>
                                                                <div class="stats-progress progress">
                                                                    <div class="progress-bar" style="width: 70.1%;"></div>
                                                                </div>
                                                                <div class="stats-desc">Better than last week (70.1%)</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row m-t-20">
                                                    <div class="col-6">
                                                        <p-panel header="Fluxo de caixa">
                                                            <h4 class="m-b-30 m-t-10">R$ 1.454,342,92</h4>
                                                            <p-chart type="bar" [data]="chtTotalVendas" [options]="barChartOptions"></p-chart>
                                                        </p-panel>
                                                    </div>
                                                    <div class="col-6">
                                                        <p-panel header="Contas a Pagar x a Receber ">
                                                            <h4 class="m-b-30 m-t-10">R$ 42,92</h4>
                                                            <p-chart type="bar" [data]="chtTicketMedio" [options]="barChartOptions"></p-chart>
                                                        </p-panel>
                                                    </div>
                                                </div>                                                
                                                
                                            </p-tabPanel>
                                        </p-tabView>
                                    </div>
                                    
                                </div>                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>    
        </div>
    </div>
</div>