import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { environment } from '@environments/environment';
import { ToastrService } from 'ngx-toastr';
import { Organizacao } from 'src/app/_entity/cadastro/organizacao';
import { AuthenticationService } from 'src/app/_services';

@Component({
  selector: 'app-upgrade',
  templateUrl: './upgrade.component.html',
  styleUrls: ['./upgrade.component.css']
})
export class UpgradeComponent implements OnInit {

  public data: any;
  public plano: any;
  public planos: any[] = [];
  public activeIndex: number = 0;

  public loading: boolean = false;
  
  constructor(
    public http: HttpClient,
    public toastr: ToastrService,
    public authenticationService: AuthenticationService) { }

  ngOnInit(): void {
    this.createData();
    this.data.user = this.authenticationService.currentUserValue;
    this.data.organizacao.nome = this.data.user.organizacao;

    try {
      this.loading = true;
      this.http.get<any>(`${environment.apiUrl}/organizacao/find`).subscribe(
        data => {
          this.data.organizacao = data;
          this.loading = false;

          this.http.get<any>(`${environment.apiUrl}/support/plano/${this.data.organizacao.plano}`).subscribe(
            plano => {
              this.plano = plano;
            },
            error => {
            }
          )
        },
        error => {
          this.loading = false;
        }
      );
    }
    catch (e) {
      this.loading = false;
    }

    this.http.get<any>(`${environment.apiUrl}/support/planos`).subscribe(
      data => {
          this.planos = data;
      },
      error => {
          this.toastr.error('Não foi possível carregar informações dos planos');
      }
    );
  }

  public createData(){
    this.data = new Object;
    this.data.organizacao = new Organizacao;
  }
}
