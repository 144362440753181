import { HttpClient } from '@angular/common/http';
import { Component, HostListener, OnInit } from '@angular/core';
import { environment } from '@environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng-lts/dynamicdialog';
import { Categoria } from 'src/app/_entity/cadastro/categoria';
import { Contato } from 'src/app/_entity/cadastro/contato';
import { CentroCusto } from 'src/app/_entity/financa/centroCusto';
import { TituloPagar } from 'src/app/_entity/financa/tituloPagar';
import { PagamentoStatus } from 'src/app/_entity/suporte/pagamentoStatus';
import { BaseServiceDialog } from 'src/app/_services/base.service.dialog';
import { CategoriaDialogComponent } from 'src/app/cadastro/categoria/categoria.component';
import { FornecedorDialogComponent } from 'src/app/cadastro/fornecedor/fornecedor.component';
import { CentroCustoDialogComponent } from '../centro-custo/centro-custo.dialog.component';
import { KeyValue } from 'src/app/_entity/suporte/keyValue';
import { Filters } from 'src/app/_entity/filters';
import { Filter } from 'src/app/_entity/filter';

@Component({
  selector: 'app-titulo-pagar.dialog',
  templateUrl: './titulo-pagar.dialog.component.html'
})
export class TituloPagarDialogComponent extends BaseServiceDialog implements OnInit {

  public data: TituloPagar = null;

  public categoria: Categoria;
  public fornecedor: Contato;
  public centroCusto: CentroCusto;

  public autocompleteCategoria: any[] = [];
  public autocompleteFornecedor: any[] = [];
  public autocompleteCentroCusto: any[] = [];

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public dialogService: DialogService,
    public http: HttpClient,
    public toastr: ToastrService,
    public translate: TranslateService) {

    super('financa/titulo-pagar', http, toastr, translate, ref, config.data);

    this.datas = config.data;
    this.data = this.datas.data;
  }

  ngOnInit(): void {
    // Caso seja uma iniciativa ja criada para edicao, busca a ultima
    // versao no banco de dados e apos inicializa os autocompletes
    if (this.data && this.data._id) {
      this.find(this.data._id);
    }
    // Caso seja uma nova apenas inicializa os autocompletes
    else {
      this.createData();
    }
  }

  public beforeSave(callback: (valid: boolean) => void): void {
    if (!this.data || !this.data.identificador) {
      this.toastr.error('Campo Nome deve ser preenchido.');
      callback(false);
    }
    else {
      callback(true);
    }
  }

  public afterFind(): void {
      if (this.data) {
        if (this.data.idCategoria) {
          this.http.get<any>(`${environment.apiUrl}/categoria/find/${this.data.idCategoria}`).subscribe(
            data => {
              this.categoria = data;
            }
          );
        }
        if (this.data.idFornecedor) {
          this.http.get<any>(`${environment.apiUrl}/fornecedor/find/${this.data.idFornecedor}`).subscribe(
            data => {
              this.fornecedor = data;
            }
          );
        }
        if (this.data.idCentroCusto) {
          this.http.get<any>(`${environment.apiUrl}/financa/centro-custo/find/${this.data.idCentroCusto}`).subscribe(
            data => {
              this.centroCusto = data;
            }
          );
        }
      }
  }

  public addFornecedor(): void {
    const dialogRef = this.dialogService.open(FornecedorDialogComponent, {
      data: new Object,
      width: '850px',
      height: '500px',
      transitionOptions: '1ms',
      showHeader: false
    });

    dialogRef.onClose.subscribe(result => {
    });
  }
  public addCategoria(): void {
    let datas: any = new Object;

    let cat: Categoria = new Categoria;
    cat.origem = new KeyValue;
    cat.origem.code = 'financa';

    datas.data = cat;

    const dialogRef = this.dialogService.open(CategoriaDialogComponent, {
      data: datas,
      width: '850px',
      height: '500px',
      transitionOptions: '1ms',
      showHeader: false
    });

    dialogRef.onClose.subscribe(result => {
    });
  }
  public addCentroCusto(): void {
    const dialogRef = this.dialogService.open(CentroCustoDialogComponent, {
      data: new Object,
      width: '850px',
      height: '500px',
      transitionOptions: '1ms',
      showHeader: false
    });

    dialogRef.onClose.subscribe(result => {
    });
  }

  public onSelectCategoria(): void {
    if (this.categoria) {
      this.data.idCategoria = this.categoria._id;
    }
  }
  public onSelectFornecedor(): void {
    if (this.fornecedor) {
      this.data.idFornecedor = this.fornecedor._id;
      this.data.nmFornecedor = this.fornecedor.nome;
    }
  }
  public onSelectCentroCusto(): void {
    if (this.centroCusto) {
      this.data.idCentroCusto = this.centroCusto._id;
    }
  }

  public acFindCategoria(event): void {
    this.http.post<any>(`${environment.apiUrl}/categoria/lazylist`, this.createFilters(event.query)).subscribe(
      data => {
        this.autocompleteCategoria = data.result;
      },
      error => {
      }
    );
  }
  public acFindFornecedor(event): void {
    this.http.get<any>(`${environment.apiUrl}/fornecedor/pesquisar-nome-codigo/${event.query}`).subscribe(
      data => {
          this.autocompleteFornecedor = data;
      },
      error => {
      }
    );
  }
  public acFindCentroCusto(event): void {
    this.http.get<any>(`${environment.apiUrl}/financa/centro-custo/pesquisar-nome-codigo/${event.query}`).subscribe(
      data => {
          this.autocompleteCentroCusto = data;
      },
      error => {
      }
    );
  }

  public createData(): void {
    if (this.data == null) {
      this.data = new TituloPagar;
      this.data.statusPagamento = PagamentoStatus.PENDENTE;
    }
  }

  public createFilters(nome: string): Filters {
    const filters = new Filters();
    const filterName = new Filter('lk', 'descricao', this.clearSpecialCharacters(nome));
    const filterTipo = new Filter('eq', 'origem.code', 'financa');

    filters.offset = 0;
    filters.pageSize = 5;
    filters.orderField = "descricao";
    filters.orderDirection = 'asc';
    filters.filters = [filterName, filterTipo];

    return filters;
  }

  @HostListener('window:keydown.control.enter', ['$event'])
  keSave(event: KeyboardEvent) {
    this.save();
  }

  @HostListener('window:keydown.control.s', ['$event'])
  keSaveClose(event: KeyboardEvent) {
    this.saveClose();
  }

  @HostListener('window:keydown.esc', ['$event'])
  keClose(event: KeyboardEvent) {
    this.dialogRef.close();
  }
}