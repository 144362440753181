import { BaseEntity } from "../BaseEntity";
import { Documento } from "../suporte/documento";
import { DocumentoFiscal } from "../suporte/documentoFiscal";
import { Endereco } from "../suporte/endereco";
import { KeyValue } from "../suporte/keyValue";

export class Vendedor extends BaseEntity {
	public nome: string;
    public email: string;
    public telefone: string;
    public genero: string;
    public comissao: number;
    public comissaoTipo: KeyValue;
    public observacao: string;
    public nascimento: Date;
    public profissao: string;
    public naturalidade: string;
    public nomePai: string;
    public nomeMae: string;
    public cpfPai: string;
    public cpfMae: string;
    public endereco: Endereco;
    public documento: Documento;
    public documentoFiscal: DocumentoFiscal;
}