import { BaseEntity } from "../BaseEntity";
import { Categoria } from "./categoria";
import { KeyValue } from "../suporte/keyValue";

export class Servico extends BaseEntity {
	nome: string;
    descricao: string;
    idCategoria: string;
    unidadeMedida: KeyValue;
    valorVenda: number;
    valorPromocional: number;
    nbs: string;
    categoria: Categoria;
}
