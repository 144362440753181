import { BaseEntity } from "../BaseEntity";
import { Conta } from "./conta";

export class Usuario extends BaseEntity {

	nome: string;
	email: string;
	password: string;
	telefone: string;
	token: string;
	organizacao: string;
	codigoConfirmacao: string;
	contas: Conta[];

}