import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LayoutPublicComponent } from '../../suporte/layout-public/layout-public.component';
import { environment } from '@environments/environment';
import { HttpClient } from '@angular/common/http';
import { Usuario } from 'src/app/_entity/cadastro/usuario';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-recuperar-senha',
  templateUrl: './recuperar-senha.component.html',
  styleUrls: ['./recuperar-senha.component.css']
})
export class RecuperarSenhaComponent implements OnInit {

  loginForm: FormGroup;
  loading = false;
  submitted = false;
  error = false;
  time = new Date();

  bt = 'Continuar';
  msg = '';

  token: string = null;
  email: string = null;

  formSenha: boolean = false;
  validaToken: boolean = false;
  tokenValido: boolean = false;

  user: Usuario;
  password: string;

  public constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    public toastr: ToastrService,
    private app: LayoutPublicComponent,
    public http: HttpClient) {
  }

  ngAfterViewInit() {
    this.app.setTitle('Recupere seu ID Besales | BeSales');
  }

  ngOnInit() {
    this.token = this.route.snapshot.queryParams['token'];
    this.email = this.route.snapshot.queryParams['email'];

    if (this.token != null && this.token != undefined && this.email != null && this.email != undefined) {
      this.formSenha = true;
      this.validaToken = true;

      this.http.get<any>(`${environment.apiUrl}/valida-token?email=${this.email}&token=${this.token}`).subscribe(
        data => {
          this.validaToken = false;
          this.tokenValido = true;

          this.user = new Usuario;
          this.user.email = this.email;
          this.user.token = this.token;
        },
        error => {
          this.validaToken = false;
          this.tokenValido = false;
          this.error = true;
          this.msg = 'Token inválido ou expirado, por favor tente novamente';
        }
      );
    }
    else {
      this.loginForm = this.formBuilder.group({
        email: ['']
      });
    }
  }

  // convenience getter for easy access to form fields
  get f() { 
    if (!this.formSenha)
      return this.loginForm.controls; 
    else
      return null;
  }

  onSubmit() {
    this.error = false;
    this.msg = '';
    this.submitted = false;

    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!regex.test(this.f.email.value)){
      this.error = true;
      this.msg = 'E-mail informado é inválido';
      
      return;
    }

    this.loading = true;
    this.bt = 'Validando...';

    this.http.post<any>(`${environment.apiUrl}/recuperar-senha`, this.f.email.value).subscribe(
      data => {
        this.loading = false;
        this.submitted = true;
        this.bt = 'Continuar';
        this.msg = 'Um e-mail foi enviado com as instruções para a alteração da senha';

        this.f.email.setValue('');
      },
      error => {
        this.loading = false;
        this.error = true;
        this.bt = 'Continuar';
        this.msg = error;
      }
    );
  }

  public atualizarSenha(): void {
    this.error = false;

    if (this.user.password == null) {
      this.error = true;  
      this.msg = 'Informe a nova senha';
      return;
    }
    if (this.user.password.length < 6) {
      this.error = true;
      this.msg = 'Nova senha deve ter no mínimo 6 caracteres';
      return;
    }
    if (this.user.password != this.password) {
      this.error = true;
      this.msg = 'Confirmação de senha não confere';
      return;
    }

    this.http.post<any>(`${environment.apiUrl}/atualiza-senha`, this.user).subscribe(
      data => {
        this.toastr.success('Senha atualizada com sucesso');
        this.router.navigate(['/login']);
      },
      error => {
        this.loading = false;
        this.error = true;
        this.bt = 'Continuar';
        this.msg = error;
      }
    );
  }
}
