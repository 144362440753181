<div id="content" class="content content-full-width">

    <h1 class="page-header">BeSales Store</h1>
    
    <div class="vertical-box with-grid inbox bg-light">

        <div class="vertical-box-column width-200">
            <div class="vertical-box">

                <div class="wrapper d-none d-sm-none d-md-none d-lg-block">
                    <div class="d-flex align-items-center justify-content-center">
                        Menu
                    </div>
                </div>

                <div class="vertical-box-row d-lg-table-row collapse">
                    <div class="vertical-box-cell">
                        <div class="vertical-box-inner-cell">
                            <div class="keras-scrollbar">
                                <div class="wrapper p-0">
                                    <ul class="nav nav-inbox">
                                        <li [ngClass]="activeIndex == 0 ? 'active' : ''"><a [routerLink]="['/store']" [queryParams]="{ index: 0 }">Todos</a></li>
                                        <li [ngClass]="activeIndex == 1 ? 'active' : ''"><a [routerLink]="['/store']" [queryParams]="{ index: 1 }">Conta</a></li>
                                        <li [ngClass]="activeIndex == 2 ? 'active' : ''"><a [routerLink]="['/store']" [queryParams]="{ index: 2 }">E-commerce</a></li>
                                        <li [ngClass]="activeIndex == 3 ? 'active' : ''"><a [routerLink]="['/store']" [queryParams]="{ index: 3 }">Comércio</a></li>
                                        <li [ngClass]="activeIndex == 4 ? 'active' : ''"><a [routerLink]="['/store']" [queryParams]="{ index: 4 }">Serviço</a></li>
                                        <li [ngClass]="activeIndex == 5 ? 'active' : ''"><a [routerLink]="['/store']" [queryParams]="{ index: 5 }">Indústria</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

        <div class="vertical-box-column">
            <div class="vertical-box">
                <div class="vertical-box-row">
                    <div class="vertical-box-cell">
                        <div class="vertical-box-inner-cell bg-white">
                            <div class="keras-scrollbar">
                                <div class="bs-configuracao bs-dashboard">
                                    <div class="col-12">
                                        
                                        <div class="d-block d-lg-none m-b-20">
                                            <div class="row">
                                                <div class="col-12">
                                                    <a [routerLink]="['/store']" [queryParams]="{ index: 0 }" class="menu-small">Todos</a>
                                                    <a [routerLink]="['/store']" [queryParams]="{ index: 1 }" class="menu-small">Conta</a>
                                                    <a [routerLink]="['/store']" [queryParams]="{ index: 2 }" class="menu-small">E-commerce</a>
                                                    <a [routerLink]="['/store']" [queryParams]="{ index: 3 }" class="menu-small">Comércio</a>
                                                    <a [routerLink]="['/store']" [queryParams]="{ index: 4 }" class="menu-small">Serviço</a>
                                                    <a [routerLink]="['/store']" [queryParams]="{ index: 5 }" class="menu-small">Indústria</a>
                                                </div>
                                            </div>
                                        </div>

                                        <router-outlet></router-outlet>

                                        <p-tabView [(activeIndex)]="activeIndex">
                                            
                                            <p-tabPanel>
                                                <h4 class="m-b-30">Utilização</h4>
                                                <div class="row">
                                                    <div class="col-xl-3 col-md-6">
                                                        <div class="widget widget-stats bg-blue">
                                                            <div class="bs-widget-stats">
                                                                
                                                            </div>
                                                            <div class="stats-info">
                                                                <h4>ARMAZENAMENTO FOTOS</h4>
                                                                <p>10% <small>de 20 MB</small></p>
                                                            </div>
                                                            <div class="stats-link">
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-xl-3 col-md-6">
                                                        <div class="widget widget-stats bg-info">
                                                            <div class="bs-widget-stats">
                                                                
                                                            </div>
                                                            <div class="stats-info">
                                                                <h4>ARMAZENAMENTO ARQUIVOS</h4>
                                                                <p>60% <small>de 20 MB</small></p>
                                                            </div>
                                                            <div class="stats-link">
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-xl-3 col-md-6">
                                                        <div class="widget widget-stats bg-red">
                                                            <div class="bs-widget-stats">
                                                               
                                                            </div>
                                                            <div class="stats-info">
                                                                <h4>USUÁRIOS</h4>
                                                                <p>1 <small>de 1 Usuário</small></p>
                                                            </div>
                                                            <div class="stats-link">
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-xl-3 col-md-6">
                                                        <div class="widget widget-stats bg-blue">
                                                            <div class="bs-widget-stats">
                                                                
                                                            </div>
                                                            <div class="stats-info">
                                                                <h4>CONCORRENTES MONITORADOS</h4>
                                                                <p>0 <small>de 5 Concorrentes</small></p>
                                                            </div>
                                                            <div class="stats-link">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <h4 class="m-b-30 m-t-30">Meu plano</h4>
                                                <div class="row">

                                                    <div class="col-xl-3 col-md-6">
                                                        <div class="widget widget-stats bg-bs-white">
                                                            <div class="stats-icon"><i class="fa fa-desktop"></i></div>
                                                            <div class="stats-info">
                                                                <h4>PLANO ATUAL</h4>
                                                                <p>Básico</p>
                                                            </div>
                                                            <div class="stats-link">
                                                                <a href="javascript:;">Fazer Upgrade <i
                                                                        class="fa fa-arrow-alt-circle-right"></i></a>
                                                            </div>
                                                        </div>
                                                    </div>


                                                    <div class="col-xl-3 col-md-6">
                                                        <div class="widget widget-stats bg-bs-white">
                                                            <div class="stats-icon"><i class="fa fa-link"></i></div>
                                                            <div class="stats-info">
                                                                <h4>VALOR MÊS</h4>
                                                                <p>R$ 20.40</p>
                                                            </div>
                                                            <div class="stats-link">
                                                                <a href="javascript:;">Ver Detalhes <i
                                                                        class="fa fa-arrow-alt-circle-right"></i></a>
                                                            </div>
                                                        </div>
                                                    </div>


                                                    <div class="col-xl-3 col-md-6">
                                                        <div class="widget widget-stats bg-bs-white">
                                                            <div class="stats-icon"><i class="fa fa-users"></i></div>
                                                            <div class="stats-info">
                                                                <h4>PERIODICIDADE</h4>
                                                                <p>Mensal</p>
                                                            </div>
                                                            <div class="stats-link">
                                                                <a href="javascript:;">Ver Detalhes <i
                                                                        class="fa fa-arrow-alt-circle-right"></i></a>
                                                            </div>
                                                        </div>
                                                    </div>


                                                    <div class="col-xl-3 col-md-6">
                                                        <div class="widget widget-stats bg-bs-white">
                                                            <div class="stats-icon"><i class="fa fa-clock"></i></div>
                                                            <div class="stats-info">
                                                                <h4>SITUAÇÃO</h4>
                                                                <p>Ativo</p>
                                                            </div>
                                                            <div class="stats-link">
                                                                <a href="javascript:;">Ver Detalhes <i
                                                                        class="fa fa-arrow-alt-circle-right"></i></a>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-xl-3 col-md-6">
                                                        <div class="widget widget-stats bg-bs-white">
                                                            <div class="stats-icon"><i class="fa fa-desktop"></i></div>
                                                            <div class="stats-info">
                                                                <h4>QTE DE ACESSOS</h4>
                                                                <p>922</p>
                                                            </div>
                                                            <div class="stats-link">
                                                                <a href="javascript:;">Ver Detalhes <i
                                                                        class="fa fa-arrow-alt-circle-right"></i></a>
                                                            </div>
                                                        </div>
                                                    </div>


                                                    <div class="col-xl-3 col-md-6">
                                                        <div class="widget widget-stats bg-bs-white">
                                                            <div class="stats-icon"><i class="fa fa-link"></i></div>
                                                            <div class="stats-info">
                                                                <h4>ÚLTIMO ACESSO</h4>
                                                                <p>10/10/2023 12:23</p>
                                                            </div>
                                                            <div class="stats-link">
                                                                <a href="javascript:;">Ver Detalhes <i
                                                                        class="fa fa-arrow-alt-circle-right"></i></a>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-xl-3 col-md-6">
                                                        <div class="widget widget-stats bg-bs-white">
                                                            <div class="stats-icon"><i class="fa fa-users"></i></div>
                                                            <div class="stats-info">
                                                                <h4>FORMA DE PAGAMENTO</h4>
                                                                <p>Cartão de Crédito</p>
                                                            </div>
                                                            <div class="stats-link">
                                                                <a href="javascript:;">Ver Detalhes <i
                                                                        class="fa fa-arrow-alt-circle-right"></i></a>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-xl-3 col-md-6">
                                                        <div class="widget widget-stats bg-bs-white">
                                                            <div class="stats-icon"><i class="fa fa-users"></i></div>
                                                            <div class="stats-info">
                                                                <h4>ÚLTIMO PAGAMENTO</h4>
                                                                <p>-</p>
                                                            </div>
                                                            <div class="stats-link">
                                                                <a href="javascript:;">Ver Detalhes <i
                                                                        class="fa fa-arrow-alt-circle-right"></i></a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                </div>
                                            </p-tabPanel>

                                            <p-tabPanel>
                                                
                                            </p-tabPanel>

                                            <p-tabPanel>
                                                <h4 class="m-b-30">E-commerce</h4>
                                                <div class="row">
                                                    <div class="col-xl-3 col-md-6">
                                                        <div class="widget widget-stats bg-bs-white">
                                                            <div class="stats-icon">
                                                                <img src="../../assets/images/ml.svg" class="m-b-10">
                                                            </div>
                                                            <div class="stats-info">
                                                                <h3>MercadoLivre</h3>
                                                                <small>Integração com Mercado Livre</small>
                                                            </div>
                                                            <div class="stats-link">
                                                                <a routerLink="/store/mercado-libre">Instalar <i class="fa fa-arrow-alt-circle-right"></i></a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </p-tabPanel>

                                            <p-tabPanel>
                                                <h4 class="m-b-30">Histórico de Pagamento</h4>
                                                <div class="row">
                                                    <div class="col-xl-3 col-md-6">
                                                    </div>
                                                </div>
                                            </p-tabPanel>
                                        </p-tabView>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>