<div id="content" class="content content-full-width">

    <h1 class="page-header">Informações da minha conta BeSales</h1>
    
    <div class="vertical-box with-grid inbox bg-light">

        <div class="vertical-box-column width-200">
            <div class="vertical-box">

                <div class="wrapper d-none d-sm-none d-md-none d-lg-block">
                    <div class="d-flex align-items-center justify-content-center">
                        Menu
                    </div>
                </div>

                <div class="vertical-box-row d-lg-table-row collapse">
                    <div class="vertical-box-cell">
                        <div class="vertical-box-inner-cell">
                            <div class="keras-scrollbar">
                                <div class="wrapper p-0">
                                    <ul class="nav nav-inbox">
                                        <li [ngClass]="activeIndex == 0 ? 'active' : ''"><a (click)="activeIndex = 0"
                                                href="javascript:;">Geral</a></li>
                                        <li [ngClass]="activeIndex == 1 ? 'active' : ''"><a (click)="activeIndex = 1"
                                                href="javascript:;">Cobrança</a></li>
                                        <li [ngClass]="activeIndex == 2 ? 'active' : ''"><a (click)="activeIndex = 2"
                                                href="javascript:;">Forma de Pagamento</a></li>
                                        <li [ngClass]="activeIndex == 3 ? 'active' : ''"><a (click)="activeIndex = 3"
                                                href="javascript:;">Histórico de Pagamento</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

        <div class="vertical-box-column">
            <div class="vertical-box">
                <div class="vertical-box-row">
                    <div class="vertical-box-cell">
                        <div class="vertical-box-inner-cell bg-white">
                            <div class="keras-scrollbar">
                                <div class="bs-configuracao bs-dashboard">
                                    <div class="col-12">
                                        
                                        <p-tabView [(activeIndex)]="activeIndex">
                                            
                                            
                                            
                                            <p-tabPanel>
                                                <h4 class="m-b-30">Utilização</h4>
                                            </p-tabPanel>

                                            <p-tabPanel>
                                                <h4 class="m-b-30">Informações de Cobrança</h4>
                                            </p-tabPanel>

                                            <p-tabPanel>
                                                <h4 class="m-b-30">Forma de Pagamento</h4>
                                                <div class="row">
                                                    <div class="col-xl-3 col-md-6">
                                                    </div>
                                                </div>
                                            </p-tabPanel>

                                            <p-tabPanel>
                                                <h4 class="m-b-30">Histórico de Pagamento</h4>
                                                <div class="row">
                                                    <div class="col-xl-3 col-md-6">
                                                    </div>
                                                </div>
                                            </p-tabPanel>
                                        </p-tabView>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>