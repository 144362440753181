import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-integracao',
  templateUrl: './integracao.component.html',
  styleUrls: ['./integracao.component.css']
})
export class IntegracaoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
