import { HttpClient } from '@angular/common/http';
import { Component, HostListener, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from 'src/app/_services';
import { BaseService } from 'src/app/_services/base.service';
import { BaseServiceDialog } from 'src/app/_services/base.service.dialog';
import { LayoutPrivateComponent } from 'src/app/suporte/layout-private/layout-private.component';
import { Categoria } from 'src/app/_entity/cadastro/categoria';
import { KeyValue } from 'src/app/_entity/suporte/keyValue';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { Filters } from 'src/app/_entity/filters';
import { Filter } from 'src/app/_entity/filter';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng-lts/dynamicdialog';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-categoria',
  templateUrl: './categoria.component.html',
  styleUrls: ['./categoria.component.css'],
  providers: [DialogService]
})
export class CategoriaComponent extends BaseService implements OnInit {

  constructor(
    public app: LayoutPrivateComponent,
    public http: HttpClient,
    public dialog: DialogService,
    public toastr: ToastrService,
    public translate: TranslateService,
    private authenticationService: AuthenticationService) {

      super('categoria', app, http, dialog, toastr, translate);
      this.dialogwidth = '600px';
      this.dialogheight = '350px';
  }

  ngOnInit(): void {
    this.find(null);
    this.dialogClass = CategoriaDialogComponent;
    this.authService = this.authenticationService;

    this.buildFilterItens();
  }

  public buildFilterItens(): void {
    this.filters.filters[0].att = 'descricao';
    this.filters.filters[0].attAlias = 'Descrição';

    this.filterItens = [{
          label: 'Nome',
          command: () => {
          this.filterChangeAtt('descricao', 'Descrição')
          }
      },
      {
          label: 'Categoria',
          command: () => {
          this.filterChangeAtt('origem.value', 'Origem')
          }
      }];
  }
}

@Component({
  selector: 'app-categoria-dialog',
  templateUrl: 'categoria.dialog.component.html',
  styleUrls: ['./categoria.component.css']
})
export class CategoriaDialogComponent extends BaseServiceDialog implements OnInit {

  public arvoreCategoria: string = null;
  public categorias: Categoria[] = [];

  constructor(
    public ref: DynamicDialogRef, 
    public config: DynamicDialogConfig,
    public http: HttpClient,
    public toastr: ToastrService,
    public translate: TranslateService) {

      super('categoria', http, toastr, translate, ref, config.data);
      // Caso seja uma edicao de iniciativa vindo da lista de iniciatias
      if (config.data && config.data.data) {
        this.data = config.data.data;
        this.arvoreCategoria = this.data.arvoreCategoriaTexto;
      }
      // Caso seja a inclusão de uma ação vinda de outra lista. Ex. Cliente
      else if (config.data && config.data.contato) {
        this.createData();
        this.data.contato = config.data.contato;
      }
      // Caso seja o cadastro de uma nova iniciativa
      else {
        this.createData();
      }
  }

  ngOnInit() {
    // Caso seja uma iniciativa ja criada para edicao, busca a ultima
    // versao no banco de dados e apos inicializa os autocompletes
    if (this.data && this.data._id) {
      this.find(this.data._id);
    }
    // Caso seja uma nova apenas inicializa os autocompletes
    else {
      this.afterFind();
    }
  }

  // Apos executar a consulta faz uma callback a finalizar
  public afterFind(): void {
    // Caso nao encontre a iniciativa alerta e fecha o dialog
    if (!this.data) {
      this.createData();
      this.datas.reload = true;
      this.toastr.error('Registro não encontrado, pode ser que foi excluído por outro usuário');
      this.close();
    }
  }

  public onInputCategoriaPaiBlur(): void {
    if (this.data && this.data.categoriaPai && this.data.categoriaPai._id) {
      this.data.idCategoriaPai = this.data.categoriaPai._id;
    }
  }

  public autocomplete(event): void {
    this.http.post<any>(`${environment.apiUrl}/categoria/lazylist`, this.createFilters(event.query)).subscribe(
      data => {
          this.categorias = data.result;
      },
      error => {
      }
    );
  }

  // Sobscreve  o metodo de criacao de filtro do autocomplete
  public createFilters(nome: string): Filters {
    const filters = new Filters();
    const filter1 = new Filter('eq', 'origem.code', this.data.origem.code);
    const filter2 = new Filter('lk', 'descricao', this.clearSpecialCharacters(nome));
  
    filters.offset = 0;
    filters.pageSize = 5;
    filters.orderField = "descricao";
    filters.orderDirection = 'asc';
    filters.filters = [filter1, filter2];
  
    return filters;
  }

  public createData(): void {
    if (this.data == null) {
      this.data = new Categoria();
      this.data.origem = new KeyValue();
      this.data.origem.code = 'produto';
    }
  }

  public beforeSave(callback: (valid: boolean) => void): void {
    if (!this.data || !this.data.descricao || this.data.descricao.trim().length <= 1) {
      this.toastr.error('Campo Descrição deve ser preenchido.');
      callback(false);
    }
    else {
      callback(true);
    }
  }

  public afterSave(baseDoc: any): void {
    let origem = baseDoc.origem;
    let categoriaPai = baseDoc.categoriaPai;
    this.data = null;
    this.createData();
    this.toastr.success('', 'Operação realizada com sucesso');

    if (this.saveAndClose) {
      this.close();
    }
    else {
      if (categoriaPai) {
        this.data.categoriaPai = categoriaPai;
        this.data.idCategoriaPai = categoriaPai._id;
      }
      if (origem) {
        this.data.origem = origem;
      }
    }
  }

  @HostListener('window:keydown.control.enter', ['$event'])
  keSave(event: KeyboardEvent) {
    this.save();
  }

  @HostListener('window:keydown.control.s', ['$event'])
  keSaveClose(event: KeyboardEvent) {
    this.saveClose();
  }

  @HostListener('window:keydown.esc', ['$event'])
  keClose(event: KeyboardEvent) {
    this.dialogRef.close();
  }
}