import { HttpClient } from '@angular/common/http';
import { Component, HostListener, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng-lts/dynamicdialog';
import { PropostaComercial } from 'src/app/_entity/venda/propostaComercial';
import { BaseServiceDialog } from 'src/app/_services/base.service.dialog';
import { environment } from '@environments/environment';
import { Filters } from 'src/app/_entity/filters';
import { Filter } from 'src/app/_entity/filter';
import { Contato } from 'src/app/_entity/cadastro/contato';
import { Vendedor } from 'src/app/_entity/cadastro/vendedor';
import { PropostaItem } from 'src/app/_entity/suporte/propostaItem';
import { Produto } from 'src/app/_entity/cadastro/produto';
import { Servico } from 'src/app/_entity/cadastro/servico';
import { PropostaStatus } from 'src/app/_entity/suporte/propostaStatus';
import { Pedido } from 'src/app/_entity/venda/pedido';
import { PedidoItem } from 'src/app/_entity/suporte/pedidoItem';
import { PedidoStatus } from 'src/app/_entity/suporte/pedidoStatus';
import { PagamentoForma } from 'src/app/_entity/suporte/pagamentoForma';
import { PedidoOrigem } from 'src/app/_entity/suporte/pedidoOrigem';

@Component({
  selector: 'app-pedido.dialog',
  templateUrl: './pedido.dialog.component.html'
})
export class PedidoDialogComponent extends BaseServiceDialog implements OnInit {

  public data: Pedido;
  public upload_path_arquivo: string;

  public contato: Contato;
  public vendedor: Vendedor;
  // public propostaItens: PropostaItem[] = [];

  public pedidoItem: PedidoItem;

  public autocompleteCliente: any[] = [];
  public autocompleteVendedor: any[] = [];
  public autocompleteProdutoServico: any[] = [];

  // public propostaStatus = Object.values(PropostaStatus);

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public http: HttpClient,
    public toastr: ToastrService,
    public translate: TranslateService) {

    super('pedido', http, toastr, translate, ref, config.data);

    this.datas = config.data;
    this.data = this.datas.data;
  }

  ngOnInit(): void {
    // Caso seja uma iniciativa ja criada para edicao, busca a ultima
    // versao no banco de dados e apos inicializa os autocompletes
    if (this.data && this.data._id) {
      this.find(this.data._id);
    }
    // Caso seja uma nova apenas inicializa os autocompletes
    else {
      this.createData();
    }
  }

  public afterFind(): void {
    if (this.data.idCliente) {
      this.contato = new Contato;
      this.contato._id = this.data.idCliente;
      this.contato.nome = this.data.nmCliente;
    }
    if (this.data.idVendedor) {
      this.vendedor = new Vendedor;
      this.vendedor._id = this.data.idVendedor;
      this.vendedor.nome = this.data.nmVendedor;
    }
    if (this.data.status == null) {
      this.data.pedidoStatus = PedidoStatus.FINALIZADO;
    }
  }

  public beforeSave(callback: (valid: boolean) => void): void {
    if (!this.data) {
      this.toastr.error('Pedido inválido / vazio ou nulo');
      callback(false);
    }
    else if (!this.data.pedidoItens) {
      this.toastr.error('Pedido inválido / nenhum item selecionado');
      callback(false);
    }
    else {
      callback(true);
    }
  }

  public addItem(): void {
    this.data.pedidoItens.push(new PedidoItem);
  }
  public removeItem(index: number): void {
    this.data.pedidoItens.splice(index, 1);

    this.data.valorTotal = 0;
    this.data.valorSubTotal = 0;

    this.data.pedidoItens.forEach(element => {
      this.data.valorTotal += element.valorTotal;
      this.data.valorSubTotal += element.valorTotal;
    });

    this.data.valorTotal -= this.data.valorDesconto;
  }

  public calculaSubTotal(index: number): void {
    this.data.pedidoItens[index].valorTotal = this.data.pedidoItens[index].valorUnitario * this.data.pedidoItens[index].quantidade;

    this.data.valorTotal = 0;
    this.data.valorSubTotal = 0;

    this.data.pedidoItens.forEach(element => {
      this.data.valorTotal += element.valorTotal;
      this.data.valorSubTotal += element.valorTotal;
    });

    this.data.valorTotal -= this.data.valorDesconto;
  }

  public calculaTotal(): void {
    if (this.data.valorDesconto >= this.data.valorSubTotal) {
      this.toastr.error('Desconto não pode ser maior que o valor total');
    }
    else {
      this.data.valorTotal = this.data.valorSubTotal - this.data.valorDesconto;
    }
  }

  public calculaTroco(): void {
    if (this.data.valorRecebido < this.data.valorTotal) {
      this.data.valorTroco = 0;
      this.toastr.error('Valor recebido não pode ser menor que o valor total');
    }
    else {
      this.data.valorTroco = this.data.valorRecebido - this.data.valorTotal;
    }
  }

  public onInputClienteSelect(): void {
    if (this.contato) {
      this.data.idCliente = this.contato._id;
      this.data.nmCliente = this.contato.nome;
    }
  }
  public onInputProdutoSelect(index: number): void {
    if (this.pedidoItem) {
      // this.data.pedidoItens[index].tipo = this.pedidoItens.tipo;
      this.data.pedidoItens[index].quantidade = this.pedidoItem.quantidade;
      this.data.pedidoItens[index].idProduto = this.pedidoItem.idProduto;
      this.data.pedidoItens[index].produtoSku = this.pedidoItem.produtoSku;
      this.data.pedidoItens[index].nomeProduto = this.pedidoItem.nomeProduto;
      this.data.pedidoItens[index].valorUnitario = this.pedidoItem.valorUnitario;

      this.data.pedidoItens[index].valorTotal = this.pedidoItem.valorUnitario * this.pedidoItem.quantidade;

      this.data.valorTotal = 0;
      this.data.valorSubTotal = 0;

      this.data.pedidoItens.forEach(element => {
        this.data.valorTotal += element.valorTotal;
        this.data.valorSubTotal += element.valorTotal;
    });
      
    this.data.valorTotal -= this.data.valorDesconto;
      this.pedidoItem = new PedidoItem;
    }
  }
  public onInputVendedorSelect(): void {
    if (this.vendedor) {
      this.data.idVendedor = this.vendedor._id;
      this.data.nmVendedor = this.vendedor.nome;
    }
  }

  public acFind(event): void {
    this.http.get<any>(`${environment.apiUrl}/cliente/pesquisar-nome-codigo/${event.query}`).subscribe(
      data => {
          this.autocompleteCliente = data;
      },
      error => {
      }
    );
  }

  public acFindVendedor(event): void {
    this.http.get<any>(`${environment.apiUrl}/vendedor/pesquisar-nome-codigo/${event.query}`).subscribe(
      data => {
          this.autocompleteVendedor = data;
      },
      error => {
      }
    );
  }

  public acFindProduto(event): void {
    this.http.get<any>(`${environment.apiUrl}/pedido/pesquisar-produto-servico/${event.query}`).subscribe(
      data => {
        this.autocompleteProdutoServico = data;
      },
      error => {
      }
    );
  }

  // Sobscreve  o metodo de criacao de filtro do autocomplete
  public createFilters(nome: string): Filters {
    const filters = new Filters();
    const filter1 = new Filter('lk', 'nome', this.clearSpecialCharacters(nome));
    const filter2 = new Filter('eq', 'status.code', 'active');
  
    filters.offset = 0;
    filters.pageSize = 5;
    filters.orderField = "nome";
    filters.orderDirection = 'asc';
    filters.filters = [filter1, filter2];
  
    return filters;
  }

  public createData(): void {
    if (this.data == null) {
      this.data = new Pedido;
    }
    if (this.data.pagamentoForma == null) {
      this.data.pagamentoForma = new PagamentoForma('cc', 'Cartão de Crédito');
    }
    if (this.data.pedidoItens == null) {
      this.data.pedidoItens = [];
    }
    this.data.valorSubTotal = 0;
    this.data.valorDesconto = 0;
    this.data.valorTotal = 0;
    this.data.qteParcelas = 1;

    this.data.pedidoStatus = PedidoStatus.FINALIZADO;
    this.data.pedidoOrigem = PedidoOrigem.PEDIDO_VENDA;
  }

  @HostListener('window:keydown.control.enter', ['$event'])
  keSave(event: KeyboardEvent) {
    this.save();
  }

  @HostListener('window:keydown.control.s', ['$event'])
  keSaveClose(event: KeyboardEvent) {
    this.saveClose();
  }

  @HostListener('window:keydown.esc', ['$event'])
  keClose(event: KeyboardEvent) {
    this.dialogRef.close();
  }
}