<div id="page-container">

    <div class="login login-with-news-feed">
  
      <div class="news-feed">
        <div class="news-image" style="background-image: url('/assets/images/sonoma.jpeg')"></div>
        <div class="news-caption">
          <h4 class="caption-title"><b><span class="text-primary">Be</span>Sales</b></h4>
          <p>
            Experimente a simplicidade do <span class="text-primary">Be</span>Sales. Mais seguro, inteligente e fácil de utilizar!
          </p>
        </div>
      </div>
  
  
      <div class="right-content">
  
        <div class="login-header">
          <div class="brand">
            <span class="logo"></span> <span class="text-primary">Be</span>Sales
          </div>
        </div>
        
        <div class="alert alert-success m-t-30 m-b-30" *ngIf="submitted">
            {{msg}}
        </div>
        <div class="alert alert-danger m-t-30 m-b-30" *ngIf="error">
            {{msg}}
        </div>
  
        <div class="login-content" *ngIf="!formSenha">
            <h4>Redefinir sua senha</h4>
            
            <p>Para continuar, insira o endereço de e-mail que você usa com sua conta.</p>
            
            <form [formGroup]="loginForm" (ngSubmit)="onSubmit()" class="margin-bottom-0">
                <div class="form-group m-b-15">
                    <input autofocus required type="text" placeholder="E-mail" formControlName="email" class="form-control form-control-lg" [ngClass]="{ 'is-invalid': error }" />
                </div>
                <div class="login-buttons">
                    <button class="btn btn-success btn-block btn-lg" [disabled]="loading">{{bt}}</button>
                </div>
                <div class="m-t-20 text-inverse text-center">
                    Voltar para o Login? Clique <a routerLink="/login">aqui</a>.
                </div>
                
                <hr>
                <p class="text-center text-grey-darker mb-0">
                    &copy; BeSales All Right Reserved {{ time | date: 'yyyy' }}
                </p>
            </form>
        </div>
        
        <div class="login-content" *ngIf="formSenha">
            <p *ngIf="validaToken">Validando Token</p>
            
            <div *ngIf="tokenValido">
                <div class="form-group m-b-15">
                    <label class="control-label">Nova senha <span class="text-danger">*</span></label>
                    <div class="row m-b-15">
                        <div class="col-md-12">
                            <input type="password" [(ngModel)]="user.password" pPassword promptLabel="Informe uma senha" weakLabel="Fraco" mediumLabel="Médio" strongLabel="Forte" placeholder="Senha" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
                        </div>
                    </div>
                    <label class="control-label">Confirme a nova senha <span class="text-danger">*</span></label>
                    <div class="row m-b-15">
                        <div class="col-md-12">
                            <input type="password" [(ngModel)]="password" placeholder="Confirme a senha" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.repassword.errors }" />
                        </div>
                    </div>
                </div>
                <div class="login-buttons">
                    <button (click)="atualizarSenha()" class="btn btn-success btn-block btn-lg" [disabled]="loading">{{bt}}</button>
                </div>
            </div>
        </div>
  
      </div>
  
    </div>