import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, NavigationEnd } from '@angular/router';
import { AuthenticationService } from 'src/app/_services';

declare let gtag: Function;

@Component({
  selector: 'app-layout-public',
  templateUrl: './layout-public.component.html',
  styleUrls: ['./layout-public.component.css']
})
export class LayoutPublicComponent {

  currentUser: any;

  public constructor(
    private titleService: Title,
    public router: Router,
    private authenticationService: AuthenticationService) {

    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);

    this.router.events.subscribe(event => {

      if(event instanceof NavigationEnd){
        gtag('config', 'UA-170995864-1',
          {
            'page_path': event.urlAfterRedirects
          }
        );
      }
    });
  }

  public navTitle: string = '';

  public setTitle( newTitle: string) {
    this.titleService.setTitle( newTitle );
  }

  public setNavTitle( newTitle: string) {
    this.navTitle = newTitle;
  }

  logout() {
    this.authenticationService.logout();
    this.router.navigate(['/login']);
  }
}
