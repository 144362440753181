import { BaseEntity } from "../BaseEntity";
import { Banco } from "../suporte/banco";

export class ContaCorrente extends BaseEntity {

	idBanco!: string;
    agencia!: number;
    conta!: number;
    contaDigito!: number;
    titular!: string;
    saldo!: number;
    dtAbertura!: Date;
    tipo!: string;
    apelido!: string;
    observacoes!: string;
    banco!: Banco;
    bancos!: Banco[];
}
