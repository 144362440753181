import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { DialogService } from 'primeng-lts/dynamicdialog';
import { BaseService } from 'src/app/_services/base.service';
import { LayoutPrivateComponent } from 'src/app/suporte/layout-private/layout-private.component';
import { Upload } from 'src/app/_services/base.service.import.dialog';
import { PrecificacaoAnuncioDialogComponent } from './anuncio.dialog.component';

@Component({
  selector: 'app-anuncio',
  templateUrl: './anuncio.component.html',
  styleUrls: ['./anuncio.component.css'],
  providers: [DialogService]
})
export class PrecificacaoAnuncioComponent extends BaseService implements OnInit {
  
  public upload: Upload;

  constructor(
    public app: LayoutPrivateComponent,
    public http: HttpClient,
    public dialog: DialogService,
    public toastr: ToastrService,
    public translate: TranslateService) {

      super('integracao/mercadolibre/produto', app, http, dialog, toastr, translate);
      
      this.dialogClass = PrecificacaoAnuncioDialogComponent;
  }

  ngOnInit(): void {
    this.find();
    this.buildFilterItens();
  }

  public buildFilterItens(): void {
    this.filters.filters[0].att = 'nome';
    this.filters.filters[0].attAlias = 'Nome';

    this.filterItens = [{
          label: 'Nome',
          command: () => {
            this.filterChangeAtt('nome', 'Nome')
          }
      },
      {
          label: 'Categoria',
          command: () => {
            this.filterChangeAtt('categorias.descricao', 'Categoria')
          }
      },
      {
        label: 'NBS',
        command: () => {
          this.filterChangeAtt('nbs', 'NBS')
        }
      }];
  }
}