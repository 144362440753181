import { BaseEntity } from '../BaseEntity';
import { ProdutoAnexo } from '../suporte/produtoAnexo';
import { PagamentoForma } from '../suporte/pagamentoForma';
import { ServicoOrdemAcao } from '../suporte/servicoOrdemAcao';
import { ServicoOrdemItem } from '../suporte/servicoOrdemItem';
import { ServicoOrdemStatus } from '../suporte/servicoOrdemStatus';

export class ServicoOrdem extends BaseEntity {
  public numero!: number;
    public idCliente!: string;
    public nmCliente!: string;
    public descricao!: string;
    public observacoes!: string;
    public dtInicio!: Date;
    public dtPrevisao!: Date;
    public valorTotal!: number;
    public valorDesconto!: number;
    public valorSubTotal!: number;
    public qteParcelas!: number;
    public idVendedor!: string;
    public nmVendedor!: string;
    public nmTecnico!: string;
    public ordemStatus!: ServicoOrdemStatus;
    public pagamentoForma!: PagamentoForma;
    public ordemItens!: ServicoOrdemItem[];
    public ordemAcoes!: ServicoOrdemAcao[];
    public anexos!: ProdutoAnexo[];
}