<div id="content" class="content content-full-width">

    <h1 class="page-header">Performance de Precificação</h1>

    <div class="vertical-box with-grid inbox bg-light">

        <div class="vertical-box-column width-200">
            <div class="vertical-box">

                <div class="wrapper d-none d-sm-none d-md-none d-lg-block">
                    <div class="d-flex align-items-center justify-content-center">
                        Menu
                    </div>
                </div>

                <div class="vertical-box-row d-lg-table-row collapse">
                    <div class="vertical-box-cell">
                        <div class="vertical-box-inner-cell">
                            <div class="keras-scrollbar">
                                <div class="wrapper p-0">
                                    <ul class="nav nav-inbox">
                                        <li [ngClass]="activeIndex == 0 ? 'active' : ''"><a (click)="activeIndex = 0" href="javascript:;">Vendas</a></li>
                                        <li [ngClass]="activeIndex == 1 ? 'active' : ''"><a (click)="activeIndex = 1" href="javascript:;">Finanças</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

        <div class="vertical-box-column">
            <div class="vertical-box">
                <div class="vertical-box-row">
                    <div class="vertical-box-cell">
                        <div class="vertical-box-inner-cell bg-white">
                            <div class="keras-scrollbar">
                                <div class="bs-configuracao bs-dashboard">
                                    <div class="col-12">
                                        <h4>Bom dia, Cadu</h4>
                                        <div class="d-sm-flex align-items-center mb-3 m-t-20">
                                            <div ngbDropdown container="body">
                                                <button pButton ngbDropdownToggle icon="far fa-lg fa-fw fa-calendar-alt" type="button" label="Últimos 7 dias" class="p-button-outlined p-button-info"></button>
                                                <div ngbDropdownMenu>
                                                    <button ngbDropdownItem>Mês atual</button>
                                                    <button ngbDropdownItem>Dia atual</button>
                                                    <button ngbDropdownItem>Últimos 7 dias</button>
                                                    <button ngbDropdownItem>Últimos 30 dias</button>
                                                    <button ngbDropdownItem>Últimos 6 meses</button>
                                                    <button ngbDropdownItem>Último ano</button>
                                                </div>
                                            </div>
                                            <div ngbDropdown container="body" class="m-l-10">
                                                <button pButton ngbDropdownToggle icon="fas fa-lg fa-fw fa-filter" type="button" label="Filtro" class="p-button-outlined p-button-info"></button>
                                                <div ngbDropdownMenu>
                                                    <button ngbDropdownItem>Atualizar agora</button>
                                                    <button ngbDropdownItem>Atualizar a cada 10 segundos</button>
                                                    <button ngbDropdownItem>Atualizar a cada 30 segundos</button>
                                                    <button ngbDropdownItem>Atualizar a cada 60 segundos</button>
                                                </div>
                                            </div>
                                            <div ngbDropdown container="body" class="m-l-10">
                                                <button pButton ngbDropdownToggle icon="fas fa-lg fa-fw fa-history" type="button" label="Atualizado agora" class="p-button-outlined p-button-info"></button>
                                                <div ngbDropdownMenu>
                                                    <button ngbDropdownItem>Atualizar agora</button>
                                                    <button ngbDropdownItem>Atualizar a cada 10 segundos</button>
                                                    <button ngbDropdownItem>Atualizar a cada 30 segundos</button>
                                                    <button ngbDropdownItem>Atualizar a cada 60 segundos</button>
                                                </div>
                                            </div>
                                        </div>
                                        <p-tabView [(activeIndex)]="activeIndex">
                                    
                                            <p-tabPanel>
                                                <div class="row">                                                    
                                                    <div class="col-3">
                                                        <div class="widget widget-stats bg-gradient-orange m-b-10">
                                                            <div class="stats-icon stats-icon-lg"><i class="fa fa-file-alt fa-fw"></i>
                                                            </div>
                                                            <div class="stats-content">
                                                                <div class="stats-title">PEDIDOS</div>
                                                                <div class="stats-number">7,842,900</div>
                                                                <div class="stats-progress progress">
                                                                    <div class="progress-bar" style="width: 70.1%;"></div>
                                                                </div>
                                                                <div class="stats-desc">Better than last week (70.1%)</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-3">
                                                        <div class="widget widget-stats bg-gradient-blue m-b-10">
                                                            <div class="stats-icon stats-icon-lg"><i class="fa fa-globe fa-fw"></i>
                                                            </div>
                                                            <div class="stats-content">
                                                                <div class="stats-title">VENDAS ONLINE</div>
                                                                <div class="stats-number">7,842,900</div>
                                                                <div class="stats-progress progress">
                                                                    <div class="progress-bar" style="width: 70.1%;"></div>
                                                                </div>
                                                                <div class="stats-desc">Better than last week (70.1%)</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-3">
                                                        <div class="widget widget-stats bg-gradient-blue m-b-10">
                                                            <div class="stats-icon stats-icon-lg"><i class="fa fa-archive fa-fw"></i>
                                                            </div>
                                                            <div class="stats-content">
                                                                <div class="stats-title">VENDAS FÍSICAS</div>
                                                                <div class="stats-number">7,842,900</div>
                                                                <div class="stats-progress progress">
                                                                    <div class="progress-bar" style="width: 0%;"></div>
                                                                </div>
                                                                <div class="stats-desc">Better than last week (70.1%)</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-3">
                                                        <div class="widget widget-stats bg-gradient-purple m-b-10">
                                                            <div class="stats-icon stats-icon-lg"><i class="fa fa-dollar-sign fa-fw"></i>
                                                            </div>
                                                            <div class="stats-content">
                                                                <div class="stats-title">VALOR TOTAL</div>
                                                                <div class="stats-number">7,842,900</div>
                                                                <div class="stats-progress progress">
                                                                    <div class="progress-bar" style="width: 70.1%;"></div>
                                                                </div>
                                                                <div class="stats-desc">Better than last week (70.1%)</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row m-t-20">
                                                    <div class="col-6">
                                                        <p-panel header="Total das vendas">
                                                            <h4 class="m-b-30 m-t-10">R$ 1.454,342,92</h4>
                                                            <p-chart type="bar" [data]="chtTotalVendas" [options]="barChartOptions"></p-chart>
                                                        </p-panel>
                                                    </div>
                                                    <div class="col-6">
                                                        <p-panel header="Ticket médio">
                                                            <h4 class="m-b-30 m-t-10">R$ 42,92</h4>
                                                            <p-chart type="bar" [data]="chtTicketMedio" [options]="barChartOptions"></p-chart>
                                                        </p-panel>
                                                    </div>
                                                </div>                                                
                                                <div class="row m-t-20">
                                                    <div class="col-6">
                                                        <p-panel header="Pedido por origem">
                                                            <!-- <p-chart type="bar" [data]="chtTotalVendas" [options]="barChartOptions"></p-chart> -->
                                                            <div class="row justify-content-center" style="height: 272px;">
                                                                <div class="col-md-6 align-self-center text-center">
                                                                    <img class="img-fluid mx-auto d-block" src="../../assets/images/bg-dash.svg">
                                                                    <p class="m-t-30 font-weight-bold">Coletando informações.</p>
                                                                </div>
                                                            </div>
                                                        </p-panel>
                                                    </div>
                                                    <div class="col-6">
                                                        <p-panel header="Top 10 Produtos mais vendidos">
                                                            <!-- <p-chart type="horizontalBar" [data]="chtTop10Produtos"></p-chart> -->
                                                            
                                                            <div class="row justify-content-center" style="height: 272px;">
                                                                <div class="col-md-6 align-self-center text-center">
                                                                    <img class="img-fluid mx-auto d-block" src="../../assets/images/bg-dash.svg">
                                                                    <p class="m-t-30 font-weight-bold">Coletando informações.</p>
                                                                </div>
                                                            </div>
                                                        </p-panel>
                                                    </div>
                                                </div>
                                                <div class="row m-t-20 m-b-30">
                                                    <div class="col-6">
                                                        <p-panel header="Horários de maiores vendas">
                                                            <p-chart type="bar" [data]="chtHorarioVendas"></p-chart>
                                                        </p-panel>
                                                    </div>
                                                    <div class="col-6">
                                                        <p-panel header="Vendas x Devoluções">
                                                            <p-chart type="bar" [data]="chtVendaDevolucao"></p-chart>
                                                        </p-panel>
                                                    </div>
                                                </div>
                                            </p-tabPanel>
                                            
                                
                                            <p-tabPanel>
                                                <div class="row">                                                    
                                                    <div class="col-3">
                                                        <div class="widget widget-stats bg-gradient-orange m-b-10">
                                                            <div class="stats-icon stats-icon-lg"><i class="fa fa-file-alt fa-fw"></i>
                                                            </div>
                                                            <div class="stats-content">
                                                                <div class="stats-title">SALDO ATUAL</div>
                                                                <div class="stats-number">7,842,900</div>
                                                                <div class="stats-progress progress">
                                                                    <div class="progress-bar" style="width: 70.1%;"></div>
                                                                </div>
                                                                <div class="stats-desc">Better than last week (70.1%)</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-3">
                                                        <div class="widget widget-stats bg-gradient-blue m-b-10">
                                                            <div class="stats-icon stats-icon-lg"><i class="fa fa-globe fa-fw"></i>
                                                            </div>
                                                            <div class="stats-content">
                                                                <div class="stats-title">CONTAS A PAGAR</div>
                                                                <div class="stats-number">7,842,900</div>
                                                                <div class="stats-progress progress">
                                                                    <div class="progress-bar" style="width: 70.1%;"></div>
                                                                </div>
                                                                <div class="stats-desc">Better than last week (70.1%)</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-3">
                                                        <div class="widget widget-stats bg-gradient-blue m-b-10">
                                                            <div class="stats-icon stats-icon-lg"><i class="fa fa-archive fa-fw"></i>
                                                            </div>
                                                            <div class="stats-content">
                                                                <div class="stats-title">CONTAS A RECEBER</div>
                                                                <div class="stats-number">7,842,900</div>
                                                                <div class="stats-progress progress">
                                                                    <div class="progress-bar" style="width: 0%;"></div>
                                                                </div>
                                                                <div class="stats-desc">Better than last week (70.1%)</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-3">
                                                        <div class="widget widget-stats bg-gradient-purple m-b-10">
                                                            <div class="stats-icon stats-icon-lg"><i class="fa fa-dollar-sign fa-fw"></i>
                                                            </div>
                                                            <div class="stats-content">
                                                                <div class="stats-title">CONTAS VENCENDO HOJE</div>
                                                                <div class="stats-number">7,842,900</div>
                                                                <div class="stats-progress progress">
                                                                    <div class="progress-bar" style="width: 70.1%;"></div>
                                                                </div>
                                                                <div class="stats-desc">Better than last week (70.1%)</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row m-t-20">
                                                    <div class="col-6">
                                                        <p-panel header="Fluxo de caixa">
                                                            <h4 class="m-b-30 m-t-10">R$ 1.454,342,92</h4>
                                                            <p-chart type="bar" [data]="chtTotalVendas" [options]="barChartOptions"></p-chart>
                                                        </p-panel>
                                                    </div>
                                                    <div class="col-6">
                                                        <p-panel header="Contas a Pagar x a Receber ">
                                                            <h4 class="m-b-30 m-t-10">R$ 42,92</h4>
                                                            <p-chart type="bar" [data]="chtTicketMedio" [options]="barChartOptions"></p-chart>
                                                        </p-panel>
                                                    </div>
                                                </div>                                                
                                                
                                            </p-tabPanel>
                                        </p-tabView>
                                    </div>
                                    
                                </div>                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>    
        </div>
    </div>
</div>