// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // apiUrl: 'http://localhost:8082/api',
  apiUrl: 'https://api.besales.com.br/api',
  mlUrl: 'https://auth.mercadolivre.com.br/authorization?response_type=code&client_id=431617273188941&redirect_uri=https://app.besales.com.br/store/mercado-libre&code_challenge=vqRJJzWE8f7sOI26aiMMIFTSYQlh475m_K8JNhAI3LA&code_challenge_method=S256'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
