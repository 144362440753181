<div id="content" class="content content-full-width">

  <h1 class="page-header">Configuração da Conta</h1>

  <hr class="mb-4 m-t-0">
  
  <ul class="timeline">
        <li>

          <div class="timeline-time">
              <span class="date">Confirmação E-mail</span>              
          </div>


          <div class="timeline-icon">
              <a href="javascript:;">&nbsp;</a>
          </div>


          <div class="timeline-body">
            <div class="row row-space-10">
              <div class="col-md-12 m-b-10">
                <div class="alert alert-warning fade show m-b-10">
                  Foi encaminhado um código de autenticação para o seu endereço de e-mail: <strong>{{data.user.email}}</strong>. Este código, composto por seis dígitos, tem o propósito de validar e assegurar a integridade de nossos clientes.
                </div>
              </div>
              <div class="col-sm-6 col-md-3">                
                <label>Código:</label>
                <input type="text" [(ngModel)]="data.user.account" placeholder="000000" class="form-control">
              </div>
            </div>
          </div>

      </li>

      <li>

          <div class="timeline-time">
              <span class="date">Dados Corporativo</span>
          </div>


          <div class="timeline-icon">
              <a href="javascript:;">&nbsp;</a>
          </div>


          <div class="timeline-body">
            
              <div class="row row-space-10">
                <div class="col-md-3">
                  <label>Tipo de pessoa:</label>
                  <select class="form-control" [(ngModel)]="data.organizacao.documento.tipo" name="select">
                    <option value="cpf">Física</option>
                    <option value="cnpj">Jurídica</option>                    
                  </select>
                </div>
                <div class="col-md-4">                  
                  <label *ngIf="data.organizacao.documento.tipo == 'cpf'">CPF:</label>
                  <label *ngIf="data.organizacao.documento.tipo == 'cnpj'">CNPJ:</label>
                  <p-inputMask [(ngModel)]="data.organizacao.documento.valor" [mask]="data.organizacao.documento.tipo == 'cnpj' ? '99.999.999/9999-99' : '999.999.999-99'"></p-inputMask>
                </div>
              </div>
          
              <div class="row row-space-10 m-t-5">
                <div class="col-md-7">
                  <label *ngIf="data.organizacao.documento.tipo == 'cnpj'">Nome fantasia:</label>
                  <label *ngIf="data.organizacao.documento.tipo != 'cnpj'">Nome:</label>
                  <input type="text" [(ngModel)]="data.organizacao.nome" name="organizacao.nome" class="form-control">
                </div>
              </div>
          
              <div class="row row-space-10 m-t-5">
                <div class="col-md-3">
                  <label>Telefone:</label>
                  <input type="text" [(ngModel)]="data.organizacao.telefone" mask="00 00000-0000" name="organizacao.telefone" class="form-control">
                </div>       
              </div>
          
              <div *ngIf="data.organizacao.documento.tipo == 'cnpj'">
                <hr />
          
                <h4 class="m-t-30">Informações fiscais</h4>
          
                <div class="row row-space-10">
                  <div class="col-md-8">
                    <label>Razão social:</label>
                    <input type="text" [(ngModel)]="data.organizacao.documentoFiscal.nome" name="legal.name" class="form-control">
                  </div>                  
                </div>
          
                <div class="row row-space-10 m-t-5">
          
                  <div class="col-md-4">
                    <label>Inscrição Estadual:</label>
                    <input type="text" [(ngModel)]="data.organizacao.documentoFiscal.ie" name="legal.ie" class="form-control">
                  </div>
                  <div class="col-md-4">
                    <label>Inscrição Municipal:</label>
                    <input type="text" [(ngModel)]="data.organizacao.documentoFiscal.im" name="legal.im" class="form-control">
                  </div>
                </div>
              </div>
          
              <hr />
          
              <h4 class="m-t-30">Endereço</h4>
              
              <div class="row row-space-10 m-t-20">
                <div class="col-md-2">
                  <label>CEP:</label>
                  <input type="text" (blur)="carregarEndereco()" mask="00000000" maxlength="8" minlength="8" [(ngModel)]="data.organizacao.endereco.cep"
                    name="endereco.cep" class="form-control">
                </div>
                <div class="col-md-6">
                  <label>Endereço:</label>
                  <input type="text" [(ngModel)]="data.organizacao.endereco.logradouro" name="endereco.logradouro" class="form-control" [readonly]="this.loadingAdd">
                </div>
                <div class="col-md-4">
                  <label>Número:</label>
                  <input type="text" [(ngModel)]="data.organizacao.endereco.numero" name="endereco.numero"
                  class="form-control">
                </div>
              </div>
          
              <div class="row row-space-10 m-t-5">
                <div class="col-md-3">
                  <label>Estado:</label>
                  <input type="text" [(ngModel)]="data.organizacao.endereco.estado" name="endereco.estado" class="form-control" [readonly]="this.loadingAdd">
                </div>
                <div class="col-md-3">
                  <label>Cidade:</label>
                  <input type="text" [(ngModel)]="data.organizacao.endereco.cidade" name="endereco.cidade" class="form-control" [readonly]="this.loadingAdd">
                </div>
                <div class="col-md-3">
                  <label>Bairro:</label>
                  <input type="text" [(ngModel)]="data.organizacao.endereco.bairro" name="endereco.bairro" [readonly]="this.loadingAdd"
                  class="form-control">
                </div>
                <div class="col-md-3">
                  <label>Complemento:</label>
                  <input type="text" [(ngModel)]="data.organizacao.endereco.complemento" name="endereco.complemento" class="form-control">
                </div>
              </div>
          
            
          </div>

      </li>
      <li>

          <div class="timeline-time">
              <span class="date">Dados Pessoais</span>              
          </div>


          <div class="timeline-icon">
              <a href="javascript:;">&nbsp;</a>
          </div>


          <div class="timeline-body">
            <div class="row row-space-10">
              <div class="col-md-6">                
                <label>Nome:</label>
                <input type="text" [(ngModel)]="data.user.nome" disabled name="name" class="form-control">
              </div>
            </div>
        
            <div class="row row-space-10 m-t-5">
              <div class="col-md-6">
                <label>Telefone:</label>
                <input type="text" [(ngModel)]="data.user.telefone" mask="00 00000-0000" name="user.phone" class="form-control">
              </div>                    
            </div>

            <div class="row row-space-10 m-t-5">
              <div class="col-md-6">                
                <label>E-mail:</label>
                <input type="text" [(ngModel)]="data.user.email" name="email" disabled class="form-control">
              </div>
            </div>
          </div>

      </li>
      
      <li>

          <div class="timeline-time">
              <span class="date">Plano de Assinatura</span>              
          </div>


          <div class="timeline-icon">
              <a href="javascript:;">&nbsp;</a>
          </div>


          <div class="timeline-body">
            <div class="row row-space-10">
              <div class="col-lg-6 col-md-12 m-b-20" *ngFor="let d of planos">
                <div class="card border-0 rounded-4 h-100 bs-pricing-default">
                    <div class="card-body fs-14px p-30px d-flex flex-column">
                        <div class="d-flex align-items-center">
                          <div class="flex-1">
                              <div class="h5">{{d.nome}}</div>
                              <div class="display-6 fw-bold mb-0">{{d.valorMensal | currency:'R$ '}} <small> no plano mensal. </small></div>
                              <div class="display-6 fw-bold mb-0">{{d.valorAnual | currency:'R$ '}} <small> no plano anual.<br> 
                                Plano anual com <strong>(-{{d.descontoAnual}}%)</strong> de desconto, equivalente a {{d.valorAnual / 12 | currency:'R$ '}} por mês.</small></div>
                          </div>
                          <div *ngIf="d.icone == 1">
                            <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1em" height="1em" viewBox="0 0 24 24" data-icon="solar:usb-bold-duotone" class="iconify display-4 text-theme rounded-3 iconify--solar"><circle cx="12" cy="20" r="2" fill="currentColor"></circle><circle cx="6" cy="6" r="2" fill="currentColor"></circle><path fill="currentColor" fill-rule="evenodd" d="M12 1.25a.75.75 0 0 1 .624.334l2 3a.75.75 0 1 1-1.248.832L12 3.352l-1.376 2.064a.75.75 0 1 1-1.248-.832l2-3A.75.75 0 0 1 12 1.25" clip-rule="evenodd"></path><path fill="currentColor" d="M16 8c0-.943 0-1.414.293-1.707C16.586 6 17.057 6 18 6c.943 0 1.414 0 1.707.293C20 6.586 20 7.057 20 8c0 .943 0 1.414-.293 1.707C19.414 10 18.943 10 18 10c-.943 0-1.414 0-1.707-.293C16 9.414 16 8.943 16 8"></path><path fill="currentColor" d="m12.616 1.571l.008.013l.003.004zM12 3.352l.75 1.125v10.515c.121-.062.248-.115.38-.16l3.265-1.088c.51-.17.855-.647.855-1.186V9.991c.21.009.458.009.75.009s.54 0 .75-.009v2.567a2.75 2.75 0 0 1-1.88 2.61l-3.265 1.088a1.25 1.25 0 0 0-.855 1.186v.703a1.995 1.995 0 0 0-1.5 0v-3.703a1.25 1.25 0 0 0-.855-1.186L7.13 12.167a2.75 2.75 0 0 1-1.88-2.609V7.855a1.994 1.994 0 0 0 1.5 0v1.703c0 .539.344 1.016.855 1.186l3.265 1.089c.132.044.259.097.38.159V4.477z" opacity=".5"></path></svg>
                          </div>
                          <div *ngIf="d.icone == 2">
                            <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1em" height="1em" viewBox="0 0 24 24" data-icon="solar:map-arrow-up-bold-duotone" class="iconify display-4 text-theme rounded-3 iconify--solar"><path fill="currentColor" d="M8.037 9.858a.5.5 0 0 0-.68.243l-4.193 9.402c-.667 1.496.815 3.047 2.202 2.306l5.904-3.152c.46-.245 1-.245 1.459 0l5.904 3.152c1.387.741 2.869-.81 2.202-2.306l-1.572-3.524a2 2 0 0 0-.932-.975z"></path><path fill="currentColor" d="M8.61 8.467a.5.5 0 0 1-.234-.65l2.151-4.823c.59-1.324 2.355-1.324 2.945 0l3.968 8.898a.5.5 0 0 1-.68.65z" opacity=".5"></path></svg>
                          </div>
                          <div *ngIf="d.icone == 3">
                            <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1em" height="1em" viewBox="0 0 24 24" data-icon="solar:cup-first-bold-duotone" class="iconify display-4 text-theme rounded-3 iconify--solar"><path fill="currentColor" d="M12 16c-5.76 0-6.78-5.74-6.96-10.294c-.05-1.266-.076-1.9.4-2.485c.476-.586 1.045-.682 2.184-.874A26.374 26.374 0 0 1 12 2c1.783 0 3.253.157 4.377.347c1.138.192 1.708.288 2.183.874c.476.586.451 1.219.4 2.485C18.78 10.259 17.76 16 12 16" opacity=".5"></path><path fill="currentColor" d="m17.64 12.422l2.817-1.565c.752-.418 1.128-.627 1.336-.979C22 9.526 22 9.096 22 8.235v-.073c0-1.043 0-1.565-.283-1.958s-.778-.558-1.768-.888L19 5l-.017.085c-.005.189-.013.395-.022.621c-.088 2.225-.377 4.733-1.32 6.716M5.04 5.706c.087 2.225.376 4.733 1.32 6.716l-2.817-1.565c-.752-.418-1.129-.627-1.336-.979C2 9.526 2 9.096 2 8.235v-.073c0-1.043 0-1.565.283-1.958s.778-.558 1.768-.888L5 5l.017.087c.005.188.013.394.022.62"></path><path fill="currentColor" fill-rule="evenodd" d="M5.25 22a.75.75 0 0 1 .75-.75h12a.75.75 0 0 1 0 1.5H6a.75.75 0 0 1-.75-.75" clip-rule="evenodd"></path><path fill="currentColor" d="M15.458 21.25H8.543l.297-1.75a1 1 0 0 1 .98-.804h4.36a1 1 0 0 1 .981.804z" opacity=".5"></path><path fill="currentColor" d="M12 16c-.26 0-.51-.011-.75-.034v2.73h1.5v-2.73A7.98 7.98 0 0 1 12 16"></path><path fill="currentColor" fill-rule="evenodd" d="M12.787 5.807a.75.75 0 0 1 .463.693v4a.75.75 0 0 1-1.5 0V8.31l-.22.22a.75.75 0 1 1-1.06-1.06l1.5-1.5a.75.75 0 0 1 .817-.163" clip-rule="evenodd"></path></svg>
                          </div>
                          <div *ngIf="d.icone == 4">
                            <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1em" height="1em" viewBox="0 0 24 24" data-icon="solar:buildings-bold-duotone" class="iconify display-4 text-theme rounded-3 iconify--solar"><path fill="currentColor" fill-rule="evenodd" d="M7 5h4c1.886 0 2.828 0 3.414.586C15 6.172 15 7.114 15 9v12.25h7a.75.75 0 0 1 0 1.5H2a.75.75 0 0 1 0-1.5h1V9c0-1.886 0-2.828.586-3.414C4.172 5 5.114 5 7 5M5.25 8A.75.75 0 0 1 6 7.25h6a.75.75 0 0 1 0 1.5H6A.75.75 0 0 1 5.25 8m0 3a.75.75 0 0 1 .75-.75h6a.75.75 0 0 1 0 1.5H6a.75.75 0 0 1-.75-.75m0 3a.75.75 0 0 1 .75-.75h6a.75.75 0 0 1 0 1.5H6a.75.75 0 0 1-.75-.75M9 18.25a.75.75 0 0 1 .75.75v2.25h-1.5V19a.75.75 0 0 1 .75-.75" clip-rule="evenodd"></path><path fill="currentColor" d="M15 2h2c1.886 0 2.828 0 3.414.586C21 3.172 21 4.114 21 6v15.25h-6V9c0-1.886 0-2.828-.586-3.414C13.842 5.013 12.928 5 11.126 5V3.5c.084-.387.225-.68.46-.914C12.17 2 13.114 2 15 2" opacity=".5"></path></svg>
                          </div>
                        </div>
                        
                        <hr class="my-4">
                        
                        <div class="mb-5 text-body text-opacity-75 flex-1">
                            <div class="d-flex align-items-center mb-1" *ngFor="let desc of d.detalhes">
                                <i class="{{desc.icone}}"></i>
                                <div class="flex-1 ps-3"><span class="small">{{desc.descricao}}:&nbsp; </span> <b class="text-body">{{desc.valor}}</b></div>
                            </div>                            
                        </div>
                        <div class="mx-n2">
                          <button (click)="plano(d._id, d.nome, true)" class="btn btn-primary btn-lg w-100 m-b-10">Escolher {{d.nome}} anual <i class="fa fa-arrow-right"></i></button>
                          <button (click)="plano(d._id, d.nome, false)" class="btn btn-white btn-lg w-100">Escolher {{d.nome}} mensal <i class="fa fa-arrow-right"></i></button>
                        </div>
                    </div>
                </div>
              </div>
            </div>

            <div class="col-md-12" *ngIf="data.organizacao.plano">
              <span *ngIf="data.organizacao.planoAnual">Plano selecionado: <strong>{{selplano}}</strong>. Frequência de pagamento: <strong>Anual</strong>.</span>
              <span *ngIf="!data.organizacao.planoAnual">Plano selecionado: <strong>{{selplano}}</strong>. Frequência de pagamento: <strong>Mensal</strong>.</span>
            </div>

          </div>

      </li>
      
      <li>

          <div class="timeline-time" id="dvPgto">
              <span class="date">Forma de Pagamento</span>              
          </div>


          <div class="timeline-icon">
              <a href="javascript:;">&nbsp;</a>
          </div>


          <div class="timeline-body">
            <div class="row row-space-10">
              <div class="col-md-12 m-b-20">
                <div class="alert alert-warning fade show m-b-10">
                  <strong>Importante</strong> não é necessário efetuar nenhum pagamento ou fornecer o número do cartão de crédito neste momento. A cobrança será enviada após o período de teste, conforme o formato de pagamento selecionado.
                </div>
              </div>
              <div class="col-md-12 m-b-20">
                <a [routerLink]="" (click)="pgtoForma('cartao_credito')" class="btn btn-white d-flex" style="height: 46px;">
                  <i class="fa fa-credit-card" style="font-size: 24px;color: #00acac;margin-top: 3px;"></i> 
                  <span style="margin-top: 6px; margin-left: 8px;">Cartão de Crédito</span>
                </a>
              </div>
              <div class="col-md-12 m-b-20">
                <a [routerLink]="" (click)="pgtoForma('pix')" class="btn btn-white d-flex">
                  <img src="../../../assets/images/pix.png" width="30px;">
                  <span class="m-t-5 m-l-5">Pix</span>
                </a>
              </div>
              <div class="col-md-12 m-b-20">
                <a [routerLink]="" (click)="pgtoForma('boleto')" class="btn btn-white d-flex" style="height: 46px;">
                  <i class="fa fa-barcode" style="font-size: 24px;margin-top: 3px;"></i> 
                  <span style="margin-top: 6px; margin-left: 8px;">Boleto</span>
                </a>
              </div>
              <div class="col-md-12" *ngIf="data.organizacao.formaCobranca">
                <span *ngIf="data.organizacao.formaCobranca == 'cartao_credito'">Forma de pagamento escolhida: <strong>Cartão de Crédito</strong></span>
                <span *ngIf="data.organizacao.formaCobranca == 'pix'">Forma de pagamento escolhida: <strong>Pix</strong></span>
                <span *ngIf="data.organizacao.formaCobranca == 'boleto'">Forma de pagamento escolhida: <strong>Boleto</strong></span>
              </div>
            </div>
          </div>

      </li>
      <li>

          <div class="timeline-time">
              <span class="date">Dados do Parceiro</span>              
          </div>


          <div class="timeline-icon">
              <a href="javascript:;">&nbsp;</a>
          </div>


          <div class="timeline-body">
            <div class="row row-space-10">
              <div class="col-md-12 m-b-20">
                <div class="alert alert-warning fade show m-b-10">
                  Se você não possui um código de parceiro ou não tem certeza sobre o que inserir neste campo, não se preocupe, basta deixar este campo vazio.
                </div>
              </div>
              <div class="col-md-6">                
                <label>Código:</label>
                <input type="text" [(ngModel)]="data.organizacao.parceiro" class="form-control">
              </div>
            </div>
          </div>
           

      </li>
      
      
    </ul>
    <div class="timeline-button">
      <button (click)="initialize(data)" [disabled]="recording" class="btn btn-success btn-lg">
        Iniciar minha jornada com BeSales <i class="fa fa-circle-o-notch fa-spin" *ngIf="recording"></i>
      </button>
    </div>

</div>