export class BaseEntity {

	_id: string;
	account: string;
	status: string;
	created: Date;

	constructor(
	) { }
}
