export class ServicoOrdemItem {
    public tipo!: string;
    public quantidade!: number;
    public valorUnitario!: number;
    public valorTotal!: number;
    public idItem!: string;
    public nomeItem!: string;
    public sku!: string;
    public img!: string;
}
