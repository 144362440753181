import { HttpClient } from '@angular/common/http';
import { Component, HostListener, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from 'src/app/_services';
import { BaseService } from 'src/app/_services/base.service';
import { BaseServiceDialog } from 'src/app/_services/base.service.dialog';
import { BaseServiceImportDialog, Upload } from 'src/app/_services/base.service.import.dialog';
import { LayoutPrivateComponent } from 'src/app/suporte/layout-private/layout-private.component';
import { Funcionario } from 'src/app/_entity/cadastro/funcionario';
import { KeyValue } from 'src/app/_entity/suporte/keyValue';
import { environment } from '@environments/environment';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng-lts/dynamicdialog';
import { Categoria } from 'src/app/_entity/cadastro/categoria';
import { Filters } from 'src/app/_entity/filters';
import { Filter } from 'src/app/_entity/filter';
import { Endereco } from 'src/app/_entity/suporte/endereco';

@Component({
  selector: 'app-funcionario',
  templateUrl: './funcionario.component.html',
  styleUrls: ['./funcionario.component.css'],
  providers: [DialogService]
})
export class FuncionarioComponent extends BaseService implements OnInit {

  public upload: Upload;
  
  constructor(
    public app: LayoutPrivateComponent,
    public http: HttpClient,
    public dialog: DialogService,
    public toastr: ToastrService,
    public translate: TranslateService,
    private authenticationService: AuthenticationService) {

    super('funcionario', app, http, dialog, toastr, translate);
  }

  ngOnInit(): void {
    this.find();
    this.dialogClass = FuncionarioDialogComponent;
    this.authService = this.authenticationService;
    
    this.buildFilterItens();
  }

  public buildFilterItens(): void {
    this.filters.filters[0].att = 'nome';
    this.filters.filters[0].attAlias = 'Nome';

    this.filterItens = [{
          label: 'Nome',
          command: () => {
          this.filterChangeAtt('nome', 'Nome')
          }
      },
      {
          label: 'CPF',
          command: () => {
          this.filterChangeAtt('cpf', 'CPF')
          }
      },
      {
          label: 'RG',
          command: () => {
          this.filterChangeAtt('rg', 'RG')
          }
      },
      {
          label: 'Telefone',
          command: () => {
          this.filterChangeAtt('telefone', 'Telefone')
          }
      }];
  }

  public openDialogImportar() {
    this.upload = new Upload();
    this.upload.carregado = false;

    const dialogRef = this.dialog.open(FuncionarioDialogImportarComponent, {
      data: this.upload,
      width: '80%',
      height: '500px',
      transitionOptions: '1ms',
      showHeader: false
    });

    dialogRef.onClose.subscribe(result => {
      if (this.upload != null && this.upload.carregado)
        this.find(null);
    });
  }
}

@Component({
  selector: 'app-funcionario-dialog',
  templateUrl: 'funcionario.dialog.component.html',
})
export class FuncionarioDialogComponent extends BaseServiceDialog implements OnInit {

  public data: Funcionario = null;
  public loadingAdd: boolean = false;

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public http: HttpClient,
    public toastr: ToastrService,
    public translate: TranslateService) {

    super('funcionario', http, toastr, translate, ref, config.data);

    this.datas = config.data;
    this.data = this.datas.data;
  }

  ngOnInit(): void {
    // Caso seja uma iniciativa ja criada para edicao, busca a ultima
    // versao no banco de dados e apos inicializa os autocompletes
    if (this.data && this.data._id) {
      this.find(this.data._id);
    }
    // Caso seja uma nova apenas inicializa os autocompletes
    else {
      this.createData();
    }
  }

  public beforeSave(callback: (valid: boolean) => void): void {
    if (!this.data || !this.data.nome) {
      this.toastr.error('Campo Nome deve ser preenchido.');
      callback(false);
    }
    else {
      callback(true);
    }
  }

  public createData(): void {
    if (this.data == null) {
      this.data = new Funcionario;
      this.data.endereco = new Endereco;
      this.data.comissaoTipo = new KeyValue;
      this.data.comissaoTipo.code = 'porcentagem';
    }
  }

  public carregarEndereco(): void {
    try {
      this.loadingAdd = true;
      this.http.get<any>(`${environment.apiUrl}/support/address/${this.data.endereco.cep}`).subscribe(
        address => {
          this.data.endereco.logradouro = address.logradouro;
          this.data.endereco.bairro = address.bairro;
          this.data.endereco.estado = address.estado;
          this.data.endereco.cidade = address.cidade;

          this.loadingAdd = false;
        },
        error => {
          this.loadingAdd = false;
        }
      );
    }
    catch (e) {
      this.loadingAdd = false;
    }
  }

  @HostListener('window:keydown.control.enter', ['$event'])
  keSave(event: KeyboardEvent) {
    this.save();
  }

  @HostListener('window:keydown.control.s', ['$event'])
  keSaveClose(event: KeyboardEvent) {
    this.saveClose();
  }

  @HostListener('window:keydown.esc', ['$event'])
  keClose(event: KeyboardEvent) {
    this.dialogRef.close();
  }
}

@Component({
  selector: 'app-funcionario-dialog-importar',
  templateUrl: 'funcionario.dialog.importar.component.html',
})
export class FuncionarioDialogImportarComponent extends BaseServiceImportDialog {
  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public http: HttpClient,
    public toastr: ToastrService,
    public translate: TranslateService,
    public authenticationService: AuthenticationService) {
    super('funcionario', http, toastr, translate, authenticationService, ref, config.data);
  }

}