import { HttpClient } from '@angular/common/http';
import { Component, HostListener, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng-lts/dynamicdialog';
import { PropostaComercial } from 'src/app/_entity/venda/propostaComercial';
import { BaseServiceDialog } from 'src/app/_services/base.service.dialog';
import { environment } from '@environments/environment';
import { Filters } from 'src/app/_entity/filters';
import { Filter } from 'src/app/_entity/filter';
import { Contato } from 'src/app/_entity/cadastro/contato';
import { Vendedor } from 'src/app/_entity/cadastro/vendedor';
import { PropostaItem } from 'src/app/_entity/suporte/propostaItem';
import { Produto } from 'src/app/_entity/cadastro/produto';
import { Servico } from 'src/app/_entity/cadastro/servico';
import { PropostaStatus } from 'src/app/_entity/suporte/propostaStatus';

@Component({
  selector: 'app-proposta.dialog',
  templateUrl: './proposta.dialog.component.html'
})
export class PropostaDialogComponent extends BaseServiceDialog implements OnInit {

  public data: PropostaComercial;
  public upload_path_arquivo: string;

  public contato: Contato;
  public vendedor: Vendedor;
  // public propostaItens: PropostaItem[] = [];

  public propostaItem: PropostaItem;

  public autocompleteCliente: any[] = [];
  public autocompleteVendedor: any[] = [];
  public autocompleteProdutoServico: any[] = [];

  // public propostaStatus = Object.values(PropostaStatus);

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public http: HttpClient,
    public toastr: ToastrService,
    public translate: TranslateService) {

    super('proposta', http, toastr, translate, ref, config.data);

    this.datas = config.data;
    this.data = this.datas.data;

    this.upload_path_arquivo = `${environment.apiUrl}/proposta/upload-arquivo`;
  }

  ngOnInit(): void {
    // Caso seja uma iniciativa ja criada para edicao, busca a ultima
    // versao no banco de dados e apos inicializa os autocompletes
    if (this.data && this.data._id) {
      this.find(this.data._id);
    }
    // Caso seja uma nova apenas inicializa os autocompletes
    else {
      this.createData();
    }
  }

  public afterFind(): void {
    if (this.data.idCliente) {
      this.contato = new Contato;
      this.contato._id = this.data.idCliente;
      this.contato.nome = this.data.nmCliente;
    }
    if (this.data.idVendedor) {
      this.vendedor = new Vendedor;
      this.vendedor._id = this.data.idVendedor;
      this.vendedor.nome = this.data.nmVendedor;
    }
    if (this.data.status == null) {
      this.data.propostaStatus = PropostaStatus.ABERTO;
    }
  }

  public beforeSave(callback: (valid: boolean) => void): void {
    if (!this.data || !this.data.natureza) {
      this.toastr.error('Campo Natureza da Operação deve ser preenchido.');
      callback(false);
    }
    else {
      callback(true);
    }
  }

  public addItem(): void {
    this.data.propostaItens.push(new PropostaItem);
  }
  public removeItem(index: number): void {
    this.data.propostaItens.splice(index, 1);

    this.data.valorTotal = 0;
    this.data.valorSubTotal = 0;

    this.data.propostaItens.forEach(element => {
      this.data.valorTotal += element.valorTotal;
      this.data.valorSubTotal += element.valorTotal;
    });

    this.data.valorTotal -= this.data.valorDesconto;
  }
  public removeAnexo(index: number): void {
    this.data.anexos.splice(index, 1);
  }

  public calculaSubTotal(index: number): void {
    this.data.propostaItens[index].valorTotal = this.data.propostaItens[index].valorUnitario * this.data.propostaItens[index].quantidade;

    this.data.valorTotal = 0;
    this.data.valorSubTotal = 0;

    this.data.propostaItens.forEach(element => {
      this.data.valorTotal += element.valorTotal;
      this.data.valorSubTotal += element.valorTotal;
    });

    this.data.valorTotal -= this.data.valorDesconto;
  }

  public calculaTotal(): void {
    if (this.data.valorDesconto >= this.data.valorSubTotal) {
      this.data.valorDesconto = 0;
      this.toastr.error('Desconto não pode ser maior que o valor total');
    }
    else {
      this.data.valorTotal = this.data.valorSubTotal - this.data.valorDesconto;
    }
  }

  public onInputClienteSelect(): void {
    if (this.contato) {
      this.data.idCliente = this.contato._id;
      this.data.nmCliente = this.contato.nome;
    }
  }
  public onInputProdutoSelect(index: number): void {
    if (this.propostaItem) {
      this.data.propostaItens[index].tipo = this.propostaItem.tipo;
      this.data.propostaItens[index].quantidade = this.propostaItem.quantidade;
      this.data.propostaItens[index].idItem = this.propostaItem.idItem;
      this.data.propostaItens[index].itemSku = this.propostaItem.itemSku;
      this.data.propostaItens[index].nomeItem = this.propostaItem.nomeItem;
      this.data.propostaItens[index].valorUnitario = this.propostaItem.valorUnitario;

      this.data.propostaItens[index].valorTotal = this.propostaItem.valorUnitario * this.propostaItem.quantidade;

      this.data.valorTotal = 0;
      this.data.valorSubTotal = 0;

      this.data.propostaItens.forEach(element => {
        this.data.valorTotal += element.valorTotal;
        this.data.valorSubTotal += element.valorTotal;
    });
      
    this.data.valorTotal -= this.data.valorDesconto;
      this.propostaItem = new PropostaItem;
    }
  }
  public onInputVendedorSelect(): void {
    if (this.vendedor) {
      this.data.idVendedor = this.vendedor._id;
      this.data.nmVendedor = this.vendedor.nome;
    }
  }

  public acFind(event): void {
    this.http.get<any>(`${environment.apiUrl}/cliente/pesquisar-nome-codigo/${event.query}`).subscribe(
      data => {
          this.autocompleteCliente = data;
      },
      error => {
      }
    );
  }

  public acFindVendedor(event): void {
    this.http.get<any>(`${environment.apiUrl}/vendedor/pesquisar-nome-codigo/${event.query}`).subscribe(
      data => {
          this.autocompleteVendedor = data;
      },
      error => {
      }
    );
  }

  public acFindProduto(event): void {
    this.http.get<any>(`${environment.apiUrl}/proposta/pesquisar-produto-servico/${event.query}`).subscribe(
      data => {
        this.autocompleteProdutoServico = data;
      },
      error => {
      }
    );
  }

  // Sobscreve  o metodo de criacao de filtro do autocomplete
  public createFilters(nome: string): Filters {
    const filters = new Filters();
    const filter1 = new Filter('lk', 'nome', this.clearSpecialCharacters(nome));
    const filter2 = new Filter('eq', 'status.code', 'active');
  
    filters.offset = 0;
    filters.pageSize = 5;
    filters.orderField = "nome";
    filters.orderDirection = 'asc';
    filters.filters = [filter1, filter2];
  
    return filters;
  }

  public onUploadArquivo(event): void {
    if (event.originalEvent && event.originalEvent.body) {
      if (!this.data.anexos) {
        this.data.anexos = [];
      }
      this.data.anexos = this.data.anexos.concat(event.originalEvent.body);
      this.toastr.success('Operação realizada com sucesso', 'File Uploaded');
    }
    else {
      this.toastr.error('Ocorreu um problema ao enviar os arquivos', 'Ops.');
    }
  }

  public createData(): void {
    if (this.data == null) {
      this.data = new PropostaComercial;
    }
    if (this.data.propostaItens == null) {
      this.data.propostaItens = [];
    }
    this.data.valorSubTotal = 0;
    this.data.valorDesconto = 0;
    this.data.valorTotal = 0;

    this.data.propostaStatus = PropostaStatus.ABERTO;
  }

  @HostListener('window:keydown.control.enter', ['$event'])
  keSave(event: KeyboardEvent) {
    this.save();
  }

  @HostListener('window:keydown.control.s', ['$event'])
  keSaveClose(event: KeyboardEvent) {
    this.saveClose();
  }

  @HostListener('window:keydown.esc', ['$event'])
  keClose(event: KeyboardEvent) {
    this.dialogRef.close();
  }
}