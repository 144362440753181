import { AfterViewInit, Component } from '@angular/core';
import { LayoutPrivateComponent } from '../suporte/layout-private/layout-private.component';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from '../_services';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements AfterViewInit {
  public activeIndex: number = 0;
  public chtTotalVendas: any;
  public chtTicketMedio: any;
  public chtPedidoPorOrigem: any;
  public chtTop10Produtos: any;
  public chtHorarioVendas: any;
  public chtVendaDevolucao: any;

  public fRangeValue: string = 'u7d';
  public fRangeLabel: string = 'Últimos 7 dias';

  public tvTotal: number = 0;
  public tvLabel: any[] = [];
  public tvValue: any[] = [];

  public tmTotal: number = 0;
  public tmLabel: any[] = [];
  public tmValue: any[] = [];

  public phTotal: number = 0;
  public phLabel: any[] = [];
  public phValue: any[] = [];

  public topTotal: number = 0;
  public topLabel: any[] = [];
  public topValue: any[] = [];

  public poTotal: number = 0;
  public poLabel: any[] = [];
  public poValue: any[] = [];

  public vdTotal: number = 0;
  public vdLabel: any[] = [];
  public vdValue: any[] = [];

  public dvTotal: number = 0;
  public dvLabel: any[] = [];
  public dvValue: any[] = [];

  public countPedidos: any;
  public lbCountPedidos: string;
  
  public sumPedidos: any;
  public lbsumPedidos: string;
  
  public sumPedidosOnline: any;
  public lbsumPedidosOnline: string;
  
  public sumPedidosLocal: any;
  public lbsumPedidosLocal: string;

  public username: any;
  public nextCall: number = 60;
  public textCall: string = '5m';

  public intervalId: any;

  public pnTotal: boolean = true;
  public pnPedido: boolean = true;
  public pnOnline: boolean = true;
  public pnFisica: boolean = true;
  public ldDashVenda: boolean = true;
  public ldDashTm: boolean = true;
  public ldDashPo: boolean = true;
  public ldDashTop: boolean = true;
  public ldDashPh: boolean = true;
  public ldDashVd: boolean = true;

  ngAfterViewInit(): void {
    setTimeout(() => {
        this.app.setTitle(this.translate.instant(`dashboard.page.title`));
        this.app.setNavTitle(this.translate.instant(`dashboard.nav.title`));
    });
  }

  constructor(
    public app: LayoutPrivateComponent,
    public http: HttpClient,
    public authenticationService: AuthenticationService,
    public translate: TranslateService) {

      this.username = this.authenticationService.currentUserValue;
      this.loadDashs();
      this.updateDashs(60000 * 5);
   
      setInterval(() => {
        this.nextCall--;
        if (this.nextCall > 60) {
          this.textCall = Math.floor(this.nextCall / 60) + 'm';
          if (this.nextCall % 60 > 0) {
            this.textCall += ' e ' + this.nextCall % 60 + 's'
          }
        }
        else{
          this.textCall = this.nextCall + 's';
        }
      }, 1000);
  }

  public calcProgress(value: number): number {
    let total = 100;
    if (value < 0) {
      total = total + value;
    }
    return parseFloat(total.toFixed(2));
  }

  public updateDashs(tempo?: number): void {
    if (tempo) {
      if (this.intervalId) {
        clearInterval(this.intervalId);
      }
      this.nextCall = tempo / 1000;
      this.intervalId = setInterval(() => {
        this.nextCall = tempo / 1000;
        this.loadDashs();
      }, tempo);
    }
  }

  public loadDashs(): void {
    this.pnTotal = true;
    this.pnPedido = true;
    this.pnOnline = true;
    this.pnFisica = true;
    
    this.ldDashVenda = true;
    this.ldDashTm = true;
    this.ldDashPo = true;
    this.ldDashTop = true;
    this.ldDashPh = true;
    this.ldDashVd = true;
    
    this.loadTotalVendas();
    this.loadTicketMedio();
    this.loadTotalVendasPorHora();
    this.loadTopProdutos();
    this.loadPorOrigem();
    this.loadVendasDevolucoes();

    this.http.get(`${environment.apiUrl}/dashboard/countPedidos`).subscribe(
      (data: any) => {
        this.countPedidos = data;
        this.lbCountPedidos = this.buildText(data.total);
        this.pnTotal = false;
      }
    );
    this.http.get(`${environment.apiUrl}/dashboard/sumPedidos`).subscribe(
      (data: any) => {
        this.sumPedidos = data;
        this.lbsumPedidos = this.buildText(data.total);
        this.pnPedido = false;
      }
    );
    this.http.get(`${environment.apiUrl}/dashboard/sumPedidosOnline`).subscribe(
      (data: any) => {
        this.sumPedidosOnline = data;
        this.lbsumPedidosOnline = this.buildText(data.total);
        this.pnOnline = false;
      }
    );
    this.http.get(`${environment.apiUrl}/dashboard/sumPedidosLocal`).subscribe(
      (data: any) => {
        this.sumPedidosLocal = data;
        this.lbsumPedidosLocal = this.buildText(data.total);
        this.pnFisica = false;
      }
    );
  }

  public buildText(total: number): string {
    if (total == 0) {
      return 'Igual a semana passada considerando o mesmo horário';
    }
    else if (total > 0) {
      return 'Melhor que a semana passada ('+parseFloat(total.toFixed(2))+'%) no mesmo horário';
    }
    else {
      return 'Pior que a semana passada ('+parseFloat(total.toFixed(2))+'%) no mesmo horário';
    }
  }

  public changeRange(range: string, label: string): void {
    this.fRangeValue = range;
    this.fRangeLabel = label;
    this.loadDashs();
  }

  public loadTotalVendas(): void {
    this.http.get(`${environment.apiUrl}/dashboard/totalVendas?range=${this.fRangeValue}`).subscribe(
      (data: any) => {
        this.tvTotal = data.total;
        this.tvLabel = data.labels;
        this.tvValue = data.values;
        this.ldDashVenda = false;
        this.buildTotalVendas();
      }
    );
  }

  public buildTotalVendas(): void {
    this.chtTotalVendas = {
      labels: this.tvLabel,
      datasets: [
        {
          label: '',
          backgroundColor: '#00a1e0',
          borderColor: '#018dc4',
          borderWidth: 1,
          data: this.tvValue
        }
      ]
    }
  }
  
  public loadTotalVendasPorHora(): void {
    this.http.get(`${environment.apiUrl}/dashboard/totalVendasPorHora?range=${this.fRangeValue}`).subscribe(
      (data: any) => {
        this.phTotal = data.total;
        this.phLabel = data.labels;
        this.phValue = data.values;
        this.ldDashPh = false;
        this.buildTotalVendasPorHora();
      }
    );
  }

  public buildTotalVendasPorHora(): void {
    this.chtHorarioVendas = {
      labels: this.phLabel,
      datasets: [
        {
          label: 'Vendas por hora',
          backgroundColor: '#00a1e0',
          borderColor: '#018dc4',
          borderWidth: 1,
          data: this.phValue
        }
      ]
    }
  }
  
  public loadTopProdutos(): void {
    this.http.get(`${environment.apiUrl}/dashboard/topProdutos?range=${this.fRangeValue}`).subscribe(
      (data: any) => {
        this.topTotal = data.total;
        this.topLabel = data.labels;
        this.topValue = data.values;
        this.ldDashTop = false;
        this.buildTopProdutos();
      }
    );
  }

  public buildTopProdutos(): void {
    this.chtTop10Produtos = {
      labels: this.topLabel,
      datasets: [
        {
          label: 'Produtos',     
          backgroundColor: '#00a1e0',
          borderColor: '#018dc4',     
          borderWidth: 1,
          data: this.topValue
        }
      ]
    }
  }
  
  public loadPorOrigem(): void {
    this.http.get(`${environment.apiUrl}/dashboard/pedidoPorOrigem?range=${this.fRangeValue}`).subscribe(
      (data: any) => {
        this.poTotal = data.total;
        this.poLabel = data.labels;
        this.poValue = data.values;
        this.ldDashPo = false;
        this.buildPorOrigem();
      }
    );
  }

  public buildPorOrigem(): void {
    this.chtPedidoPorOrigem = {
      labels: this.poLabel,
      datasets: [
        {
          label: 'Origem',     
          backgroundColor: '#00a1e0',
          borderColor: '#018dc4',     
          borderWidth: 1,
          data: this.poValue
        }
      ]
    }
  }
  
  public loadVendasDevolucoes(): void {
    this.http.get(`${environment.apiUrl}/dashboard/pedidoVendasDevolucoes?range=${this.fRangeValue}`).subscribe(
      (data: any) => {
        this.vdTotal = data[0].total;
        this.vdLabel = data[0].labels;
        this.vdValue = data[0].values;
        
        this.dvTotal = data[1].total;
        this.dvLabel = data[1].labels;
        this.dvValue = data[1].values;

        this.ldDashVd = false;
        this.buildVendasDevolucoes();
      }
    );
  }

  public buildVendasDevolucoes(): void {
    this.chtVendaDevolucao = {
      labels: this.vdLabel,
      datasets: [
        {
          label: 'Vendas',
          backgroundColor: '#00a1e0',
          borderColor: '#018dc4',
          borderWidth: 1,
          data: this.vdValue
        },
        {
          label: 'Devoluções',
          backgroundColor: '#FF6384',
          borderColor: '#FF6384',
          borderWidth: 1,
          data: this.dvValue
        }
      ]
    }
  }

  public loadTicketMedio(): void {
    this.http.get(`${environment.apiUrl}/dashboard/ticketMedio?range=${this.fRangeValue}`).subscribe(
      (data: any) => {
        this.tmTotal = data.total;
        this.tmLabel = data.labels;
        this.tmValue = data.values;
        this.ldDashTm = false;
        this.buildTicketMedio();
      }
    );
  }

  public buildTicketMedio(): void {
    this.chtTicketMedio = {
      labels: this.tmLabel,
      datasets: [
        {
          label: '',
          backgroundColor: '#00a1e0',
          borderColor: '#018dc4',
          borderWidth: 1,
          data: this.tmValue
        }
      ]
    }
  }
  
  public barChartOptions = {
    legend: {
      display: false
    },
    tooltips: {
      callbacks: {
        label: function(tooltipItem: any, data: any) {
          let value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
          return 'R$ ' + value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
        }
      }
    },
    scales: {
      xAxes: [{
        gridLines: {
          display: false
        }
      }],
      yAxes: [{
        gridLines: {
          display: true
        },
        ticks: {
          beginAtZero: true,
          callback: function(value: number) {
            return formatCurrency(value);
          }
        }
      }]
    }
  };

  public getDaysOfLastWeek(): string[] {
    const daysOfWeek: string[] = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'];
    const today = new Date();
    // Dia da semana atual (0 = Domingo, 1 = Segunda-feira, ..., 6 = Sábado)
    const dayOfWeek = today.getDay(); 

    const daysOfLastWeek: string[] = [];

    // Adiciona os dias da semana anterior ao array, começando pelo dia seguinte ao atual
    for (let i = 1; i <= 7; i++) {
      // Calcula o índice do dia da semana seguinte
      const index = (dayOfWeek + i) % 7; 
      daysOfLastWeek.push(daysOfWeek[index]);
    }

    return daysOfLastWeek;
  }
}

function formatCurrency(value: number): string {
  return 'R$ ' + value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
}

function randomColor() {
  return '#' + Math.floor(Math.random() * 16777215).toString(16);
}