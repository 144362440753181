import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { LayoutPrivateComponent } from '../suporte/layout-private/layout-private.component';
import { Filters } from '../_entity/filters';
import { Confirmation } from '../_entity/confirmation';
import { ConfirmationComponent } from '../suporte/confirmation/confirmation.component';
import { AuthenticationService } from './authentication.service';
import { DialogService } from 'primeng-lts/dynamicdialog';
import { MenuItem } from 'primeng-lts/api';

@Injectable({ providedIn: 'root' })
export abstract class BaseService implements AfterViewInit {

    public authService: AuthenticationService;
    public filters = new Filters();

    public data: any;
    public datas: any;
    public dialogClass: any;

    public lorder: string;
    public direction: boolean;

    public loading = true;
    public sloading = false;
    public showEmpty = false;
    public downloading = false;

    public dialogwidth: string = '900px';
    public dialogheight: string = '600px';

    public filterItens: MenuItem[];
    public selectedDatas: [] = [];
    public ckbselgroup: boolean = false;

    constructor(
        public mapping: string,
        public app: LayoutPrivateComponent,
        public http: HttpClient,
        public dialog: DialogService,
        public toastr: ToastrService,
        public translate: TranslateService) {
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.app.setTitle(this.translate.instant(`${this.mapping}.page.title`));
            this.app.setNavTitle(this.translate.instant(`${this.mapping}.nav.title`));
        });
    }

    public filterByStatus(status: string): void {
        this.filters.status = status;
        this.find();
    }

    public filterByPeriod(period: string): void {
        this.filters.period = period;
        this.find();
    }

    public save(baseDoc: any): void {
        try {
            this.beforeSave(baseDoc);

            this.http.post<any>(`${environment.apiUrl}/${this.mapping}/save`, baseDoc).subscribe(
                data => {
                    this.afterSave(data);
                },
                error => {
                    this.sloading = false;
                }
            );
        }
        catch (e) {
            this.toastr.error('', e.message);
        }
    }

    public doClone(baseDoc: any): void {
        try {
            this.sloading = true;

            this.http.post<any>(`${environment.apiUrl}/${this.mapping}/clone`, baseDoc).subscribe(
                data => {
                    this.find();
                    this.sloading = false;
                },
                error => {
                    this.sloading = false;
                }
            );
        }
        catch (e) {
            this.toastr.error('', e.message);
        }
    }

    public doDelete(baseDoc: any): void {
        try {
            this.sloading = true;
            this.beforeDelete(baseDoc);

            this.http.delete<any>(`${environment.apiUrl}/${this.mapping}/delete/` + baseDoc._id).subscribe(
                data => {
                    this.find();
                    this.sloading = false;

                    this.afterDelete(data);
                },
                error => {
                    this.sloading = false;
                }
            );
        }
        catch (e) {
            this.sloading = false;
            this.toastr.error('', e.message);
        }
    }

    public doDeleteSelectedItens(): void {
        try {
            this.sloading = true;
            // this.beforeDelete(baseDoc);

            this.http.post<any>(`${environment.apiUrl}/${this.mapping}/batch-delete`, this.selectedDatas).subscribe(
                data => {
                    this.find();
                    this.sloading = false;

                    // this.afterDelete(data);
                },
                error => {
                    this.sloading = false;
                }
            );
        }
        catch (e) {
            this.sloading = false;
            this.toastr.error('', e.message);
        }
    }

    public doLockSelectedItens(): void {
        try {
            this.sloading = true;
            // this.beforeDelete(baseDoc);

            this.http.post<any>(`${environment.apiUrl}/${this.mapping}/batch-lock`, this.selectedDatas).subscribe(
                data => {
                    this.find();
                    this.sloading = false;

                    this.toastr.success('', 'Registros atualizados com sucesso!');
                },
                error => {
                    this.sloading = false;
                }
            );
        }
        catch (e) {
            this.sloading = false;
            this.toastr.error('', e.message);
        }
    }

    public doUnLockSelectedItens(): void {
        try {
            this.sloading = true;
            // this.beforeDelete(baseDoc);

            this.http.post<any>(`${environment.apiUrl}/${this.mapping}/batch-unlock`, this.selectedDatas).subscribe(
                data => {
                    this.find();
                    this.sloading = false;

                    this.toastr.success('', 'Registros atualizados com sucesso!');
                },
                error => {
                    this.sloading = false;
                }
            );
        }
        catch (e) {
            this.sloading = false;
            this.toastr.error('', e.message);
        }
    }

    public doLockUnlock(baseDoc: any): void {
        try {
            this.sloading = true;

            this.http.put<any>(`${environment.apiUrl}/${this.mapping}/lockUnlock`, baseDoc).subscribe(
                data => {
                    this.find();
                    this.sloading = false;

                    this.toastr.success('', 'Registro atualizado com sucesso!');
                },
                error => {
                    this.sloading = false;
                }
            );
        }
        catch (e) {
            this.sloading = false;
            this.toastr.error('', e.message);
        }
    }

    public delete(baseDoc: any): void {

        const confirmation = this.dialog.open(ConfirmationComponent, {
            width: '400px',
            transitionOptions: '1ms',
            showHeader: false,
            data: new Confirmation('Confirmação', 'Confirma a exclusão do item selecionado?')
        });

        confirmation.onClose.subscribe(dialogResult => {
            if (dialogResult) {
                this.doDelete(baseDoc);
            }
        });
    }

    public lockUnlock(baseDoc: any): void {

        const confirmation = this.dialog.open(ConfirmationComponent, {
            width: '400px',
            transitionOptions: '1ms',
            showHeader: false,
            data: new Confirmation('Confirmação', 'Confirma a alteração do item selecionado?')
        });

        confirmation.onClose.subscribe(dialogResult => {
            if (dialogResult) {
                this.doLockUnlock(baseDoc);
            }
        });
    }

    public initials(value: string) {
        try {
            var initial = '';
            if (value == null || value == '') {
                return initial;
            }
            else {
                var values = value.split(" ");

                if (values?.length == 1) {
                    initial = values[0].charAt(0);
                }
                else {
                    initial = values[0].charAt(0);
                    initial += values[values.length - 1].charAt(0);
                }

                return initial;
            }
        }
        catch (e) {
            return '';
        }
    }

    public paging(page: number): void {
        this.beforeFilter();

        this.sloading = true;
        this.filters.offset = page;

        this.http.post<any>(`${environment.apiUrl}/${this.mapping}/lazylist`, this.filters).subscribe(
            data => {
                this.data = data;

                if (this.loading === true && this.data.result.length == 0) {
                    this.showEmpty = true;
                }

                this.loading = false;
                this.sloading = false;
            },
            error => {
                this.sloading = false;
            }
        );
    }

    public download(): void {

        this.downloading = true;

        let currentUser = this.authService.currentUserValue;
        const url = `${environment.apiUrl}/${this.mapping}/download`;

        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${currentUser.token}`,
                'Account': `${currentUser.selected_account}`
            },
            body: JSON.stringify(this.filters),
        };

        fetch(url, options)
            .then(response => {
                if (!response.ok) {
                    this.toastr.error('Não foi possível gerar a planilha');
                }
                this.downloading = false;
                return response.blob();
            })
            .then(blob => {
                // Cria um URL temporário para o blob
                const blobUrl = URL.createObjectURL(blob);

                // Cria um link <a> para iniciar o download
                const link = document.createElement('a');
                link.href = blobUrl;
                link.setAttribute('download', 'planilha-' + this.mapping + '-' + this.formatDate(new Date()) + '.xlsx');
                document.body.appendChild(link);

                // Simula um clique no link para iniciar o download
                link.click();

                // Remove o link do DOM
                document.body.removeChild(link);

                // Revoga o URL temporário do blob
                URL.revokeObjectURL(blobUrl);
            })
            .catch(error => {
                this.toastr.error('Não foi possível gerar a planilha');
            });
    }

    public formatDate(date: Date): string {
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Os meses são indexados a partir de zero
        const year = date.getFullYear().toString();
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const seconds = date.getSeconds().toString().padStart(2, '0');

        return `${day}-${month}-${year}-${hours}_${minutes}_${seconds}`;
    }

    public cleanFilter(): void {
        this.filters.status = null;
        this.filters.period = null;
        this.filters.filters[0].value = "";
        this.find();
    }

    public find(order?: string): void {
        this.data = new Object;
        this.beforeFilter();

        this.sloading = true;
        this.filters.offset = 0;
        this.filters.filters[0].typeC = 'lk';

        // Zera todos os dados selecionado no checkbox
        this.ckbselgroup = false;
        this.selectedDatas = [];

        if (order != null && this.lorder == order) {
            this.lorder = order;

            if (this.direction) {
                this.direction = false;
                this.filters.orderDirection = 'desc';
            }
            else {
                this.direction = true;
                this.filters.orderDirection = 'asc';
            }

            this.filters.orderField = order;
        }
        else if (order != null) {
            this.lorder = order;
            this.direction = true;
            this.filters.orderDirection = 'asc';
            this.filters.orderField = order;
        }

        this.http.post<any>(`${environment.apiUrl}/${this.mapping}/lazylist`, this.filters).subscribe(
            data => {
                this.data = data;

                // Sempre inicia considerando que não está vazio e  depois valida
                this.showEmpty = false;
                // Caso o filtro esteja vazio e não retornou nada quer dizer que
                // não tem nenhum registro cadastrdo
                if (this.filters && this.filters.filters && !this.filters.filters[0].value) {
                    if (this.data && this.data.result && this.data.result.length == 0) {
                        this.showEmpty = true;
                    }
                }

                this.loading = false;
                this.sloading = false;
                this.afterFind();
            },
            error => {
                this.sloading = false;
            }
        );
    }

    public filterChangeAtt(att: string, alias: string): void {
        this.filters.filters[0].att = att;
        this.filters.filters[0].attAlias = alias;
    }

    public openDialog(data: any): void {
        this.datas = new Object;
        this.datas.data = data;
        this.datas.datas = this.data;

        this.datas.reload = false;

        const dialogRef = this.dialog.open(this.dialogClass, {
            data: this.datas,
            width: this.dialogwidth,
            height: this.dialogheight,
            transitionOptions: '1ms',
            showHeader: false
        });

        dialogRef.onClose.subscribe((result: any) => {
            if (this.datas.reload) {
                this.find(null);
            }
            this.afterClosed(data);
        });
    }

    public lockAll(): void {
        const confirmation = this.dialog.open(ConfirmationComponent, {
            width: '400px',
            transitionOptions: '1ms',
            showHeader: false,
            data: new Confirmation('Confirmação', 'Confirma o bloqueio em massa dos itens selecionados?')
        });

        confirmation.onClose.subscribe(dialogResult => {
            if (dialogResult) {
                this.doLockSelectedItens();
            }
        });
    }

    public unlockAll(): void {
        const confirmation = this.dialog.open(ConfirmationComponent, {
            width: '400px',
            transitionOptions: '1ms',
            showHeader: false,
            data: new Confirmation('Confirmação', 'Confirma o desbloqueio em massa dos itens selecionados?')
        });

        confirmation.onClose.subscribe(dialogResult => {
            if (dialogResult) {
                this.doUnLockSelectedItens();
            }
        });
    }

    public deleteAll(): void {
        const confirmation = this.dialog.open(ConfirmationComponent, {
            width: '400px',
            transitionOptions: '1ms',
            showHeader: false,
            data: new Confirmation('Confirmação', 'Confirma a exclusão em massa dos itens selecionados?')
        });

        confirmation.onClose.subscribe(dialogResult => {
            if (dialogResult) {
                this.doDeleteSelectedItens();
            }
        });
    }

    public clone(data: any): void {
        const confirmation = this.dialog.open(ConfirmationComponent, {
            width: '400px',
            transitionOptions: '1ms',
            showHeader: false,
            data: new Confirmation('Confirmação', 'Deseja realmente clonar o item selecionado?')
        });

        confirmation.onClose.subscribe(dialogResult => {
            if (dialogResult) {
                this.doClone(data);
            }
        });
    }

    public selectItems(type: string): void {
        switch (type) {
            case 'todos': {
                this.selectedDatas = this.data.result;
                break;
            }
            case 'nenhum': {
                this.selectedDatas = [];
                break;
            }
            case 'ativos': {
                this.selectedDatas = [];
                this.selectedDatas = this.data.result.filter(item => item.status && item.status.code === 'active');
                break;
            }
            case 'bloqueados': {
                this.selectedDatas = [];
                this.selectedDatas = this.data.result.filter(item => item.status && item.status.code === 'blocked');
                break;
            }
        }
        this.validateSelectItems();
    }

    public selectUnselectItems(event: any): void {
        if (event.checked) {
            this.selectedDatas = this.data.result;
        }
        else {
            this.selectedDatas = [];
        }
    }

    public validateSelectItems(event?: any): void {
        if (this.selectedDatas.length == this.data.result.length){
            this.ckbselgroup = true;
        }
        else {
            this.ckbselgroup = false;
        }
    }

    public buildFilterItens(): void {
        this.filters.filters[0].att = 'nome';
        this.filters.filters[0].attAlias = 'Nome';

        this.filterItens = [{
            label: 'Nome',
            command: () => {
                this.filterChangeAtt('nome', 'Nome')
            }
        }];
    }

    public afterSave(baseDoc: any): void { }
    public beforeSave(baseDoc: any): void { }
    public afterDelete(baseDoc: any): void { }
    public beforeDelete(baseDoc: any): void { }
    public afterClosed(baseDoc: any): void { }
    public beforeFilter(): void { }
    public afterFind(): void { }
}
