import { HttpClient } from '@angular/common/http';
import { Component, HostListener, OnInit } from '@angular/core';
import { environment } from '@environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng-lts/dynamicdialog';
import { Contato } from 'src/app/_entity/cadastro/contato';
import { Vendedor } from 'src/app/_entity/cadastro/vendedor';
import { PagamentoForma } from 'src/app/_entity/suporte/pagamentoForma';
import { Pedido } from 'src/app/_entity/venda/pedido';
import { AuthenticationService } from 'src/app/_services';
import { BaseService } from 'src/app/_services/base.service';
import { BaseServiceDialog } from 'src/app/_services/base.service.dialog';
import { Upload } from 'src/app/_services/base.service.import.dialog';
import { LayoutPrivateComponent } from 'src/app/suporte/layout-private/layout-private.component';
// import * as html2pdf from 'html2pdf.js';
import { Organizacao } from 'src/app/_entity/cadastro/organizacao';
import { PedidoDialogComponent } from './pedido.dialog.component';

@Component({
  selector: 'app-pedido',
  templateUrl: './pedido.component.html',
  styleUrls: ['./pedido.component.css'],
  providers: [DialogService]
})
export class PedidoComponent extends BaseService implements OnInit {

  public upload: Upload;
  
  constructor(
    public app: LayoutPrivateComponent,
    public http: HttpClient,
    public dialog: DialogService,
    public toastr: ToastrService,
    public translate: TranslateService,
    private authenticationService: AuthenticationService) {

    super('pedido', app, http, dialog, toastr, translate);
  }

  ngOnInit(): void {
    this.find();
    this.dialogClass = PedidoDialogComponent;
    this.authService = this.authenticationService;

    // this.dialogwidth = '800px';
    // this.dialogheight = '480px';
    this.buildFilterItens();
  }

  public badgePagamentoForma(pedido: Pedido): string {
    let bdg = 'label label-default s-120';
    
    if (pedido && pedido.pagamentoForma && 
      pedido.pagamentoForma.code === PagamentoForma.PIX.code ||
      pedido.pagamentoForma.code === PagamentoForma.DINHEIRO.code ||
      pedido.pagamentoForma.code === PagamentoForma.CARTAO_DEBITO.code ) {
      
        bdg = 'label label-green-oco s-120';
    }
    else if (pedido && pedido.pagamentoForma && 
      pedido.pagamentoForma.code === PagamentoForma.CARTAO_CREDITO.code ||
      pedido.pagamentoForma.code === PagamentoForma.OUTROS.code) {

        bdg = 'label label-success-oco s-120';
    }
    
    return bdg;
  }

  public buildFilterItens(): void {
    this.filters.filters[0].att = 'nome';
    this.filters.filters[0].attAlias = 'Nome';

    this.filterItens = [{
          label: 'Nome',
          command: () => {
            this.filterChangeAtt('nome', 'Nome')
          }
      },
      {
          label: 'Categoria',
          command: () => {
            this.filterChangeAtt('categorias.descricao', 'Categoria')
          }
      },
      {
        label: 'NBS',
        command: () => {
          this.filterChangeAtt('nbs', 'NBS')
        }
      }];
  }

  public openDialogFatura(pedido: Pedido): void {
    const dialogRef = this.dialog.open(PedidoFaturaDialogComponent, {
      data: pedido,
      width: '900px',
      height: '650px',
      transitionOptions: '1ms',
      showHeader: false
    });

    dialogRef.onClose.subscribe(result => {
    });
  }

  public openDialogDetalhe(pedido: Pedido): void {
    const dialogRef = this.dialog.open(PedidoDetalheDialogComponent, {
      data: pedido,
      width: '900px',
      height: '650px',
      transitionOptions: '1ms',
      showHeader: false
    });

    dialogRef.onClose.subscribe(result => {
    });
  }
}

@Component({
  selector: 'app-pedido-fatura-dialog',
  templateUrl: 'pedido.fatura.dialog.component.html',
})
export class PedidoFaturaDialogComponent extends BaseServiceDialog implements OnInit {

  public data: Pedido;
  public org: Organizacao;
  public contato: Contato;
  public gerandoPDF: boolean = false;

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public http: HttpClient,
    public toastr: ToastrService,
    public translate: TranslateService,
    private authenticationService: AuthenticationService) {

      super('pedido', http, toastr, translate, ref, config.data);

      this.data = config.data;
  }

  ngOnInit(): void {
    // Caso seja uma iniciativa ja criada para edicao, busca a ultima
    // versao no banco de dados e apos inicializa os autocompletes
    if (this.data && this.data._id) {
      this.find(this.data._id);

      this.http.get<any>(`${environment.apiUrl}/organizacao/find/${this.authenticationService.currentUserValue.organizacao}`).subscribe(
        data => {
          this.org = data;
        },
        error => {
            this.toastr.error('Não foi possível carregar o registro');
        }
      );

      if (this.data.idCliente) {
        this.http.get<any>(`${environment.apiUrl}/cliente/find/${this.data.idCliente}`).subscribe(
          data => {
            this.contato = data;
          },
          error => {
              this.toastr.error('Não foi possível carregar o registro');
          }
        );
      }
    }
    else {
      this.toastr.error('Pedido não informado');
      this.ref.close();
    }
  }

  public gerarPDF(): void {
    // this.gerandoPDF = true; 

    // setTimeout(() => {
    //   const options = {
    //     filename: 'meu_documento.pdf',
    //     image: { type: 'jpeg', quality: 0.98 },
    //     html2canvas: { scale: 2 },
    //     jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
    //     output: 'blob' 
    //   };

    //   const element: HTMLElement = document.getElementById('conteudo-pdf');
      
    //   this.gerandoPDF = false; 
     
    //   html2pdf()
    //     .from(element)
    //     .set(options)
    //     .toPdf()
    //     .get('pdf')
    //     .then(pdf => {
          
    //       const blob = new Blob([pdf.output('arraybuffer')], { type: 'application/pdf' });
    //       const url = URL.createObjectURL(blob);
    //       const newWindow = window.open(url, '_blank', 'toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=800,height=600');
          
    //     });
    // }, 200);
  }
}

@Component({
  selector: 'app-pedido-detalhe-dialog',
  templateUrl: 'pedido.detalhe.dialog.component.html',
})
export class PedidoDetalheDialogComponent extends BaseServiceDialog implements OnInit {

  public data: Pedido = null;
  public cliente: Contato = null;
  public vendedor: Vendedor = null;

  public carregandoContato: boolean = false;
  public carregandoVendedor: boolean = false;
  public editarAnotacaoPedido: boolean = false;

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public http: HttpClient,
    public toastr: ToastrService,
    public translate: TranslateService,
    public dialog: DialogService) {

      super('pedido', http, toastr, translate, ref, config.data);
      
      this.data = config.data;
  }

  ngOnInit(): void {
    // Caso seja uma iniciativa ja criada para edicao, busca a ultima
    // versao no banco de dados e apos inicializa os autocompletes
    if (this.data && this.data._id) {
      this.find(this.data._id);
    }
    else {
      this.toastr.error('Pedido não informado');
      this.dialogRef.close();
    }
  }

  public openFaturaDialog(): void {
    this.dialogRef.close();
    
    this.dialog.open(PedidoFaturaDialogComponent, {
      data: this.data,
      width: '900px',
      height: '650px',
      transitionOptions: '1ms',
      showHeader: false
    });
  }

  public habilitarAnotacaoPedido(acao: boolean): void {
    this.editarAnotacaoPedido = acao;
  }

  public salvarAnotacaoPedido(event?: any): void {
    try {
      this.http.put<any>(`${environment.apiUrl}/${this.mapping}/salvarAnotacaoPedido`, this.data).subscribe(
        data => {                    
            this.toastr.success('Anotação atualizada com sucesso.');
            this.habilitarAnotacaoPedido(false);
        },
        error => {
          this.toastr.error(error);
        }
      );
    }
    catch (e) {
      this.toastr.error(e.message);
    }
  }

  public atualizarStatusCliente(): void {
    try {
      this.carregandoContato = true;
      this.http.get<any>(`${environment.apiUrl}/cliente/find/${this.data.idCliente}`).subscribe(
          data => {
              this.cliente = data;
              this.carregandoContato = false;
          },
          error => {
              this.carregandoContato = false;
              this.toastr.error(error);
          }
      );
    }
    catch (e) {
      this.carregandoContato = false;
      this.toastr.error(e.message);
    }
  }
  
  public atualizarStatusVendedor(): void {
    try {
      this.carregandoVendedor = true;
      this.http.get<any>(`${environment.apiUrl}/vendedor/find/${this.data.idVendedor}`).subscribe(
          data => {
              this.vendedor = data;
              this.carregandoVendedor = false;
          },
          error => {
              this.carregandoVendedor = false;
              this.toastr.error(error);
          }
      );
    }
    catch (e) {
      this.carregandoVendedor = false;
      this.toastr.error(e.message);
    }
  }

  @HostListener('window:keydown.esc', ['$event'])
  keClose(event: KeyboardEvent) {
    this.dialogRef.close();
  }
}