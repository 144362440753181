import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng-lts/dynamicdialog';
import { BaseService } from 'src/app/_services/base.service';
import { LayoutPrivateComponent } from 'src/app/suporte/layout-private/layout-private.component';
import { BaseServiceImportDialog, Upload } from 'src/app/_services/base.service.import.dialog';
import { AuthenticationService } from 'src/app/_services';
import { CobrancaDialogComponent } from './cobranca.dialog.component';

@Component({
  selector: 'app-cobranca',
  templateUrl: './cobranca.component.html',
  styleUrls: ['./cobranca.component.css'],
  providers: [DialogService]
})
export class CobrancaComponent extends BaseService implements OnInit {
  
  public upload: Upload;

  constructor(
    public app: LayoutPrivateComponent,
    public http: HttpClient,
    public dialog: DialogService,
    public toastr: ToastrService,
    public translate: TranslateService) {

      super('servico', app, http, dialog, toastr, translate);
      
      this.dialogClass = CobrancaDialogComponent;
  }

  ngOnInit(): void {
    this.find();
    this.buildFilterItens();
  }

  public openDialogImportar(): void {
    this.upload = new Upload();
    this.upload.carregado = false;

    const dialogRef = this.dialog.open(CobrancaDialogImportarComponent, {
      data: this.upload,
      width: '80%',
      height: '500px',
      transitionOptions: '1ms',
      showHeader: false
    });

    dialogRef.onClose.subscribe(result => {
      if (this.upload != null && this.upload.carregado)
        this.find(null);
    });
  }

  public buildFilterItens(): void {
    this.filters.filters[0].att = 'nome';
    this.filters.filters[0].attAlias = 'Nome';

    this.filterItens = [{
          label: 'Nome',
          command: () => {
            this.filterChangeAtt('nome', 'Nome')
          }
      },
      {
          label: 'Categoria',
          command: () => {
            this.filterChangeAtt('categorias.descricao', 'Categoria')
          }
      },
      {
        label: 'NBS',
        command: () => {
          this.filterChangeAtt('nbs', 'NBS')
        }
      }];
  }
}

@Component({
  selector: 'app-cobranca-dialog-importar',
  templateUrl: 'cobranca.dialog.importar.component.html',
})
export class CobrancaDialogImportarComponent extends BaseServiceImportDialog {
  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public http: HttpClient,
    public toastr: ToastrService,
    public translate: TranslateService,
    public authenticationService: AuthenticationService) {
    super('servico', http, toastr, translate, authenticationService, ref, config.data);
  }

}