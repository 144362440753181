<div id="content" class="content content-full-width">

    <h1 class="page-header">Responder Perguntas</h1>
    
    <div class="vertical-box with-grid inbox bg-light">

        <div class="vertical-box-column width-200">
            <div class="vertical-box">

                <div class="wrapper d-none d-sm-none d-md-none d-lg-block">
                    <div class="d-flex align-items-center justify-content-center">
                        Menu
                    </div>
                </div>

                <div class="vertical-box-row d-lg-table-row collapse">
                    <div class="vertical-box-cell">
                        <div class="vertical-box-inner-cell">
                            <div class="keras-scrollbar">
                                <div class="wrapper p-0">
                                    <ul class="nav nav-inbox">
                                        <li [ngClass]="activeIndex == 0 ? 'active' : ''"><a (click)="activeIndex = 0"
                                                href="javascript:;">Perguntas</a></li>
                                        <li [ngClass]="activeIndex == 1 ? 'active' : ''"><a (click)="activeIndex = 1"
                                                href="javascript:;">Simulador</a></li>
                                        <li [ngClass]="activeIndex == 2 ? 'active' : ''"><a (click)="activeIndex = 2"
                                                href="javascript:;">Configuração</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

        <div class="vertical-box-column">
            <div class="vertical-box">
                <div class="vertical-box-row">
                    <div class="vertical-box-cell">
                        <div class="vertical-box-inner-cell bg-white">
                            <div class="keras-scrollbar">
                                <div class="bs-responder-pergunta">
                                    <div class="col-12">
                                        
                                        <p-tabView [(activeIndex)]="activeIndex">
                                            
                                            
                                            
                                            <p-tabPanel>
                                                
                                                <div class="row p-l-20 p-r-20">

                                                    <div class="col-7">
                                                        
                                                        <p-panel header="Pergunta">
                                                
                                                          <div class="row align-items-center">
                                                            <div class="col-lg-12 d-flex align-items-center">
                                                              <div class="h-65px w-65px d-flex align-items-center justify-content-center position-relative">
                                                                <img src="https://store.storeimages.cdn-apple.com/4982/as-images.apple.com/is/iphone-card-40-iphone15prohero-202309_FMT_WHH?wid=508&hei=472&fmt=p-jpg&qlt=95&.v=1693086369818" class="mw-100 mh-100" />
                                                              </div>

                                                              <div class="ps-3 flex-1">
                                                                <div><a href="#" class="text-decoration-none text-dark f-s-14 f-w-500">iPhone 13 Pro Max</a></div>
                                                                <div class="text-dark text-opacity-50 small">
                                                                  <div class="row m-0" style="color: #777;">
                                                                    MLB3301420286
                                                                    <a class="p-l-20">SSANVIDO</a>
                                                                  </div>
                                                                </div>
                                                                <hr style="margin: 6px 0 8px;">
                                                                <div class="text-dark text-opacity-50 small">
                                                                  <div class="row m-0" style="color: #777;">
                                                                    <span>R$ 1000,00</span>
                                                                    <i class="fas fa-lg fa-fw m-l-20 m-t-2 m-r-5 fa-bullhorn"></i> <span>Premiun</span>
                                                                    <i class="fas fa-lg fa-fw m-l-20 m-t-2 m-r-5 fa-truck"></i> <span>Grátis</span>
                                                                    <i class="fas fa-lg fa-fw m-l-20 m-t-2 m-r-5 fa-shopping-cart"></i> <span>2.343</span>
                                                                    <i class="fas fa-lg fa-fw m-l-20 m-t-2 m-r-5 fa-cubes"></i> <span>145</span>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                
                                                          <hr class="mb-4">
                                                
                                                          <div class="flex-1">
                                                            <div class="text-dark text-opacity-50 small">
                                                              <div class="row m-0" style="color: #777;">
                                                                <a>SSANVIDO</a>
                                                                <span class="p-l-20"> Ontem às 11:23</span>
                                                              </div>
                                                            </div>
                                                          </div>
                                                
                                                          <div class="alert alert-info" style="font-size: 13px;">
                                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque ac posuere lacus, quis suscipit sem. Nulla sagittis aliquam erat non convallis.
                                                          </div>
                                                
                                                        <div class="p-inputgroup m-b-20">
                                                            <input type="text" pInputText placeholder="Keyword">   
                                                            <button type="button" pButton icon="pi pi-refresh" styleClass="p-button-warn"></button>      
                                                        </div>
                                                
                                                          <div class="input-group">
                                                            <textarea class="form-control" rows="4">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque ac posuere lacus, quis suscipit sem. Nulla sagittis aliquam erat non convallis.</textarea>
                                                          </div>
                                                          <a href="#" class="ms-auto text-decoration-none m-b-10 text-gray-600" style="float: right; font-size: 9px;">GERAR NOVA RESPOSTA</a>
                                                
                                                          <div class="input-group m-b-20">
                                                            <textarea class="form-control" rows="4" placeholder="Informações complementares"></textarea>
                                                          </div>
                                                
                                                          <div class="p-inputgroup m-b-30">
                                                            <input type="text" pInputText placeholder="Keyword">   
                                                            <button type="button" pButton icon="pi pi-refresh" styleClass="p-button-warn"></button>      
                                                        </div>
                                                        
                                                
                                                        <div class="panel-footer">
                                                        
                                                        
                                                            <button class="btn btn-danger m-b-5">
                                                              Excluir
                                                            </button>
                                                        
                                                            <button class="btn btn-primary m-b-5 m-l-5 pull-right">
                                                              Responder <i class="fa fa-circle-o-notch fa-spin"></i>
                                                            </button>
                                                        
                                                            <button class="btn btn-white m-b-5 pull-right">
                                                              Pular <i class="fa fa-circle-o-notch fa-spin"></i>
                                                            </button>
                                                        
                                                        
                                                        </div>
                                                      
                                                    </p-panel>
                                                
                                                    </div>
                                                
                                                
                                                
                                                    <div class="col-4">
                                                        <div class="row m-b-20">
                                                            <p-panel header="Anotações">
                                                                <div class="keras-scrollbar" style="height: 55px;">
                                                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque ac posuere
                                                                    lacus, quis suscipit sem. Nulla sagittis aliquam erat non convallis.
                                                                </div>
                                                            </p-panel>
                                                        </div>
                                                        
                                                        <div class="row m-b-20">
                                                            <p-panel header="Perguntas Anteriores">
                                                                <div class="keras-scrollbar" style="height: 180px;">
                                                                    <div class="chats">
                                                                        <div class="left">
                                                                        <span class="date-time">yesterday 11:23pm</span>
                                                                        <a href="javascript:;" class="name">Sowse Bawdy</a>
                                                
                                                                        <div class="message">
                                                                            Lorem ipsum dolor sit amet, consectetuer adipiscing elit volutpat. Praesent mattis interdum
                                                                            arcu eu feugiat.
                                                                        </div>
                                                                        </div>
                                                                        <div class="right">
                                                                        <span class="date-time">08:12am</span>
                                                                        <a href="javascript:;" class="name"><span class="label label-primary">SSANVIDO</span></a>
                                                
                                                                        <div class="message">
                                                                            Nullam posuere, nisl a varius rhoncus, risus tellus hendrerit neque.
                                                                        </div>
                                                                        </div>
                                                
                                                                        <div class="left">
                                                                        <span class="date-time">yesterday 11:23pm</span>
                                                                        <a href="javascript:;" class="name">Sowse Bawdy</a>
                                                
                                                                        <div class="message">
                                                                            Lorem ipsum dolor sit amet, consectetuer adipiscing elit volutpat. Praesent mattis interdum
                                                                            arcu eu feugiat.
                                                                        </div>
                                                                        </div>
                                                                        <div class="right">
                                                                        <span class="date-time">08:12am</span>
                                                                        <a href="javascript:;" class="name"><span class="label label-primary">SSANVIDO</span></a>
                                                
                                                                        <div class="message">
                                                                            Nullam posuere, nisl a varius rhoncus, risus tellus hendrerit neque.
                                                                        </div>
                                                                        </div>
                                                
                                                                    </div>
                                                                </div>
                                                            </p-panel>
                                                        </div>
                                                        
                                                        <div class="row m-b-20">
                                                            <p-panel header="Histórico do Cliente">
                                                                <div class="keras-scrollbar" style="height: 157px;">
                                                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque ac posuere
                                                                    lacus, quis suscipit sem. Nulla sagittis aliquam erat non convallis.
                                                                </div>
                                                            </p-panel>
                                                        </div>
                                                
                                                    </div>
                                                  </div>
                                            </p-tabPanel>

                                            <p-tabPanel>
                                                <h4 class="m-b-30">Informações de Cobrança</h4>
                                            </p-tabPanel>

                                            <p-tabPanel>
                                                <h4 class="m-b-30">Forma de Pagamento</h4>
                                                <div class="row">
                                                    <div class="col-xl-3 col-md-6">
                                                    </div>
                                                </div>
                                            </p-tabPanel>

                                            <p-tabPanel>
                                                <h4 class="m-b-30">Histórico de Pagamento</h4>
                                                <div class="row">
                                                    <div class="col-xl-3 col-md-6">
                                                    </div>
                                                </div>
                                            </p-tabPanel>
                                        </p-tabView>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>