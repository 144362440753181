import { Component } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng-lts/dynamicdialog';

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.css']
})
export class ConfirmationComponent {

  title: string;
  message: string;

  constructor(
    public ref: DynamicDialogRef, 
    public config: DynamicDialogConfig) {
    
      this.title = config.data.title;
      this.message = config.data.message;
  }

  onConfirm(): void {
    this.ref.close(true);
  }

  onDismiss(): void {
    this.ref.close(false);
  }

}
