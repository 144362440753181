<div id="content" class="content content-full-width">

    <h1 class="page-header">Gerenciamento de Perguntas</h1>

    <div class="vertical-box with-grid inbox bg-light">
        <div class="vertical-box-column width-200">
            <div class="vertical-box">

                <div class="wrapper d-none d-sm-none d-md-none d-lg-block">
                    <div class="d-flex align-items-center justify-content-center" style="margin: 5px 0 6px;">
                        Menu
                    </div>
                </div>

                <div class="vertical-box-row d-lg-table-row collapse">
                    <div class="vertical-box-cell">
                        <div class="vertical-box-inner-cell">
                            <div class="keras-scrollbar">
                                <div class="wrapper p-0">
                                    <div class="nav-title"><b>STATUS</b></div>
                                    <ul class="nav nav-inbox">
                                        <li [ngClass]="filters.status == null ? 'active' : ''" (click)="filterByStatus(null)"><a href="javascript:;">Todos</a></li>
                                        <li [ngClass]="filters.status == 'active'? 'active' : ''" (click)="filterByStatus('active')"><a href="javascript:;" >Não Respondidas</a></li>
                                        <li [ngClass]="filters.status == 'blocked' ? 'active' : ''" (click)="filterByStatus('blocked')"><a href="javascript:;">Respondidas</a></li>
                                        <li [ngClass]="filters.status == 'blocked' ? 'active' : ''" (click)="filterByStatus('blocked')"><a href="javascript:;">Bloqueadas</a></li>
                                    </ul>

                                    <div class="nav-title"><b>PERÍODO</b></div>
                                    <ul class="nav nav-inbox">
                                        <li [ngClass]="filters.period == null ? 'active' : ''" (click)="filterByPeriod(null)"><a href="javascript:;">Todos</a></li>
                                        <li [ngClass]="filters.period == '0' ? 'active' : ''" (click)="filterByPeriod('0')"><a href="javascript:;">Hoje</a></li>
                                        <li [ngClass]="filters.period == '7' ? 'active' : ''" (click)="filterByPeriod('7')"><a href="javascript:;">Últimos 7 dias</a></li>
                                        <li [ngClass]="filters.period == '30' ? 'active' : ''" (click)="filterByPeriod('30')"><a href="javascript:;">Últimos 30 dias</a></li>
                                        <li [ngClass]="filters.period == '90' ? 'active' : ''" (click)="filterByPeriod('90')"><a href="javascript:;">Últimos 90 dias</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
       
        <div class="vertical-box-column">
            <div class="vertical-box">
                
                
                <div class="wrapper">
                    <div class="input-group">
                        <button class="btn btn-sm btn-white mr-2 d-none d-sm-none d-md-none d-lg-block" (click)="find()" pTooltip="Atualizar" tooltipPosition="bottom">
                            <i class="pi pi-undo"></i>
                        </button>
                        
                        <div class="input-group-prepend mr-2" *ngIf="selectedDatas.length > 0">
                            <button class="btn btn-sm btn-white" (click)="unlockAll()" pTooltip="Desbloquear em massa" tooltipPosition="bottom">
                                <i class="pi pi-lock"></i>
                            </button>
                            <button class="btn btn-sm btn-white" (click)="lockAll()" pTooltip="Bloquear em massa" tooltipPosition="bottom">
                                <i class="pi pi-unlock"></i>
                            </button>
                            <button class="btn btn-sm btn-white" (click)="deleteAll()" pTooltip="Excluir em massa" tooltipPosition="bottom">
                                <i class="pi pi-trash"></i>
                            </button>
                        </div>
                        
                        <div class="input-group-prepend" (click)="menu.toggle($event)">
                            <button class="btn btn-white btn-sm">{{filters.filters[0].attAlias}}</button>
                            <button class="btn btn-white btn-sm"><span class="caret"></span></button>
                        </div>
                        <p-menu #menu [popup]="true" [model]="filterItens"></p-menu>

                        <input type="text" [(ngModel)]="filters.filters[0].value" name="name"
                            (keyup.enter)="find()" placeholder="{{'search.placeholder' | translate}}" class="form-control m-r-3" style="height: 29px;">

                        <div class="btn-group ml-auto">
                            <button class="btn btn-sm btn-white d-none d-sm-none d-md-none d-lg-block" [disabled]="downloading" (click)="download()" tooltipPosition="bottom" pTooltip="Exportar dados para planilha">
                                <i class="pi pi-download" *ngIf="!downloading"></i>
                                <i class="pi pi-spin pi-spinner" *ngIf="downloading"></i>
                            </button>
                        </div>

                        <div class="btn-group ml-auto">
                            <label style="margin: 0px 10px 0px; padding-top: 6px;" *ngIf="!showEmpty && !loading && !sloading">
                                {{data?.paginator?.intervalBegin}}-{{data?.paginator?.intervalEnd}} de {{data?.paginator?.totalResult}}
                            </label>
                        </div>
                        <div class="btn-group ml-0">
                            <button (click)="paging(data?.paginator?.currentPage - 1)" tooltipPosition="bottom" pTooltip="Anteriores" [disabled]="data?.paginator?.currentPage == 1" class="btn btn-white btn-sm">
                                <i class="pi pi-chevron-left"></i>
                            </button>
                            <button (click)="paging(data?.paginator?.currentPage + 1)" tooltipPosition="bottom" pTooltip="Próximas" [disabled]="data?.paginator?.currentPage == data?.paginator?.totalPages" class="btn btn-white btn-sm">
                                <i class="pi pi-chevron-right"></i>
                            </button>
                        </div>
                    </div>
                </div>


                <div class="vertical-box-row">
                    <div class="vertical-box-cell">
                        <div class="vertical-box-inner-cell bg-white">
                            <div class="keras-scrollbar">

                                <div class="p-40 fa-3x" style="position: absolute; left: 44%;" *ngIf="loading || sloading">
                                    <i class="fas fa-spinner fa-pulse"></i>
                                </div>

                                <div class="table-responsive" *ngIf="data?.paginator?.totalResult > 0">
                                    <table class="table table-striped be-result-table">
                                        <tbody>
                                            <tr>
                                                <th class="be-result-th-action" colspan="2">
                                                    <div ngbDropdown container="body">
                                                        <p-checkbox [(ngModel)]="ckbselgroup" binary="true" (onChange)="selectUnselectItems($event)" class="be-result-table-checkbox-box"></p-checkbox>
                                                        <button class="btn btn-sm" ngbDropdownToggle><span class="caret"></span></button>
                                                        <div ngbDropdownMenu>
                                                            <button (click)="selectItems('todos')" ngbDropdownItem>Todos</button>
                                                            <button (click)="selectItems('nenhum')" ngbDropdownItem>Nenhum</button>
                                                            <button (click)="selectItems('ativos')" ngbDropdownItem>Ativos</button>
                                                            <button (click)="selectItems('bloqueados')" ngbDropdownItem>Bloqueado</button>
                                                        </div>
                                                    </div>
                                                </th>
                                                <th (click)="find('nome')" style="width: 25%;">Produto</th>
                                                <th (click)="find('categorias.descricao')" style="width: 25%;">Pergunta</th>
                                                <th (click)="find('unidadeMedida.code')" style="width: 25%;">Resposta</th>
                                                <th (click)="find('status.code')" class="text-center">Status</th>
                                            </tr>

                                            <tr *ngFor="let d of data?.result">
                                                <td class="be-result-td-checkbox">
                                                    <p-checkbox name="ckbgroup" [value]="d" [(ngModel)]="selectedDatas" (onChange)="validateSelectItems($event)" class="be-result-table-checkbox-box"></p-checkbox>
                                                </td>
                                                <td class="be-result-td-menu">
                                                    <div ngbDropdown container="body">
                                                        <button pButton type="button" icon="pi pi-bars" class="p-button-sm p-button-rounded p-button-text p-button-plain be-result-table-menu" ngbDropdownToggle></button>
                                                        <div ngbDropdownMenu>
                                                            <button (click)="openDialog(d)"    ngbDropdownItem><i class="fa fa-edit"></i>Editar registro</button>
                                                            <button (click)="openDialogCRM(d)" ngbDropdownItem *ngIf="d?.status?.code != 'blocked'"><i class="fa fa-user-plus"></i>Inserir ação CRM</button>
                                                            <button (click)="openDialogCRM(d)" ngbDropdownItem *ngIf="d?.status?.code != 'blocked'"><i class="fa fa-user"></i>Ver ações CRM</button>
                                                            <button (click)="openDialogCRM(d)" ngbDropdownItem *ngIf="d?.status?.code != 'blocked'"><i class="fa fa-cart-plus"></i>Inciar venda</button>
                                                            <button (click)="openDialog(d)"    ngbDropdownItem><i class="fa fa-history"></i>Visualizar histórico</button>
                                                            
                                                            <div class="dropdown-divider"></div>
                                                            
                                                            <button (click)="lockUnlock(d)" ngbDropdownItem *ngIf="d?.status?.code != 'blocked'"><i class="fa fa-ban"></i> Bloquear</button>
                                                            <button (click)="lockUnlock(d)" ngbDropdownItem *ngIf="d?.status?.code == 'blocked'"><i class="fa fa-ban"></i> Desbloquear</button>
                                                            <button (click)="delete(d)"     ngbDropdownItem><i class="fa fa-trash"></i> Remover registro</button>
                                                        </div>
                                                    </div>
                                                </td>
                                                
                                                <td (click)="openDialog(d)">
                                                    <div class="col-lg-12 d-flex align-items-center p-0">
                                                        <div
                                                            class="h-65px w-65px d-flex align-items-center justify-content-center position-relative">
                                                            <img [src]="d.produtos[0]?.pictures[0]?.secure_url" class="mw-100 mh-100" />
                                                        </div>
                                                        <div class="ps-3 flex-1">
                                                            <div>
                                                                <span class="text-decoration-none text-dark f-s-14 f-w-500">{{d.produtos[0]?.title}}</span>
                                                            </div>
                                                            <div class="text-dark text-opacity-50 small">
                                                                <div class="row m-0" style="color: #999;">
                                                                    {{d.produtos[0]?.id}}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td (click)="openDialog(d)">
                                                    <div class="text-dark text-opacity-50 small">
                                                        <div class="row m-0" style="color: #999;">
                                                            <span> {{d.date_created | date: 'dd/MM/yyyy HH:mm'}}</span>
                                                        </div>
                                                    </div>
                                                    <div class="limitar-texto">
                                                        {{d.text}}
                                                    </div>
                                                </td>
                                                <td (click)="openDialog(d)">
                                                    <div class="text-dark text-opacity-50 small">
                                                        <div class="row m-0" style="color: #999;">
                                                            <span> {{d.answer?.date_created | date: 'dd/MM/yyyy HH:mm'}}</span>
                                                        </div>
                                                    </div>
                                                    <div class="limitar-texto">
                                                        {{d.answer?.text}}
                                                    </div>
                                                </td>
                                                <td (click)="openDialog(d)" class="text-center" *ngIf="d?.status == 'UNANSWERED'"><span class="label label-danger s-90">Não Respondida</span></td>
                                                <td (click)="openDialog(d)" class="text-center" *ngIf="d?.status == 'ANSWERED'"><span class="label label-primary s-90">Respondida</span></td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>

                                <div class="row m-t-40" *ngIf="showEmpty">
                                    <div class="col-md-3"></div>
                                    <div class="col-md-6">

                                        <div class="note note-info">
                                            <div class="note-icon"><i class="fa fa-comments"></i></div>
                                            <div class="note-content">
                                                <h5 class="p-t-10">Nenhuma pergunta recebida até o momento.</h5>
                                            </div>
                                        </div>

                                    </div>
                                    <div class="col-md-3"></div>
                                </div>

                                <div class="row m-t-40" *ngIf="!showEmpty && data?.paginator?.totalResult == 0">
                                    <div class="col-md-3"></div>
                                    <div class="col-md-6">

                                        <div class="note note-info cursor-pointer" (click)="cleanFilter()">
                                            <div class="note-icon"><i class="fa fa-lightbulb"></i></div>
                                            <div class="note-content">
                                                <h4 class="p-t-10"><b>Sua pesquisa não retornou resultados.</b></h4>
                                                <p>Tente outras opções de pesquisa, tipos de campos ou remova os filtros clicando aqui.</p>
                                            </div>
                                        </div>

                                    </div>
                                    <div class="col-md-3"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>