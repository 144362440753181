<div class="bs-dialog-header">
    <i class="fa fa-wrench"></i> SERVIÇOS <small> Gestão de Serviços </small>
  </div>
  
  <div class="bs-dialog-main">
    <div class="bs-dialog-content">
  
      <!-- Carregando informações... -->
      <div class="modal-overlay" *ngIf="loading">
        <div class="alert alert-light fade show m-b-0 text-center" style="color: #222;">
          <i class="fas fa-circle-notch fa-spin"></i> 
        </div>
      </div>
  
      <div class="be-form">
  
        <div class="row">
          <div class="col-6">
            <label>Nome do serviço:</label>
            <input type="text" pInputText [(ngModel)]="data.nome" class="form-control">
          </div>
          
          <div class="col-6">
            <label>Categoria</label>
            <input type="text" pInputText [(ngModel)]="data.nome" class="form-control">
          </div>
        </div>
  
        <div class="row">
          <div class="col-6">
            <label>Nomenclatura brasileira de serviço (NBS)</label>
            <input type="text" pInputText [(ngModel)]="data.nome" class="form-control">
          </div>
        </div>
  
        <div class="row">
          <div class="col-3">
            <label>Unidade de medida:</label>
            <select [(ngModel)]="data.unidadeMedida.code" name="medida.code" class="form-control">
              <option label="Hora" value="hr">Hora</option>
              <option label="Dia" value="dia">Dia</option>
              <option label="Semana" value="semana">Semana</option>
              <option label="Mês" value="mes">Mês</option>
              <option label="Valor Fechado" value="vf">Valor Fechado</option>
            </select>
          </div>
          <div class="col-3">
            <label>Valor de venda:</label>
            <div class="p-inputgroup">
              <span class="p-inputgroup-addon">R$</span>
              <p-inputNumber [(ngModel)]="data.valorVenda" mode="decimal" locale="pt-BR" [minFractionDigits]="2" [maxFractionDigits]="2" maxlength="17"></p-inputNumber>
            </div>
          </div>
          <div class="col-3">
            <label>Valor promocional:</label>
            <div class="p-inputgroup">
              <span class="p-inputgroup-addon">R$</span>
              <p-inputNumber [(ngModel)]="data.valorPromocional" mode="decimal" locale="pt-BR" [minFractionDigits]="2" [maxFractionDigits]="2" maxlength="17"></p-inputNumber>
            </div>
          </div>
        </div>
        
        <div class="row">
          <div class="col-12">
            <label>Observações:</label>
            <textarea [(ngModel)]="data.descricao" rows="3" class="form-control" placeholder="Observações"></textarea>
          </div>
        </div>
  
      </div>    
    </div>
  </div>
  
  <div class="bs-dialog-footer">
    <div class="bs-footer-action-close">
      <button (click)="close()" class="btn btn-white" pTooltip="Esc" tooltipPosition="bottom">Fechar</button>
    </div>
    <div class="bs-footer-action">
      <button (click)="save()" [disabled]="recording" class="btn btn-white" pTooltip="Ctrl + Enter" tooltipPosition="bottom">Salvar e criar outro</button>
      <button (click)="saveClose()" [disabled]="recording" class="btn btn-primary" pTooltip="Ctrl + S" tooltipPosition="bottom">Salvar</button>
    </div>
  </div>