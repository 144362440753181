<div class="bs-dialog-header">
    <i class="fa fa-chart-pie"></i> CENTRO DE CUSTO <small> Gestão de Centro de Custo </small>
  </div>
  
  <div class="bs-dialog-main">
    <div class="bs-dialog-content">
  
      <!-- Carregando informações... -->
      <div class="modal-overlay" *ngIf="loading">
        <div class="alert alert-light fade show m-b-0 text-center" style="color: #222;">
          <i class="fas fa-circle-notch fa-spin"></i> 
        </div>
      </div>
  
      <div class="be-form">
  
        <div class="row">
          <div class="col-2">
            <label>Código:</label>
            <input type="text" pInputText [(ngModel)]="data.codigo" class="form-control">
          </div>
          <div class="col-4">
            <label>Nome:</label>
            <input type="text" pInputText [(ngModel)]="data.nome" class="form-control">
          </div>
        </div>

        <div class="row">
          <div class="col-6">
            <label>Responsável:</label>
            <p-autoComplete [(ngModel)]="funcionario" (onSelect)="onSelectFuncionario()" field="nome" [suggestions]="autocompleteFuncionario" (completeMethod)="acFindFuncionario($event)" autoHighlight="true" forceSelection="true" emptyMessage="Funcionário não encontrado" class="be-autocomplete">
              <ng-template let-funcionario pTemplate="item">
                {{funcionario.nome}}
              </ng-template>
            </p-autoComplete>
          </div>
        </div>

        <div class="row">
          <div class="col-6">
            <label>Departamento:</label>
            <input type="text" pInputText [(ngModel)]="data.departamento" class="form-control">
          </div>
        </div>
  
        <div class="row">
          <div class="col-3">
            <label>Orçamento:</label>
            <div class="p-inputgroup">
              <span class="p-inputgroup-addon">R$</span>
              <p-inputNumber [(ngModel)]="data.orcamento" mode="decimal" locale="pt-BR" [minFractionDigits]="2" [maxFractionDigits]="2" maxlength="17"></p-inputNumber>
            </div>
          </div>
        </div>
        
        <div class="row">
          <div class="col-12">
            <label>Observações:</label>
            <textarea [(ngModel)]="data.descricao" rows="3" class="form-control" placeholder="Observações"></textarea>
          </div>
        </div>
  
      </div>    
    </div>
  </div>
  
  <div class="bs-dialog-footer">
    <div class="bs-footer-action-close">
      <button (click)="close()" class="btn btn-white" pTooltip="Esc" tooltipPosition="bottom">Fechar</button>
    </div>
    <div class="bs-footer-action">
      <button (click)="save()" [disabled]="recording" class="btn btn-white" pTooltip="Ctrl + Enter" tooltipPosition="bottom">Salvar e criar outro</button>
      <button (click)="saveClose()" [disabled]="recording" class="btn btn-primary" pTooltip="Ctrl + S" tooltipPosition="bottom">Salvar</button>
    </div>
  </div>