<div class="bs-dialog-header">
  <i class="fa fa-upload"></i> CLIENTES <small> Importar Registros </small>
</div>

<div class="bs-dialog-main">
  <div class="bs-dialog-content">

  <div *ngIf="carregando" style="height: 511px;">
    <div class="alert alert-light fade show m-b-0 text-center" style="color: #222;">
      <i class="fas fa-circle-notch fa-spin m-r-10"></i> 
      Carregando arquivo... Esta ação poderá demorar um pouco dependendo do tamanho do arquivo.
    </div>
  </div>
  <div *ngIf="!carregando">

    <div class="panel-body">
      <div class="alert alert-light fade show m-b-0" style="color: #222;">
        <strong>Selecione</strong> um arquivo no formato Excel <strong>(.xls ou .xlsx)</strong> ou texto
        <strong>(.csv)</strong>.
      </div>
    </div>

    <div class="m-t-15">
      <span class="btn btn-primary fileinput-button" *ngIf="registro == null">
        <i class="fa fa-upload"></i>
        Carregar planilha de clientes
        <input (change)="this.onFilechange($event)" type="file" id="formFile" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" />
      </span>
      <span class="btn btn-primary fileinput-button" *ngIf="registro != null">
        <i class="fa fa-upload"></i>
        Carregar outra planilha de clientes
        <input (change)="this.onFilechange($event)" type="file" id="formFile" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" />
      </span>

      <button [disabled]="downloading" (click)="download()" class="btn btn-default m-l-20">
        <i class="fa fa-download" *ngIf="!downloading"></i>
        <i class="fas fa-circle-notch fa-spin" *ngIf="downloading"></i>
        Download de uma planilha de exemplo
      </button>

      <button (click)="confirmar()" class="btn btn-success pull-right" *ngIf="registro != null">
        <i class="fa fa-cloud-upload-alt"></i>
        Importar todos os dados da planilha
      </button>
    </div>

    <hr />

    <div class="alert alert-light fade show" style="color: #222;" *ngIf="registro == null">
      Exemplo de uma planilha excel compatível com BeSales
    </div>

    <div class="alert alert-light fade show" style="color: #222;" *ngIf="registro != null">
      <h5>Verifique todos os dados antes de continuar a importação.</h5>
      <span class="badge badge-default badge-square pull-right">Você pode editar os dados antes de carregar.</span>
      Todas as informações presentes nos arquivos enviados serão importadas na sua conta e a operação não poderá ser desfeita.
    </div>

    <span *ngIf="registro != null" class="label label-success"><strong>{{registro.registros}}</strong> Registros</span>
    <span *ngIf="registro != null" class="label label-danger m-l-20"><strong>{{registro.erros}}</strong> Registros com erro</span>

    <table class="table table-bordered table-overlay table-import bg-white m-t-5">
      <thead>
        <tr>
          <th *ngIf="registro != null"></th>
          <th class="text-nowrap" *ngIf="registro != null">Linha</th>
          <th class="text-nowrap">Nome</th>
          <th class="text-nowrap">Email</th>
          <th class="text-nowrap">Telefone</th>
          <th class="text-nowrap">Gênero</th>
          <th class="text-nowrap">Observação</th>
          <th class="text-nowrap">Nascimento / Abertura</th>
          <th class="text-nowrap">Profissao</th>
          <th class="text-nowrap">Naturalidade</th>
          <th class="text-nowrap">Nome Pai</th>
          <th class="text-nowrap">Nome Mãe</th>
          <th class="text-nowrap">CPF Pai</th>
          <th class="text-nowrap">CPF Mãe</th>
          <th class="text-nowrap">CEP</th>
          <th class="text-nowrap">Logradouro</th>
          <th class="text-nowrap">Número</th>
          <th class="text-nowrap">Complemento</th>
          <th class="text-nowrap">Bairro</th>
          <th class="text-nowrap">Cidade</th>
          <th class="text-nowrap">Estado</th>
          <th class="text-nowrap">Tipo Documento</th>
          <th class="text-nowrap">Numero Documento</th>
          <th class="text-nowrap">Razão Social</th>
          <th class="text-nowrap">Simples</th>
          <th class="text-nowrap">Indicador de Inscrição Estadual</th>
          <th class="text-nowrap">Inscrição Estadual</th>
          <th class="text-nowrap">Inscrição Municipal</th>
          <th class="text-nowrap">SUFRAMA</th>
        </tr>
      </thead>
      
      <tbody *ngIf="registro != null">
        <tr *ngFor="let d of registro?.uploadDetails; let i = index" [ngClass]="d.erro ? 'table-danger' : ''">
        
          <td class="text-center">
            <a href="javascript:;" (click)="removerLinha(i)" tooltipPosition="bottom" pTooltip="Remover linha"><i class="fa fa-times" style="color: red;"></i></a>
          </td>

          <td class="text-nowrap text-center">
            {{i+1}}
          </td>
          
          <td class="text-nowrap" tooltipPosition="bottom" pTooltip="{{d.erroDetail}}"><input type="text" [ngClass]="d.erro ? 'table-danger' : ''" [ngClass]="d.erro ? 'table-danger' : ''" [(ngModel)]="d.data.nome">
            <span>{{d.data?.nome}}</span>
          </td>
          <td class="text-nowrap" tooltipPosition="bottom" pTooltip="{{d.erroDetail}}"><input type="text" [ngClass]="d.erro ? 'table-danger' : ''" [(ngModel)]="d.data.email">
            <span>{{d.data?.email}}</span>
          </td>
          <td class="text-nowrap" tooltipPosition="bottom" pTooltip="{{d.erroDetail}}"><input type="text" [ngClass]="d.erro ? 'table-danger' : ''" [(ngModel)]="d.data.telefone">
            <span>{{d.data?.telefone}}</span>
          </td>
          <td class="text-nowrap" tooltipPosition="bottom" pTooltip="{{d.erroDetail}}"><input type="text" [ngClass]="d.erro ? 'table-danger' : ''" [(ngModel)]="d.data.genero">
            <span>{{d.data?.genero}}</span>
          </td>
          <td class="text-nowrap" tooltipPosition="bottom" pTooltip="{{d.erroDetail}}"><input type="text" [ngClass]="d.erro ? 'table-danger' : ''" [(ngModel)]="d.data.observacao">
            <span>{{d.data?.observacao}}</span>
          </td>
          <td class="text-nowrap" tooltipPosition="bottom" pTooltip="{{d.erroDetail}}"><input type="text" [ngClass]="d.erro ? 'table-danger' : ''" [(ngModel)]="d.data.nascimento">
            <span>{{d.data?.nascimento}}</span>
          </td>
          <td class="text-nowrap" tooltipPosition="bottom" pTooltip="{{d.erroDetail}}"><input type="text" [ngClass]="d.erro ? 'table-danger' : ''" [(ngModel)]="d.data.profissao">
            <span>{{d.data?.profissao}}</span>
          </td>
          <td class="text-nowrap" tooltipPosition="bottom" pTooltip="{{d.erroDetail}}"><input type="text" [ngClass]="d.erro ? 'table-danger' : ''" [(ngModel)]="d.data.naturalidade">
            <span>{{d.data?.naturalidade}}</span>
          </td>
          <td class="text-nowrap" tooltipPosition="bottom" pTooltip="{{d.erroDetail}}"><input type="text" [ngClass]="d.erro ? 'table-danger' : ''" [(ngModel)]="d.data.nomePai">
            <span>{{d.data?.nomePai}}</span>
          </td>
          <td class="text-nowrap" tooltipPosition="bottom" pTooltip="{{d.erroDetail}}"><input type="text" [ngClass]="d.erro ? 'table-danger' : ''" [(ngModel)]="d.data.nomeMae">
            <span>{{d.data?.nomeMae}}</span>
          </td>
          <td class="text-nowrap" tooltipPosition="bottom" pTooltip="{{d.erroDetail}}"><input type="text" [ngClass]="d.erro ? 'table-danger' : ''" [(ngModel)]="d.data.cpfPai">
            <span>{{d.data?.cpfPai}}</span>
          </td>
          <td class="text-nowrap" tooltipPosition="bottom" pTooltip="{{d.erroDetail}}"><input type="text" [ngClass]="d.erro ? 'table-danger' : ''" [(ngModel)]="d.data.cpfMae">
            <span>{{d.data?.cpfMae}}</span>
          </td>
          <td class="text-nowrap" tooltipPosition="bottom" pTooltip="{{d.erroDetail}}"><input type="text" [ngClass]="d.erro ? 'table-danger' : ''" [(ngModel)]="d.data.endereco.cep">
            <span>{{d.data?.endereco?.cep}}</span>
          </td>
          <td class="text-nowrap" tooltipPosition="bottom" pTooltip="{{d.erroDetail}}"><input type="text" [ngClass]="d.erro ? 'table-danger' : ''" [(ngModel)]="d.data.endereco.logradouro">
            <span>{{d.data?.endereco?.logradouro}}</span>
          </td>
          <td class="text-nowrap" tooltipPosition="bottom" pTooltip="{{d.erroDetail}}"><input type="text" [ngClass]="d.erro ? 'table-danger' : ''" [(ngModel)]="d.data.endereco.numero">
            <span>{{d.data?.endereco?.numero}}</span>
          </td>
          <td class="text-nowrap" tooltipPosition="bottom" pTooltip="{{d.erroDetail}}"><input type="text" [ngClass]="d.erro ? 'table-danger' : ''" [(ngModel)]="d.data.endereco.complemento">
            <span>{{d.data?.endereco?.complemento}}</span>
          </td>
          <td class="text-nowrap" tooltipPosition="bottom" pTooltip="{{d.erroDetail}}"><input type="text" [ngClass]="d.erro ? 'table-danger' : ''" [(ngModel)]="d.data.endereco.bairro">
            <span>{{d.data?.endereco?.bairro}}</span>
          </td>
          <td class="text-nowrap" tooltipPosition="bottom" pTooltip="{{d.erroDetail}}"><input type="text" [ngClass]="d.erro ? 'table-danger' : ''" [(ngModel)]="d.data.endereco.cidade">
            <span>{{d.data?.endereco?.cidade}}</span>
          </td>
          <td class="text-nowrap" tooltipPosition="bottom" pTooltip="{{d.erroDetail}}"><input type="text" [ngClass]="d.erro ? 'table-danger' : ''" [(ngModel)]="d.data.endereco.estado">
            <span>{{d.data?.endereco?.estado}}</span>
          </td>
          <td class="text-nowrap" tooltipPosition="bottom" pTooltip="{{d.erroDetail}}"><input type="text" [ngClass]="d.erro ? 'table-danger' : ''" [(ngModel)]="d.data.documento.tipo">
            <span>{{d.data?.documento?.tipo}}</span>
          </td>
          <td class="text-nowrap" tooltipPosition="bottom" pTooltip="{{d.erroDetail}}"><input type="text" [ngClass]="d.erro ? 'table-danger' : ''" [(ngModel)]="d.data.documento.valor">
            <span>{{d.data?.documento?.valor}}</span>
          </td>
          <td class="text-nowrap" tooltipPosition="bottom" pTooltip="{{d.erroDetail}}"><input type="text" [ngClass]="d.erro ? 'table-danger' : ''" [(ngModel)]="d.data.documentoFiscal.nome">
            <span>{{d.data?.documentoFiscal?.nome}}</span>
          </td>
          <td class="text-nowrap" tooltipPosition="bottom" pTooltip="{{d.erroDetail}}"><input type="text" [ngClass]="d.erro ? 'table-danger' : ''" [(ngModel)]="d.data.documentoFiscal.simples">
            <span>{{d.data?.documentoFiscal?.simples}}</span>
          </td>
          <td class="text-nowrap" tooltipPosition="bottom" pTooltip="{{d.erroDetail}}"><input type="text" [ngClass]="d.erro ? 'table-danger' : ''" [(ngModel)]="d.data.documentoFiscal.ieIndicador">
            <span>{{d.data?.documentoFiscal?.ieIndicador}}</span>
          </td>
          <td class="text-nowrap" tooltipPosition="bottom" pTooltip="{{d.erroDetail}}"><input type="text" [ngClass]="d.erro ? 'table-danger' : ''" [(ngModel)]="d.data.documentoFiscal.ie">
            <span>{{d.data?.documentoFiscal?.ie}}</span>
          </td>
          <td class="text-nowrap" tooltipPosition="bottom" pTooltip="{{d.erroDetail}}"><input type="text" [ngClass]="d.erro ? 'table-danger' : ''" [(ngModel)]="d.data.documentoFiscal.im">
            <span>{{d.data?.documentoFiscal?.im}}</span>
          </td>
          <td class="text-nowrap" tooltipPosition="bottom" pTooltip="{{d.erroDetail}}"><input type="text" [ngClass]="d.erro ? 'table-danger' : ''" [(ngModel)]="d.data.documentoFiscal.suframa">
            <span>{{d.data?.documentoFiscal?.suframa}}</span>
          </td>
          
        </tr>
      </tbody>
      
      <tbody *ngIf="registro == null">        
        <tr>
          <td class="text-nowrap">João Silva</td>
          <td class="text-nowrap"><a target="_new">joao@example.com</a></td>
          <td class="text-nowrap">11987654321</td>
          <td class="text-nowrap">Masculino</td>
          <td class="text-nowrap">Observação 1</td>
          <td class="text-nowrap">15/03/1985</td>
          <td class="text-nowrap">Engenheiro Civil</td>
          <td class="text-nowrap">São Paulo</td>
          <td class="text-nowrap">José Silva</td>
          <td class="text-nowrap">Maria Silva</td>
          <td class="text-nowrap">123.456.78900</td>
          <td class="text-nowrap">987.654.32100</td>
          <td class="text-nowrap">12345678</td>
          <td class="text-nowrap">Av. Paulista</td>
          <td class="text-nowrap">123</td>
          <td class="text-nowrap">Sala 101</td>
          <td class="text-nowrap">Bela Vista</td>
          <td class="text-nowrap">São Paulo</td>
          <td class="text-nowrap">SP</td>
          <td class="text-nowrap">CPF</td>
          <td class="text-nowrap">34567890192</td>
          <td class="text-nowrap"></td>
          <td class="text-nowrap"></td>
          <td class="text-nowrap"></td>
          <td class="text-nowrap"></td>
          <td class="text-nowrap"></td>
          <td class="text-nowrap"></td>
        </tr>
        <tr>
          <td class="text-nowrap">Maria Oliveira</td>
          <td class="text-nowrap"><a target="_new">maria@example.com</a></td>
          <td class="text-nowrap">21987654321</td>
          <td class="text-nowrap">Feminino</td>
          <td class="text-nowrap">Observação 2</td>
          <td class="text-nowrap">20/07/1990</td>
          <td class="text-nowrap">Médica</td>
          <td class="text-nowrap">Rio de Janeiro</td>
          <td class="text-nowrap">José Oliveira</td>
          <td class="text-nowrap">Ana Oliveira</td>
          <td class="text-nowrap">987.654.32100</td>
          <td class="text-nowrap">123.456.78900</td>
          <td class="text-nowrap">54321987</td>
          <td class="text-nowrap">Rua Copacabana</td>
          <td class="text-nowrap">456</td>
          <td class="text-nowrap">Apt 202</td>
          <td class="text-nowrap">Copacabana</td>
          <td class="text-nowrap">Rio de Janeiro</td>
          <td class="text-nowrap">RJ</td>
          <td class="text-nowrap">CPF</td>
          <td class="text-nowrap">34567890192</td>
          <td class="text-nowrap"></td>
          <td class="text-nowrap"></td>
          <td class="text-nowrap"></td>
          <td class="text-nowrap"></td>
          <td class="text-nowrap"></td>
          <td class="text-nowrap"></td>
        </tr>
        <tr>
          <td class="text-nowrap">Carlos Santos</td>
          <td class="text-nowrap"><a target="_new">carlos@example.com</a></td>
          <td class="text-nowrap">31987654321</td>
          <td class="text-nowrap">Masculino</td>
          <td class="text-nowrap">Observação 3</td>
          <td class="text-nowrap">10/12/1980</td>
          <td class="text-nowrap">Advogado</td>
          <td class="text-nowrap">Minas Gerais</td>
          <td class="text-nowrap">José Santos</td>
          <td class="text-nowrap">Ana Santos</td>
          <td class="text-nowrap">456.789.12300</td>
          <td class="text-nowrap">654.321.98700</td>
          <td class="text-nowrap">67890123</td>
          <td class="text-nowrap">Rua da Paz</td>
          <td class="text-nowrap">789</td>
          <td class="text-nowrap"></td>
          <td class="text-nowrap">Savassi</td>
          <td class="text-nowrap">Belo Horizonte</td>
          <td class="text-nowrap">MG</td>
          <td class="text-nowrap">CPF</td>
          <td class="text-nowrap">34567890192</td>
          <td class="text-nowrap"></td>
          <td class="text-nowrap"></td>
          <td class="text-nowrap"></td>
          <td class="text-nowrap"></td>
          <td class="text-nowrap"></td>
          <td class="text-nowrap"></td>
        </tr>
        <tr>
          <td class="text-nowrap">Empresa A</td>
          <td class="text-nowrap"><a target="_new">empresaA@example.com</a></td>
          <td class="text-nowrap">11987654321</td>
          <td class="text-nowrap"></td>
          <td class="text-nowrap">Observação 1</td>
          <td class="text-nowrap">15/03/1985</td>
          <td class="text-nowrap"></td>
          <td class="text-nowrap"></td>
          <td class="text-nowrap"></td>
          <td class="text-nowrap"></td>
          <td class="text-nowrap"></td>
          <td class="text-nowrap"></td>
          <td class="text-nowrap">12345678</td>
          <td class="text-nowrap">Rua Comercial</td>
          <td class="text-nowrap">123</td>
          <td class="text-nowrap">Loja 101</td>
          <td class="text-nowrap">Centro</td>
          <td class="text-nowrap">São Paulo</td>
          <td class="text-nowrap">SP</td>
          <td class="text-nowrap">CNPJ</td>
          <td class="text-nowrap">1234567800019</td>
          <td class="text-nowrap">Comércio Silva</td>
          <td class="text-nowrap">Sim</td>
          <td class="text-nowrap">Indicador 1</td>
          <td class="text-nowrap">12345</td>
          <td class="text-nowrap">54321</td>
          <td class="text-nowrap">12345678901</td>
        </tr>
        <tr>
          <td class="text-nowrap">Empresa B</td>
          <td class="text-nowrap"><a target="_new">empresaB@example.com</a></td>
          <td class="text-nowrap">21987654321</td>
          <td class="text-nowrap"></td>
          <td class="text-nowrap">Observação 2</td>
          <td class="text-nowrap">20/07/1990</td>
          <td class="text-nowrap"></td>
          <td class="text-nowrap"></td>
          <td class="text-nowrap"></td>
          <td class="text-nowrap"></td>
          <td class="text-nowrap"></td>
          <td class="text-nowrap"></td>
          <td class="text-nowrap">54321987</td>
          <td class="text-nowrap">Av. Prestadora</td>
          <td class="text-nowrap">456</td>
          <td class="text-nowrap">Sala 202</td>
          <td class="text-nowrap">Copacabana</td>
          <td class="text-nowrap">Rio de Janeiro</td>
          <td class="text-nowrap">RJ</td>
          <td class="text-nowrap">CNPJ</td>
          <td class="text-nowrap">9876543200011</td>
          <td class="text-nowrap">Prestadora Ltda</td>
          <td class="text-nowrap">Sim</td>
          <td class="text-nowrap">Indicador 2</td>
          <td class="text-nowrap">54321</td>
          <td class="text-nowrap">12345</td>
          <td class="text-nowrap">98765432109</td>
        </tr>
        <tr>
          <td class="text-nowrap">Empresa C</td>
          <td class="text-nowrap"><a target="_new">empresaC@example.com</a></td>
          <td class="text-nowrap">31987654321</td>
          <td class="text-nowrap"></td>
          <td class="text-nowrap">Observação 3</td>
          <td class="text-nowrap">10/12/1980</td>
          <td class="text-nowrap"></td>
          <td class="text-nowrap"></td>
          <td class="text-nowrap"></td>
          <td class="text-nowrap"></td>
          <td class="text-nowrap"></td>
          <td class="text-nowrap"></td>
          <td class="text-nowrap">67890123</td>
          <td class="text-nowrap">Rua Inovadora</td>
          <td class="text-nowrap">789</td>
          <td class="text-nowrap"></td>
          <td class="text-nowrap">Savassi</td>
          <td class="text-nowrap">Belo Horizonte</td>
          <td class="text-nowrap">MG</td>
          <td class="text-nowrap">CNPJ</td>
          <td class="text-nowrap">2345678900018</td>
          <td class="text-nowrap">Inovação S/A</td>
          <td class="text-nowrap">Sim</td>
          <td class="text-nowrap">Indicador 3</td>
          <td class="text-nowrap">67890</td>
          <td class="text-nowrap">54321</td>
          <td class="text-nowrap">45678912345</td>
        </tr>   
      </tbody>
    </table>

  </div>

</div>
</div>

<div class="bs-dialog-footer">
  <div class="bs-footer-action-close">
    <button (click)="close()" class="btn btn-white" pTooltip="Esc" tooltipPosition="bottom">Fechar</button>
  </div>
</div>