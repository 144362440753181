import { Filter } from "./filter";

export class Filters {

  public orderField: string;
  public orderDirection: string;
  public offset: number;
  public pageSize: number;
  public status: string;
  public period: string;
  public filters: Filter[];

  constructor(
  ) {  
    this.filters = [new Filter()];
  }

  
}
