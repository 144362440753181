<div id="content" class="content content-full-width">

    <h1 class="page-header">Gerenciamento de Preços</h1>

    <div class="vertical-box with-grid inbox bg-light">
        <div class="vertical-box-column width-200">
            <div class="vertical-box">

                <div class="wrapper d-none d-sm-none d-md-none d-lg-block">
                    <div class="d-flex align-items-center justify-content-center">
                        <button (click)="openDialog(null)" class="btn btn-primary p-l-40 p-r-40 btn-sm" pTooltip="Adicionar novo registro" tooltipPosition="bottom">
                            Adicionar <i class="pl-1 fa fa-plus-circle"></i>
                        </button>
                    </div>
                </div>

                <div class="vertical-box-row d-lg-table-row collapse">
                    <div class="vertical-box-cell">
                        <div class="vertical-box-inner-cell">
                            <div class="keras-scrollbar">
                                <div class="wrapper p-0">
                                    <div class="nav-title"><b>STATUS</b></div>
                                    <ul class="nav nav-inbox">
                                        <li [ngClass]="filters.status == null ? 'active' : ''" (click)="filterByStatus(null)"><a href="javascript:;">Todos</a></li>
                                        <li [ngClass]="filters.status == 'active'? 'active' : ''" (click)="filterByStatus('active')"><a href="javascript:;" >Aberto</a></li>
                                        <li [ngClass]="filters.status == 'blocked' ? 'active' : ''" (click)="filterByStatus('blocked')"><a href="javascript:;">Rascunho</a></li>
                                        <li [ngClass]="filters.status == 'blocked' ? 'active' : ''" (click)="filterByStatus('blocked')"><a href="javascript:;">Pendente</a></li>
                                        <li [ngClass]="filters.status == 'blocked' ? 'active' : ''" (click)="filterByStatus('blocked')"><a href="javascript:;">Aguardando</a></li>
                                        <li [ngClass]="filters.status == 'blocked' ? 'active' : ''" (click)="filterByStatus('blocked')"><a href="javascript:;">Aprovado</a></li>
                                        <li [ngClass]="filters.status == 'blocked' ? 'active' : ''" (click)="filterByStatus('blocked')"><a href="javascript:;">Reprovado</a></li>
                                        <li [ngClass]="filters.status == 'blocked' ? 'active' : ''" (click)="filterByStatus('blocked')"><a href="javascript:;">Concluído</a></li>
                                    </ul>

                                    <div class="nav-title"><b>PERÍODO</b></div>
                                    <ul class="nav nav-inbox">
                                        <li [ngClass]="filters.period == null ? 'active' : ''" (click)="filterByPeriod(null)"><a href="javascript:;">Todos</a></li>
                                        <li [ngClass]="filters.period == '0' ? 'active' : ''" (click)="filterByPeriod('0')"><a href="javascript:;">Hoje</a></li>
                                        <li [ngClass]="filters.period == '7' ? 'active' : ''" (click)="filterByPeriod('7')"><a href="javascript:;">Últimos 7 dias</a></li>
                                        <li [ngClass]="filters.period == '30' ? 'active' : ''" (click)="filterByPeriod('30')"><a href="javascript:;">Últimos 30 dias</a></li>
                                        <li [ngClass]="filters.period == '90' ? 'active' : ''" (click)="filterByPeriod('90')"><a href="javascript:;">Últimos 90 dias</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
       
        <div class="vertical-box-column">
            <div class="vertical-box">
                
                
                <div class="wrapper">
                    <div class="input-group">
                        <button class="btn btn-sm btn-white mr-2 d-none d-sm-none d-md-none d-lg-block" (click)="find()" pTooltip="Atualizar" tooltipPosition="bottom">
                            <i class="pi pi-undo"></i>
                        </button>
                        
                        <div class="input-group-prepend mr-2" *ngIf="selectedDatas.length > 0">
                            <button class="btn btn-sm btn-white" (click)="unlockAll()" pTooltip="Desbloquear em massa" tooltipPosition="bottom">
                                <i class="pi pi-lock"></i>
                            </button>
                            <button class="btn btn-sm btn-white" (click)="lockAll()" pTooltip="Bloquear em massa" tooltipPosition="bottom">
                                <i class="pi pi-unlock"></i>
                            </button>
                            <button class="btn btn-sm btn-white" (click)="deleteAll()" pTooltip="Excluir em massa" tooltipPosition="bottom">
                                <i class="pi pi-trash"></i>
                            </button>
                        </div>
                        
                        <div class="input-group-prepend" (click)="menu.toggle($event)">
                            <button class="btn btn-white btn-sm">{{filters.filters[0].attAlias}}</button>
                            <button class="btn btn-white btn-sm"><span class="caret"></span></button>
                        </div>
                        <p-menu #menu [popup]="true" [model]="filterItens"></p-menu>

                        <input type="text" [(ngModel)]="filters.filters[0].value" name="name"
                            (keyup.enter)="find()" placeholder="{{'search.placeholder' | translate}}" class="form-control m-r-3" style="height: 29px;">

                        <div class="btn-group ml-auto">
                            <button class="btn btn-sm btn-white d-none d-sm-none d-md-none d-lg-block" [disabled]="downloading" (click)="download()" tooltipPosition="bottom" pTooltip="Exportar dados para planilha">
                                <i class="pi pi-download" *ngIf="!downloading"></i>
                                <i class="pi pi-spin pi-spinner" *ngIf="downloading"></i>
                            </button>
                        </div>

                        <div class="btn-group ml-auto">
                            <label style="margin: 0px 10px 0px; padding-top: 6px;" *ngIf="!showEmpty && !loading && !sloading">
                                {{data?.paginator?.intervalBegin}}-{{data?.paginator?.intervalEnd}} de {{data?.paginator?.totalResult}}
                            </label>
                        </div>
                        <div class="btn-group ml-0">
                            <button (click)="paging(data?.paginator?.currentPage - 1)" tooltipPosition="bottom" pTooltip="Anteriores" [disabled]="data?.paginator?.currentPage == 1" class="btn btn-white btn-sm">
                                <i class="pi pi-chevron-left"></i>
                            </button>
                            <button (click)="paging(data?.paginator?.currentPage + 1)" tooltipPosition="bottom" pTooltip="Próximas" [disabled]="data?.paginator?.currentPage == data?.paginator?.totalPages" class="btn btn-white btn-sm">
                                <i class="pi pi-chevron-right"></i>
                            </button>
                        </div>
                    </div>
                </div>


                <div class="vertical-box-row">
                    <div class="vertical-box-cell">
                        <div class="vertical-box-inner-cell bg-white">
                            <div class="keras-scrollbar">

                                <div class="p-40 fa-3x" style="position: absolute; left: 44%;" *ngIf="loading || sloading">
                                    <i class="fas fa-spinner fa-pulse"></i>
                                </div>

                                <div class="table-responsive" *ngIf="data?.paginator?.totalResult > 0">
                                    <table class="table m-b-0 result-table">
                                        <tbody>
                                            <tr>
                                                <th class="be-result-th-action" colspan="2">
                                                    <div ngbDropdown container="body">
                                                        <p-checkbox [(ngModel)]="ckbselgroup" binary="true" (onChange)="selectUnselectItems($event)" class="be-result-table-checkbox-box"></p-checkbox>
                                                        <button class="btn btn-sm" ngbDropdownToggle><span class="caret"></span></button>
                                                        <div ngbDropdownMenu>
                                                            <button (click)="selectItems('todos')" ngbDropdownItem>Todos</button>
                                                            <button (click)="selectItems('nenhum')" ngbDropdownItem>Nenhum</button>
                                                            <button (click)="selectItems('ativos')" ngbDropdownItem>Ativos</button>
                                                            <button (click)="selectItems('bloqueados')" ngbDropdownItem>Bloqueado</button>
                                                        </div>
                                                    </div>
                                                </th>
                                                <th (click)="find('nome')" style="width: 36%;">Anúncio</th>
                                                <th (click)="find('categorias.descricao')" class="text-center" style="width: 9%;">Qte</th>
                                                <th (click)="find('unidadeMedida.code')" class="text-right" style="width: 14%;">Preço atual</th>
                                                <th (click)="find('valorVenda')" class="text-center" style="width: 14%;">Limite</th>
                                                <th (click)="find('status.code')" class="text-center" style="width: 17%;">Modo</th>
                                                <th (click)="find('status.code')" class="text-center" style="width: 10%;">Status</th>
                                            </tr>

                                            <tr *ngFor="let d of data?.result">
                                                <td class="be-result-td-checkbox">
                                                    <p-checkbox name="ckbgroup" [value]="d" [(ngModel)]="selectedDatas" (onChange)="validateSelectItems($event)" class="be-result-table-checkbox-box"></p-checkbox>
                                                </td>
                                                <td class="be-result-td-menu">
                                                    <div ngbDropdown container="body">
                                                        <button pButton type="button" icon="pi pi-bars" class="p-button-sm p-button-rounded p-button-text p-button-plain be-result-table-menu" ngbDropdownToggle></button>
                                                        <div ngbDropdownMenu>
                                                            <button (click)="openDialog(d)"    ngbDropdownItem><i class="fa fa-edit"></i>Editar registro</button>
                                                            <button (click)="openDialogCRM(d)" ngbDropdownItem *ngIf="d?.status?.code != 'blocked'"><i class="fa fa-user-plus"></i>Inserir ação CRM</button>
                                                            <button (click)="openDialogCRM(d)" ngbDropdownItem *ngIf="d?.status?.code != 'blocked'"><i class="fa fa-user"></i>Ver ações CRM</button>
                                                            <button (click)="openDialogCRM(d)" ngbDropdownItem *ngIf="d?.status?.code != 'blocked'"><i class="fa fa-cart-plus"></i>Inciar venda</button>
                                                            <button (click)="openDialog(d)"    ngbDropdownItem><i class="fa fa-history"></i>Visualizar histórico</button>
                                                            
                                                            <div class="dropdown-divider"></div>
                                                            
                                                            <button (click)="lockUnlock(d)" ngbDropdownItem *ngIf="d?.status?.code != 'blocked'"><i class="fa fa-ban"></i> Bloquear</button>
                                                            <button (click)="lockUnlock(d)" ngbDropdownItem *ngIf="d?.status?.code == 'blocked'"><i class="fa fa-ban"></i> Desbloquear</button>
                                                            <button (click)="delete(d)"     ngbDropdownItem><i class="fa fa-trash"></i> Remover registro</button>
                                                        </div>
                                                    </div>
                                                </td>
                                                
                                                <td class="p-0" style="vertical-align: top;">

                                                    <table class="table m-b-5">
                                                        <tr>
                                                            <td class="p-2 p-l-5 border-top-0">
                                                                <a href="javascript:;" (click)="openDialogAdd(d);"
                                                                    style="border-bottom: dashed 1px #428bca;color: #428bca;">{{d.id}} - {{d.title}}</a>
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td class="p-2 p-l-5 border-top-0">
                                                                <span class="ellipsis"><strong>UNIPHORMES</strong> - 10
                                                                    Máscara Em Tecido
                                                                    Proteção Respiratória Lavável </span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td class="p-2 p-l-5 border-top-0">
                                                                <span class="ellipsis">UNIPHORMES - 10 Máscara Em Tecido
                                                                    Proteção Respiratória Lavável </span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td class="p-2 border-top-0 text-center">
                                                                <button type="button" (click)="openDialog(d);"
                                                                    class="btn btn-wide btn-default btn-o btn-xs"
                                                                    style="border: 1px dotted;width: 96%;">
                                                                    + Adicionar
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    </table>

                                                </td>
                                                <td class="p-0" style="vertical-align: top;">

                                                    <table class="table m-b-5 text-center">
                                                        <tr>
                                                            <td class="p-2 border-top-0">
                                                                <a href="javascript:;" (click)="openDialogAdd(d);"
                                                                    style="border-bottom: dashed 1px #428bca;color: #428bca;">N/I</a>
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td class="p-2 border-top-0">
                                                                <span class="ellipsis">5692</span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td class="p-2 border-top-0">
                                                                <span class="ellipsis">5692</span>
                                                            </td>
                                                        </tr>

                                                    </table>

                                                </td>
                                                <td class="p-0"
                                                    style="vertical-align: top; border-right: 1px dotted #999; padding-right: 5px !important;">

                                                    <table class="table m-b-5 text-end">
                                                        <tr>
                                                            <td class="p-2 border-top-0">
                                                                <a href="javascript:;" (click)="openDialogAdd(d);"
                                                                    style="border-bottom: dashed 1px #428bca;color: #428bca;">R$
                                                                    28,00</a>
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td class="p-2 border-top-0">
                                                                <span class="ellipsis">R$ 28,00</span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td class="p-2 border-top-0">
                                                                <span class="ellipsis">R$ 28,00</span>
                                                            </td>
                                                        </tr>

                                                    </table>

                                                </td>
                                                <td class="p-0" style="vertical-align: top;">
                                                    <table class="table m-b-5">
                                                        <tr>
                                                            <td class="border-top-0 p-r-5 p-b-0 p-t-2">

                                                                <label class="m-0">
                                                                    Mínimo:
                                                                </label>
                                                                <input type="number" class="form-control text-end"
                                                                    ng-model="value.min" placeholder="R$ 0,00"
                                                                    style="font-size: 12px; padding: 0 4px; height: 23px;">

                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td class="border-top-0 p-r-5 p-b-0 p-t-2">

                                                                <label class="m-0">
                                                                    Máximo:
                                                                </label>
                                                                <input type="number" class="form-control text-end"
                                                                    ng-model="value.max" placeholder="R$ 0,00"
                                                                    style="font-size: 12px; padding: 0 4px; height: 23px;">

                                                            </td>
                                                        </tr>
                                                    </table>
                                                </td>
                                                <td class="p-r-0 p-b-0 p-t-0 p-l-10" style="vertical-align: top;">
                                                    <table class="table m-b-5">
                                                        <tr>
                                                            <td class="border-top-0 p-r-5 p-b-0 p-t-2">
                                                                <a href="javascript:;" (click)="openDialogConfig(d);"
                                                                    style="border-bottom: dashed 1px #428bca;color: #428bca;">
                                                                    Mesmo valor que o concorrente mais caro.
                                                                </a>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td class="border-top-0 p-0 p-t-10 text-center" style="vertical-align: bottom;">
                                                                <a href="javascript:;" class="btn btn-default btn-icon btn-circle"><i class="fa fa-save" style="color: #444;"></i></a>
                                                                <a href="javascript:;" class="btn btn-default btn-icon btn-circle m-l-10"><i class="fa fa-chart-bar" style="color: #444;"></i></a>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </td>
                                                <td (click)="openDialog(d)" class="text-center" *ngIf="d?.status?.code != 'blocked'"><span class="label label-primary s-90">{{d?.status?.value}}</span></td>
                                                <td (click)="openDialog(d)" class="text-center" *ngIf="d?.status?.code == 'blocked'"><span class="label label-danger s-90">{{d?.status?.value}}</span></td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>

                                <div class="row m-t-40" *ngIf="showEmpty">
                                    <div class="col-md-3"></div>
                                    <div class="col-md-6">

                                        <div class="note note-info cursor-pointer" (click)="openDialog(null)">
                                            <div class="note-icon"><i class="fa fa-lightbulb"></i></div>
                                            <div class="note-content">
                                                <h4 class="p-t-10"><b>Você não possui nenhum item cadastrado.</b></h4>
                                                <p>Para inserir novos registros você pode clicar aqui ou utilizar o botão acima.</p>
                                            </div>
                                        </div>

                                    </div>
                                    <div class="col-md-3"></div>
                                </div>

                                <div class="row m-t-40" *ngIf="!showEmpty && data?.paginator?.totalResult == 0">
                                    <div class="col-md-3"></div>
                                    <div class="col-md-6">

                                        <div class="note note-info cursor-pointer" (click)="cleanFilter()">
                                            <div class="note-icon"><i class="fa fa-lightbulb"></i></div>
                                            <div class="note-content">
                                                <h4 class="p-t-10"><b>Sua pesquisa não retornou resultados.</b></h4>
                                                <p>Tente outras opções de pesquisa, tipos de campos ou remova os filtros clicando aqui.</p>
                                            </div>
                                        </div>

                                    </div>
                                    <div class="col-md-3"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>