import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { OnBoarding } from '../../_entity/suporte/onBoarding';
import { AuthenticationService } from '../../_services'
import { environment } from '@environments/environment';
import { LayoutPrivateComponent } from '../../suporte/layout-private/layout-private.component';
import { BaseService } from '../../_services/base.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { Organizacao } from 'src/app/_entity/cadastro/organizacao';
import { Documento } from 'src/app/_entity/suporte/documento';
import { Endereco } from 'src/app/_entity/suporte/endereco';
import { DocumentoFiscal } from 'src/app/_entity/suporte/documentoFiscal';
import { DialogService } from 'primeng-lts/dynamicdialog';

@Component({
  selector: 'app-configuracao',
  templateUrl: './configuracao.component.html',
  styleUrls: ['./configuracao.component.css'],
  providers: [DialogService]
})
export class ConfiguracaoComponent extends BaseService implements OnInit {

  constructor(public app: LayoutPrivateComponent,
    public http: HttpClient,
    public dialog: DialogService,
    public router: Router,
    public toastr: ToastrService,
    public translate: TranslateService,
    public authenticationService: AuthenticationService) {

    super('onboarding', app, http, dialog, toastr, translate);

    this.recording = false;
  }

  public recording: boolean;
  public loadingAdd = false;
  public submitted = false;
  public selplano: string;
  public planos: any[] = [];

  ngOnInit() {
    this.createData();
    this.data.user = this.authenticationService.currentUserValue;
    this.data.organizacao.nome = this.data.user.organizacao;

    this.http.get<any>(`${environment.apiUrl}/support/planos`).subscribe(
      data => {
          this.planos = data;
      },
      error => {
          this.toastr.error('Não foi possível carregar informações dos planos');
      }
  );
  }

  public initialize(baseDoc: any): void {
    try {
      this.beforeSave(baseDoc);

      this.http.post<any>(`${environment.apiUrl}/${this.mapping}/onboarding`, baseDoc).subscribe(
          data => {
              this.afterSave(data);
              this.authenticationService.register(data.user);

              this.router.navigate(["/dashboard"]);
          },
          error => {
              this.sloading = false;
              this.recording = false;
          }
      );
  }
  catch (e) {
      this.recording = false;
      this.toastr.error('', e.message);
  }
  }

  public beforeSave(baseDoc: any): void {
      this.recording = true;
  }

  public afterSave(baseDoc: any): void {
      this.recording = false;
  }

  public createData(){
    this.data = new OnBoarding;
    this.data.organizacao = new Organizacao;
    this.data.organizacao.documento = new Documento;
    this.data.organizacao.documento.tipo = 'cnpj';
    this.data.organizacao.endereco = new Endereco;    
    this.data.organizacao.documentoFiscal = new DocumentoFiscal;    
  }

  public plano(id: string, nome: string, anual: boolean): void {
    this.selplano = nome;
    this.data.organizacao.plano = id;
    this.data.organizacao.planoAnual = anual;

    this.scrollToElement('dvPgto');
  }

  public pgtoForma(data: string): void {
    this.data.organizacao.formaCobranca = data;
  }

  public scrollToElement(id: string): void {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }

  public carregarEndereco() {    
      try {
        this.loadingAdd = true;
        this.http.get<any>(`${environment.apiUrl}/support/address/${this.data.organizacao.endereco.cep}`).subscribe(
            address => {
                this.data.organizacao.endereco.logradouro = address.logradouro;
                this.data.organizacao.endereco.bairro = address.bairro;
                this.data.organizacao.endereco.estado = address.estado;
                this.data.organizacao.endereco.cidade = address.cidade;

                this.loadingAdd = false;
            },
            error => {
              this.loadingAdd = false;
            }
        );
    }
    catch (e) {
      this.loadingAdd = false;
    }    
  }
}
