<div class="bs-dialog-header">
    <i class="fa fa-comments"></i> PERGUNTAS <small> Responder Pergunta </small>
  </div>
  
  <div class="bs-dialog-main">
    <div class="bs-dialog-content">
  
      <div class="row p-r-10">

        <div class="col-md-8 col-sm-12 m-b-20">
            
            <p-panel header="Pergunta">
    
              <div class="row align-items-center">
                <div class="col-lg-12 d-flex align-items-center">
                  <div class="h-65px w-65px d-flex align-items-center justify-content-center position-relative">
                    <img [src]="data?.produtos[0]?.pictures[0]?.secure_url" class="mw-100 mh-100" />
                  </div>

                  <div class="ps-3 flex-1">
                    <div><a href="{{data?.produtos[0]?.permalink}}" target="_blank" class="text-decoration-none text-dark f-s-14 f-w-500">{{data?.produtos[0]?.title}}</a></div>
                    <div class="text-dark text-opacity-50 small">
                      <div class="row m-0" style="color: #777;">
                        {{data?.produtos[0]?.id}}
                      </div>
                    </div>
                    <hr style="margin: 6px 0 8px;">
                    <div class="text-dark text-opacity-50 small">
                      <div class="row m-0" style="color: #777;">
                        <span>{{data?.produtos[0]?.price | currency:'BRL':'symbol':'1.2-2'}}</span>
                        <i class="fas fa-lg fa-fw m-l-20 m-t-2 m-r-5 fa-bullhorn"></i> 
                        <span *ngIf="data?.produtos[0]?.listing_type_id == 'gold_pro'">Premiun</span>
                        <span *ngIf="data?.produtos[0]?.listing_type_id == 'gold_premium'">Diamante</span>
                        <span *ngIf="data?.produtos[0]?.listing_type_id == 'gold_special'">Clássico</span>
                        <span *ngIf="data?.produtos[0]?.listing_type_id == 'gold'">Ouro</span>
                        <span *ngIf="data?.produtos[0]?.listing_type_id == 'silver'">Prata</span>
                        <span *ngIf="data?.produtos[0]?.listing_type_id == 'bronze'">Bronze</span>
                        <span *ngIf="data?.produtos[0]?.listing_type_id == 'free'">Grátis</span>
                        
                        <i class="fas fa-lg fa-fw m-l-20 m-t-2 m-r-5 fa-truck"></i> 
                        <span *ngIf="data?.produtos[0]?.shipping?.free_shipping">Grátis</span>
                        <span *ngIf="!data?.produtos[0]?.shipping?.free_shipping">Normal</span>
                        <i class="fas fa-lg fa-fw m-l-20 m-t-2 m-r-5 fa-shopping-cart"></i> 
                        <span>{{data?.produtos[0]?.sold_quantity}}</span>
                        <i class="fas fa-lg fa-fw m-l-20 m-t-2 m-r-5 fa-cubes"></i>
                        <span>{{data?.produtos[0]?.available_quantity}}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
    
              <hr class="mb-4">
    
              <div class="flex-1">
                <div class="text-dark text-opacity-50 small">
                  <div class="row m-0" style="color: #777;">
                    <a href="{{user?.permalink}}" target="_blank">{{user?.nickname}}</a>
                    <span class="p-l-20"> {{data?.date_created | date:  'dd/MM/yyyy HH:mm'}}</span>
                  </div>
                </div>
              </div>
    
              <div class="alert alert-info" style="font-size: 13px;">
                {{data?.text}}
              </div>
    
            <div class="p-inputgroup m-b-20">
                <input type="text" pInputText placeholder="Saudação">   
                <button type="button" pButton icon="pi pi-refresh" styleClass="p-button-warn"></button>      
            </div>
    
              <div class="input-group" *ngIf="data.answer">
                <textarea [(ngModel)]="data.answer.text" rows="4" class="form-control" placeholder="Entre com a resposta aqui..."></textarea>
              </div>
              <a href="#" class="ms-auto text-decoration-none m-b-10 text-gray-600" style="float: right; font-size: 9px;">GERAR NOVA RESPOSTA</a>
    
              <div class="p-inputgroup m-b-30">
                <input type="text" pInputText placeholder="Keyword">   
                <button type="button" pButton icon="pi pi-refresh" styleClass="p-button-warn"></button>      
            </div>
          
        </p-panel>
    
        </div>
    
    
    
        <div class="col-md-4 col-sm-12">
            <div class="row m-b-20 panel-header-with-icon">
                <p-panel header="Anotações" class="w-100">
                  <ng-template pTemplate="icons">
                    <button *ngIf="editarAnotacao" class="p-panel-header-icon p-link" pTooltip="Salvar" tooltipPosition="bottom">
                        <span class="pi pi-save"></span>
                    </button>
                    <button class="p-panel-header-icon p-link" (click)="habilitarAnotacao(true)" pTooltip="Editar" tooltipPosition="bottom">
                        <span class="pi pi-user-edit"></span>
                    </button>
                  </ng-template>

                  <div class="keras-scrollbar" style="height: 150px;">
                    <div class="keras-scrollbar" *ngIf="anotacao && !editarAnotacao" style="height: 150px;">
                      {{anotacao.text}}
                    </div>
                    <div class="keras-scrollbar" *ngIf="!anotacao && !editarAnotacao" style="height: 150px;">
                      Nenhuma anotação para este produto.
                    </div>
                    <div class="keras-scrollbar" *ngIf="editarAnotacao">
                      <textarea (blur)="salvarAnotacao($event)" [(ngModel)]="anotacao.text" blur rows="7" class="form-control"></textarea>
                    </div>
                  </div>
                </p-panel>
            </div>
            
            <div class="row m-b-20">
                <p-panel header="Perguntas Anteriores" class="w-100 panel-chat">
                    <div class="keras-scrollbar" style="height: 279px;">
                        <div class="chats" *ngIf="!perguntasAnteriores || perguntasAnteriores.length == 1">
                          Nenhuma pergunta encontrada.
                        </div>
                        <div class="chats" *ngIf="perguntasAnteriores && perguntasAnteriores.length > 1">
                          <div *ngFor="let d of perguntasAnteriores">
                            <div class="left">
                              <span class="name">{{d.date_created | date:'dd/MM/yyyy HH:mm'}}</span>
                              <div class="message">
                                  {{d.text}}
                              </div>
                            </div>
                            <div class="right" *ngIf="d.answer">
                              <span class="name">{{d?.answer?.date_created | date:'dd/MM/yyyy HH:mm'}}</span>
                              <div class="message">
                                {{d?.answer?.text}}
                              </div>
                            </div>
                          </div>
                        </div>
                    </div>
                </p-panel>
            </div>
            
            <!-- <div class="row m-b-20">
                <p-panel header="Histórico do Cliente">
                    <div class="keras-scrollbar" style="height: 157px;">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque ac posuere
                        lacus, quis suscipit sem. Nulla sagittis aliquam erat non convallis.
                    </div>
                </p-panel>
            </div> -->
    
        </div>
      </div>

      
    </div>
  </div>
  
  <div class="bs-dialog-footer">
    <div class="bs-footer-action-close">
      <button (click)="close()" class="btn btn-white" pTooltip="Esc" tooltipPosition="bottom">Fechar</button>
    </div>
    <div class="bs-footer-action">
      <button *ngIf="!data.answer?.date_created" (click)="responder()" [disabled]="recording" class="btn btn-primary" pTooltip="Ctrl + S" tooltipPosition="bottom">Responder</button>
    </div>
  </div>