<div class="bs-dialog-header">
  <i class="fa fa-calendar-alt"></i> AGENDA <small> Gestão de Eventos </small>
</div>

<div class="bs-dialog-main">
  <div class="bs-dialog-content">

    <!-- Carregando informações... -->
    <div class="modal-overlay" *ngIf="loading">
      <div class="alert alert-light fade show m-b-0 text-center" style="color: #222;">
        <i class="fas fa-circle-notch fa-spin"></i> 
      </div>
    </div>

    <div class="be-form">

      
      <div class="row">
        <div class="col-12">
          <label>Nome do Evento:</label>
          <input type="text" pInputText [(ngModel)]="data.title" class="form-control">
        </div>
      </div>
      <div class="row">
        <div class="col-5">
          <label>Começa:</label>
        </div>
        <div class="col-7">
          <label>Termina:</label>
        </div>
      </div>
      <div class="row m-t-0">
        <div class="col-3 p-r-2">
          <!-- <p-calendar [(ngModel)]="data.start" [showTime]="true" [locale]="pt_BR" showButtonBar="true" appendTo="body" dateFormat="dd/mm/yy"></p-calendar> -->
          <!-- <p-inputMask mask="99/99/9999 99:99" [(ngModel)]="data.start" placeholder="99/99/9999" slotChar="dd/mm/yyyy hh:mm"></p-inputMask> -->
          <input [(ngModel)]="data.strstart" (blur)="validateEndDate()" type="date" pattern="\d{4}-\d{2}-\d{2}" class="form-control">
        </div>
        <div class="col-2 p-l-0">
          <p-inputMask mask="99:99" [(ngModel)]="data.hrstart" (onBlur)="validateEndDate()" placeholder="00:00"></p-inputMask>
        </div>
        <div class="col-3 p-r-2">
          <!-- <p-calendar [(ngModel)]="data.end" [showTime]="true" [locale]="pt_BR" showButtonBar="true" appendTo="body" dateFormat="dd/mm/yy"></p-calendar> -->
          <input [(ngModel)]="data.strend" (blur)="validateEndDate()" type="date" pattern="\d{4}-\d{2}-\d{2}" class="form-control">
        </div>
        <div class="col-2 p-l-0">
          <p-inputMask mask="99:99" [(ngModel)]="data.hrend" (onBlur)="validateEndDate()" placeholder="00:00"></p-inputMask>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <label>Observações:</label>
          <textarea [(ngModel)]="data.descricao" rows="3" class="form-control" placeholder="Observações"></textarea>
        </div>
      </div>


    </div>    
  </div>
</div>

<div class="bs-dialog-footer">
  <div class="bs-footer-action-close">
    <button (click)="close()" class="btn btn-white" pTooltip="Esc" tooltipPosition="bottom">Fechar</button>
  </div>
  <div class="bs-footer-action">
    <button (click)="delete()" [disabled]="recording" class="btn btn-link" style="color: red;" *ngIf="!datas.newEvent && data?.origem == 'private'">Remover Evento</button>
    <!-- <button (click)="save()" [disabled]="recording" class="btn btn-white" pTooltip="Ctrl + Enter" tooltipPosition="bottom" *ngIf="datas.newEvent && data?.origem == 'private'">Salvar e criar outro</button> -->
    <button (click)="saveClose()" [disabled]="recording" class="btn btn-primary" pTooltip="Ctrl + S" tooltipPosition="bottom" *ngIf="datas.newEvent || data?.origem == 'private'">Salvar</button>
  </div>
</div>