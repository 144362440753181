export class DocumentoFiscal {

	nome: string;
	simples: boolean;
	ieIndicador: string;
	ie: string;
	im: string;
	suframa: string;

	constructor(
	) { }
}
