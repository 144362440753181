<div class="bs-dialog-header">
    <i class="fa fa-university"></i> CONTA CORRENTE <small> Gestão de Contas Bancárias </small>
  </div>
  
  <div class="bs-dialog-main">
    <div class="bs-dialog-content">
  
      <!-- Carregando informações... -->
      <div class="modal-overlay" *ngIf="loading">
        <div class="alert alert-light fade show m-b-0 text-center" style="color: #222;">
          <i class="fas fa-circle-notch fa-spin"></i> 
        </div>
      </div>
  
      <div class="be-form">
  
        <div class="row">
          <div class="col-6">
            <label>Apelido:</label>
            <input type="text" pInputText [(ngModel)]="data.apelido" class="form-control">
          </div>
        </div>

        <div class="row">
          <div class="col-6">
            <label>Banco:</label>
            <p-autoComplete [(ngModel)]="banco" (onSelect)="onSelectBanco()" field="nome" [suggestions]="autocompleteBanco" (completeMethod)="acFindBanco($event)" autoHighlight="true" forceSelection="true" emptyMessage="Banco não encontrado" class="be-autocomplete">
              <ng-template let-banco pTemplate="item">
                {{banco.nome}}
              </ng-template>
            </p-autoComplete>
          </div>
          <div class="col-3">
            <label>Tipo de Conta:</label>
            <select [(ngModel)]="data.tipo" class="form-control">
              <option value="corrente">Conta Corrente</option>
              <option value="poupanca">Conta Poupança</option>
            </select>
          </div>
        </div>
  
        <div class="row">
          <div class="col-2">
            <label>Agência</label>
            <p-inputNumber [(ngModel)]="data.agencia" mode="decimal" locale="pt-BR" [useGrouping]="false" maxlength="5"></p-inputNumber>
          </div>
          <div class="col-3">
            <label>Conta:</label>
            <p-inputNumber [(ngModel)]="data.conta" mode="decimal" locale="pt-BR" [useGrouping]="false" maxlength="10"></p-inputNumber>
          </div>
          <div class="col-1">
            <label>Dígito:</label>
            <p-inputNumber [(ngModel)]="data.contaDigito" mode="decimal" locale="pt-BR" [useGrouping]="false" maxlength="1"></p-inputNumber>
          </div>
        </div>

        <div class="row">
          <div class="col-6">
            <label>Titular:</label>
            <input type="text" pInputText [(ngModel)]="data.titular" class="form-control">
          </div>
          <div class="col-3">
            <label>Data Abertura:</label>
            <input type="date" [(ngModel)]="data.dtAbertura" class="form-control" pattern="\d{4}-\d{2}-\d{2}" placeholder="dd/MM/yyyy">
          </div>
        </div>
  
        <div class="row">
          <div class="col-3">
            <label>Saldo Atual:</label>
            <div class="p-inputgroup">
              <span class="p-inputgroup-addon">R$</span>
              <p-inputNumber [(ngModel)]="data.saldo" mode="decimal" locale="pt-BR" [minFractionDigits]="2" [maxFractionDigits]="2" maxlength="17"></p-inputNumber>
            </div>
          </div>          
        </div>
        
        <div class="row">
          <div class="col-12">
            <label>Observações:</label>
            <textarea [(ngModel)]="data.observacoes" rows="2" class="form-control" placeholder="Observações"></textarea>
          </div>
        </div>
  
      </div>    
    </div>
  </div>
  
  <div class="bs-dialog-footer">
    <div class="bs-footer-action-close">
      <button (click)="close()" class="btn btn-white" pTooltip="Esc" tooltipPosition="bottom">Fechar</button>
    </div>
    <div class="bs-footer-action">
      <button (click)="save()" [disabled]="recording" class="btn btn-white" pTooltip="Ctrl + Enter" tooltipPosition="bottom">Salvar e criar outro</button>
      <button (click)="saveClose()" [disabled]="recording" class="btn btn-primary" pTooltip="Ctrl + S" tooltipPosition="bottom">Salvar</button>
    </div>
  </div>