<div class="bs-dialog-header">
	<i class="pi pi-file"></i> TERMO DE USO DA BESALES
</div>
  
<div class="bs-dialog-main">
	<div class="bs-dialog-content">
  
		<p>
			Pelo presente Instrumento Particular de TERMO DE USO e ANEXO DE PROTEÇÃO DE DADOS, de um lado a BESALES TECNOLOGIA LTDA, com sede na cidade de São Paulo - SP, na Rua Baceunas, 144, inscrita no CNPJ/MF sob o nº 54.891.264/0001-01, doravante denominada "CONTRATADA" e, de outro lado, a pessoa, física ou jurídica, doravante denominada "USUÁRIO", identificada na Confirmação Contratual, em conformidade com a legislação vigente, têm entre si justo e avençado o seguinte:
		</p>
		<br>
		
		<h5>CONSIDERAÇÕES PRELIMINARES</h5>

		<ol type="i">
				<li>Ao optar por uma das contas oferecidas, o USUÁRIO automaticamente concederá aceitação ao conteúdo do presente Termo de Uso, concordará, ainda, que qualquer indivíduo que utilizar a sua conta em seu nome também respeitará referido Termo de Uso.</li>
				<li>A aceitação deste Termo de Uso manifesta a vontade inequívoca das partes no contrato celebrado por meio eletrônico e na concordância com as condições necessárias para utilização do serviço, listadas abaixo. A não aceitação ou violação dos Termos de Uso resultarão na suspensão e encerramento de sua conta BESALES.</li>
				<li>A utilização do BESALES implica na aceitação plena, irrestrita e sem reservas de todos os termos e condições previstos neste documento, inclusive no caso de eventuais revisões ou publicação de novas versões.</li>
				<li>O Anexo de Proteção de Dados (abaixo) complementa e integra o presente Termo de Uso, entrando em vigor a partir de sua incorporação a ele, a qual poderá ser especificada no Acordo, em um Pedido ou em uma alteração firmada do Acordo.</li>
				<li>A CONTRATADA tem o direito de revisar o Termo de Uso a qualquer momento, sendo que o uso continuado do serviço por parte do USUÁRIO será considerado como aceitação de tais revisões. O USUÁRIO poderá consultar a versão mais atual do Termo de Uso a qualquer momento pelo endereço: <a href="https://www.besales.com.br/termos" target="_blank">https://www.besales.com.br/termos</a>. Se você tiver uma assinatura ativa do BESALES, informaremos sobre as atualizações quando ocorrerem.</li>
				<li>O uso continuado da Plataforma ou dos demais serviços da BESALES TECNOLOGIA, após a revisão deste Termo de Uso, implica aceitação das alterações, sem ressalvas.</li>
				<li>A celebração do presente contrato é comprovada pela concordância do USUÁRIO pela via eletrônica e pela confirmação de leitura no ato da contratação. O USUÁRIO declara desde já ter lido o presente contrato na sua integralidade, concordando com todas as cláusulas e disposições transcritas abaixo, além de declarar ter preenchido a ficha cadastral com suas informações.</li>
			</ol>
		<br>

		<h5>DEFINIÇÕES</h5>
			<p>1.1. Para o perfeito entendimento e interpretação deste Termos de Uso, fica estabelecido que:</p>
			<ol type="a">
				<li>USUÁRIO: termo usado para referir-se a qualquer afiliado do USUÁRIO que tenha permissão para utilizar os serviços do BESALES sem ter assinado o presente Termo de Uso com a CONTRATADA;</li>
				<li>CENTRAL DE SUPORTE: é o serviço de suporte técnico disponibilizado ao USUÁRIO;</li>
				<li>BESALES: software desenvolvido e registrado em nome da BESALES TECNOLOGIA, que possibilita o controle e processamento de informações e dados internos de empresas, caracterizando-se como uma ferramenta de gestão empresarial, com acesso remoto via rede mundial de computadores, por meio de por meio de dispositivos próprios, cuja responsabilidade é do USUÁRIO;</li>
				<li>SOFTWARE AS A SERVICE (SaaS): sistema de armazenamento de dados localizado em servidor remoto, em que o acesso é feito via internet;</li>
				<li>WEBSITE: é a página ou sequência de páginas do software desenvolvido, com a finalidade de ofertar o Serviço.</li>
			</ol>
			<br>

		<h5>OBJETO</h5>

			<p>2.1. O objeto deste contrato é a prestação pela CONTRATADA, pela duração do presente contrato, dos serviços de acesso e licença de uso do sistema BESALES, comercializado como software as a service, armazenamento dos dados incluídos no sistema pelo USUÁRIO e prestação de suporte à distância na utilização das funcionalidades do sistema BESALES; mediante pagamento periódico, e de acordo com a opção de contratação solicitada pelo USUÁRIO no momento da contratação pelo sítio <a href="https://www.besales.com.br/planos" target="_blank">https://www.besales.com.br/planos</a>.</p>
			<p>2.2 O objeto do presente contrato realiza-se pelo acesso via internet pelo USUÁRIO ao software de código fechado BESALES por meio de login e senha, vedando-se qualquer tipo de reprodução, cópia ou engenharia reversa do mesmo pela CONTRATANTE.</p>
			<p>2.3 Para tanto, a CONTRATADA se exime de qualquer responsabilidade sobre o equipamento técnico e meios necessários, como, por exemplo, computador, provedor de acesso à rede mundial de computadores e modem. O presente instrumento regula a prestação do Serviço conforme os termos e condições abaixo dispostos além da legislação e norma técnicas aplicáveis.</p>
			<p>2.4 O Serviço será prestado nos formatos abaixo ofertados:</p>
			<ol type="a">
				<li>Aplicativos Gratuitos com Limitações de Faturamento;</li>
				<li>Contas Profissionais: acesso pago com diversas opções de pacotes de funcionalidades, cujo detalhamento é apresentado ao USUÁRIO na página <a href="https://www.besales.com.br/planos" target="_blank">https://www.besales.com.br/planos</a>;</li>
			</ol>
			<br>
			<p>2.5 A CONTRATADA se reserva o direito de alterar, modificar, suspender ou descontinuar qualquer porção do website a qualquer momento, bem como as opções de contas, valores e formatos a serem ofertados ao USUÁRIO.</p>
			<p>2.6 O presente instrumento é válido também para os casos de pacotes gratuitos oferecidos pela CONTRATADA; podendo referidos planos serem revogados a qualquer tempo, sem necessidade de prévia informação ao USUÁRIO.</p>
			<br>

			<!--  -->

			<h5>PAGAMENTO DO SERVIÇO</h5>

			<p>3.1. A CONTRATADA poderá alterar, a qualquer momento, a forma de negociação dos Serviços por meio do item <a href="https://erp.besales.com.br/dados_conta" target="_blank">https://erp.besales.com.br/dados_conta</a>.</p>
			<p>3.2. Na prestação de serviços no formato de "Contas Profissionais", o Serviço será cobrado periodicamente, de forma pré-paga; mediante o plano de assinatura escolhido pelo USUÁRIO no momento da contratação, conforme item 3.1.</p>
			<p>3.3 O USUÁRIO terá o direito de usar o Serviço durante todo o período da contratação, não estando a CONTRATADA obrigada a restituir quantias já pagas caso o USUÁRIO desista do Serviço sem que tenha sido completado o período de pelo qual foi o pagamento efetuado.</p>
			<p>3.4 Os planos darão acesso às funcionalidades do sistema na medida especificada pela descrição do plano escolhido no momento da contratação. Os planos oferecidos pela CONTRATADA terão valores distintos, a depender das funcionalidades desejadas e da quantidade de informações armazenadas nos servidores da CONTRATADA. Todos os planos, independentemente do valor e das funcionalidades contratadas, estão sujeitos às cláusulas do presente termo de uso.</p>
			<br>

			<!--  -->

			<h5>OBRIGAÇÕES DA CONTRATADA</h5>

			<p>4.1. São obrigações da CONTRATADA, além das demais previstas neste instrumento, na legislação aplicável e no anexo a este contrato:</p>
			<ol type="a">
				<li>Disponibilizar o Serviço envidando os melhores esforços para assegurar e desenvolver a qualidade do mesmo, comprometendo-se, ainda, a respeitar a privacidade do USUÁRIO, garantindo que não divulgará informações relativas a sua utilização, mantendo sigilo sobre as informações cadastrais fornecidas, inclusive login e senha, que só serão divulgados a terceiros em razão de determinação judicial, ressalvadas as hipóteses previstas neste Contrato;</li>
				<li>Comunicar ao USUÁRIO a interrupção da prestação do Serviço por ocasião de manutenções preventivas e/ou corretivas;</li>
				<li>Fornecer um suporte básico para a utilização do sistema BESALES, disponibilizando para tanto atendimento de segundas às sextas-feiras, das 8h30 às 11h30 e 13h30 às 17h30, por meio da ferramenta de suporte <a href="https://ajuda.besales.com.br/hc/pt-br" target="_blank">https://ajuda.besales.com.br/hc/pt-br</a>;</li>
				<li>Disponibilizar ao USUÁRIO a possibilidade de extração dos dados que inserir no sistema BESALES, em formato CSV;</li>
				<li>Tratar o USUÁRIO e seus funcionários de forma respeitosa e urbana.</li>
			</ol>
			<br>

			<!--  -->

			<h5>OBRIGAÇÕES DO USUÁRIO</h5>

			<p>5.1. São obrigações do USUÁRIO, além das demais previstas neste instrumento e na legislação aplicável:</p>
			<ol type="a">
				<li>pagar os valores devidos de acordo com o Serviço escolhido;</li>
				<li>Tratar a CONTRATADA e seus funcionários de forma respeitosa e urbana;</li>
				<li>ser o único responsável, juntamente com todos USUÁRIOs que tenha habilitado no BESALES; pelo uso e guarda do login e senha de acesso ao Serviço, obrigando-se a honrar todos os compromissos financeiros e/ou legais daí resultantes;</li>
				<li>Utilizar o BESALES unicamente para os fins estabelecidos no presente instrumento; somente para fins lícitos e de estrito acordo com a legislação brasileira;</li>
				<li>O USUÁRIO é responsável por todas as informações inseridas e enviadas ao sistema BESALES, responsabilizando-se por quaisquer violações legais a direitos de terceiros decorrentes da operação do serviço de acordo com o presente termo; incluindo também, mas não somente, as ações realizadas por meio de funcionários ou representantes da empresa.</li>
				<li>O USUÁRIO declara ter ciência que o sistema BESALES é operado por meio da rede mundial de computadores, sendo de sua inteira responsabilidade sobre a disponibilidade sobre os equipamentos técnicos e meios pelos quais realiza o acesso necessários, como, por exemplo, computador, provedor de acesso à rede mundial de computadores e modem e demais intermediários envolvidos na conexão particular do USUÁRIO, não tendo a CONTRATADA qualquer obrigação sobre os dispositivos e conexões particulares do USUÁRIO.</li>
				<li>O USUÁRIO obriga-se a manter em sigilo as credenciais de acesso ao sistema (nome de USUÁRIO e senha), assumindo a responsabilidade de não compartilhar com terceiros as suas informações de acesso; não sendo a CONTRATADA responsável pela guarda e segurança dos arquivos e informações enviadas em caso de mau uso ou compartilhamento das senhas geradas quando da contratação do sistema BESALES.</li>
				<li>O USUÁRIO não poderá compartilhar, dividir ou fornecer a sua conta para utilização de terceiros, sob pena de cancelamento da conta e rescisão do presente contrato, além da eliminação das informações armazenadas.</li>
				<li>O USUÁRIO obriga-se a aceitar as atualizações e modificações realizadas pela CONTRATADA.</li>
				<li>O USUÁRIO declara ter fornecido dados verdadeiros e corretos no momento de preenchimento das suas informações cadastrais no procedimento de contratação, responsabilizando-se por eventuais erros de digitação ou por dados inseridos erroneamente; e tem legitimidade para utilização destes dados;</li>
				<li>O USUÁRIO assume a obrigação de informar à CONTRATADA imediatamente quaisquer alterações cadastrais, sobretudo de mudanças decorrentes no contrato social, de endereço físico ou de correio eletrônico, sendo facultado à CONTRATADA utilizar serviço próprio ou de terceiros para apurar a validade e veracidade das informações prestadas.</li>
				<li>O USUÁRIO é obrigado a comunicar à CONTRATADA, eventual extravio ou alienação das informações de acesso à conta (nome de USUÁRIO e senha), não se responsabilizando a CONTRATADA pela segurança e privacidade das informações em caso de uso ou compartilhamento negligente ou imprudente das informações de acesso; conforme pactuado na alínea “f”, acima.</li>
				<li>O USUÁRIO declara que o e-mail informado no preenchimento da sua ficha cadastral é uma forma de comunicação eficaz, válida e suficiente para recebimento de comunicados relacionados à sua conta do sistema BESALES, bem como para informações de cancelamento ou suspensão da conta.</li>
				<li>O USUÁRIO autoriza que a página inicial de acesso ao sistema BESALES seja também eficaz, válida e suficiente para a divulgação de assuntos relacionados ao serviço ora contratado, inclusive em relação a eventual reajuste de preço dos planos ou de suas funcionalidades.</li>
				<li>O USUÁRIO obriga-se a manter os meios de acesso ao sistema BESALES sempre atualizados (tais como navegador e sistema operacional) realizando as atualizações sugeridas pelo fornecedores de tais produtos. A BESALES TECNOLOGIA não responderá por danos causados ao USUÁRIO quando decorrerem deste não seguir as instruções dos fabricantes de software ou do navegador para acesso ao BESALES.</li>
				<li>O USUÁRIO declara que leu e compreende os termos da POLÍTICA DE PRIVACIDADE E PROTEÇÃO DE DADOS da CONTRATADA, bem como suas eventuais atualizações.</li>
			</ol>
			<br>

			<!--  -->

			<h5>INTERRUPÇÕES, GARANTIAS E RESPONSABILIDADES</h5>

			<p>6.1. O Serviço poderá ser interrompido, sem que haja qualquer direito de indenização ou compensação ao USUÁRIO, eventualmente para:</p>
			<ol type="a">
				<li>Manutenções técnicas e/ou operacionais que exijam o desligamento temporário do sistema ou impossibilitem o acesso ao mesmo;</li>
				<li>Casos fortuitos ou de força maior;</li>
				<li>Ações de terceiros que impeçam a prestação;</li>
				<li>Falta de fornecimento de energia elétrica;</li>
				<li>Interrupção ou suspensão da prestação dos serviços de telecomunicações;</li>
				<li>Ocorrências de falhas no sistema de transmissão e/ou roteamento no acesso à rede mundial de computadores;</li>
				<li>Interrupções, falhas e/ou suspensão do serviço de infraestrutura de nossos fornecedores de infraestrutura terceiros.</li>
			</ol>
			<br>

			<p>6.2. A CONTRATADA não será responsável por danos e/ou prejuízos decorrentes de interrupções relacionadas aos eventos previstos no item acima, ou daqueles em que não tenham concorrido exclusivamente para sua ocorrência.</p>
			<p>6.3. Tendo em vista que é impossível o funcionamento integral e ininterrupto de qualquer sistema de telecomunicação ou de informática, durante 365 dias por ano, 24 horas por dia, nesta situação de fragilidade também se incluindo, em razão de sua complexidade, o Serviço, a CONTRATADA não garante seu funcionamento ininterrupta e/ou isenta de erros.</p>
			<p>6.4. A CONTRATADA isenta-se expressamente de quaisquer responsabilidades e indenizações, perdas e danos, lucros cessantes, prejuízos de quaisquer espécies ou sob quaisquer títulos, perdas de negócios, perda ou extravio de dados, defeitos de computador, equipamentos ou periféricos, ou quaisquer outros danos diretos, indiretos, acidentais, especiais, consequenciais ou punitivos, decorrentes direta ou indireta da utilização do Serviço causados ao USUÁRIO ou a terceiros.</p>
			<p>6.5 A CONTRATADA isenta-se de responsabilidade por qualquer acesso indevido, atividade suspeita na conta da CONTRATADA ou, ainda, pelas eventuais consequências e danos decorrentes da falha dos USUÁRIOs do sistema em manter a confidencialidade da senha e/ou da falta ou atraso na comunicação à CONTRATADA referente a eventual acesso indevido ou uso não autorizado da senha do USUÁRIO por terceiros.</p>
			<br>

			<!--  -->

			<h5>LIMITAÇÃO DE RESPONSABILIDADE</h5>

			<p>7.1 A CONTRATADA não é responsável por quaisquer danos causados em razão da ocorrência de “casos fortuitos” ou de “força maior”, conforme determinado pelo artigo 393 do Código Civil Brasileiro. A suspensão, ainda que parcial, do serviço de internet ou telefonia à sede da CONTRATADA é considerado caso fortuito para fins do presente contrato.</p>
			<p>7.2. A CONTRATADA é isenta de qualquer responsabilidade por danos causados através da utilização do sistema BESALES por pessoas não autorizadas ou terceiros que não façam parte do quadro de funcionários do USUÁRIO ou ainda casos de violação a direitos nos quais não exista culpa subjetiva da CONTRATADA; conforme estipulado no item 5.1, alínea “f”.</p>
			<p>7.3. A CONTRATADA não é responsável pelas informações pessoais ou informações de conta fornecidas pelo USUÁRIO; bem como não responde pelo preenchimento dos dados cadastrais de maneira falsa ou incorreta.</p>
			<p>7.4. Nos casos nos quais a CONTRATADA seja considerada responsável por danos causados pelo sistema BESALES, a indenização paga ao USUÁRIO será limitada à quantia correspondente ao valor de 3 (três) mensalidades do plano contratado pelo USUÁRIO. O USUÁRIO declara estar de acordo com a presente cláusula de limitação quantitativa da responsabilidade da CONTRATADA. Caso o USUÁRIO não esteja de acordo com a presente limitação, deverá interromper a utilização do serviço imediatamente.</p>
			<p>7.5 A CONTRATADA não poderá ser responsabilizada por pagamentos errôneos realizados pela USUÁRIO a fornecedores, USUÁRIOs, funcionários, agentes, ou qualquer outra pessoa, seja referente a qualquer tipo de pagamento gerado pelo sistema, incluindo, mas não somente, impostos e comissões.</p>
			<p>7.6 É de responsabilidade exclusiva do USUÁRIO a parametrização e a conferência dos cálculos realizados pelo sistema como, por exemplo, a atualização de alíquotas, bases de cálculo e classificações fiscais bem como o cálculo final dos impostos devidos. Os colaboradores da CONTRATADA não estão autorizados a realizar essas atividades para o USUÁRIO.</p>
			<p>7.7. O USUÁRIO deverá promover verificações periódicas nos cálculos e lançamentos informados pelo sistema para fins de verificar a sua correção. Os pagamentos realizados pelo USUÁRIO, sem a devida conferência, não serão de responsabilidade da CONTRATADA.</p>
			<br>

			<!--  -->

			<h5>VIGÊNCIA E CANCELAMENTO</h5>

			<p>8.1. O presente Contrato terá prazo de vigência indeterminado, podendo ser rescindido a qualquer tempo por ambas as Partes, mediante aviso prévio de 30 (trinta) dias; por meio de comunicação expressa à parte contrária; por meio do sistema e/ou e-mail.</p>
			<p>8.2 Em caso de tratamento desrespeitoso por parte dos USUÁRIOS ou da CONTRATADA aos funcionários do USUÁRIO; o presente contrato poderá ser rescindido de forma imediata, sem observar o aviso prévio do item 8.1.</p>
			<p>8.3 Caso o cancelamento seja feito antes do término de um período já pago, o encerramento da conta terá efeito imediato e o USUÁRIO não terá direito ao reembolso do período contratado, ficando disponível o acesso até o final do período pago.</p>
			<p>8.3.1 Em caso de cancelamento antecipado, será devida multa equivalente a 10% (dez) das parcelas restantes do plano.</p>
			<p>8.3.2 Em se tratando de plano anual contratado com desconto, além da multa prevista no item acima, o cancelamento antecipado acarretará na cobrança proporcional das mensalidades anteriormente pagas sem a aplicação do desconto.</p>
			<p>8.4. A infração por qualquer das partes de qualquer das disposições do presente instrumento ou das disposições legais e regulamentares a que o mesmo se subordina, faculta à Parte Lesada, a seu exclusivo critério, rescindir o presente instrumento, independente da comunicação prévia do item 8.1.</p>
			<p>8.5. A CONTRATADA rescindirá imediatamente o Serviço de um determinado USUÁRIO caso haja indícios de que referido USUÁRIO esteja utilizando o Serviço para incitar ou cometer qualquer atitude ilegal ou que conduza a comportamento ilegal.</p>
			<p>8.6. No caso de não pagamento da referida assinatura ou de cancelamento dos serviços, a CONTRATADA manterá as informações do USUÁRIO pelo período de 90 (noventa) dias, contados da suspensão de acesso. Durante este período, o USUÁRIO poderá extrair suas informações em formato CSV (um tipo de arquivo de texto usado para transferência de informações entre aplicativos).</p>
			<p>8.7 Após os 90 (noventa) dias determinados no item 8.6, o contrato será cancelado definitivamente e todos os serviços serão interrompidos e a CONTRATADA não estará mais obrigada a manter os dados do USUÁRIO em seus bancos de dados. As informações armazenadas no sistema BESALES serão eliminadas de forma irrecuperável.</p>
			<p>8.8 Em caso de recontratação dos serviços após 90 (noventa) dias dias da suspensão de acesso, a CONTRATADA não garante ao USUÁRIO a recuperação dos dados armazenados no sistema anteriormente. A recontratação terá os mesmos efeitos práticos de uma nova assinatura e será disponibilizada ao USUÁRIO uma nova conta com banco de dados novo para início das operações.</p>
			<p>8.9 A qualquer tempo a CONTRATADA poderá cancelar os serviços, a seu único critério, informando o USUÁRIO com 60 dias de antecedência, período em que deverá manter todos os serviços em pleno funcionamento. Após 60 dias da manifestação do cancelamento pela CONTRATADA, os serviços serão inteiramente interrompidos e não será mais possível acessar qualquer dos dados armazenados. Durante este período, o USUÁRIO poderá extrair suas informações em formato CSV. Após este período, os dados do USUÁRIO serão eliminados.</p>
			<br>

			<!--  -->

			<h5>REAJUSTE</h5>

			<p>9.1. A critério da CONTRATADA os valores das contas profissionais poderão ser reajustados na periodicidade permitida em lei.</p>
			<br>

			<!--  -->

			<h5>CADASTRO E USO DOS DADOS CADASTRAIS</h5>

			<p>10.1. Ao efetuar seu cadastro aceitando as condições e termos do Termo de Uso, o USUÁRIO declara e garante, expressamente, para todos os fins de direito, possuir capacidade jurídica para a celebração deste Contrato, bem como que é financeiramente responsável pela utilização dos produtos e serviços objeto deste Contrato.</p>
			<p>10.2. O USUÁRIO compromete-se a comunicar imediatamente a CONTRATADA sobre qualquer extravio, roubo, perda ou uso não autorizado de seu login e/ou senha, a fim de que as mesmas possam bloqueá-los.</p>
			<p>10.3. A CONTRATADA exonera-se de toda e qualquer responsabilidade decorrente do uso indevido, negligente ou imprudente do login e/ou da senha.</p>
			<p>10.4. No preenchimento do formulário específico de cadastramento, o USUÁRIO deverá prestar as informações solicitadas para identificação, cadastro e uso das soluções da CONTRATADA.</p>
			<p>10.5. O USUÁRIO deverá fornecer, na Confirmação Contratual, informações verdadeiras, atualizadas e completas, sendo facultado à CONTRATADA utilizar serviço próprio ou de terceiros para apurar a validade e veracidade das informações. O USUÁRIO deverá, ainda, informar qualquer alteração em seus dados cadastrais, inclusive mudança de endereços convencional ou e-mail.</p>
			
			<p>10.6. Caso os dados informados pelo USUÁRIO no momento do cadastramento estejam errados ou incompletos, sofram alteração não comunicada nos termos do item anterior, ou impossibilitem a comprovação e identificação do USUÁRIO, a CONTRATADA poderá, a seus exclusivos critérios, suspender ou cancelar automaticamente e independente de comunicação prévia, os serviços ora contratados, ficando os mesmos isentos de qualquer responsabilidade ou ressarcimento ao USUÁRIO.</p>
			<p>10.7. A CONTRATADA garante que adota os melhores níveis quanto a segurança na proteção dos referidos dados cadastrais do USUÁRIO, tendo instalado os meios e medidas técnicas para tentar evitar a perda, mau uso, alteração, acesso não autorizado ou subtração indevida dos dados cadastrais recolhidos.</p>
			<p>10.8 O USUÁRIO declara estar ciente de que as medidas de segurança relativas a Internet não são integralmente infalíveis; de modo que o USUÁRIO reconhece que a CONTRATADA não é responsável por qualquer acesso indevido, atividade suspeita na conta do USUÁRIO ou, ainda, pelas eventuais consequências e danos decorrentes da falha do USUÁRIO em manter a confidencialidade da senha e/ou da falta ou atraso na comunicação à CONTRATADA referente a eventual acesso indevido ou uso não autorizado da senha do USUÁRIO por terceiros.</p>
			<p>10.9 O USUÁRIO declara concordar que, mesmo após o encerramento da vigência do presente contrato, seu e-mail permaneça na base de dados da CONTRATADA.</p>
			<br>

			<!--  -->

			<h5>PROPRIEDADE INTELECTUAL</h5>

			<p>11.1. Ressalvada estipulação expressa em contrário, são de propriedade exclusiva da CONTRATADA o conteúdo do website www.besales.com.br como textos, gráficos, planilhas, ícones, desenhos, programas de computador, aí incluídos o suporte lógico, a descrição básica e o material de apoio, os dados de qualquer natureza relacionados aos serviços objeto deste contrato, estando todos protegidos pelas leis brasileiras de direito intelectual, especialmente as de direito autoral, de proteção jurídica do programa de computador, de marcas, patentes, modelos e projetos.</p>
			<p>11.2. O uso não autorizado, a divulgação, ainda que restrita, especialmente a de caráter comercial, a publicação, distribuição, transmissão, compilação, alteração, assim como a reprodução total ou parcial não autorizada dos conteúdos disponibilizados pelo Portal, por meio mecânico, eletrônico ou qualquer outro, são práticas estritamente proibidas, sujeitando o infrator às sanções de natureza penal, civil e administrativa cabíveis.</p>
			<br>

			<!--  -->

			<h5>CONFIDENCIALIDADE</h5>

			<p>12.1 As Partes acordam que todas as informações inseridas no sistema BESALES, informações que forem transmitidas e ou recebidas do referido sistema, serão consideradas Confidenciais, não sendo permitido a quaisquer das partes a divulgação destas informações, ressalvados os casos de ordem ou determinação judicial de Autoridade Pública. Neste sentido, a CONTRATADA informará imediatamente ao USUÁRIO acerca de tal situação de modo que esta possa contestar e ou adotar as medidas legais que entender necessárias à proteção das informações confidenciais.</p>
			<p>12.2 Não serão tratados como violação de confidencialidade as informações que estejam em domínio público à época que for desenvolvida ou fornecida.</p>
			<p>12.3 A CONTRATADA não será responsável por violações dos dados e informações acima referidos resultantes de atos de funcionários, prepostos ou de pessoas autorizadas pela USUÁRIO, e nem daquelas resultantes da ação criminosa ou irregular de terceiros “hackers” fora dos limites da previsibilidade técnica do momento em que a mesma vier a ocorrer.</p>
			<br>

			<!--  -->

			<h5>DISPOSIÇÕES GERAIS</h5>

			<p>13.1. As partes reconhecem o serviço de e-mail como forma válida, eficaz e suficiente de comunicação e aceitam a página inicial de acesso do Portal como meio válido, eficaz e suficiente para a divulgação de qualquer assunto que se refira aos serviços objeto deste Contrato, bem como as condições de sua prestação ou a qualquer outro assunto nele abordado, ressalvadas as disposições expressamente diversas previstas neste instrumento.</p>
			<p>13.2. A CONTRATADA não será responsável por nenhum dano que porventura seja ocasionado por perda de conteúdo armazenado, por atrasos na transmissão, perda na transmissão, ou interrupção dos serviços causados por negligência das empresas envolvidas no processo ou erros e omissões do USUÁRIO, falha de performance, interrupção, delegação, defeito, atrasos na operação ou transmissão falha de linha de comunicação de acesso ocasionada pela empresa de telefonia responsável pelo serviço de suporte ao serviço prestado, furto ou destruição por algum acesso não autorizado, alterações ou uso de gravações, não se responsabilizando, inclusive, por informações sigilosas enviadas por e-mail.</p>
			<p>13.3. As partes concordam que a CONTRATADA poderá, a seus exclusivos critérios, alterar qualquer procedimento técnico referente aos serviços contratados sem prévio aviso.</p>
			<p>13.4. O não exercício por qualquer uma das partes de qualquer direito consagrado no presente instrumento não representará novação, transação ou renúncia de tal direito, o qual poderá ser exercido a qualquer tempo.</p>
			<p>13.5. Se qualquer cláusula, termo ou condição deste Contrato for considerada nula ou vier a ser anulada por qualquer decisão judicial transitada em julgado, tal nulidade ou anulabilidade não contaminará as demais cláusulas deste Contrato, o qual permanecerá em pleno vigor, desde que ainda se possa manter o equilíbrio contratual ora ajustado.</p>
			<p>13.6. As Partes conferem ao presente instrumento força de Título Executivo Extrajudicial, nos termos do artigo 784, II, do Código de Processo Civil.</p>
			<br>

			<!--  -->

			<h5>USO DA MARCA</h5>

			<p>14.1 Ao contratar o sistema BESALES, o USUÁRIO autoriza a CONTRATADA a divulgar sua marca como USUÁRIO no site do sistema BESALES no site da CONTRATADA, em anúncios nas várias mídias de mercado, reportagens, eventos, testemunhais, entre outras formas de divulgação, sem que isso represente qualquer infração a este contrato ou direito comercial, direito de imagem, ou de qualquer outra natureza, para o USUÁRIO.</p>
			<p>14.2 Caso o USUÁRIO prefira não ter sua marca divulgada nos termos acima, este deverá enviar comunicado formal e protocolado à CONTRATADA, que se comprometerá a não divulgar sua marca como USUÁRIO do sistema BESALES.</p>
			<br>

			<!--  -->

			<h5>FORO</h5>

			<p>15.1. As Partes elegem, para dirimir eventuais demandas emergentes do presente contrato, com renúncia a qualquer outro, por mais privilegiado que seja, o Foro da cidade de Bento Gonçalves no estado do Rio Grande do Sul.</p>
			<br>
			<!--  -->
			<h4 class="text-center">ANEXO DE PROTEÇÃO DE DADOS</h4>

			<p>Este anexo é parte integral e indissociável dos Termos de Uso celebrado entre a "CONTRATADA" e o "USUÁRIO".</p>
			<p>As partes formalizam que o presente ANEXO DE PROTEÇÃO DE DADOS, em conjunto com o TERMO DE USO DO BESALES, formam as instruções definitivas para o USUÁRIO e a CONTRATADA no que tange ao Tratamento de Dados Pessoais no uso do sistema BESALES. Quaisquer instruções adicionais e fora do escopo do aqui tratado deverão ser objeto de acordo escrito e prévio pelas partes.</p>
			<p>Os termos do presente ANEXO DE PROTEÇÃO DE DADOS poderão ser alterados. O USUÁRIO poderá consultar a versão mais atual do Termo de Uso a qualquer momento pelo endereço: <a href="https://www.besales.com.br/termos" target="_blank" rel="noopener noreferrer">https://www.besales.com.br/termos</a>. Se você tiver uma assinatura ativa do BESALES, informaremos sobre as atualizações quando elas ocorrerem.</p>
			<br>
			<!--  -->

			<h5>DEFINIÇÕES</h5>

			<p>1.1. Para o perfeito entendimento e interpretação deste Anexo de Proteção de Dados, fica estabelecido que:</p>
			<ol type="a">
				<li>CONTROLADOR: é a pessoa física ou jurídica que contrata o serviço. É a pessoa jurídica ou física que, isoladamente ou em conjunto com outros, determina as finalidades e meios do presente Termo de Uso. No presente Termo de Uso, designada como USUÁRIO.</li>
				<li>OPERADOR: desenvolvedora do sistema BESALES e prestadora dos serviços de software as a service. Entidade que processa dados pessoais em nome do USUÁRIO, conforme contemplado pelo Contrato e este contrato de processamento de dados.</li>
				<li>LEI DE PROTEÇÃO DE DADOS APLICÁVEL: leis e regulamentos relacionados ao processamento e proteção de dados pessoais aplicáveis no país onde a CONTRATADA está estabelecida;</li>
				<li>DADOS PESSOAIS: designa qualquer informação relativa a uma pessoa física identificada ou identificável;</li>
				<li>TITULAR DOS DADOS: pessoal natural a quem se referem os dados pessoais que são objeto de tratamento;</li>
				<li>TRATAMENTO: qualquer operação ou conjunto de operações realizadas com dados pessoais, seja ou não por meios automáticos, tais como a coleta, produção, recepção, classificação, utilização, acesso, reprodução, transmissão, distribuição, processamento, arquivamento, armazenamento, eliminação, avaliação ou controle da informação, modificação, comunicação, transferência, difusão ou extração.</li>
				<li>VIOLAÇÃO DE PROTEÇÃO DE DADOS: significa uma violação de segurança que leva à destruição acidental ou ilegal, perda, alteração, divulgação não autorizada ou acesso aos Dados Pessoais Processados do USUÁRIO para os propósitos deste contrato de processamento de dados.</li>
			</ol>
			<br>

			<!--  -->

			<h5>RESPONSABILIDADES DO USUÁRIO</h5>

			<p>2.1. O controle dos Dados Pessoais é de responsabilidade exclusiva do USUÁRIO, que é quem tem a relação com os Titulares, a decisão sobre esses Dados Pessoais e que tem a obrigação de assegurar que o Tratamento seja feito conforme a base legal aplicável e segundo os princípios previstos na LEI DE PROTEÇÃO DE DADOS APLICÁVEL. Na condição de CONTROLADOR dos Dados Pessoais, o USUÁRIO, deve manter a CONTRATADA indene a tal respeito, responsabilizando-se por eventual violação ou não conformidade com a LEI DE PROTEÇÃO DE DADOS APLICÁVEL.</p>
			<p>2.2. Os dados pessoais serão tratados conforme o TERMO DE USO DO BESALES e este ANEXO DE PROTEÇÃO DE DADOS, ficando o USUÁRIO responsável pelas seguintes obrigações:</p>
			<ol type="a">
				<li>Definir os objetivos, as bases legais, finalidades e tempo de retenção do Tratamento de Dados Pessoais, garantindo que este ocorra em conformidade com a legislação de proteção de Dados Pessoais aplicável;</li>
				<li>Informar ao Titular e obter todas as necessárias autorizações e o consentimento destes, se assim for necessário e aplicável, sendo o único responsável pela eventual não obtenção do consentimento, quanto ao compartilhamento e tratamento destes Dados Pessoais com a CONTRATADA para os fins deste ANEXO DE PROTEÇÃO DE DADOS;</li>
				<li>Informar aos respectivos Titulares de que a CONTRATADA poderá compartilhar os Dados Pessoais com parceiros de divulgação quando necessário para prestar, manter e aperfeiçoar os serviços ofertados ao USUÁRIO;</li>
				<li>Elaborar notificações (incluindo notificações de violação de proteção de dados) às autoridades de proteção de dados e titulares dos dados, se necessário;</li>
				<li>Realizar a avaliação dos riscos resultantes do tratamento de dados pessoais;</li>
				<li>garantir que todos os Dados Pessoais sujeitos ao Tratamento pela CONTRATADA estejam e se mantenham corretos e atualizados, assegurando que todas as instruções transmitidas à CONTRATADA em relação ao Tratamento dos Dados Pessoais estarão de acordo com a LEI DE PROTEÇÃO DE DADOS APLICÁVEL;</li>
				<li>Informar à CONTRATADA, imediata e detalhadamente, sobre quaisquer erros ou irregularidades relacionadas às Leis de Proteção de Dados no Processamento de Dados Pessoais de que tenha conhecimento, quando relacionadas ao tratamento de dados no ambiente de utilização dos serviços da CONTRATADA.</li>
			</ol>
			<br>

			<!--  -->

			<h5>RESPONSABILIDADES DA CONTRATADA</h5>
			<p>3.1 A CONTRATADA se compromete a tratar os Dados Pessoais, aos quais tiver acesso em razão do uso do sistema BESALES pelo USUÁRIO, somente nos limites aqui previstos e manter os Dados Pessoais armazenados exclusivamente pelo tempo necessário para desenvolver os serviços no âmbito dos TERMOS DE USO, POLÍTICA DE PRIVACIDADE e este ANEXO DE PROTEÇÃO DE DADOS.</p>
			<p>3.2. A CONTRATADA não utilizará os Dados Pessoais para benefício próprio ou de terceiros que não seja o USUÁRIO CONTROLADOR dos Dados Pessoais, nem tampouco os compartilhará a quem quer que seja, salvo nas hipóteses abaixo:</p>
			<ol type="a">
				<li>Quando necessário para o Tratamento, incluindo compartilhamento com SUBOPERADORES, de acordo com as orientações legais do USUÁRIO, que responderá por essas orientações;</li>
				<li>Caso a CONTRATADA seja instada a divulgar os Dados Pessoais por determinação de autoridade competente, ordem judicial, decisão arbitral ou em âmbito administrativo, informando previamente, ou, quando não for possível, logo após a divulgação, ao USUÁRIO;</li>
				<li>Com parceiros de divulgação quando necessário para prestar, manter e aperfeiçoar os serviços ofertados ao USUÁRIO, inclusive para as finalidades dispostas na cláusula 10.7 do TERMO DE USO do BESALES, sendo que, nessa hipótese, tais empresas garantirão, no mínimo, as proteções e salvaguardas previstas nesta cláusula.</li>
			</ol>
			<br>
			<p>3.3. A CONTRATADA definirá os meios para o Tratamento, sendo responsável pela disponibilização das medidas de segurança norteadoras do presente instrumento, devendo o USUÁRIO, a seu critério, adotar tais medidas de segurança.</p>
			<p>3.3.1. O USUÁRIO declara estar ciente de que as medidas de segurança relativas a Internet não são integralmente infalíveis; de modo que o USUÁRIO reconhece que a CONTRATADA não é responsável por qualquer acesso indevido, atividade suspeita na conta do USUÁRIO ou, ainda, pelas eventuais consequências e danos decorrentes da falha do USUÁRIO em manter a confidencialidade da senha e/ou da falta ou atraso na comunicação à CONTRATADA referente a eventual acesso indevido ou uso não autorizado da senha do USUÁRIO por terceiros.</p>
			<p>3.4. A CONTRATADA poderá documentar e arquivar todas as decisões e instruções do USUÁRIO relativas ao Tratamento dos Dados Pessoais necessários à execução dos TERMOS DE USO, POLÍTICA DE PRIVACIDADE e deste ANEXO DE PROTEÇÃO DE DADOS, por período suficiente para que possa se resguardar caso seja instaurado procedimento administrativo ou judicial, em razão da não observância da legislação aplicável.</p>
			<p>3.4.1. O USUÁRIO não pode exigir a exclusão de Dados Pessoais, uma vez que uma Lei ou regras aplicáveis exija que a CONTRATADA mantenha materiais que contenham esses Dados Pessoais. Quando a CONTRATADA precisar reter Dados Pessoais por tais razões, seu processamento será restrito pela CONTRATADA, ou terceiros por ela contratados, até que o período de retenção aplicável tenha expirado. Além disso, o tratamento dos Dados Pessoais será restrito em vez de excluir os Dados Pessoais, na extensão legalmente permitida pela Lei de Proteção de Dados Aplicáveis, em particular, se a exclusão não for razoavelmente viável ou se for somente possível com um custo desproporcional devido ao tipo específico de armazenamento. O USUÁRIO reconhece e aceita que algumas solicitações podem resultar em reivindicações de remuneração adicionais para a CONTRATADA. A CONTRATADA informará o CONTRATADA antes de executar a solicitação.</p>
			<p>3.5. A CONTRATADA não deverá ser responsabilizada por quaisquer reclamações, perdas e danos, despesas processuais judiciais, administrativas ou arbitrais, em qualquer instância ou tribunal, multas, ou qualquer outra situação que envolva o pagamento de valores pecuniários, exceto se os eventos que levaram a tais consequências decorrem de culpa exclusiva e comprovada da CONTRATADA, e estejam diretamente relacionados aos serviços prestados para o USUÁRIO:</p>
			<ol type="a">
				<li>Conforme o quanto disposto no caput, o USUÁRIO se compromete a manter a CONTRATADA indene de qualquer processo judicial, administrativo ou arbitral que venha a ser instaurado;</li>
				<li>Enquanto OPERADOR, a CONTRATADA resguarda seu direito de regresso contra o USUÁRIO, caso venha a suportar qualquer dos procedimentos/custos acima mencionados, sem prejuízo do ressarcimento das despesas decorrentes do processo, além de outras medidas, como denunciação à lide;</li>
				<li>A CONTRATADA resguarda o seu direito de recusar, mediante notificação formal por escrito, qualquer operação ordenada pelo USUÁRIO que implique em Tratamento de Dados Pessoais em desconformidade com as normas de proteção de Dados Pessoais vigentes.</li>
			</ol>
			<br>
			<p>3.6. Caso provada a responsabilidade da CONTRATADA, a indenização devida ao USUÁRIO será limitada à quantia correspondente ao valor de 3 (três) mensalidades do plano contratado pelo USUÁRIO. O USUÁRIO declara estar de acordo com a presente cláusula de limitação quantitativa da responsabilidade da CONTRATADA. Caso o USUÁRIO não esteja de acordo com a presente limitação, deverá interromper a utilização do serviço imediatamente.</p>
			<!--  -->

			<h5>DISPOSIÇÕES GERAIS</h5>
			<p>4.1. O não exercício por qualquer uma das partes de qualquer direito consagrado no presente instrumento não representará novação, transação ou renúncia de tal direito, o qual poderá ser exercido a qualquer tempo.</p>
			<p>4.2. Se qualquer cláusula, termo ou condição deste Contrato for considerada nula ou vier a ser anulada por qualquer decisão judicial transitada em julgado, tal nulidade ou anulabilidade não contaminará as demais cláusulas deste Contrato, o qual permanecerá em pleno vigor, desde que ainda se possa manter o equilíbrio contratual ora ajustado.</p>
			<p>4.3. A CONTRATADA se reserva ao direito de alterar o presente ANEXO DE PROTEÇÃO DE DADOS, a seu critério, a qualquer tempo e sem necessidade de notificação prévia do USUÁRIO, podendo alterar, adicionar, modificar ou remover quaisquer partes deste ANEXO.</p>

			<br><br><br>
			<p>Data da versão: 10 de junho de 2024.</p>

	</div>
</div>

<div class="bs-dialog-footer">
	<div class="bs-footer-action-close">
		<button (click)="close()" class="btn btn-white" pTooltip="Esc" tooltipPosition="bottom">Fechar</button>
	</div>
</div>