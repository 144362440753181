import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { DynamicDialogRef } from 'primeng-lts/dynamicdialog';

@Injectable({ providedIn: 'root' })
export abstract class BaseServiceDialog {
    
    public data: any;
    public originalData: any;
    public loading: boolean;
    public recording: boolean;
    public saveAndClose: boolean = false;

    constructor(
        public mapping: string,
        public http: HttpClient,
        public toastr: ToastrService,
        public translate: TranslateService,
        public dialogRef: DynamicDialogRef,
        public datas: any) {
            this.recording = false;
            this.originalData = this.deep(datas.data);
    }

    public find(_id: string): void {
        this.loading = true;
        this.http.get<any>(`${environment.apiUrl}/${this.mapping}/find/${_id}`).subscribe(
            data => {
                this.data = data;
                this.afterFind();

                this.loading = false;
            },
            error => {
                this.loading = false;
                this.toastr.error('Não foi possível carregar o registro');
            }
        );
    }

    public save(): void {        
        try {
            this.beforeSave(
                valid => {
                    if (valid) {
                        this.recording = true;
                        this.datas.reload = true;
                        this.http.post<any>(`${environment.apiUrl}/${this.mapping}/save`, this.data).subscribe(
                            data => {                    
                                this.refreshArray(data);
                                this.afterSave(data);
                                this.recording = false;
                            },
                            error => {
                                if (this.originalData !== null){
                                    this.refreshArray(this.originalData);
                                }
                                this.recording = false;
                            }
                        );
                    }
                }
            );
        }
        catch (e) {
            this.recording = false;
            this.toastr.error('', e.message);
        }
    }

    public clearSpecialCharacters(texto: string): string {
        // Define a expressão regular para encontrar caracteres especiais
        const regex = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/g;
        // Remove os caracteres especiais usando a expressão regular
        return texto.replace(regex, '');
      }

    public close() {
        this.dialogRef.close();
    }

    public saveClose() {
        this.saveAndClose = true;
        
        this.save();
    }

    public refreshArray(obj) {
        if (this.datas.datas && this.datas.datas.result) {
            var index: any;
            for (var i = 0; i < this.datas.datas.result.length; i++) {
                if (this.datas.datas.result[i]['_id'] == obj._id) {
                    index = i;
                    break;
                }
            }

            if (index == null) {
                this.datas.datas.result.push(obj);
            }
            else {
                this.datas.datas.result[index] = obj;
            }
        }
    }

    public deep<T>(value: T): T {
        if (typeof value !== 'object' || value === null) {
            return value
        }
        if (Array.isArray(value)) {
            return this.deepArray(value)
        }
        return this.deepObject(value)
    }

    public deepObject<T>(source: T) {
        const result = {} as T
        Object.keys(source).forEach((key) => {
            const value = source[key as keyof T]
            result[key as keyof T] = this.deep(value)
        }, {})
        return result as T
    }

    public deepArray<T extends any[]>(collection: T): any {
        return collection.map((value) => {
            return this.deep(value)
        })
    }

    public beforeSave(callback: (valid: boolean) => void): void {
        callback(true);
    }    
     // Apos executar a consulta faz uma callback a finalizar
    public afterFind(): void {
        // Caso nao encontre a iniciativa alerta e fecha o dialog
        if (!this.data) {
            this.createData();
            this.datas.reload = true;
            this.toastr.error('Registro não encontrado, pode ser que foi excluído por outro usuário');

            this.close();
        }
    }
    // Apos salvar manda a mensagem e cria novo objeto
    public afterSave(baseDoc: any): void {
        this.data = null;
        this.createData();
        this.toastr.success('', 'Operação realizada com sucesso');

        if (this.saveAndClose) {
            this.close();
        }
    }

    public createData(): void {}
}
