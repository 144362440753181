<div class="bs-dialog-header">
  <i class="fa fa-users"></i> CONCORRENTES <small> Adicionar Concorrente </small>
  </div>
  
  <div class="bs-dialog-main">
    <div class="bs-dialog-content">
  
      <!-- Carregando informações... -->
    <div class="modal-overlay" *ngIf="loading">
      <div class="alert alert-light fade show m-b-0 text-center" style="color: #222;">
        <i class="fas fa-circle-notch fa-spin"></i> 
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <p-panel header="Pesquisar Concorrente">
          <div class="row">
            <div class="col-md-12">
              <div class="p-inputgroup">
                <input type="text" [(ngModel)]="q" pInputText placeholder="Pesquise pelo ID do anúncio ex. MLB123456789 ou pela url ex. https://produto.mercadolivre.com.br/...">   
                <button (click)="buscarConcorrente()" type="button" pButton label="Buscar"></button>
            </div>
            </div>
          </div>
        </p-panel>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12 m-t-30">
        <p-panel header="Concorrentes">
          <div class="row">
            <div class="col-md-12 text-center" *ngIf="sellers.length == 0">
              Nenhum concorrente encontrado
            </div>
              <table class="table m-b-0" *ngIf="sellers.length > 0">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Username</th>
                    <th width="10%"></th>
                  </tr>
                </thead>
                <tbody>
                   <tr *ngFor="let d of sellers">
                    <td>
                      {{d?.id}}
                    </td>
                    <td>{{d?.nickname}}</td>
                    <td class="with-btn">
                      <button (click)="remove(d)" class="btn btn-sm btn-danger">Remover</button>
                    </td>
                  </tr>
                </tbody>
              </table>
          </div>
        </p-panel>
      </div>
    </div>

    
  </div>
  
  <div class="bs-dialog-footer">
    <div class="bs-footer-action-close">
      <button (click)="close()" class="btn btn-white" pTooltip="Esc" tooltipPosition="bottom">Fechar</button>
    </div>
    <div class="bs-footer-action">
      <button (click)="saveClose()" [disabled]="recording" class="btn btn-primary" pTooltip="Ctrl + S" tooltipPosition="bottom">Salvar</button>
    </div>
  </div>