<div id="content" class="content content-full-width">

    <h1 class="page-header">Configurações do Sistema</h1>

    <div class="vertical-box with-grid inbox bg-light">

        <div class="vertical-box-column width-200">
            <div class="vertical-box">

                <div class="wrapper d-none d-sm-none d-md-none d-lg-block">
                    <div class="d-flex align-items-center justify-content-center">
                        Menu
                    </div>
                </div>

                <div class="vertical-box-row d-lg-table-row collapse">
                    <div class="vertical-box-cell">
                        <div class="vertical-box-inner-cell">
                            <div class="keras-scrollbar">
                                <div class="wrapper p-0">
                                    <ul class="nav nav-inbox">
                                        <li [ngClass]="activeIndex == 0 ? 'active' : ''"><a (click)="activeIndex = 0" href="javascript:;">Geral</a></li>
                                        <li [ngClass]="activeIndex == 1 ? 'active' : ''"><a (click)="activeIndex = 1" href="javascript:;">Cadastros</a></li>
                                        <li [ngClass]="activeIndex == 2 ? 'active' : ''"><a (click)="activeIndex = 2" href="javascript:;">Vendas</a></li>
                                        <li [ngClass]="activeIndex == 3 ? 'active' : ''"><a (click)="activeIndex = 3" href="javascript:;">Finanças</a></li>
                                        <li [ngClass]="activeIndex == 4 ? 'active' : ''"><a (click)="activeIndex = 4" href="javascript:;">Serviços</a></li>
                                        <li [ngClass]="activeIndex == 5 ? 'active' : ''"><a (click)="activeIndex = 5" href="javascript:;">Usuários</a></li>
                                        <li [ngClass]="activeIndex == 6 ? 'active' : ''"><a (click)="activeIndex = 6" href="javascript:;">Integrações</a></li>
                                        <li [ngClass]="activeIndex == 7 ? 'active' : ''"><a (click)="activeIndex = 7" href="javascript:;">Auditoria</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

        <div class="vertical-box-column">
            <div class="vertical-box">
                <div class="vertical-box-row">
                    <div class="vertical-box-cell">
                        <div class="vertical-box-inner-cell bg-white">
                            <div class="keras-scrollbar">
                                <div class="bs-configuracao">
                                    <div class="row">
                                        <div class="col-8">

                                            <p-tabView [(activeIndex)]="activeIndex">
                                        
                                                <p-tabPanel>
                                                    <h4>Configurações Gerais</h4>
                                                    <hr>
                                                    <table class="table">
                                                        <tbody>
                                                            <tr><td class="b-t-0"><a href="#">Configurações do servidor de e-mail</a></td></tr>
                                                            <tr><td><a href="#">Configurações do envio de documentos</a></td></tr>
                                                            <tr><td><a href="#">Configurações das etiquetas</a></td></tr>
                                                            <tr><td><a href="#">Configurações da agenda</a></td></tr>
                                                            <tr><td><a href="#">Interface do usuário</a></td></tr>
                                                            <tr><td><a href="#">Central de notificações</a></td></tr>
                                                            <tr><td><a href="#">Impressão automática</a></td></tr>
                                                            <tr><td><a href="#">Token API</a></td></tr>
                                                            <tr><td><a href="#">Configurações de API</a></td></tr>
                                                        </tbody>
                                                    </table>
                                                </p-tabPanel>
                                                
                                    
                                                <p-tabPanel>
                                                    <h4>Configurações de Cadastros</h4>
                                                    <hr>
                                                    <table class="table">
                                                        <tbody>
                                                            <tr><td class="b-t-0"><a href="#">Configurações do cadastro de clientes</a></td></tr>
                                                            <tr><td><a href="#">Configurações do cadastro de produtos</a></td></tr>
                                                            <tr><td><a href="#">Configurações de variações de produtos</a></td></tr>
                                                            <tr><td><a href="#">Configurações de atributos de produtos</a></td></tr>
                                                            <tr><td><a href="#">Configurações de marcas de produtos</a></td></tr>
                                                            <tr><td><a href="#">Tabelas de medidas</a></td></tr>
                                                            <tr><td><a href="#">Configurações das tags</a></td></tr>
                                                            <tr><td><a href="#">Tipos de contato</a></td></tr>
                                                            <tr><td><a href="#">Linhas de produto</a></td></tr>
                                                        </tbody>
                                                    </table>
                                                </p-tabPanel>
                                    
                                                <p-tabPanel>
                                                    <h4>Configurações de Vendas</h4>
                                                    <hr>
                                                    <table class="table">
                                                        <tbody>
                                                            <tr><td class="b-t-0"><a href="#">Configurações do PDV</a></td></tr>
                                                            <tr><td><a href="#">Configurações das propostas comerciais</a></td></tr>
                                                            <tr><td><a href="#">Configurações dos pedidos de venda</a></td></tr>
                                                            <tr><td><a href="#">Configurações do envio de documentos</a></td></tr>
                                                            <tr><td><a href="#">Configurações dos marcadores nas vendas</a></td></tr>
                                                            <tr><td><a href="#">Configurações dos marcadores nas propostas comerciais</a></td></tr>
                                                            <tr><td><a href="#">Configurações dos marcadores nas notas fiscais</a></td></tr>
                                                            <tr><td><a href="#">Configurações dos marcadores nas devoluções de vendas</a></td></tr>
                                                        </tbody>
                                                    </table>
                                                </p-tabPanel>
                                    
                                                <p-tabPanel>
                                                    <h4>Configurações de Finanças</h4>
                                                    <hr>
                                                    <table class="table">
                                                        <tbody>
                                                            <tr><td class="b-t-0"><a href="#">Configurações gerais</a></td></tr>
                                                            <tr><td><a href="#">Categorias de receita e despesa</a></td></tr>
                                                            <tr><td><a href="#">Formas de recebimento</a></td></tr>
                                                            <tr><td><a href="#">Formas de pagamento</a></td></tr>
                                                            <tr><td><a href="#">Cadastro de contas bancárias</a></td></tr>
                                                            <tr><td><a href="#">Cadastro de gateways</a></td></tr>
                                                            <tr><td><a href="#">Cadastro de contas financeiras</a></td></tr>
                                                            <tr><td><a href="#">Configurações do contas a pagar</a></td></tr>
                                                            <tr><td><a href="#">Configurações do contas a receber</a></td></tr>
                                                            <tr><td><a href="#">Configurações do envio de documentos</a></td></tr>
                                                            <tr><td><a href="#">Configurações dos marcadores no caixa</a></td></tr>
                                                            <tr><td><a href="#">Configurações dos marcadores nas contas a pagar</a></td></tr>
                                                            <tr><td><a href="#">Configurações dos marcadores nas contas a receber</a></td></tr>
                                                        </tbody>
                                                    </table>
                                                </p-tabPanel>
                                    
                                                <p-tabPanel>
                                                    <h4>Configurações de Serviços</h4>
                                                    <hr>
                                                    <table class="table">
                                                        <tbody>
                                                            <tr><td class="b-t-0"><a href="#">Configurações das ordens de serviço</a></td></tr>
                                                            <tr><td><a href="#">Configurações dos contratos</a></td></tr>
                                                            <tr><td><a href="#">Configurações do envio de documentos</a></td></tr>
                                                            <tr><td><a href="#">Configurações dos marcadores nas ordens de serviço</a></td></tr>
                                                            <tr><td><a href="#">Configurações dos marcadores nas notas de serviço</a></td></tr>
                                                            <tr><td><a href="#">Configurações dos marcadores nos contratos</a></td></tr>
                                                            <tr><td><a href="#">Cadastro de CNAEs</a></td></tr>
                                                            <tr><td><a href="#">Configurações de campos adicionais para ordens de serviço</a></td></tr>
                                                        </tbody>
                                                    </table>
                                                </p-tabPanel>
                                            </p-tabView>
                                        </div>


                                        <div class="col-4">
                                            <div class="col-12">
                                                <div class="widget widget-stats bg-gradient-teal m-b-10">
                                                    <div class="stats-icon stats-icon-lg"><i class="fa fa-globe fa-fw"></i>
                                                    </div>
                                                    <div class="stats-content">
                                                        <div class="stats-title">Armazenamento de dados</div>
                                                        <div class="stats-number">7,842,900</div>
                                                        <div class="stats-progress progress">
                                                            <div class="progress-bar" style="width: 70.1%;"></div>
                                                        </div>
                                                        <div class="stats-desc">Better than last week (70.1%)</div>
                                                    </div>
                                                </div>
                                            </div>
                                
                                            <div class="col-12">
                                                <div class="widget widget-stats bg-gradient-blue m-b-10">
                                                    <div class="stats-icon stats-icon-lg"><i
                                                            class="fa fa-dollar-sign fa-fw"></i></div>
                                                    <div class="stats-content">
                                                        <div class="stats-title">Armazenamento de arquivos</div>
                                                        <div class="stats-number">180,200</div>
                                                        <div class="stats-progress progress">
                                                            <div class="progress-bar" style="width: 40.5%;"></div>
                                                        </div>
                                                        <div class="stats-desc">Better than last week (40.5%)</div>
                                                    </div>
                                                </div>
                                            </div>
                                
                                            <div class="col-12">
                                                <div class="widget widget-stats bg-gradient-purple m-b-10">
                                                    <div class="stats-icon stats-icon-lg"><i
                                                            class="fa fa-archive fa-fw"></i></div>
                                                    <div class="stats-content">
                                                        <div class="stats-title">Usuários</div>
                                                        <div class="stats-number">38,900</div>
                                                        <div class="stats-progress progress">
                                                            <div class="progress-bar" style="width: 76.3%;"></div>
                                                        </div>
                                                        <div class="stats-desc">Better than last week (76.3%)</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>    
        </div>
    </div>
</div>