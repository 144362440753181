import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-separacao',
  templateUrl: './separacao.component.html',
  styleUrls: ['./separacao.component.css']
})
export class SeparacaoComponent implements OnInit {

  public activeIndex: number = 0;
  
  constructor() { }

  ngOnInit(): void {
  }

}
