import { Component, OnInit } from '@angular/core';
import { LayoutPrivateComponent } from '../suporte/layout-private/layout-private.component';
import { HttpClient } from '@angular/common/http';
import { DialogService } from 'primeng-lts/dynamicdialog';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { MercadoLivreDialogComponent } from './mercadolivre/mercadolivre.dialog.component';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-store',
  templateUrl: './store.component.html',
  styleUrls: ['./store.component.css'],
  providers: [DialogService]
})
export class StoreComponent implements OnInit {

  public activeIndex: number = 0;
  
  constructor(
    public app: LayoutPrivateComponent,
    public http: HttpClient,
    public dialog: DialogService,
    public toastr: ToastrService,
    public translate: TranslateService,
    private activatedRoute: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams
      .subscribe(params => {
        if (params.index) {
          try {
            this.activeIndex = params.index;
          }
          catch {
            this.activeIndex = 0;
          }
        }
      }
    );
  }

  public openDialogML(): void {
    const dialogRef = this.dialog.open(MercadoLivreDialogComponent, {
      width: '500px',
      height: '400px',
      transitionOptions: '1ms',
      showHeader: false
    });

    dialogRef.onClose.subscribe(result => {
    });
  }
}
