<div class="bs-dialog-header">
  <i class="fa fa-users"></i> FUNCIONÁRIOS <small> Gestão de Funcionários </small>
</div>

<div class="bs-dialog-main">
  <div class="bs-dialog-content">

    <!-- Carregando informações... -->
    <div class="modal-overlay" *ngIf="loading">
      <div class="alert alert-light fade show m-b-0 text-center" style="color: #222;">
        <i class="fas fa-circle-notch fa-spin"></i> 
      </div>
    </div>

    <div class="be-form">

      <div class="row">
        <div class="col-6">
          <label>Nome:</label>
          <input type="text" pInputText [(ngModel)]="data.nome" class="form-control">
        </div>
        <div class="col-2">
          <label>RG:</label>
          <input type="text" pInputText [(ngModel)]="data.rg" class="form-control">
        </div>
        <div class="col-2">
          <label>CPF:</label>
          <p-inputNumber [(ngModel)]="data.cpf" mode="decimal" [useGrouping]="false" maxlength="11"></p-inputNumber>
        </div>
        <div class="col-2">
          <label>CTPS: </label>
          <p-inputNumber [(ngModel)]="data.numeroCTPS" mode="decimal" [useGrouping]="false" maxlength="15"></p-inputNumber>
        </div>
      </div>
  
      <div class="row">
        <div class="col-6">
          <label>E-mail:</label>
          <input type="text" pInputText [(ngModel)]="data.email" class="form-control">
        </div>
        <div class="col-3">
          <label>Telefone:</label>
          <p-inputNumber type="text" [(ngModel)]="data.telefone" [useGrouping]="false"></p-inputNumber>
        </div>
      </div>

      <div class="row">
        <div class="col-3">
          <label>Gênero:</label>
          <select class="form-control" [(ngModel)]="data.genero">
            <option></option>
            <option value="masculino">Masculino</option>
            <option value="feminino">Feminino</option>
            <option value="ni">Não Informar</option>
          </select>
        </div>
        <div class="col-3">
          <label>Estado civil:</label>
          <select class="form-control" [(ngModel)]="data.estadoCivil">
            <option></option>
            <option value="solteiro">Solteiro(a)</option>
            <option value="casado">Casado(a)</option>
            <option value="divorciado">Divorciado(a)</option>
            <option value="viuvo">Viúvo(a)</option>
            <option value="uniaoEstavel">União estável</option>
            <option value="separadoJudicialmente">Separado(a) judicialmente</option>
            <option value="outros">Outros</option>
          </select>
        </div>
        <div class="col-3">
          <label>Data de nascimento:</label>
          <input type="date" [(ngModel)]="data.nascimento" class="form-control" pattern="\d{4}-\d{2}-\d{2}" placeholder="dd/MM/yyyy">
        </div>
      </div>

      <hr />
  
      <h4 class="m-t-30">Dados do contrato</h4>

      <div class="row m-t-20">
        <div class="col-3">
          <label>Status contratação:</label>
          <select class="form-control" [(ngModel)]="data.statusContratacao">
            <option></option>
            <option value="ativo">Ativo</option>
            <option value="inativo">Inativo</option>
            <option value="ferias">Em férias</option>
            <option value="afastado">Afastado</option>
            <option value="licencaMedica">Em licença médica</option>
            <option value="demitido">Demitido</option>
            <option value="periodoExperiencia">Em período de experiência</option>
            <option value="treinamento">Em treinamento</option>
            <option value="avisoPrevio">Em aviso prévio</option>
            <option value="contratacao">Em processo de contratação</option>
          </select>
        </div>
        <div class="col-3">
          <label>Data Admissao:</label>
          <input type="date" [(ngModel)]="data.admissao" class="form-control" pattern="\d{4}-\d{2}-\d{2}" placeholder="dd/MM/yyyy">
        </div>
        <div class="col-3">
          <label>Formação acadêmica:</label>
          <input type="text" pInputText [(ngModel)]="data.formacaoAcademica" class="form-control">
        </div>
        <div class="col-3">
          <label>Cargo:</label>
          <input type="text" pInputText [(ngModel)]="data.cargoDepartamento" class="form-control">
        </div>
      </div>

      <div class="row">
        
        <div class="col-3">
          <label>Salário:</label>
          <div class="p-inputgroup">
            <span class="p-inputgroup-addon">R$</span>
            <p-inputNumber [(ngModel)]="data.salario" mode="decimal" locale="pt-BR" [minFractionDigits]="2" [maxFractionDigits]="2" maxlength="17"></p-inputNumber>
          </div>
        </div>
      
        <div class="col-3">
          <label>Modelo de comissão: </label>
          <select [(ngModel)]="data.comissaoTipo.code" class="form-control">
            <option value="porcentagem">Porcentagem</option>
            <option value="valor">Valor fixo</option>
          </select>
        </div>
        <div class="col-3">
          <label>Comissão:</label>
          <div class="p-inputgroup">
            <span class="p-inputgroup-addon" *ngIf="data.comissaoTipo.code == 'valor'">R$</span>
            <span class="p-inputgroup-addon" *ngIf="data.comissaoTipo.code == 'porcentagem'">%</span>
            <p-inputNumber [(ngModel)]="data.comissao" mode="decimal" *ngIf="data.comissaoTipo.code == 'valor'" locale="pt-BR" [minFractionDigits]="2" [maxFractionDigits]="2" maxlength="17"></p-inputNumber>
            <p-inputNumber [(ngModel)]="data.comissao" mode="decimal" *ngIf="data.comissaoTipo.code == 'porcentagem'" [minFractionDigits]="2" [maxFractionDigits]="5" maxlength="15"></p-inputNumber>
          </div>
        </div>
      </div>

      <hr />
  
      <h4 class="m-t-30">Dados do bancário</h4>

      <div class="row m-t-20">
        <div class="col-3">
          <label>Banco:</label>
          <input type="text" pInputText [(ngModel)]="data.banco" class="form-control">
        </div>
        <div class="col-1">
          <label>Agência:</label>
          <input type="text" pInputText [(ngModel)]="data.agencia" class="form-control">
        </div>
        <div class="col-2">
          <label>Conta:</label>
          <input type="text" pInputText [(ngModel)]="data.conta" class="form-control">
        </div>
      </div>
  
      <hr />
  
      <h4 class="m-t-30">Endereço</h4>
      
      <div class="row m-t-20">
        <div class="col-2">
          <label>CEP:</label>
            <p-inputMask [(ngModel)]="data.endereco.cep" mask="99999-999" (onBlur)="carregarEndereco()"></p-inputMask>
        </div>
        <div class="col-6">
          <label>Endereço:</label>
          <input type="text" pInputText [(ngModel)]="data.endereco.logradouro" class="form-control" [readonly]="this.loadingAdd">
        </div>
        <div class="col-4">
          <label>Número:</label>
          <input type="text" pInputText [(ngModel)]="data.endereco.numero" class="form-control">
        </div>
      </div>
  
      <div class="row">
        <div class="col-3">
          <label>Complemento:</label>
          <input type="text" pInputText [(ngModel)]="data.endereco.complemento" class="form-control">
        </div>
        <div class="col-3">
          <label>Bairro:</label>
          <input type="text" pInputText [(ngModel)]="data.endereco.bairro" [readonly]="this.loadingAdd" class="form-control">
        </div>
        <div class="col-3">
          <label>Cidade:</label>
          <input type="text" pInputText [(ngModel)]="data.endereco.cidade" class="form-control" [readonly]="this.loadingAdd">
        </div>
        <div class="col-3">
          <label>Estado:</label>
          <input type="text" pInputText [(ngModel)]="data.endereco.estado" class="form-control" [readonly]="this.loadingAdd">
        </div>
      </div>
  
    </div>

  </div>
</div>

<div class="bs-dialog-footer">
  <div class="bs-footer-action-close">
    <button (click)="close()" class="btn btn-white" pTooltip="Esc" tooltipPosition="bottom">Fechar</button>
  </div>
  <div class="bs-footer-action">
    <button (click)="save()" [disabled]="recording" class="btn btn-white" pTooltip="Ctrl + Enter" tooltipPosition="bottom">Salvar e criar outro</button>
    <button (click)="saveClose()" [disabled]="recording" class="btn btn-primary" pTooltip="Ctrl + S" tooltipPosition="bottom">Salvar</button>
  </div>
</div>