<div class="bs-dialog-header">
  <i class="fa fa-gift m-r-10"></i> ANIVERSARIANTES
</div>

<div class="bs-dialog-main">
  <div class="bs-dialog-content">

  

  <!-- Carregando informações... -->
  <div class="modal-overlay" *ngIf="carregando">
    <div class="alert alert-light fade show m-b-0 text-center" style="color: #222;">
      <i class="fas fa-circle-notch fa-spin"></i> 
    </div>
  </div>
  <div *ngIf="data?.result?.length == 0" style="height: 451px;">
    <div class="alert alert-light fade show m-b-0 text-center" style="color: #222;">
      Nenhum aniversariante encontrado.
    </div>
  </div>
  <div style="height: 451px;">
    <table class="table table-sm text-uppercase" *ngIf="data?.result?.length > 0">
      <tbody>
        <tr>
          <th style="border-top: none;">Nome</th>
          <th style="border-top: none;" width="10%" class="text-center">Documento</th>
          <th style="border-top: none;" width="20%" class="text-center">Cidade</th>
          <th style="border-top: none;" width="10%" class="text-center">Telefone</th>
          <th style="border-top: none;" width="20%" class="text-center">Data nascimento</th>
          <th style="border-top: none;" width="10%" class="text-center">Status</th>
        </tr>
        <tr *ngFor="let d of data?.result">
          <td>{{d?.nome}}</td>
          <td class="text-center">{{d?.documento?.valor}}</td>
          <td class="text-center"><span *ngIf="d?.endereco?.cidade != null">{{d?.endereco?.cidade}} - {{d?.endereco?.estado}}</span></td>
          <td class="text-center">{{d?.telefone}}</td>
          <td class="text-center">{{d?.nascimento | date : 'dd/MM/yyyy' }}</td>
          <td class="text-center">{{d?.status?.value}}</td>
        </tr>
      </tbody>
    </table>
  </div>

</div>
</div>

<div class="bs-dialog-footer">
  <div class="bs-footer-action-close">
    <button (click)="close()" class="btn btn-white" pTooltip="Esc" tooltipPosition="bottom">Fechar</button>
  </div>
</div>