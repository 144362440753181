import { BaseEntity } from "../BaseEntity";
import { Endereco } from "../suporte/endereco";
import { KeyValue } from "../suporte/keyValue";

export class Funcionario extends BaseEntity {
	nome: string;
    email: string;
    telefone: string;
    rg: string;
    cpf: string;
    estadoCivil: string;
    genero: string;
    numeroCTPS: string;
    salario: number;
    comissao: number;
    comissaoTipo: KeyValue;
    formacaoAcademica: string;
    statusContratacao: string;
    cargoDepartamento: string;
    endereco: Endereco;
    admissao: Date;
    nascimento: Date;
    banco: string;
    agencia: string;
    conta: string;
}