<div id="page-container">

  <div class="login login-with-news-feed">

    <div class="news-feed">
      <div class="news-image" style="background-image: url('/assets/images/login-bg-11.jpeg')"></div>
      <div class="news-caption">
        <h4 class="caption-title"><b><span class="text-primary">Be</span>Sales</b></h4>
        <p>
          Experimente a simplicidade do <span class="text-primary">Be</span>Sales. Mais seguro, inteligente e fácil de utilizar!
        </p>
      </div>
    </div>


    <div class="right-content">

      <div class="login-header">
        <div class="brand">
          <span class="logo"></span> <span class="text-primary">Be</span>Sales
          <small>Inteligência para micro e pequenas empresas</small>
        </div>
        <div class="icon">
          <i class="fa fa-sign-in-alt"></i>
        </div>
      </div>


      <div class="login-content">
        <form [formGroup]="loginForm" (ngSubmit)="onSubmit()" class="margin-bottom-0">
          <div class="form-group m-b-15">
            <input autofocus required type="text" placeholder="E-mail" formControlName="email" class="form-control form-control-lg" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
          </div>
          <div class="form-group m-b-15">
            <input required type="password" placeholder="Senha" formControlName="password" class="form-control form-control-lg" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
          </div>
          <div class="login-buttons">
            <button class="btn btn-success btn-block btn-lg" [disabled]="loading">{{bt}}</button>
          </div>
          <div class="m-t-20 text-inverse text-center">
            Esqueceu a senha? Clique <a routerLink="/recuperar-senha">aqui</a> para recuperar.
          </div>
          <div class="m-t-5 m-b-40 p-b-40 text-inverse text-center">
            Ainda não tem um ID BeSales? Clique <a routerLink="/cadastro">aqui</a> para registrar.
          </div>
          <hr>
          <p class="text-center text-grey-darker mb-0">
            &copy; BeSales All Right Reserved {{ time | date: 'yyyy' }}
          </p>
        </form>
      </div>

    </div>

  </div>