import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap'; // REVER

import { DynamicDialogModule } from 'primeng-lts/dynamicdialog';
import { ButtonModule } from 'primeng-lts/button';
import { TooltipModule } from 'primeng-lts/tooltip';
import { InputTextModule } from 'primeng-lts/inputtext';
import { CalendarModule } from 'primeng-lts/calendar';
import { InputMaskModule } from 'primeng-lts/inputmask';
import { InputNumberModule } from 'primeng-lts/inputnumber';
import { FileUploadModule } from 'primeng-lts/fileupload';
import { AutoCompleteModule } from 'primeng-lts/autocomplete';
import { ChipsModule } from 'primeng-lts/chips';
import { TabViewModule } from 'primeng-lts/tabview';
import { PasswordModule } from 'primeng-lts/password';
import { DropdownModule } from 'primeng-lts/dropdown';
import { MenuModule } from 'primeng-lts/menu';
import { CheckboxModule } from 'primeng-lts/checkbox';
import { PanelModule } from 'primeng-lts/panel';
import { ChartModule } from 'primeng-lts/chart';
import { FullCalendarModule } from 'primeng-lts/fullcalendar';


import { JwtInterceptor, ErrorInterceptor } from './_helpers';
import { AppComponent } from './app.component';
import { LayoutPublicComponent } from './suporte/layout-public/layout-public.component';
import { LayoutPrivateComponent } from './suporte/layout-private/layout-private.component';
import { PageContentComponent } from './suporte/page-content/page-content.component';
import { CadastroComponent, CadastroComponentDialog } from './inicio/cadastro/cadastro.component';
import { ConfiguracaoComponent } from './inicio/configuracao/configuracao.component';
import { LoginComponent } from './inicio/login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ConfirmationComponent } from './suporte/confirmation/confirmation.component';
import { ClienteComponent, ClienteDialogComponent, ClienteDialogImportarComponent, ClienteDialogAniversarianteComponent } from './cadastro/cliente/cliente.component';
import { ProdutoComponent, ProdutoDialogComponent, ProdutoDialogImportarComponent } from './cadastro/produto/produto.component';
import { ServicoComponent, ServicoDialogComponent, ServicoDialogImportarComponent } from './cadastro/servico/servico.component';
import { AnuncioComponent } from './cadastro/anuncio/anuncio.component';
import { FornecedorComponent, FornecedorDialogComponent, FornecedorDialogImportarComponent } from './cadastro/fornecedor/fornecedor.component';
import { TransportadoraComponent, TransportadoraDialogComponent, TransportadoraDialogImportarComponent } from './cadastro/transportadora/transportadora.component';
import { VendedorComponent, VendedorDialogComponent, VendedorDialogImportarComponent } from './cadastro/vendedor/vendedor.component';
import { FuncionarioComponent, FuncionarioDialogComponent, FuncionarioDialogImportarComponent } from './cadastro/funcionario/funcionario.component';
import { CrmComponent, CrmDialogComponent } from './crm/acao/crm.component';
import { PdvComponent } from './venda/pdv/pdv.component';
import { PedidoComponent, PedidoDetalheDialogComponent, PedidoFaturaDialogComponent } from './venda/pedido/pedido.component';
import { PedidoDialogComponent } from './venda/pedido/pedido.dialog.component';
import { PropostaComponent, PropostaDialogImportarComponent } from './venda/proposta/proposta.component';
import { SeparacaoComponent } from './venda/separacao/separacao.component';
import { ExpedicaoComponent } from './venda/expedicao/expedicao.component';
import { DevolucaoComponent } from './venda/devolucao/devolucao.component';
import { ContaCorrenteComponent, ContaCorrenteDialogImportarComponent } from './financa/conta-corrente/conta-corrente.component';
import { LancamentoComponent, LancamentoDialogImportarComponent } from './financa/lancamento/lancamento.component';
import { TituloPagarComponent, TituloPagarDialogImportarComponent } from './financa/titulo-pagar/titulo-pagar.component';
import { TituloReceberComponent, TituloReceberDialogImportarComponent } from './financa/titulo-receber/titulo-receber.component';
import { CentroCustoComponent, CentroCustoDialogImportarComponent } from './financa/centro-custo/centro-custo.component';
import { GeralComponent } from './configuracao/geral/geral.component';
import { UsuarioComponent } from './configuracao/usuario/usuario.component';
import { IntegracaoComponent } from './configuracao/integracao/integracao.component';
import { AuditoriaComponent } from './configuracao/auditoria/auditoria.component';
import { RecuperarSenhaComponent } from './inicio/recuperar-senha/recuperar-senha.component';
import { CategoriaComponent, CategoriaDialogComponent } from './cadastro/categoria/categoria.component';
import { AgendaComponent, AgendaDialogComponent } from './home/agenda/agenda.component';
import { AgendaEventService } from './home/agenda/agenda.event.service';
import { MinhacontaComponent, MinhacontaPgtoDialogComponent } from './home/minhaconta/minhaconta.component';
import { UpgradeComponent } from './home/upgrade/upgrade.component';
import { ContratoComponent, ContratoDialogImportarComponent } from './servico/contrato/contrato.component';
import { OrdemComponent, OrdemDialogImportarComponent } from './servico/ordem/ordem.component';
import { CobrancaComponent, CobrancaDialogImportarComponent } from './servico/cobranca/cobranca.component';
import { StoreComponent } from './store/store.component';
import { PropostaDialogComponent } from './venda/proposta/proposta.dialog.component';
import { ContaCorrenteDialogComponent } from './financa/conta-corrente/conta-corrente.dialog.component';
import { LancamentoDialogComponent } from './financa/lancamento/lancamento.dialog.component';
import { TituloPagarDialogComponent } from './financa/titulo-pagar/titulo-pagar.dialog.component';
import { TituloReceberDialogComponent } from './financa/titulo-receber/titulo-receber.dialog.component';
import { CentroCustoDialogComponent } from './financa/centro-custo/centro-custo.dialog.component';
import { ContratoDialogComponent } from './servico/contrato/contrato.dialog.component';
import { OrdemDialogComponent } from './servico/ordem/ordem.dialog.component';
import { CobrancaDialogComponent } from './servico/cobranca/cobranca.dialog.component';
import { RecebidaComponent } from './pergunta/recebida/recebida.component';
import { ResponderComponent } from './pergunta/responder/responder.component';
import { ResponderDialogComponent } from './pergunta/responder/responder.dialog.component';
import { PrecificacaoAnuncioComponent } from './precificacao/anuncio/anuncio.component';
import { PrecificacaoAnuncioDialogComponent } from './precificacao/anuncio/anuncio.dialog.component';
import { PrecificacaoPerformanceComponent } from './precificacao/performance/performance.component';
import { ConcorrenteHomeComponent } from './concorrente/home/home.component';
import { ConcorrenteDetalheDialogComponent } from './concorrente/home/detalhe.dialog.component';
import { ConcorrenteDialogComponent } from './concorrente/configuracao/concorrente.dialog.component';
import { RelatorioHomeComponent } from './relatorio/home/home.component';
import { MercadoLivreDialogComponent } from './store/mercadolivre/mercadolivre.dialog.component';
import { MercadoLivreInitializeComponent } from './store/mercadolivre/mercadolivre.initialize.component';
import { NotificacaoService } from './_services/notificacao.service';
import { AniversarianteComponent } from './crm/aniversariante/aniversariante.component';

export function TranslationLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    LayoutPublicComponent,
    LayoutPrivateComponent,
    PageContentComponent,
    CadastroComponent, CadastroComponentDialog,
    ConfiguracaoComponent,
    LoginComponent,
    DashboardComponent,
    ConfirmationComponent,
    ClienteComponent, ClienteDialogComponent, ClienteDialogImportarComponent, ClienteDialogAniversarianteComponent,
    ProdutoComponent, ProdutoDialogComponent, ProdutoDialogImportarComponent,
    ServicoComponent, ServicoDialogComponent, ServicoDialogImportarComponent,
    AnuncioComponent,
    FornecedorComponent, FornecedorDialogComponent, FornecedorDialogImportarComponent,
    TransportadoraComponent, TransportadoraDialogComponent, TransportadoraDialogImportarComponent,
    VendedorComponent, VendedorDialogComponent, VendedorDialogImportarComponent,
    FuncionarioComponent, FuncionarioDialogComponent, FuncionarioDialogImportarComponent,
    CrmComponent, CrmDialogComponent,
    PdvComponent,
    PedidoComponent, PedidoFaturaDialogComponent, PedidoDetalheDialogComponent, PedidoDialogComponent,
    PropostaComponent, PropostaDialogComponent, PropostaDialogImportarComponent,
    SeparacaoComponent,
    ExpedicaoComponent,
    DevolucaoComponent,
    ContaCorrenteComponent, ContaCorrenteDialogComponent, ContaCorrenteDialogImportarComponent,
    LancamentoComponent, LancamentoDialogComponent, LancamentoDialogImportarComponent,
    TituloPagarComponent, TituloPagarDialogComponent, TituloPagarDialogImportarComponent,
    TituloReceberComponent, TituloReceberDialogComponent, TituloReceberDialogImportarComponent,
    CentroCustoComponent, CentroCustoDialogComponent, CentroCustoDialogImportarComponent,
    GeralComponent,
    UsuarioComponent,
    IntegracaoComponent,
    AuditoriaComponent,
    RecuperarSenhaComponent,
    CategoriaComponent, 
    CategoriaDialogComponent, 
    AgendaComponent, AgendaDialogComponent,
    MinhacontaComponent, MinhacontaPgtoDialogComponent,
    UpgradeComponent, 
    ContratoComponent, ContratoDialogComponent, ContratoDialogImportarComponent,
    OrdemComponent, OrdemDialogComponent, OrdemDialogImportarComponent,
    CobrancaComponent, CobrancaDialogComponent, CobrancaDialogImportarComponent,
    StoreComponent, MercadoLivreDialogComponent, MercadoLivreInitializeComponent,
    RecebidaComponent,
    ResponderComponent, ResponderDialogComponent,
    PrecificacaoAnuncioComponent,
    PrecificacaoAnuncioDialogComponent,
    PrecificacaoPerformanceComponent,
    ConcorrenteHomeComponent, ConcorrenteDialogComponent, ConcorrenteDetalheDialogComponent,
    RelatorioHomeComponent,
    AniversarianteComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    

    ChipsModule, TabViewModule, DynamicDialogModule, ButtonModule, TooltipModule, InputTextModule, CalendarModule, InputMaskModule, InputNumberModule, FileUploadModule,
    AutoCompleteModule, PasswordModule, DropdownModule, MenuModule, CheckboxModule, PanelModule, ChartModule, FullCalendarModule,

    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ToastrModule.forRoot(),
    HttpClientModule,
    TranslateModule.forRoot({
      loader: { provide: TranslateLoader, useFactory: TranslationLoaderFactory, deps: [HttpClient] }
    }),
    NgbModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    AgendaEventService, NotificacaoService,
    Title
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
