import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { environment } from '@environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { AutoComplete } from 'primeng-lts/autocomplete';
import { DialogService } from 'primeng-lts/dynamicdialog';
import { InputNumber } from 'primeng-lts/inputnumber';
import { Contato } from 'src/app/_entity/cadastro/contato';
import { Produto } from 'src/app/_entity/cadastro/produto';
import { Vendedor } from 'src/app/_entity/cadastro/vendedor';
import { Pedido } from 'src/app/_entity/venda/pedido';
import { PedidoItem } from 'src/app/_entity/suporte/pedidoItem';
import { AuthenticationService } from 'src/app/_services';
import { BaseService } from 'src/app/_services/base.service';
import { LayoutPrivateComponent } from 'src/app/suporte/layout-private/layout-private.component';
import { PagamentoForma } from 'src/app/_entity/suporte/pagamentoForma';
import { PedidoOrigem } from 'src/app/_entity/suporte/pedidoOrigem';

@Component({
  selector: 'app-pdv',
  templateUrl: './pdv.component.html',
  styleUrls: ['./pdv.component.css'],
  providers: [DialogService]
})
export class PdvComponent extends BaseService implements OnInit {

  public recording: boolean = false;

  public currentUser: any;
  public margin: string = '';
  public time: Date = new Date();
  public isFullScreen: boolean = false;
  
  public cliente: Contato;
  public clientes: Contato[] = [];
  public vendedor: Vendedor;
  public vendedores: Vendedor[] = [];

  public produto: Produto;
  public produtos: Produto[] = [];

  public pedido: Pedido;
  public pedidoItem: PedidoItem;
  public pedidoItemApresentacao: PedidoItem;

  // Variaveis para controlar modal de acoes
  public modal: boolean = false;
  public modalQte: boolean = false;
  public modalCliente: boolean = false;
  public modalDesconto: boolean = false;
  public modalVendedor: boolean = false;
  public modalCancelarItem: boolean = false;
  public modalFinalizarVenda: boolean = false;
  public modalFinalizarVendaResumo: boolean = false;

  @ViewChild('qteInput') qteInput!: InputNumber;
  @ViewChild('qteParcelasInput') qteParcelasInput!: InputNumber;
  @ViewChild('valorRecebidoInput') valorRecebidoInput!: InputNumber;
  @ViewChild('descontoInput') descontoInput!: InputNumber;
  @ViewChild('cancelarItemInput') cancelarItemInput!: InputNumber;
  @ViewChild('acClienteInput') acClienteInput!: AutoComplete;
  @ViewChild('acVendedorInput') acVendedorInput!: AutoComplete;
  @ViewChild('autoCompleteInput') autoCompleteInput!: AutoComplete;

  constructor(
    public app: LayoutPrivateComponent,
    public http: HttpClient,
    public dialog: DialogService,
    public toastr: ToastrService,
    public translate: TranslateService,
    private elementRef: ElementRef,
    private authenticationService: AuthenticationService) { 

      super('pdv', app, http, dialog, toastr, translate);
  }

  ngOnInit(): void {
    // Usuario corrente para mostrar no PDV
    this.currentUser = this.authenticationService.currentUserValue;
    // Relogio no PDV
    setInterval(() => {
      this.time = new Date();
    }, 1000);

    this.createData();
  }
  
  ngAfterViewInit() {
    setTimeout(() => {
      // Chama o método focusInput() do componente Autocomplete
      this.autoCompleteInput.focusInput(); 
    }, 0);

    // No carrinho conforme add itens, scroll para o fim da pagina
    this.scrollDown();
  }
  
  public beforeSave(callback: (valid: boolean) => void): void {

    if (this.cliente != null) {
      this.pedido.idCliente = this.cliente._id;
      this.pedido.nmCliente = this.cliente.nome;
    }
    if (this.vendedor != null) {
      this.pedido.idVendedor = this.vendedor._id;
      this.pedido.nmVendedor = this.vendedor.nome;
    }

    this.data = this.pedido;

    if (!this.data || !this.data.pedidoItens || this.data.pedidoItens.length == 0) {
      this.toastr.error('Carrinho está vazio');
      callback(false);
    }
    else {
      callback(true);
    }
  }

  public createData(): void {
    this.produto = new Produto;
    this.pedido = new Pedido;
    this.pedido.idUsuario = this.currentUser._id;
    this.pedido.nmUsuario = this.currentUser.nome;
    this.pedido.pedidoOrigem = PedidoOrigem.PDV;
    this.pedidoItem = new PedidoItem;
    this.pedidoItemApresentacao = new PedidoItem;

    this.pedido.valorSubTotal = 0;
    this.pedido.valorDesconto = 0;
    this.pedido.totalItens = 0;
    this.pedido.valorRecebido = 0;
    this.pedido.valorTroco = 0;
    this.pedido.qteParcelas = 1;
    this.pedido.pedidoItens = [];
    this.pedido.pagamentoForma = null;

    this.pedidoItem.quantidade = 1.0;
    this.pedidoItem.valorUnitario = 0;
    this.pedidoItem.valorTotal = 0;
    this.pedidoItem.produto = this.produto;

    this.pedidoItemApresentacao.quantidade = 0;
    this.pedidoItemApresentacao.valorUnitario = 0;
    this.pedidoItemApresentacao.valorTotal = 0;
    this.pedidoItemApresentacao.produto = this.produto;

    this.modal = false;
    this.modalQte = false;
    this.modalCliente = false;
    this.modalDesconto = false;
    this.modalVendedor = false;
    this.modalCancelarItem = false;
    this.modalFinalizarVenda = false;
    this.modalFinalizarVendaResumo = false;

    this.onSelectPedidoQte();
  }

  public setarFormaPagamento(code: string): void {
    switch (code) {
      case PagamentoForma.PIX.code:
        this.pedido.pagamentoForma = PagamentoForma.PIX;
        break;
      case PagamentoForma.DINHEIRO.code:
        this.pedido.pagamentoForma = PagamentoForma.DINHEIRO;
        break;
      case PagamentoForma.CARTAO_CREDITO.code:
        this.pedido.pagamentoForma = PagamentoForma.CARTAO_CREDITO;
        break;
      case PagamentoForma.CARTAO_DEBITO.code:
        this.pedido.pagamentoForma = PagamentoForma.CARTAO_DEBITO;
        break;
      case PagamentoForma.OUTROS.code:
        this.pedido.pagamentoForma = PagamentoForma.OUTROS;
        break;
      default:
        this.pedido.pagamentoForma = null;
        break;
    }
  }

  public cancelarItem(): void {
    if (this.pedido && this.pedido.itemNumero) {
      if (this.itemExiste(this.pedido.pedidoItens, this.pedido.itemNumero - 1)) {
        this.pedido.pedidoItens.splice(this.pedido.itemNumero - 1, 1);
        this.calcularValorTotal();
        this.toastr.success('', 'item cancelado com sucesso!');
      }
      else {
        this.toastr.error('', 'Item não encontrado!');
      }

      this.pedido.itemNumero = null;
    }
  }

  public onSelectProduto(): void {
    this.onSelectPedidoQte();
    this.pedidoItem.produto = this.produto;
    this.pedidoItem.idProduto =  this.produto._id;
    this.pedidoItem.nomeProduto = this.produto.nome;
    this.pedidoItem.produtoSku = this.produto.sku;
    this.pedidoItem.produtoImg = this.produto.imagens == null || this.produto.imagens.length == 0 ? null : this.produto.imagens[0].url;
    this.pedidoItem.valorUnitario = this.produto.valorVenda;
    this.pedido.pedidoItens.push(this.pedidoItem);

    this.pedidoItemApresentacao = this.pedidoItem;

    this.autoCompleteInput.focusInput();
    this.produtos = [];
    this.produto = new Produto;
    this.pedidoItem = new PedidoItem;

    this.pedidoItem.quantidade = 1.0;
    this.produto.valorVenda = 0.0;
    
    this.calcularValorTotal();
    this.onSelectPedidoQte();
    this.scrollDown();
  }

  public calcularValorTotal(): void {
    this.pedido.valorSubTotal = 0;

    this.pedido.pedidoItens.forEach(item => {
      this.pedido.valorSubTotal = this.pedido.valorSubTotal + item.valorTotal;
    });
    
    if (this.pedido.valorDesconto > this.pedido.valorSubTotal) {
      this.toastr.error('', 'Desconto não pode ser maior que o valor total');
      this.pedido.valorDesconto = 0;
    }
    else {
      this.pedido.valorTotal = this.pedido.valorSubTotal - this.pedido.valorDesconto;
    }

    this.pedido.totalItens = this.pedido.pedidoItens.length;
  }

  // Seta o valor ao momento da digitacao, assim
  // nao perde o valor ao precionar enter e sair da tela
  public modalQteSetarValor(inputValue: any): void {
    this.pedidoItem.quantidade = inputValue.value;
  }

  // Modal de quantidade ao prescionar enter fecha o modal
  // e envia o focus para a pesquisa de produto
  public modalQteOnKeyPress(event?: KeyboardEvent): void {
    if (event == null || event.key === 'Enter') {
      this.closeModal();
      this.focusProduto();
    }
  }
  
  // Seta o valor ao momento da digitacao, assim
  // nao perde o valor ao precionar enter e sair da tela
  public modalCancelarItemSetarValor(inputValue: any): void {
    this.pedido.itemNumero = inputValue.value;
  }

  public modalCancelarItemOnKeyPress(event?: KeyboardEvent): void {
    if (event == null || event.key === 'Enter') {
      this.cancelarItem();
      this.closeModal();
      this.focusProduto();
    }
  }

  // Seta o valor ao momento da digitacao, assim
  // nao perde o valor ao precionar enter e sair da tela
  public modalDescontoSetarValor(inputValue: any): void {
    this.pedido.valorDesconto = inputValue.value;
  }

  public modalDescontoOnKeyPress(event?: KeyboardEvent): void {
    if (event == null || event.key === 'Enter') {
      this.calcularValorTotal();
      this.closeModal();
      this.focusProduto();
    }
  }

  // Seta o valor ao momento da digitacao, assim
  // nao perde o valor ao precionar enter e sair da tela
  public modalValorRecebidoSetarValor(inputValue: any): void {
    this.pedido.valorRecebido = inputValue.value;
    this.pedido.valorTroco = inputValue.value - this.pedido.valorTotal;
  }

  public modalParcelasSetarValor(inputValue: any): void {
    this.pedido.qteParcelas = inputValue.value;
  }

  public modalValorRecebidoOnKeyPress(event?: KeyboardEvent): void {
    if (event == null || event.key === 'Enter') {
      if (this.pedido.pagamentoForma.code == PagamentoForma.DINHEIRO.code) {
        if (this.pedido.valorRecebido < this.pedido.valorTotal) {
          this.toastr.error('Valor recebido não pode ser menor que o valor total');
        }
        else {
          this.modalFinalizarVenda = false;
          this.modalFinalizarVendaResumo = true;
        }
      }
      else if (this.pedido.pagamentoForma.code == PagamentoForma.CARTAO_CREDITO.code) {
        if (this.pedido.qteParcelas < 1) {
          this.toastr.error('Número de parcelas não pode ser menor que 1');
        }
        else {
          this.modalFinalizarVenda = false;
          this.modalFinalizarVendaResumo = true;
        }
      }
    }
  }

  // Envia o focus para a pesquisa de produto
  public focusProduto(): void {
    this.autoCompleteInput.focusInput();
  }

  // Calcula o preco do produto com quantidade
  public onSelectPedidoQte(): void {
    this.pedidoItem.valorTotal = this.pedidoItem.quantidade * this.produto.valorVenda;
  }

  // Autocompletes para produtos e para os modals
  public autocomplete(event): void {
    this.http.get<any>(`${environment.apiUrl}/produto/pesquisar-nome-codigo/${event.query}`).subscribe(
      data => {
        this.produtos = data;
      },
      error => {
      }
    );
  }

  public acCliente(event): void {
    this.http.get<any>(`${environment.apiUrl}/cliente/pesquisar-nome-codigo/${event.query}`).subscribe(
      data => {
        this.clientes = data;
      },
      error => {
      }
    );
  
  }
  public acVendedor(event): void {
    this.http.get<any>(`${environment.apiUrl}/vendedor/pesquisar-nome-codigo/${event.query}`).subscribe(
      data => {
        this.vendedores = data;
      },
      error => {
      }
    );
  }

  // Acoes do modal
  public openModal(funcionalidade: string): void {
    this.modalQte = false;
    this.modalCliente = false;
    this.modalDesconto = false;
    this.modalVendedor = false;
    this.modalCancelarItem = false;
    this.modalFinalizarVenda = false;
    
    this.modal = true;
    this[funcionalidade] = true;
  }
  
  public closeModal(): void {
    this.modalQte = false;
    this.modalCliente = false;
    this.modalDesconto = false;
    this.modalVendedor = false;
    this.modalCancelarItem = false;
    this.modalFinalizarVenda = false;
    this.modal = false;

    setTimeout(() => {
      this.focusProduto();
    }, 300);
  }

  public itemExiste<T>(array: T[], indice: number): boolean {
    return indice >= 0 && indice < array.length;
  }

  public scrollDown() {
    setTimeout(() => {
      const element = this.elementRef.nativeElement.querySelector('.pos-sidebar-body');
      element.scrollTop = element.scrollHeight;
    }, 300);
  }
  
  @HostListener('document:fullscreenchange', ['$event'])
  @HostListener('document:webkitfullscreenchange', ['$event'])
  @HostListener('document:mozfullscreenchange', ['$event'])
  @HostListener('document:MSFullscreenChange', ['$event'])

  fullscreenmodes(event: any) {
    this.chkScreenMode();
  }
  chkScreenMode() {
    if (document.fullscreenElement) {
      this.isFullScreen = true;
      this.margin = '0 0 0 0';
    } 
    else {
      this.isFullScreen = false;
      this.margin = '0 0 0 220px';
    }
  }

  openFullscreen() {
    const docElmWithBrowsersFullScreenFunctions = document.getElementById('full') as HTMLElement & {
      mozRequestFullScreen(): Promise<void>;
      webkitRequestFullscreen(): Promise<void>;
      msRequestFullscreen(): Promise<void>;
    };
    
    if (docElmWithBrowsersFullScreenFunctions.requestFullscreen) {
      docElmWithBrowsersFullScreenFunctions.requestFullscreen();
    } else if (docElmWithBrowsersFullScreenFunctions.mozRequestFullScreen) { /* Firefox */
      docElmWithBrowsersFullScreenFunctions.mozRequestFullScreen();
    } else if (docElmWithBrowsersFullScreenFunctions.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
      docElmWithBrowsersFullScreenFunctions.webkitRequestFullscreen();
    } else if (docElmWithBrowsersFullScreenFunctions.msRequestFullscreen) { /* IE/Edge */
      docElmWithBrowsersFullScreenFunctions.msRequestFullscreen();
    }
  }

  closefullscreen() {
    const docWithBrowsersExitFunctions = document as Document & {
      mozCancelFullScreen(): Promise<void>;
      webkitExitFullscreen(): Promise<void>;
      msExitFullscreen(): Promise<void>;
    };
    if (docWithBrowsersExitFunctions.exitFullscreen) {
      docWithBrowsersExitFunctions.exitFullscreen();
    } else if (docWithBrowsersExitFunctions.mozCancelFullScreen) { /* Firefox */
      docWithBrowsersExitFunctions.mozCancelFullScreen();
    } else if (docWithBrowsersExitFunctions.webkitExitFullscreen) { /* Chrome, Safari and Opera */
      docWithBrowsersExitFunctions.webkitExitFullscreen();
    } else if (docWithBrowsersExitFunctions.msExitFullscreen) { /* IE/Edge */
      docWithBrowsersExitFunctions.msExitFullscreen();
    }
  }

  @HostListener('window:keydown.f10', ['$event'])
  scFullSreen(event: KeyboardEvent): void {
    if (this.isFullScreen){
      this.closefullscreen();
    }
    else {
      this.openFullscreen();
    }
  }

  @HostListener('window:keydown.f6', ['$event'])
  scInsereQte(event?: KeyboardEvent): void{
    this.openModal('modalQte');
    setTimeout(() => {
      this.qteInput.input.nativeElement.focus();
      this.qteInput.input.nativeElement.select();
    }, 0);
  }
  @HostListener('window:keydown.f2', ['$event'])
  scCliente(event?: KeyboardEvent): void{
    this.openModal('modalCliente');
    setTimeout(() => {
      this.acClienteInput.focusInput();
    }, 0);
  }
  @HostListener('window:keydown.f5', ['$event'])
  scVendedor(event?: KeyboardEvent): void{
    this.openModal('modalVendedor');
    setTimeout(() => {
      this.acVendedorInput.focusInput();
    }, 0);
  }
  @HostListener('window:keydown.f3', ['$event'])
  scCancelarItem(event?: KeyboardEvent): void{
    this.openModal('modalCancelarItem');
    setTimeout(() => {
      this.cancelarItemInput.input.nativeElement.focus();
      this.cancelarItemInput.input.nativeElement.select();
    }, 0);
  }
  @HostListener('window:keydown.f4', ['$event'])
  scDesconto(event?: KeyboardEvent): void{
    this.openModal('modalDesconto');
    setTimeout(() => {
      this.pedido.valorDesconto = 0;
      this.descontoInput.input.nativeElement.focus();
      this.descontoInput.input.nativeElement.select();
    }, 0);
  }
  @HostListener('window:keydown.f9', ['$event'])
  scFinalizarVenda(event?: KeyboardEvent): void{
    if (this.pedido.pedidoItens && this.pedido.pedidoItens.length > 0) {
      this.setarFormaPagamento(null);
      this.openModal('modalFinalizarVenda');
      this.autoCompleteInput.inputEL.nativeElement.blur();
    }
  }

  @HostListener('window:keydown.p', ['$event'])
  scFinalizarVendaPix(event?: KeyboardEvent): void{
    if (this.modalFinalizarVenda) {
      this.setarFormaPagamento(PagamentoForma.PIX.code);
      this.modalFinalizarVenda = false;
      this.modalFinalizarVendaResumo = true;
    }
  }

  @HostListener('window:keydown.m', ['$event'])
  scFinalizarVendaDinheiro(event?: KeyboardEvent): void{
    if (this.modalFinalizarVenda) {
      this.pedido.valorRecebido = 0;
      this.pedido.valorTroco = 0;
      this.setarFormaPagamento(PagamentoForma.DINHEIRO.code);
      setTimeout(() => {
        this.valorRecebidoInput.input.nativeElement.focus();
        this.valorRecebidoInput.input.nativeElement.select();
      }, 300);
    }
  }

  @HostListener('window:keydown.c', ['$event'])
  scFinalizarVendaCc(event?: KeyboardEvent): void{
    if (this.modalFinalizarVenda) {
      this.pedido.qteParcelas = 1;
      this.pedido.valorTroco = 0;
      this.setarFormaPagamento(PagamentoForma.CARTAO_CREDITO.code);
      setTimeout(() => {
        this.qteParcelasInput.input.nativeElement.focus();
        this.qteParcelasInput.input.nativeElement.select();
      }, 300);
    }
  }

  @HostListener('window:keydown.d', ['$event'])
  scFinalizarVendaCd(event?: KeyboardEvent): void{
    if (this.modalFinalizarVenda) {
      this.setarFormaPagamento(PagamentoForma.CARTAO_DEBITO.code);
      this.modalFinalizarVenda = false;
      this.modalFinalizarVendaResumo = true;
    }
  }

  @HostListener('window:keydown.o', ['$event'])
  scFinalizarVendaOutros(event?: KeyboardEvent): void{
    if (this.modalFinalizarVenda) {
      this.setarFormaPagamento(PagamentoForma.OUTROS.code);
      this.modalFinalizarVenda = false;
      this.modalFinalizarVendaResumo = true;
    }
  }

  @HostListener('window:keydown.v', ['$event'])
  scFinalizarVendaVoltar(event?: KeyboardEvent): void{
    if (this.modalFinalizarVenda || this.modalFinalizarVendaResumo) {
      this.setarFormaPagamento(null);
      this.modalFinalizarVenda = true;
      this.modalFinalizarVendaResumo = false;
    }
  }

  @HostListener('window:keydown.control.f', ['$event'])
  scFecharModal(event?: KeyboardEvent): void{
    this.closeModal();
  }
  
  @HostListener('window:keydown.control.enter', ['$event'])
  scConfirmaVendaModal(event?: KeyboardEvent): void{
    try {
      if (!this.recording && this.modalFinalizarVendaResumo) {
        this.recording = true;
        this.beforeSave(
            valid => {
                if (valid) {
                    this.http.post<any>(`${environment.apiUrl}/${this.mapping}/save`, this.data).subscribe(
                        data => {                    
                            this.afterSave(data);
                            this.recording = false;
                            this.toastr.success('Venda realizada com sucesso');
                            this.createData();
                            this.focusProduto();
                        },
                        error => {
                          this.toastr.error(error);
                        }
                    );
                }
            }
        );
      }
  }
  catch (e) {
      this.recording = false;
      this.toastr.error(e.message);
  }
  }
}