import { HttpClient } from '@angular/common/http';
import { Component, HostListener, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng-lts/dynamicdialog';
import { Servico } from 'src/app/_entity/cadastro/servico';
import { KeyValue } from 'src/app/_entity/suporte/keyValue';
import { BaseServiceDialog } from 'src/app/_services/base.service.dialog';

@Component({
  selector: 'app-precificacao-anuncio.dialog',
  templateUrl: './anuncio.dialog.component.html'
})
export class PrecificacaoAnuncioDialogComponent extends BaseServiceDialog implements OnInit {

  public data: Servico = null;

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public http: HttpClient,
    public toastr: ToastrService,
    public translate: TranslateService) {

    super('servico', http, toastr, translate, ref, config.data);

    this.datas = config.data;
    this.data = this.datas.data;
  }

  ngOnInit(): void {
    // Caso seja uma iniciativa ja criada para edicao, busca a ultima
    // versao no banco de dados e apos inicializa os autocompletes
    if (this.data && this.data._id) {
      this.find(this.data._id);
    }
    // Caso seja uma nova apenas inicializa os autocompletes
    else {
      this.createData();
    }
  }

  public beforeSave(callback: (valid: boolean) => void): void {
    if (!this.data || !this.data.nome) {
      this.toastr.error('Campo Nome deve ser preenchido.');
      callback(false);
    }
    else {
      callback(true);
    }
  }

  public createData(): void {
    if (this.data == null) {
      this.data = new Servico;
      this.data.unidadeMedida = new KeyValue;
      this.data.unidadeMedida.code = 'hr';
    }
  }

  @HostListener('window:keydown.control.enter', ['$event'])
  keSave(event: KeyboardEvent) {
    this.save();
  }

  @HostListener('window:keydown.control.s', ['$event'])
  keSaveClose(event: KeyboardEvent) {
    this.saveClose();
  }

  @HostListener('window:keydown.esc', ['$event'])
  keClose(event: KeyboardEvent) {
    this.dialogRef.close();
  }
}