<div class="bs-dialog-header">
  <i class="fa fa-file-invoice"></i> MERCADOLIVRE <small> Integração com Mercado Livre </small>
</div>

<div class="bs-dialog-main">
  <div class="bs-dialog-content">
    <h1 class="paragraph-header" style="margin: 58px 0 40px;">Configurar sua conta do MercadoLivre para integrar com BeSales.</h1>
    <small>* BeSales nunca terá sua senha do MercadoLivre</small>
  </div>

  <div class="row">
    <div class="col-6">
      <img style="height: 100px;" src="../../../assets/images/ml.svg">
    </div>
    <div class="col-6">
      <a [href]="mlUrl" class="btn btn-primary btn-lg">Adicionar uma conta do MercadoLivre <i class="fa fa-arrow-circle-right"></i></a>
    </div>
  </div>
</div>

<div class="bs-dialog-footer">
  <div class="bs-footer-action-close">
    <button (click)="close()" class="btn btn-white" pTooltip="Esc" tooltipPosition="bottom">Fechar</button>
  </div>
  <!-- <div class="bs-footer-action">
    <button class="btn btn-primary" pTooltip="Instalar App" tooltipPosition="bottom">Instalar</button>
  </div> -->
</div>