export class Endereco {

	cep: string;
	logradouro: string;
	numero: string;
	complemento: string;
	bairro: string;
	cidade: string;
	estado: string;

	constructor(
	) { }
}
