export class PedidoOrigem {

	public static PDV = new PedidoOrigem("pdv", "Frente de Caixa PDV");
    public static PEDIDO_VENDA = new PedidoOrigem("pedido_venda", "Pedido de Venda");
    
    public code: string;
    public value: string;

    constructor(code: string, value: string) {
        this.code = code;
        this.value = value;
    }
}
