import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-aniversariante',
  templateUrl: './aniversariante.component.html',
  styleUrls: ['./aniversariante.component.css']
})
export class AniversarianteComponent implements OnInit {

  public data: any;
  public tipo: string = 'mes';
  public carregando: boolean = false;

  constructor(public http: HttpClient) {

  }

  ngOnInit(): void {
    this.listarAniversariante(this.tipo);
  }

  public listarAniversariante(tipo: string) {
    this.tipo = tipo;
    this.carregando = true;
    this.http.get<any>(`${environment.apiUrl}/cliente/aniversariante-` + tipo).subscribe(
      data => {
        this.data = data;
        this.carregando = false;
      },
      error => {
        this.carregando = false;
      }
    );
  }
}
