import { Organizacao } from "../cadastro/organizacao";
import { Usuario } from "../cadastro/usuario";

export class OnBoarding {

	private user: Usuario;
	private organizacao: Organizacao;

	constructor(
	) { }
}