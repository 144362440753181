import { Component, HostListener, OnInit } from '@angular/core';
import { BaseServiceDialog } from 'src/app/_services/base.service.dialog';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng-lts/dynamicdialog';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-detalhe.dialog',
  templateUrl: './detalhe.dialog.component.html'
})
export class ConcorrenteDetalheDialogComponent extends BaseServiceDialog implements OnInit {

  public lbVendas: any[];
  public dsVendas: any[];
  public chtVendas: any;

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public dialogService: DialogService,
    public http: HttpClient,
    public toastr: ToastrService,
    public translate: TranslateService) {

      super('integracao/mercadolibre/concorrente', http, toastr, translate, ref, config.data);
  }

  ngOnInit(): void {
    this.loading = true;
    this.http.get<any>(`${environment.apiUrl}/${this.mapping}/ds-vendas/${this.config.data.seller.id}`).subscribe(
        data => {
          this.dsVendas = data.values;
          this.lbVendas = data.labels;
          this.buildGraficoVendas();
          this.loading = false;
        },
        error => {
          this.loading = false;
          this.toastr.error('Não foi possível carregar o registro');
        }
    );
  }

  public buildGraficoVendas() {
    this.chtVendas = {
      labels: this.lbVendas,
      datasets: [
        {
          label: '',
          backgroundColor: '#00a1e0',
          borderColor: '#018dc4',
          borderWidth: 1,
          data: this.dsVendas
        }
      ]
    }
  }

  @HostListener('window:keydown.esc', ['$event'])
  keClose(event: KeyboardEvent) {
    this.dialogRef.close();
  }
}