import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from './authentication.service';
import { DynamicDialogRef } from 'primeng-lts/dynamicdialog';

export class Upload {
    total: Number;
    carregado: boolean;
}

@Injectable({ providedIn: 'root' })
export abstract class BaseServiceImportDialog {

    constructor(
        public mapping: string,
        public http: HttpClient,
        public toastr: ToastrService,
        public translate: TranslateService,
        public authService: AuthenticationService,
        public ref: DynamicDialogRef, 
        public data: Upload) {
    }

    public file: File;
    public registro: any = null;
    public carregando: boolean = false;
    public downloading: boolean = false;

    onFilechange(event: any) {
        this.file = event.target.files[0];
        this.registro = null;
        this.upload();
    }

    upload() {
        if (this.file) {
            this.carregando = true;
            this.uploadfile(this.file, 'fileUpload').subscribe(
                resp => {
                    this.registro = resp;
                    this.carregando = false;
                },
                error => {
                    this.carregando = false;
                })
        }
    }

    confirmar() {
        if (this.registro != null) {
            this.carregando = true;
            this.http.post(`${environment.apiUrl}/${this.mapping}/loadSheetData`, this.registro).subscribe(
                resp => {
                    this.toastr.success('', 'Registros carregados com sucesso!');
                    this.data.carregado = true;
                    this.close();
                },
                error => {
                    this.carregando = false;
                })
        }
    }

    public uploadfile(file: File, local: string) {
        let formParams = new FormData();
        formParams.append('file', file)
        return this.http.post(`${environment.apiUrl}/${this.mapping}/` + local, formParams)
    }

    public removerLinha(linha: Number) {
        this.registro.uploadDetails.splice(linha, 1);
    }

    public download(): void {

        this.downloading = true;

        let currentUser = this.authService.currentUserValue;
        const url = `${environment.apiUrl}/${this.mapping}/sheetSample`;

        const options = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${currentUser.token}`,
                'Account': `${currentUser.selected_account}`
            },
            
        };

        fetch(url, options)
            .then(response => {
                this.downloading = false;
                if (!response.ok) {
                    this.toastr.error('Não foi possível gerar a planilha');
                }
                
                return response.blob();
            })
            .then(blob => {
                // Cria um URL temporário para o blob
                const blobUrl = URL.createObjectURL(blob);

                // Cria um link <a> para iniciar o download
                const link = document.createElement('a');
                link.href = blobUrl;
                link.setAttribute('download', 'besales-exemplo-' + this.mapping + '-' + this.formatDate(new Date()) + '.xlsx');
                document.body.appendChild(link);

                // Simula um clique no link para iniciar o download
                link.click();

                // Remove o link do DOM
                document.body.removeChild(link);

                // Revoga o URL temporário do blob
                URL.revokeObjectURL(blobUrl);
            })
            .catch(error => {
                this.toastr.error('Não foi possível gerar a planilha');
            });
    }

    public close() {
        this.ref.close();
    }

    public formatDate(date: Date): string {
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Os meses são indexados a partir de zero
        const year = date.getFullYear().toString();
    
        return `${day}-${month}-${year}`;
    }
}
