import { Component, HostListener, OnInit } from '@angular/core';
import { BaseServiceDialog } from 'src/app/_services/base.service.dialog';
import { MercadoLibrePergunta } from 'src/app/_entity/integracao/mercadoLibrePergunta';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng-lts/dynamicdialog';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '@environments/environment';
import { QuestionAnswer } from 'src/app/_entity/integracao/questionAnswer';

@Component({
  selector: 'app-responder.dialog',
  templateUrl: './responder.dialog.component.html'
})
export class ResponderDialogComponent extends BaseServiceDialog implements OnInit {

  public data: MercadoLibrePergunta = null;
  public user: any = null;
  public anotacao: any = null;
  public perguntasAnteriores: MercadoLibrePergunta[] = null;

  public recording: boolean = false;
  public editarAnotacao: boolean = false;

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public dialogService: DialogService,
    public http: HttpClient,
    public toastr: ToastrService,
    public translate: TranslateService) {

      super('integracao/mercadolibre/pergunta', http, toastr, translate, ref, config.data);

      this.datas = config.data;
      this.data = this.datas.data;
  }

  ngOnInit(): void {
    // Caso seja uma iniciativa ja criada para edicao, busca a ultima
    // versao no banco de dados e apos inicializa os autocompletes
    if (this.data && this.data._id) {
      this.find(this.data._id);
    }
    // Caso seja uma nova apenas inicializa os autocompletes
    else {
      this.toastr.error('Pergunta não informada');
    }
  }

  public afterFind(): void {
    if (this.data.answer  == null) {
      this.data.answer = new QuestionAnswer;
    }

    this.aditionalFind('integracao/mercadolibre/produto/anotacao/' + this.data.item_id, (data: any) => {
      this.anotacao = data;
    });
    this.aditionalFind('integracao/mercadolibre/pergunta/anteriores/' + this.data.item_id + '/' + this.data.from.id, (data: any) => {
      this.perguntasAnteriores = data;
    });
    this.aditionalFind('integracao/mercadolibre/suporte/usuario/' + this.data.from.id, (data: any) => {
      this.user = data;
    });
  }

  public habilitarAnotacao(acao: boolean): void {
    if (this.anotacao == null) {
      this.anotacao = new Object;
    }
    this.editarAnotacao = acao;
  }

  public responder(): void {
    try {
      this.recording = true;
      this.http.post<any>(`${environment.apiUrl}/integracao/mercadolibre/pergunta/resonder`, this.data).subscribe(
        data => {                    
            this.toastr.success('Resposta enviada com sucesso.');
            this.recording = false;
            this.datas.reload = true;
            this.dialogRef.close();
        },
        error => {
          this.recording = false;
        }
      );
    }
    catch (e) {
      this.recording = false;
    }
  }

  public salvarAnotacao(event?: any): void {
    try {
      this.anotacao.id_item = this.data.item_id;
      this.http.post<any>(`${environment.apiUrl}/integracao/mercadolibre/produto/anotacao/save`, this.anotacao).subscribe(
        data => {                    
            this.toastr.success('Anotação atualizada com sucesso.');
            this.habilitarAnotacao(false);
        },
        error => {
          this.toastr.error(error);
        }
      );
    }
    catch (e) {
      this.toastr.error(e.message);
    }
  }
  
  public aditionalFind(url: string, callback: (data: any) => void): void {
    this.http.get<any>(`${environment.apiUrl}/${url}`).subscribe(
      data => {
        callback(data);
      },
      error => {
        this.toastr.error('Não foi possível carregar o registro');
      }
    );
  }

  
  @HostListener('window:keydown.control.s', ['$event'])
  keSaveClose(event: KeyboardEvent) {
    if (!this.data.answer && !this.data.answer.text)
      this.responder();
  }

  @HostListener('window:keydown.esc', ['$event'])
  keClose(event: KeyboardEvent) {
    this.dialogRef.close();
  }
}