<div id="page-container">

  <div class="register register-with-news-feed">

    <div class="news-feed">
      <div class="news-image" style="background-image: url(/assets/images/login-bg-15.jpeg)"></div>
      <div class="news-caption">
        <h4 class="caption-title"><b><span class="text-primary">Be</span>Sales</b></h4>
        <p>
          Experimente a simplicidade do BeSales. Mais seguro, inteligente e fácil de utilizar!
        </p>
      </div>
    </div>


    <div class="right-content">

      <h1 class="register-header">
        Crie seu ID <span class="text-primary">Be</span>Sales
        <small>Um ID BeSales é o que você precisa para acessar todos os serviços do BeSales.</small>
      </h1>

      <div class="alert alert-danger fade show" *ngIf="error">
        <span class="close" data-dismiss="alert" style="cursor: pointer;">×</span>
        {{errorMsg}}
      </div>

      <div class="register-content">
        <form [formGroup]="registerForm" (ngSubmit)="onSubmit()" class="margin-bottom-0">
        
         

          <label class="control-label">Nome <span class="text-danger">*</span></label>
          <div class="row row-space-10">
            <div class="col-md-12 m-b-15">
              <input autofocus type="text" placeholder="Nome" formControlName="nome" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.nome.errors }" />
            </div>
          </div>
          <label class="control-label">Telefone <span class="text-danger">*</span></label>
          <div class="row m-b-15">
            <div class="col-md-12">
              <input type="text" placeholder="Telefone" mask="00 00000-0000" formControlName="telefone" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.telefone.errors }" />
            </div>
          </div>
          <label class="control-label">E-mail <span class="text-danger">*</span></label>
          <div class="row m-b-15">
            <div class="col-md-12">
              <input type="text" placeholder="E-mail" [email]="true" formControlName="email" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
            </div>
          </div>
          <label class="control-label">Senha <span class="text-danger">*</span></label>
          <div class="row m-b-15">
            <div class="col-md-6">
              <input type="password" pPassword promptLabel="Informe uma senha" weakLabel="Fraco" mediumLabel="Médio" strongLabel="Forte" placeholder="Senha" formControlName="password" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
            </div>
          
            <div class="col-md-6">
              <input type="password" placeholder="Confirme a senha" formControlName="repassword" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.repassword.errors }" />
            </div>
          </div>
          <label>
              Ao clicar em Cadastrar, você concorda com nossos <a href="javascript:;" (click)="openDialog()">termos</a> e que leu nossa <a href="javascript:;" (click)="openDialog()">Política de Dados</a>, incluindo nosso <a href="javascript:;" (click)="openDialog()">Uso de Cookies</a>.
          </label>
          <div class="register-buttons">
            <button type="submit" class="btn btn-primary btn-block btn-lg" [disabled]="loading">Cadastrar</button>
          </div>
          <div class="m-t-30 m-b-30 p-b-30">
            Já tem um ID BeSales? Clique <a routerLink="/login">aqui</a> para acessar.
          </div>
          <hr />
          <p class="text-center mb-0">
            &copy; BeSales All Right Reserved {{ time | date: 'yyyy' }}
          </p>
        </form>
      </div>

    </div>

  </div>