<div class="bs-dialog-header">
  <i class="fa fa-upload"></i> PRODUTOS <small> Importar Registros </small>
</div>

<div class="bs-dialog-main">
  <div class="bs-dialog-content">


  <div *ngIf="carregando" style="height: 511px;">
    <div class="alert alert-light fade show m-b-0 text-center" style="color: #222;">
      <i class="fas fa-circle-notch fa-spin m-r-10"></i> 
      Carregando arquivo... Esta ação poderá demorar um pouco dependendo do tamanho do arquivo.
    </div>
  </div>
  <div *ngIf="!carregando">

    <div class="panel-body">
      <div class="alert alert-light fade show m-b-0" style="color: #222;">
        <strong>Selecione</strong> um arquivo no formato Excel <strong>(.xls ou .xlsx)</strong> ou texto
        <strong>(.csv)</strong>.
      </div>
    </div>

    <div class="m-t-15">
      <span class="btn btn-primary fileinput-button" *ngIf="registro == null">
        <i class="fa fa-upload"></i>
        Carregar planilha de clientes
        <input (change)="this.onFilechange($event)" type="file" id="formFile" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" />
      </span>
      <span class="btn btn-primary fileinput-button" *ngIf="registro != null">
        <i class="fa fa-upload"></i>
        Carregar outra planilha de clientes
        <input (change)="this.onFilechange($event)" type="file" id="formFile" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" />
      </span>

      <button [disabled]="downloading" (click)="download()" class="btn btn-default m-l-20">
        <i class="fa fa-download" *ngIf="!downloading"></i>
        <i class="fas fa-circle-notch fa-spin" *ngIf="downloading"></i>
        Download de uma planilha de exemplo
      </button>

      <button (click)="confirmar()" class="btn btn-success pull-right" *ngIf="registro != null">
        <i class="fa fa-cloud-upload-alt"></i>
        Importar todos os dados da planilha
      </button>
    </div>

    <hr />

    <div class="alert alert-light fade show" style="color: #222;" *ngIf="registro == null">
      Exemplo de uma planilha excel compatível com BeSales
    </div>

    <div class="alert alert-light fade show" style="color: #222;" *ngIf="registro != null">
      <h5>Verifique todos os dados antes de continuar a importação.</h5>
      <span class="badge badge-default badge-square pull-right">Você pode editar os dados antes de carregar.</span>
      Todas as informações presentes nos arquivos enviados serão importadas na sua conta e a operação não poderá ser desfeita.
    </div>

    <span *ngIf="registro != null" class="label label-success"><strong>{{registro.registros}}</strong> Registros</span>
    <span *ngIf="registro != null" class="label label-danger m-l-20"><strong>{{registro.erros}}</strong> Registros com erro</span>

    <table class="table table-bordered table-overlay table-import bg-white m-t-5">
      <thead>
        <tr>
          <th *ngIf="registro != null"></th>
          <th class="text-nowrap" *ngIf="registro != null">Linha</th>
          <th class="text-nowrap">Produto</th>
          <th class="text-nowrap">Marca</th>
          <th class="text-nowrap">Categoria</th>
          <th class="text-nowrap">SKU</th>
          <th class="text-nowrap">EAN</th>
          <th class="text-nowrap">Valor Custo</th>
          <th class="text-nowrap">Valor Venda</th>
          <th class="text-nowrap">Valor Promocional</th>
          <th class="text-nowrap">Unidade Medida</th>
          <th class="text-nowrap">Descrição Unidade Medida</th>
          <th class="text-nowrap">Estoque</th>
          <th class="text-nowrap">Estoque Mínimo</th>
          <th class="text-nowrap">Estoque Máximo</th>
          <th class="text-nowrap">Localização Estoque</th>
          <th class="text-nowrap">Estoque Preparação</th>
          <th class="text-nowrap">Descrição</th>
          <th class="text-nowrap">Origem</th>
          <th class="text-nowrap">Origem Descrição</th>
          <th class="text-nowrap">NCM</th>
          <th class="text-nowrap">CEST</th>
          <th class="text-nowrap">Altura</th>
          <th class="text-nowrap">Largura</th>
          <th class="text-nowrap">Profundidade</th>
          <th class="text-nowrap">Peso Líquido</th>
          <th class="text-nowrap">Peso Bruto</th>
          <th class="text-nowrap">Link Vídeo</th>
          <th class="text-nowrap">Título SEO</th>
          <th class="text-nowrap">Descrição SEO</th>          
        </tr>
      </thead>
      
      <tbody *ngIf="registro != null">
        <tr *ngFor="let d of registro?.uploadDetails; let i = index" [ngClass]="d.erro ? 'table-danger' : ''">
        
          <td class="text-center">
            <a href="javascript:;" (click)="removerLinha(i)" mat-raised-button matTooltip="Remover linha"><i class="fa fa-times" style="color: red;"></i></a>
          </td>

          <td class="text-nowrap text-center">
            {{i+1}}
          </td>
          
          <td class="text-nowrap" mat-raised-button matTooltip="{{d.erroDetail}}"><input type="text" [ngClass]="d.erro ? 'table-danger' : ''" [(ngModel)]="d.data.nome">
            <span>{{d.data?.nome}}</span>
          </td>
          <td class="text-nowrap" mat-raised-button matTooltip="{{d.erroDetail}}"><input type="text" [ngClass]="d.erro ? 'table-danger' : ''" [(ngModel)]="d.data.marca">
            <span>{{d.data?.marca}}</span>
          </td>
          <td class="text-nowrap" mat-raised-button matTooltip="{{d.erroDetail}}"><input type="text" [ngClass]="d.erro ? 'table-danger' : ''" [(ngModel)]="d.data.idCategoria">
            <span>{{d.data?.idCategoria}}</span>
          </td>
          <td class="text-nowrap" mat-raised-button matTooltip="{{d.erroDetail}}"><input type="text" [ngClass]="d.erro ? 'table-danger' : ''" [(ngModel)]="d.data.sku">
            <span>{{d.data?.sku}}</span>
          </td>
          <td class="text-nowrap" mat-raised-button matTooltip="{{d.erroDetail}}"><input type="text" [ngClass]="d.erro ? 'table-danger' : ''" [(ngModel)]="d.data.ean">
            <span>{{d.data?.ean}}</span>
          </td>
          <td class="text-nowrap" mat-raised-button matTooltip="{{d.erroDetail}}"><input type="text" [ngClass]="d.erro ? 'table-danger' : ''" [(ngModel)]="d.data.valorCusto">
            <span>{{d.data?.valorCusto}}</span>
          </td>
          <td class="text-nowrap" mat-raised-button matTooltip="{{d.erroDetail}}"><input type="text" [ngClass]="d.erro ? 'table-danger' : ''" [(ngModel)]="d.data.valorVenda">
            <span>{{d.data?.valorVenda}}</span>
          </td>
          <td class="text-nowrap" mat-raised-button matTooltip="{{d.erroDetail}}"><input type="text" [ngClass]="d.erro ? 'table-danger' : ''" [(ngModel)]="d.data.valorPromocional">
            <span>{{d.data?.valorPromocional}}</span>
          </td>
          <td class="text-nowrap" mat-raised-button matTooltip="{{d.erroDetail}}"><input type="text" [ngClass]="d.erro ? 'table-danger' : ''" [(ngModel)]="d.data.unidadeMedida.code">
            <span>{{d.data?.unidadeMedida.code}}</span>
          </td>
          <td class="text-nowrap" mat-raised-button matTooltip="{{d.erroDetail}}"><input type="text" [ngClass]="d.erro ? 'table-danger' : ''" [(ngModel)]="d.data.unidadeMedida.value">
            <span>{{d.data?.unidadeMedida.value}}</span>
          </td>
          <td class="text-nowrap" mat-raised-button matTooltip="{{d.erroDetail}}"><input type="text" [ngClass]="d.erro ? 'table-danger' : ''" [(ngModel)]="d.data.estoque">
            <span>{{d.data?.estoque}}</span>
          </td>
          <td class="text-nowrap" mat-raised-button matTooltip="{{d.erroDetail}}"><input type="text" [ngClass]="d.erro ? 'table-danger' : ''" [(ngModel)]="d.data.estoqueMin">
            <span>{{d.data?.estoqueMin}}</span>
          </td>
          <td class="text-nowrap" mat-raised-button matTooltip="{{d.erroDetail}}"><input type="text" [ngClass]="d.erro ? 'table-danger' : ''" [(ngModel)]="d.data.estoqueMax">
            <span>{{d.data?.estoqueMax}}</span>
          </td>
          <td class="text-nowrap" mat-raised-button matTooltip="{{d.erroDetail}}"><input type="text" [ngClass]="d.erro ? 'table-danger' : ''" [(ngModel)]="d.data.estoqueLocalizacao">
            <span>{{d.data?.estoqueLocalizacao}}</span>
          </td>
          <td class="text-nowrap" mat-raised-button matTooltip="{{d.erroDetail}}"><input type="text" [ngClass]="d.erro ? 'table-danger' : ''" [(ngModel)]="d.data.estoquePreparacao">
            <span>{{d.data?.estoquePreparacao}}</span>
          </td>
          <td class="text-nowrap" mat-raised-button matTooltip="{{d.erroDetail}}"><input type="text" [ngClass]="d.erro ? 'table-danger' : ''" [(ngModel)]="d.data.descricao">
            <span>{{d.data?.descricao}}</span>
          </td>
          <td class="text-nowrap" mat-raised-button matTooltip="{{d.erroDetail}}"><input type="text" [ngClass]="d.erro ? 'table-danger' : ''" [(ngModel)]="d.data.origem.code">
            <span>{{d.data?.origem.code}}</span>
          </td>
          <td class="text-nowrap" mat-raised-button matTooltip="{{d.erroDetail}}"><input type="text" [ngClass]="d.erro ? 'table-danger' : ''" [(ngModel)]="d.data.origem.value">
            <span>{{d.data?.origem.value}}</span>
          </td>
          <td class="text-nowrap" mat-raised-button matTooltip="{{d.erroDetail}}"><input type="text" [ngClass]="d.erro ? 'table-danger' : ''" [(ngModel)]="d.data.ncm">
            <span>{{d.data?.ncm}}</span>
          </td>
          <td class="text-nowrap" mat-raised-button matTooltip="{{d.erroDetail}}"><input type="text" [ngClass]="d.erro ? 'table-danger' : ''" [(ngModel)]="d.data.cest">
            <span>{{d.data?.cest}}</span>
          </td>
          <td class="text-nowrap" mat-raised-button matTooltip="{{d.erroDetail}}"><input type="text" [ngClass]="d.erro ? 'table-danger' : ''" [(ngModel)]="d.data.altura">
            <span>{{d.data?.altura}}</span>
          </td>
          <td class="text-nowrap" mat-raised-button matTooltip="{{d.erroDetail}}"><input type="text" [ngClass]="d.erro ? 'table-danger' : ''" [(ngModel)]="d.data.largura">
            <span>{{d.data?.largura}}</span>
          </td>
          <td class="text-nowrap" mat-raised-button matTooltip="{{d.erroDetail}}"><input type="text" [ngClass]="d.erro ? 'table-danger' : ''" [(ngModel)]="d.data.profundidade">
            <span>{{d.data?.profundidade}}</span>
          </td>
          <td class="text-nowrap" mat-raised-button matTooltip="{{d.erroDetail}}"><input type="text" [ngClass]="d.erro ? 'table-danger' : ''" [(ngModel)]="d.data.pesoLiquido">
            <span>{{d.data?.pesoLiquido}}</span>
          </td>
          <td class="text-nowrap" mat-raised-button matTooltip="{{d.erroDetail}}"><input type="text" [ngClass]="d.erro ? 'table-danger' : ''" [(ngModel)]="d.data.pesoBruto">
            <span>{{d.data?.pesoBruto}}</span>
          </td>
          <td class="text-nowrap" mat-raised-button matTooltip="{{d.erroDetail}}"><input type="text" [ngClass]="d.erro ? 'table-danger' : ''" [(ngModel)]="d.data.linkVideo">
            <span>{{d.data?.linkVideo}}</span>
          </td>
          <td class="text-nowrap" mat-raised-button matTooltip="{{d.erroDetail}}"><input type="text" [ngClass]="d.erro ? 'table-danger' : ''" [(ngModel)]="d.data.tituloSeo">
            <span>{{d.data?.tituloSeo}}</span>
          </td>
          <td class="text-nowrap" mat-raised-button matTooltip="{{d.erroDetail}}"><input type="text" [ngClass]="d.erro ? 'table-danger' : ''" [(ngModel)]="d.data.descricaoSeo">
            <span>{{d.data?.descricaoSeo}}</span>
          </td>
          
        </tr>
      </tbody>
      
      <tbody *ngIf="registro == null">        
        <tr>
          <td class="text-nowrap">Produto D</td>
          <td class="text-nowrap">Marca D</td>
          <td class="text-nowrap">Cat 4</td>
          <td class="text-nowrap">Sku 234</td>
          <td class="text-nowrap">Ean 567</td>
          <td class="text-nowrap">25.00</td>
          <td class="text-nowrap">35.00</td>
          <td class="text-nowrap">30.00</td>
          <td class="text-nowrap">kg</td>
          <td class="text-nowrap">Kilograma</td>
          <td class="text-nowrap">250</td>
          <td class="text-nowrap">40</td>
          <td class="text-nowrap">350</td>
          <td class="text-nowrap">Localização D</td>
          <td class="text-nowrap">20</td>
          <td class="text-nowrap">Descrição do Produto D</td>
          <td class="text-nowrap">1</td>
          <td class="text-nowrap">1 - Estrangeira - Importação direta, exceto a indicada no código 6</td>
          <td class="text-nowrap">45678901</td>
          <td class="text-nowrap">65432109</td>
          <td class="text-nowrap">25.00</td>
          <td class="text-nowrap">35.00</td>
          <td class="text-nowrap">11.00</td>
          <td class="text-nowrap">3.00</td>
          <td class="text-nowrap">5.00</td>
          <td class="text-nowrap">http://www.example.com/video4</td>
          <td class="text-nowrap">Título SEO 4</td>
          <td class="text-nowrap">Descrição SEO 4</td>
        </tr>
        <tr>
          <td class="text-nowrap">Produto E</td>
          <td class="text-nowrap">Marca E</td>
          <td class="text-nowrap">Cat 5</td>
          <td class="text-nowrap">Sku 345</td>
          <td class="text-nowrap">Ean 678</td>
          <td class="text-nowrap">30.00</td>
          <td class="text-nowrap">40.00</td>
          <td class="text-nowrap">35.00</td>
          <td class="text-nowrap">kg</td>
          <td class="text-nowrap">Kilograma</td>
          <td class="text-nowrap">300</td>
          <td class="text-nowrap">50</td>
          <td class="text-nowrap">400</td>
          <td class="text-nowrap">Localização E</td>
          <td class="text-nowrap">25</td>
          <td class="text-nowrap">Descrição do Produto E</td>
          <td class="text-nowrap">1</td>
          <td class="text-nowrap">1 - Estrangeira - Importação direta, exceto a indicada no código 6</td>
          <td class="text-nowrap">56789012</td>
          <td class="text-nowrap">54321098</td>
          <td class="text-nowrap">30.00</td>
          <td class="text-nowrap">40.00</td>
          <td class="text-nowrap">13.00</td>
          <td class="text-nowrap">3.50</td>
          <td class="text-nowrap">6.00</td>
          <td class="text-nowrap">http://www.example.com/video5</td>
          <td class="text-nowrap">Título SEO 5</td>
          <td class="text-nowrap">Descrição SEO 5</td>
        </tr>
        <tr>
          <td class="text-nowrap">Produto N</td>
          <td class="text-nowrap">Marca N</td>
          <td class="text-nowrap">Cat 14</td>
          <td class="text-nowrap">Sku 987</td>
          <td class="text-nowrap">Ean 1011</td>
          <td class="text-nowrap">85.00</td>
          <td class="text-nowrap">95.00</td>
          <td class="text-nowrap">90.00</td>
          <td class="text-nowrap">un</td>
          <td class="text-nowrap">Unidade</td>
          <td class="text-nowrap">120</td>
          <td class="text-nowrap">30</td>
          <td class="text-nowrap">150</td>
          <td class="text-nowrap">Localização N</td>
          <td class="text-nowrap">70</td>
          <td class="text-nowrap">Descrição do Produto N</td>
          <td class="text-nowrap">1</td>
          <td class="text-nowrap">1 - Estrangeira - Importação direta, exceto a indicada no código 6</td>
          <td class="text-nowrap">123456789012345</td>
          <td class="text-nowrap">012345678901234</td>
          <td class="text-nowrap">10.00</td>
          <td class="text-nowrap">12.00</td>
          <td class="text-nowrap">11.00</td>
          <td class="text-nowrap">1.00</td>
          <td class="text-nowrap">1.50</td>
          <td class="text-nowrap">http://www.example.com/video14</td>
          <td class="text-nowrap">Título SEO 14</td>
          <td class="text-nowrap">Descrição SEO 14</td>
        </tr>
        
        <tr>
          <td class="text-nowrap">Produto O</td>
          <td class="text-nowrap">Marca O</td>
          <td class="text-nowrap">Cat 15</td>
          <td class="text-nowrap">Sku 1012</td>
          <td class="text-nowrap">Ean 1112</td>
          <td class="text-nowrap">90.00</td>
          <td class="text-nowrap">100.00</td>
          <td class="text-nowrap">95.00</td>
          <td class="text-nowrap">un</td>
          <td class="text-nowrap">Unidade</td>
          <td class="text-nowrap">150</td>
          <td class="text-nowrap">40</td>
          <td class="text-nowrap">180</td>
          <td class="text-nowrap">Localização O</td>
          <td class="text-nowrap">75</td>
          <td class="text-nowrap">Descrição do Produto O</td>
          <td class="text-nowrap">0</td>
          <td class="text-nowrap">0 - Nacional, exceto as indicadas nos códigos 3, 4, 5 e 8</td>
          <td class="text-nowrap">234567890123456</td>
          <td class="text-nowrap">123456789012345</td>
          <td class="text-nowrap">12.00</td>
          <td class="text-nowrap">14.00</td>
          <td class="text-nowrap">13.00</td>
          <td class="text-nowrap">1.50</td>
          <td class="text-nowrap">2.00</td>
          <td class="text-nowrap">http://www.example.com/video15</td>
          <td class="text-nowrap">Título SEO 15</td>
          <td class="text-nowrap">Descrição SEO 15</td>
        </tr>
        <tr>
          <td class="text-nowrap">Produto Q</td>
          <td class="text-nowrap">Marca Q</td>
          <td class="text-nowrap">Cat 17</td>
          <td class="text-nowrap">Sku 1214</td>
          <td class="text-nowrap">Ean 1314</td>
          <td class="text-nowrap">100.00</td>
          <td class="text-nowrap">110.00</td>
          <td class="text-nowrap">105.00</td>
          <td class="text-nowrap">qte</td>
          <td class="text-nowrap">Quantidade</td>
          <td class="text-nowrap">180</td>
          <td class="text-nowrap">50</td>
          <td class="text-nowrap">210</td>
          <td class="text-nowrap">Localização Q</td>
          <td class="text-nowrap">80</td>
          <td class="text-nowrap">Descrição do Produto Q</td>
          <td class="text-nowrap">0</td>
          <td class="text-nowrap">0 - Nacional, exceto as indicadas nos códigos 3, 4, 5 e 8</td>
          <td class="text-nowrap">345678901234567</td>
          <td class="text-nowrap">234567890123456</td>
          <td class="text-nowrap">14.00</td>
          <td class="text-nowrap">16.00</td>
          <td class="text-nowrap">15.00</td>
          <td class="text-nowrap">2.00</td>
          <td class="text-nowrap">2.50</td>
          <td class="text-nowrap">http://www.example.com/video17</td>
          <td class="text-nowrap">Título SEO 17</td>
          <td class="text-nowrap">Descrição SEO 17</td>
        </tr>
        
        <tr>
          <td class="text-nowrap">Produto R</td>
          <td class="text-nowrap">Marca R</td>
          <td class="text-nowrap">Cat 18</td>
          <td class="text-nowrap">Sku 1315</td>
          <td class="text-nowrap">Ean 1415</td>
          <td class="text-nowrap">105.00</td>
          <td class="text-nowrap">115.00</td>
          <td class="text-nowrap">110.00</td>
          <td class="text-nowrap">qte</td>
          <td class="text-nowrap">Quantidade</td>
          <td class="text-nowrap">210</td>
          <td class="text-nowrap">60</td>
          <td class="text-nowrap">240</td>
          <td class="text-nowrap">Localização R</td>
          <td class="text-nowrap">85</td>
          <td class="text-nowrap">Descrição do Produto R</td>
          <td class="text-nowrap">0</td>
          <td class="text-nowrap">0 - Nacional, exceto as indicadas nos códigos 3, 4, 5 e 8</td>
          <td class="text-nowrap">456789012345678</td>
          <td class="text-nowrap">345678901234567</td>
          <td class="text-nowrap">16.00</td>
          <td class="text-nowrap">18.00</td>
          <td class="text-nowrap">17.00</td>
          <td class="text-nowrap">2.50</td>
          <td class="text-nowrap">3.00</td>
          <td class="text-nowrap">http://www.example.com/video18</td>
          <td class="text-nowrap">Título SEO 18</td>
          <td class="text-nowrap">Descrição SEO 18</td>
        </tr>                        
      </tbody>
    </table>

  </div>
</div>
</div>

<div class="bs-dialog-footer">
  <div class="bs-footer-action-close">
    <button (click)="close()" class="btn btn-white" pTooltip="Esc" tooltipPosition="bottom">Fechar</button>
  </div>
</div>