<div id="content" class="content content-full-width">

    <h1 class="page-header">Aniversariantes</h1>

    <div class="vertical-box with-grid inbox bg-light">

        <div class="vertical-box-column width-200">
            <div class="vertical-box">

                <div class="wrapper d-none d-sm-none d-md-none d-lg-block">
                    <div class="d-flex align-items-center justify-content-center">
                        Menu
                    </div>
                </div>

                <div class="vertical-box-row d-lg-table-row collapse">
                    <div class="vertical-box-cell">
                        <div class="vertical-box-inner-cell">
                            <div class="keras-scrollbar">
                                <div class="wrapper p-0">
                                    <ul class="nav nav-inbox">
                                        <li [ngClass]="tipo == 'dia' ? 'active' : ''" (click)="listarAniversariante('dia')"><a href="javascript:;">Aniversariantes do Dia</a></li>
                                        <li [ngClass]="tipo == 'semana' ? 'active' : ''" (click)="listarAniversariante('semana')"><a href="javascript:;">Aniversariantes da Semana</a></li>
                                        <li [ngClass]="tipo == 'mes' ? 'active' : ''" (click)="listarAniversariante('mes')"><a href="javascript:;">Aniversariantes do Mês</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

        <div class="vertical-box-column">
            <div class="vertical-box">
                <div class="vertical-box-row">
                    <div class="vertical-box-cell">
                        <div class="vertical-box-inner-cell bg-white">
                            <div class="keras-scrollbar">
                                <div class="bs-configuracao bs-dashboard">
                                    <div class="col-12">
                                                <h4 class="m-b-30">Aniversariantes</h4>
                                                <div class="row">

                                                    <!-- Carregando informações... -->
                                                    <div class="p-40 fa-3x" style="position: absolute; left: 44%;" *ngIf="carregando">
                                                        <i class="fas fa-spinner fa-pulse"></i>
                                                    </div>
                                                    <div *ngIf="data?.result?.length == 0" style="height: 451px;">
                                                        <div class="alert alert-light fade show m-b-0 text-center" style="color: #222;">
                                                        Nenhum aniversariante encontrado.
                                                        </div>
                                                    </div>
                                                </div>


                                                <div class="row">
                                                    
                                                    <table class="table table-sm text-uppercase" *ngIf="data?.result?.length > 0">
                                                        <tbody>
                                                          <tr>
                                                            <th style="border-top: none;">Nome</th>
                                                            <th style="border-top: none;" width="10%" class="text-center">Documento</th>
                                                            <th style="border-top: none;" width="20%" class="text-center">Cidade</th>
                                                            <th style="border-top: none;" width="10%" class="text-center">Telefone</th>
                                                            <th style="border-top: none;" width="20%" class="text-center">Data nascimento</th>
                                                            <th style="border-top: none;" width="10%" class="text-center">Status</th>
                                                          </tr>
                                                          <tr *ngFor="let d of data?.result">
                                                            <td>{{d?.nome}}</td>
                                                            <td class="text-center">{{d?.documento?.valor}}</td>
                                                            <td class="text-center"><span *ngIf="d?.endereco?.cidade != null">{{d?.endereco?.cidade}} - {{d?.endereco?.estado}}</span></td>
                                                            <td class="text-center">{{d?.telefone}}</td>
                                                            <td class="text-center">{{d?.nascimento | date : 'dd/MM/yyyy' }}</td>
                                                            <td class="text-center">{{d?.status?.value}}</td>
                                                          </tr>
                                                        </tbody>
                                                      </table>
                                                    
                                                </div>
                                    </div>
                                    
                                </div>                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>    
        </div>
    </div>
</div>