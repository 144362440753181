import { BaseEntity } from "../BaseEntity";
import { Documento } from "../suporte/documento";
import { DocumentoFiscal } from "../suporte/documentoFiscal";
import { Endereco } from "../suporte/endereco";

export class Organizacao extends BaseEntity {

	private nome: string;
	private email: string;
	private telefone: string;
	private parceiro: string;
	private plano: string;
	private planoAnual: boolean;
	private periodoTeste: boolean;
	private formaCobranca: string;
	private diaVencimento: number;
	private vencimentoTeste: Date;
	private endereco:  Endereco;
	private documento:  Documento;
	private documentoFiscal:  DocumentoFiscal;
}