import { Produto } from "../cadastro/produto";

export class PedidoItem {

	public quantidade: number;
    public valorUnitario: number;
    public valorTotal: number;
    public idProduto: string;
    public nomeProduto: string;
    public produtoSku: string;
    public produtoImg: string;
    public produto: Produto;
}
