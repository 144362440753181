import { BaseEntity } from "../BaseEntity";
import { CrmAcao } from "../suporte/crmAcao";
import { CrmEstagio } from "../suporte/crmEstagio";
import { Contato } from "../cadastro/contato";

export class Crm extends BaseEntity {

	cliente: string;
	contato: Contato;
	assunto: string;
	observacao: string;
	previsao: Date;
	estagio: CrmEstagio;
	contatoNome: string;
	contatoEmail: string;
	contatoTelefone: string;
	contatoCelular: string;
	contatoDepartamento: string;
	idResponsavel: string;
    nomeResponsavel: string;
	crmAcoes: CrmAcao[];
}
