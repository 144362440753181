<div id="content" class="content content-full-width">

    <h1 class="page-header">Agenda BeSales</h1>

    <div class="vertical-box with-grid inbox bg-light">

        <div class="vertical-box-column width-200">
            <div class="vertical-box">

                <div class="wrapper d-none d-sm-none d-md-none d-lg-block">
                    <div class="d-flex align-items-center justify-content-center">
                        Menu
                    </div>
                </div>

                <div class="vertical-box-row d-lg-table-row collapse">
                    <div class="vertical-box-cell">
                        <div class="vertical-box-inner-cell">
                            <div class="keras-scrollbar">
                                <div class="wrapper p-0">
                                    <ul class="nav nav-inbox">
                                        <!-- <li [ngClass]="activeIndex == 0 ? 'active' : ''"><a (click)="activeIndex = 0" href="javascript:;">Meus compromissos</a></li>
                                        <li [ngClass]="activeIndex == 1 ? 'active' : ''"><a (click)="activeIndex = 1" href="javascript:;">Outros usuários</a></li> -->

                                        <li [ngClass]="filter == null ? 'active' : ''" (click)="filterEvent(null)"><a [routerLink]="">Todos</a></li>
                                        <li [ngClass]="filter == 'private' ? 'active' : ''" (click)="filterEvent('private')"><a [routerLink]="">Meus Compromissos</a></li>
                                        <li [ngClass]="filter == 'coll_crm' ? 'active' : ''" (click)="filterEvent('coll_crm')"><a [routerLink]="">Ações CRM</a></li>
                                        <li [ngClass]="filter == 'coll_titulo_pagar' ? 'active' : ''" (click)="filterEvent('coll_titulo_pagar')"><a [routerLink]="">Títulos a Pagar</a></li>
                                        <li [ngClass]="filter == 'coll_titulo_receber' ? 'active' : ''" (click)="filterEvent('coll_titulo_receber')"><a [routerLink]="">Títulos a Receber</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

        <div class="vertical-box-column">
            <div class="vertical-box">
                <div class="vertical-box-row">
                    <div class="vertical-box-cell">
                        <div class="vertical-box-inner-cell bg-white">
                            <div class="keras-scrollbar">
                                <div class="bs-agenda">
                                    <div class="col-12">
                                        <p-fullCalendar [events]="events" [options]="calendarOptions"></p-fullCalendar>
                                        <div class="weekend-toggle m-t-10">
                                            <input type="checkbox" id="showWeekends" [(ngModel)]="showWeekends" (change)="toggleWeekends()">
                                            <label for="showWeekends">Mostrar finais de semana</label>
                                        </div>
                                    </div>
                                </div>                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>    
        </div>
    </div>
</div>