<div class="bs-dialog-header">
  <i class="fa fa-eye"></i> CRM <small> Gestão de Relacionamento com Cliente </small>
</div>

<div class="bs-dialog-main">
  <div class="bs-dialog-content">

    <!-- Carregando informações... -->
    <div class="modal-overlay" *ngIf="loading">
      <div class="alert alert-light fade show m-b-0 text-center" style="color: #222;">
        <i class="fas fa-circle-notch fa-spin"></i> 
      </div>
    </div>

    <div class="alert alert-warning fade show m-b-10" *ngIf="iniciativaExiste">
      <i class="fas fa-lg fa-fw m-r-10 fa-exclamation-triangle"></i>
      <strong>{{data.contato?.nome}}</strong> já tem uma iniciativa em andamento e ativa.
    </div>

    <div class="be-form">

      <div class="row">
        <div class="col-6">
          <label>Cliente:</label>
          <p-autoComplete [(ngModel)]="data.contato" (onSelect)="onInputClienteSelect()" [disabled]="autocompleteClienteDisabled" field="nome" [suggestions]="autocompleteCliente" (completeMethod)="acFind($event)" autoHighlight="true" forceSelection="true" emptyMessage="Cliente não encontrado" class="be-autocomplete">
            <ng-template let-contato pTemplate="item">
              {{contato.nome}}
            </ng-template>
          </p-autoComplete>
        </div>
      </div>

      <div class="row">
        <div class="col-6">
          <label>Responsável pela Iniciativa:</label>
          <p-autoComplete [(ngModel)]="data.responsavel" (onBlur)="onInputRiBlur()" field="nome" [suggestions]="autocompleteRI" (completeMethod)="acFindRI($event)" autoHighlight="true" forceSelection="true" emptyMessage="Responsável não encontrado" class="be-autocomplete">
            <ng-template let-usuario pTemplate="item">
              {{usuario.nome}}
            </ng-template>
          </p-autoComplete>
        </div>
      </div>

      <div class="row">
        <div class="col-6">
          <label>Iniciativa:</label>
          <input type="text" [(ngModel)]="data.assunto" name="assunto" class="form-control">
        </div>
        <div class="col-3">
          <label>Previsto para:</label>
          <input [(ngModel)]="data.previsao" name="data" type="date" pattern="\d{4}-\d{2}-\d{2}" placeholder="dd/MM/yyyy" class="form-control">
        </div>
      </div>

      <hr />

      <div class="row">
        <div class="col-6">
          <label>Pessoa de contato:</label>
          <input type="text" [(ngModel)]="data.contatoNome" name="contatoNome" class="form-control">
        </div>
      </div>

      <div class="row">
        <div class="col-4">
          <label>Telefone:</label>
          <input type="text" [(ngModel)]="data.contatoTelefone" name="contatoTelefone" class="form-control" mask="00 00000-0000">
        </div>
        <div class="col-4">
          <label>Celular:</label>
          <input type="text" [(ngModel)]="data.contatoCelular" name="contatoCelular" class="form-control" mask="00 00000-0000">
        </div>
        <div class="col-4">
          <label>Email:</label>
          <input type="text" [(ngModel)]="data.contatoEmail" name="contatoEmail" class="form-control">
        </div>
      </div>

      <div class="row">
        <div class="col-4">
          <label>Departamento:</label>
          <input type="text" [(ngModel)]="data.contatoDepartamento" name="contatoDepartamento" class="form-control">
        </div>
        <div class="col-4">
          <label>Estágio da ação:</label>
          <select class="form-control" [(ngModel)]="data.estagio.code" name="select">
            <option value="prospecting">Prospecção</option>
            <option value="contact">Contato realizado</option>
            <option value="proposal">Proposta apresentada</option>
            <option value="negotiation">Negociação</option>
            <option value="closed">Encerrado</option>
          </select>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <label>Observações:</label>
          <textarea [(ngModel)]="data.observacao" name="observacao" rows="3" class="form-control"
            placeholder="Observações"></textarea>
        </div>
      </div>
    
    </div>
    
    <hr />

    <h4 class="m-t-30">Ações</h4>

    
    <div class="row">
      <div class="col-6 form-action">
        <div class="be-form">
          <div class="row">
            <div class="col-12  p-l-15 p-r-15">
              <label>Ação:</label>
              <input type="text" [(ngModel)]="crmAcao.acao" name="crmAcao.acao" class="form-control">
            </div>
          </div>
          <div class="row">
            <div class="col-12 p-l-15 p-r-15">
              <label>Detalhes da Ação:</label>
              <textarea [(ngModel)]="crmAcao.detalhe" name="crmAcao.detalhe" rows="3" class="form-control" placeholder=""></textarea>
            </div>
          </div>
          <div class="row">
            <div class="col-5  p-l-15 p-r-15">
              <label>Previsto para o dia:</label>
              <input [(ngModel)]="crmAcao.dtPrevisao" type="date" pattern="\d{4}-\d{2}-\d{2}" name="crmAcao.dtPrevisao" class="form-control">
            </div>
            <div class="col-5  p-l-15 p-r-15">
              <label>Horário:</label>
              <input [(ngModel)]="crmAcao.hrPrevisao" type="time" pattern="[0-2][0-9]:[0-5][0-9]" name="crmAcao.hrPrevisao" class="form-control" />
            </div>
          </div>
          <div class="row">
            <div class="col-12  p-l-15 p-r-15">
              <label>Responsável pela Ação:</label>
              <p-autoComplete [(ngModel)]="data.responsavelAcao" (onBlur)="onInputRaBlur()" field="nome" [suggestions]="autocompleteRA" (completeMethod)="acFindRA($event)" autoHighlight="true" forceSelection="true" emptyMessage="Responsável não encontrado" class="be-autocomplete">
                <ng-template let-usuario pTemplate="item">
                  {{usuario.nome}}
                </ng-template>
              </p-autoComplete>
            </div>
          </div>
          <div class="row">
            <div class="col-12  p-l-15 p-r-15">
              <button (click)="addAcao()" class="btn btn-primary btn-xs pull-right">
                Incluir ação
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="col-6 timeline-scroll">
        
        <div class="note note-info" style="margin: 20px 10px 0 30px;" *ngIf="data == null || data.crmAcoes == null || data.crmAcoes.length === 0">
          <div class="note-icon"><i class="fa fa-lightbulb"></i></div>
          <div class="note-content">
              <h4 class="p-t-10"><b>Nenhuma ação para esta iniciativa.</b></h4>
              <p>Crie uma ação agora mesmo e faça a diferença acontecer.</p>
          </div>
        </div>

        <ul class="timeline">
          <li *ngFor="let acao of data?.crmAcoes; let i = index">
            <div class="timeline-time">
                <span class="date">{{acao.dtInclusao | date: 'dd/MM/yy'}}</span>
                <span class="time">{{acao.dtInclusao | date: 'HH:mm'}}</span>
            </div>
            <div class="timeline-icon">
                <a href="javascript:;">&nbsp;</a>
            </div>
            <div class="timeline-body">
                <div class="timeline-header">
                    <span class="username">{{acao.acao}}</span>
                    <span class="pull-right m-t-5 badge badge-secondary badge-success" *ngIf="acao.concluida">Concluído</span>
                    <span class="pull-right m-t-5 badge badge-secondary badge-info" *ngIf="!acao.concluida">Aberto</span>
                </div>
                <div class="timeline-content">
                  <p class="date-time">{{acao.nomeCriador}} {{acao.dtInclusao | date: 'dd/MM/yyyy HH:mm'}}</p>
                  <p>
                    {{acao.detalhe}}
                  </p>
                </div>
                <div class="timeline-comment" *ngIf="acao.idResponsavel">
                  <p class="date-time" *ngIf="!acao.dtResposta">{{acao.nomeResponsavel}} {{acao.dtPrevisao | date: 'dd/MM/yyyy' }} {{acao.hrPrevisao}}</p>
                  <p class="date-time" *ngIf="acao.dtResposta">{{acao.nomeResponsavel}} {{acao.dtResposta | date: 'dd/MM/yyyy HH:mm' }}</p>
                  <p *ngIf="acao.resposta && !acao.editavel">{{acao.resposta}}</p>
                  <textarea [(ngModel)]="acao.resposta" rows="3" class="form-control" *ngIf="acao.editavel" name="resp{{index}}"></textarea>
                  <button (click)="cancelaEdicao(i)" *ngIf="acao.editavel" style="font-size: 9px; padding: 0; float: right;" class="btn btn-link text-inverse-lighter">Cancelar</button>
                  <p style="text-align: center; padding: 15px 0; color: #afa8a8;" *ngIf="!acao.resposta && !acao.editavel">Esta ação ainda não possui interação.</p>
                </div>
                <div class="timeline-footer">
                  <button (click)="atualizaStatusAcao(i)" *ngIf="!acao.concluida" style="font-size: 9px; padding: 0; float: left;" class="btn btn-link text-inverse-lighter"><i class="fa fa-check fa-fw fa-lg"></i> Concluída</button>
                  <button (click)="atualizaStatusAcao(i)" *ngIf="acao.concluida" style="font-size: 9px; padding: 0; float: left;" class="btn btn-link text-inverse-lighter"><i class="fa fa-check fa-fw fa-lg"></i> Reabrir</button>
                  <button (click)="editar(i)" [disabled]="(acao.idResponsavel != null && acao.idResponsavel != authenticationService.currentUserValue._id) || acao.concluida" style="font-size: 9px; padding: 0; margin-right: 10px;" class="btn btn-link text-inverse-lighter"><i class="fa fa-edit fa-fw fa-lg m-r-3"></i> Editar</button>
                  <button (click)="removerAcao(i)" style="font-size: 9px; padding: 0;" class="btn btn-link text-inverse-lighter"><i class="fa fa-trash fa-fw fa-lg m-r-3"></i> Excluir</button>
                </div>
            </div>
          </li>
            
        </ul>
      </div>
    </div>

    
  </div>
</div>

<div class="bs-dialog-footer">
  <div class="bs-footer-action-close">
    <button (click)="close()" class="btn btn-white" pTooltip="Esc" tooltipPosition="bottom">Fechar</button>
  </div>
  <div class="bs-footer-action">
    <button (click)="save()" [disabled]="recording" class="btn btn-white" pTooltip="Ctrl + Enter" tooltipPosition="bottom">Salvar e criar outro</button>
    <button (click)="saveClose()" [disabled]="recording" class="btn btn-primary" pTooltip="Ctrl + S" tooltipPosition="bottom">Salvar</button>
  </div>
</div>