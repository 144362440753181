import { BaseEntity } from "../BaseEntity";
import { PedidoStatus } from "../suporte/pedidoStatus";
import { PedidoOrigem } from "../suporte/pedidoOrigem";
import { PagamentoForma } from "../suporte/pagamentoForma";
import { PagamentoStatus } from "../suporte/pagamentoStatus";
import { PedidoItem } from "../suporte/pedidoItem";

export class Pedido extends BaseEntity {

    public numeroPedido!: number;
	public dtPedido!: Date;
    public totalItens!: number;
    public valorTotal!: number;
    public valorDesconto!: number;
    public valorSubTotal!: number;
    public valorRecebido!: number;
    public valorTroco!: number;
    public qteParcelas!: number;
    public idVendedor!: string;
    public nmVendedor!: string;
    public idCliente!: string;
    public nmCliente!: string;
    public idUsuario!: string;
    public nmUsuario!: string;
    public anotacoes!: string;
    public pedidoStatus!: PedidoStatus;
    public pedidoOrigem!: PedidoOrigem;
    public pagamentoForma!: PagamentoForma;
    public pagamentoStatus!: PagamentoStatus;
    public pedidoItens!: PedidoItem[];

    public itemNumero!: number;
}
