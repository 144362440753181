import { HttpClient } from '@angular/common/http';
import { Component, HostListener, OnInit } from '@angular/core';
import { environment } from '@environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng-lts/dynamicdialog';
import { Categoria } from 'src/app/_entity/cadastro/categoria';
import { Contato } from 'src/app/_entity/cadastro/contato';
import { Filter } from 'src/app/_entity/filter';
import { Filters } from 'src/app/_entity/filters';
import { ServicoContrato } from 'src/app/_entity/servico/servicoContrato';
import { ContratoStatus } from 'src/app/_entity/suporte/contratoStatus';
import { KeyValue } from 'src/app/_entity/suporte/keyValue';
import { BaseServiceDialog } from 'src/app/_services/base.service.dialog';
import { CategoriaDialogComponent } from 'src/app/cadastro/categoria/categoria.component';
import { ClienteDialogComponent } from 'src/app/cadastro/cliente/cliente.component';

@Component({
  selector: 'app-contrato.dialog',
  templateUrl: './contrato.dialog.component.html'
})
export class ContratoDialogComponent extends BaseServiceDialog implements OnInit {

  public data: ServicoContrato = null;

  public upload_path_arquivo: string;

  public cliente: Contato;
  public categoria: Categoria;
  public autocompleteCliente: any[] = [];
  public autocompleteCategoria: any[] = [];

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public dialogService: DialogService,
    public http: HttpClient,
    public toastr: ToastrService,
    public translate: TranslateService) {

    super('servico/contrato', http, toastr, translate, ref, config.data);

    this.datas = config.data;
    this.data = this.datas.data;

    this.upload_path_arquivo = `${environment.apiUrl}/servico/contrato/upload-arquivo`;
  }

  ngOnInit(): void {
    // Caso seja uma iniciativa ja criada para edicao, busca a ultima
    // versao no banco de dados e apos inicializa os autocompletes
    if (this.data && this.data._id) {
      this.find(this.data._id);
    }
    // Caso seja uma nova apenas inicializa os autocompletes
    else {
      this.createData();
    }
  }

  public beforeSave(callback: (valid: boolean) => void): void {
    if (!this.data || !this.data.descricao) {
      this.toastr.error('Campo Descrição deve ser preenchido.');
      callback(false);
    }
    else {
      callback(true);
    }
  }

  public afterFind(): void {
    if (this.data) {
      if (this.data.idCliente) {
        this.http.get<any>(`${environment.apiUrl}/cliente/find/${this.data.idCliente}`).subscribe(
          data => {
            this.cliente = data;
          }
        );
      }
    }
    if (this.data.idCategoria) {
      this.http.get<any>(`${environment.apiUrl}/categoria/find/${this.data.idCategoria}`).subscribe(
        data => {
          this.categoria = data;
        }
      );
    }
  }

  public addCliente(): void {
    const dialogRef = this.dialogService.open(ClienteDialogComponent, {
      data: new Object,
      width: '850px',
      height: '500px',
      transitionOptions: '1ms',
      showHeader: false
    });

    dialogRef.onClose.subscribe(result => {
    });
  }

  public addCategoria(): void {
    let datas: any = new Object;

    let cat: Categoria = new Categoria;
    cat.origem = new KeyValue;
    cat.origem.code = 'financa';

    datas.data = cat;

    const dialogRef = this.dialogService.open(CategoriaDialogComponent, {
      data: datas,
      width: '850px',
      height: '500px',
      transitionOptions: '1ms',
      showHeader: false
    });

    dialogRef.onClose.subscribe(result => {
    });
  }

  public onSelectCliente(): void {
    if (this.cliente) {
      this.data.idCliente = this.cliente._id;
      this.data.nmCliente = this.cliente.nome;
    }
  }

  public onSelectCategoria(): void {
    if (this.categoria) {
      this.data.idCategoria = this.categoria._id;
    }
  }

  public acFindCliente(event): void {
    this.http.get<any>(`${environment.apiUrl}/fornecedor/pesquisar-nome-codigo/${event.query}`).subscribe(
      data => {
          this.autocompleteCliente = data;
      },
      error => {
      }
    );
  }

  public acFindCategoria(event): void {
    this.http.post<any>(`${environment.apiUrl}/categoria/lazylist`, this.createFilters(event.query)).subscribe(
      data => {
        this.autocompleteCategoria = data.result;
      },
      error => {
      }
    );
  }

  public onUploadArquivo(event): void {
    if (event.originalEvent && event.originalEvent.body) {
      if (!this.data.anexos) {
        this.data.anexos = [];
      }
      this.data.anexos = this.data.anexos.concat(event.originalEvent.body);
      this.toastr.success('Operação realizada com sucesso', 'File Uploaded');
    }
    else {
      this.toastr.error('Ocorreu um problema ao enviar os arquivos', 'Ops.');
    }
  }

  public createData(): void {
    if (this.data == null) {
      this.data = new ServicoContrato;
      this.data.contratoStatus = ContratoStatus.ATIVO;
    }
  }

  public createFilters(nome: string): Filters {
    const filters = new Filters();
    const filterName = new Filter('lk', 'descricao', this.clearSpecialCharacters(nome));
    const filterTipo = new Filter('eq', 'origem.code', 'financa');

    filters.offset = 0;
    filters.pageSize = 5;
    filters.orderField = "descricao";
    filters.orderDirection = 'asc';
    filters.filters = [filterName, filterTipo];

    return filters;
  }

  @HostListener('window:keydown.control.enter', ['$event'])
  keSave(event: KeyboardEvent) {
    this.save();
  }

  @HostListener('window:keydown.control.s', ['$event'])
  keSaveClose(event: KeyboardEvent) {
    this.saveClose();
  }

  @HostListener('window:keydown.esc', ['$event'])
  keClose(event: KeyboardEvent) {
    this.dialogRef.close();
  }
}