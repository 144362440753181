<div class="bs-dialog-header">
  <i class="fa fa-users"></i> {{config?.data?.seller?.nickname}} <small> Detalhes </small>
  </div>
  
  <div class="bs-dialog-main">
    <div class="bs-dialog-content">
  
      <!-- Carregando informações... -->
    <div class="modal-overlay" *ngIf="loading">
      <div class="alert alert-light fade show m-b-0 text-center" style="color: #222;">
        <i class="fas fa-circle-notch fa-spin"></i> 
      </div>
    </div>

    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12">
        <p-panel header="Vendas">
            <!-- <h4 class="m-b-30 m-t-10">R$ 42,92</h4> -->
            <p-chart type="bar" [data]="chtVendas"></p-chart>
        </p-panel>
      </div>
    </div>

    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 m-t-30">
        <p-panel header="Produtos de {{config?.data?.seller?.nickname}}">
            
        </p-panel>
      </div>
    </div>

  </div>
  
  <div class="bs-dialog-footer">
    <div class="bs-footer-action-close">
      <button (click)="close()" class="btn btn-white" pTooltip="Esc" tooltipPosition="bottom">Fechar</button>
    </div>
  </div>