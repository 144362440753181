<h4 class="paragraph-header">
  Configurar sua conta do MercadoLivre para integrar com BeSales.
</h4>
<small>* BeSales nunca terá sua senha do MercadoLivre</small>

<div class="row m-t-20">
  <div class="col-md-6 col-sm-12">

    <p-panel header="Vicular conta MercadoLivre com BeSales">
        <div class="row">
            <div class="col-12 text-center">
                <img style="height: 200px" src="/assets/images/ml.svg" /><br>
            </div>
        </div>
        <div class="row">
            <div class="col-12 text-center">
                <a [href]="mlUrl" class="btn btn-primary btn-lg" style="color: #FFF;">Adicionar uma conta do MercadoLivre <i class="fa fa-arrow-circle-right"></i></a>
            </div>
        </div>
    </p-panel>

  </div>
</div>
