<div id="page-container" class="page-sidebar-fixed page-header-fixed page-content-full-height" style="position: sticky;"
    [ngClass]="sidebar ? 'page-sidebar-toggled' : ''">
    <div id="header" class="header navbar-inverse">

        <div class="navbar-header">
            <a routerLink="/home/dashboard" class="navbar-brand" *ngIf="currentUser.status.code != 'pending'">
                <span class="navbar-logo"></span> <b><span class="text-primary">Be</span>Sales</b>
            </a>
            <a routerLink="/configuracao" class="navbar-brand" *ngIf="currentUser.status.code == 'pending'">
                <span class="navbar-logo"></span> <b><span class="text-primary">Be</span>Sales</b>
            </a>
            <button type="button" class="navbar-toggle" (click)="sidebarToggled()">
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
            </button>
        </div>



        <ul class="navbar-nav navbar-right">
            <li class="navbar-form" *ngIf="currentUser.status.code != 'pending'">
                <form action="" method="POST" name="search">
                    <div class="form-group">
                        <input type="text" class="form-control" placeholder="Como posso ajudar?" />
                        <button type="submit" class="btn btn-search"><i class="fa fa-search"></i></button>
                    </div>
                </form>
            </li>
            <li ngbDropdown class="dropdown" *ngIf="currentUser.status.code != 'pending'">
                <a id="dropdownBasic1" [routerLink]="" ngbDropdownToggle class="dropdown-toggle f-s-14" (click)="abrirNotificacoes()">
                    <i class="fa fa-bell"></i>
                    <span class="label" *ngIf="notifNaoLida > 0">{{notifNaoLida}}</span>
                </a>
                <div class="dropdown-menu media-list dropdown-menu-right" ngbDropdownMenu aria-labelledby="dropdownBasic1">
                    <div class="dropdown-header">NOTIFICAÇÕES ({{notifNaoLida}})</div>

                    <div class="notification-scroll">
                        <div *ngFor="let d of notificacoes" [ngClass]="!d.lido ? 'notification-body notification-not-read' : 'notification-body'">
                            <!-- <a [routerLink]="d.url" class="none"> -->
                                <div class="notification-left">
                                    <i class="fa fa-desktop bg-silver-darker"></i>
                                </div>
                                <div class="notification-container">
                                    <h2>{{d.titulo}}</h2>
                                    <p>{{d.descricao}}</p>
                                    <small class="text-muted">{{d.data | date:'dd/MM/yyyy'}}</small>
                                </div>
                            <!-- </a> -->
                        </div>
                    </div>
                    <div class="dropdown-footer text-center">
                        <a [routerLink]="" (click)="carregarMaisNotificacoes()" *ngIf="!last">Carregar mais</a>
                        <span *ngIf="last">Todas notificações carregadas</span>
                    </div>
                </div>
            </li>
            <li ngbDropdown class="dropdown navbar-user">
                <a id="dropdownBasic2" [routerLink]="" ngbDropdownToggle class="dropdown-toggle">
                    <img src="/assets/images/user.jpeg" alt="" />
                    <span class="d-none d-md-inline">{{currentUser.nome}}</span> <b class="caret"></b>
                </a>
                <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu aria-labelledby="dropdownBasic2">
                    <a href="javascript:;" class="dropdown-item" *ngIf="currentUser.status.code != 'pending'">Editar Perfil</a>
                    <a routerLink="/home/agenda" class="dropdown-item" *ngIf="currentUser.status.code != 'pending'">
                        <span class="badge badge-danger pull-right">2</span>Agenda
                    </a>
                    <a routerLink="/configuracao/geral" class="dropdown-item" *ngIf="currentUser.status.code != 'pending'">Configurações</a>
                    <div class="dropdown-divider" *ngIf="currentUser.status.code != 'pending'"></div>
                    <a (click)="logout()" class="dropdown-item" [routerLink]="">Sair</a>
                </div>
            </li>
        </ul>

    </div>

    <div id="sidebar" class="sidebar" *ngIf="currentUser.status.code != 'pending'">

        <div>

            <ul class="nav">
                <li class="nav-profile" [ngClass]="profile ? 'active' : ''" (click)="navProfile()">
                    <a href="javascript:;" data-toggle="nav-profile">
                        <div class="cover with-shadow"></div>
                        <div class="image">
                            <img src="/assets/images/user.jpeg" alt="" />
                        </div>
                        <div class="info">
                            <b class="caret pull-right"></b>{{currentUser.nome}}
                            <!-- <small>Front end developer</small> -->
                        </div>
                    </a>
                </li>
                <li>
                    <ul class="nav nav-profile" [ngClass]="profile ? 'active' : ''" (click)="navProfile()">
                        <li><a routerLink="/configuracao/geral"><i class="fa fa-cog"></i> Configurações</a></li>
                        <li><a routerLink="/configuracao/geral"><i class="fa fa-pencil-alt"></i> Enviar Feedback</a></li>
                        <li><a routerLink="/configuracao/geral"><i class="fa fa-question-circle"></i> Ajuda</a></li>
                    </ul>
                </li>
            </ul>


            <ul class="nav">
                <li class="nav-header">Menu</li>
                
                <li class="has-sub" [ngClass]="home ? 'active' : ''" (click)="menu('home')">
                    <a [routerLink]="">
                        <b class="caret"></b>
                        <i class="fa fa-home"></i>
                        <span>Home</span>
                    </a>
                    <ul class="sub-menu">
                        <li routerLinkActive="active"><a routerLink="/home/dashboard">Dashboard</a></li>
                        <li routerLinkActive="active"><a routerLink="/home/agenda">Agenda</a></li>
                        <!-- <li routerLinkActive="active"><a routerLink="/store">Integrações</a></li> -->
                        <li routerLinkActive="active"><a routerLink="/home/minha-conta">Minha Conta</a></li>
                        <!-- <li routerLinkActive="active"><a routerLink="/home/upgrade-plano">Upgrade de Plano</a></li> -->
                    </ul>
                </li>
                <li class="has-sub" [ngClass]="crm ? 'active' : ''" (click)="menu('crm')">
                    <a [routerLink]="">
                        <b class="caret"></b>
                        <i class="fa fa-eye"></i>
                        <span>CRM</span>
                    </a>
                    <ul class="sub-menu">
                        <li routerLinkActive="active"><a routerLink="/crm/acao">Ações</a></li>
                        <!-- <li routerLinkActive="active"><a routerLink="/crm/atendimento">Atendimento</a></li> -->
                        <li routerLinkActive="active"><a routerLink="/crm/aniversariante">Aniversariantes</a></li>
                    </ul>
                </li>
                <li class="has-sub" [ngClass]="cadastro ? 'active' : ''" (click)="menu('cadastro')">
                    <a [routerLink]="">
                        <b class="caret"></b>
                        <i class="fa fa-list-ol"></i>
                        <span>Cadastros</span>
                    </a>
                    <ul class="sub-menu">
                        <li routerLinkActive="active"><a routerLink="/cadastro/cliente">Clientes</a></li>
                        <li routerLinkActive="active"><a routerLink="/cadastro/produto">Produtos</a></li>
                        <li routerLinkActive="active"><a routerLink="/cadastro/servico">Serviços</a></li>
                        <li routerLinkActive="active"><a routerLink="/cadastro/categoria">Categorias</a></li>
                        <!-- <li routerLinkActive="active"><a routerLink="/cadastro/anuncio">Anúncios</a></li> -->
                        <li routerLinkActive="active"><a routerLink="/cadastro/fornecedor">Fornecedores</a></li>
                        <li routerLinkActive="active"><a routerLink="/cadastro/transportadora">Transportadoras</a></li>
                        <li routerLinkActive="active"><a routerLink="/cadastro/vendedor">Vendedores</a></li>
                        <li routerLinkActive="active"><a routerLink="/cadastro/funcionario">Funcionários</a></li>
                    </ul>
                </li>
                
                <li class="has-sub" [ngClass]="venda ? 'active' : ''" (click)="menu('venda')">
                    <a [routerLink]="">
                        <b class="caret"></b>
                        <i class="fa fa-cart-plus"></i>
                        <span>Vendas</span>
                    </a>
                    <ul class="sub-menu">
                        <!-- <li routerLinkActive="active"><a routerLink="/venda/crm">CRM</a></li> -->
                        <li routerLinkActive="active"><a routerLink="/venda/pdv">PDV</a></li>
                        <li routerLinkActive="active"><a routerLink="/venda/pedido">Pedido de venda</a></li>
                        <li routerLinkActive="active"><a routerLink="/venda/proposta">Proposta comercial</a></li>
                        <li routerLinkActive="active"><a routerLink="/venda/separacao">Separação</a></li>
                        <li routerLinkActive="active"><a routerLink="/venda/expedicao">Expedição</a></li>
                        <li routerLinkActive="active"><a routerLink="/venda/devolucao">Devolução de Pedido</a></li>
                    </ul>
                </li>

                <li class="has-sub" [ngClass]="financa ? 'active' : ''" (click)="menu('financa')">
                    <a [routerLink]="">
                        <b class="caret"></b>
                        <i class="fa fa-money-bill-alt"></i>
                        <span>Finanças</span>
                    </a>
                    <ul class="sub-menu">
                        <li routerLinkActive="active"><a routerLink="/financa/conta-corrente">Conta Corrente</a></li>
                        <li routerLinkActive="active"><a routerLink="/financa/lancamento">Lançamentos</a></li>
                        <li routerLinkActive="active"><a routerLink="/financa/titulo-pagar">Títulos a Pagar</a></li>
                        <li routerLinkActive="active"><a routerLink="/financa/titulo-receber">Títulos a Receber</a></li>
                        <li routerLinkActive="active"><a routerLink="/financa/centro-custo">Centro de Custo</a></li>
                    </ul>
                </li>

                <li class="has-sub" [ngClass]="servico ? 'active' : ''" (click)="menu('servico')">
                    <a [routerLink]="">
                        <b class="caret"></b>
                        <i class="fa fa-wrench"></i>
                        <span>Serviços</span>
                    </a>
                    <ul class="sub-menu">
                        <li routerLinkActive="active"><a routerLink="/servico/contrato">Contratos</a></li>
                        <li routerLinkActive="active"><a routerLink="/servico/ordem">Ordens de Serviço</a></li>
                        <!-- <li routerLinkActive="active"><a routerLink="/servico/cobranca">Cobranças</a></li> -->
                    </ul>
                </li>

                <li class="has-sub" [ngClass]="marketplace ? 'active' : ''">
                    <a [routerLink]="" (click)="menu('marketplace')">
                        <b class="caret"></b>
                        <i class="fa fa-store"></i>
                        <span>Marketplace</span>
                    </a>
                    <ul class="sub-menu">
                        <li class="has-sub" [ngClass]="mercadolivre ? 'active' : ''">
                            <a [routerLink]="" (click)="submenu('mercadolivre')">
                                <b class="caret"></b>
                                <span>MercadoLivre</span>
                            </a>
                            <ul class="sub-menu">
                                <li routerLinkActive="active"> 
                                    <a routerLink="/marketplace/mercadolivre/concorrente">
                                        <span>Concorrentes</span>
                                    </a>
                                </li>
                                <li class="has-sub" [ngClass]="pergunta ? 'active' : ''">
                                    <a [routerLink]="" (click)="submenu2('pergunta')">
                                        <b class="caret"></b>
                                        <span>Perguntas</span>
                                    </a>
                                    <ul class="sub-menu">
                                        <li routerLinkActive="active"><a routerLink="/marketplace/mercadolivre/pergunta/recebida">Recebidas</a></li>
                                        <li routerLinkActive="active"><a routerLink="/marketplace/mercadolivre/pergunta/responder">Responder</a></li>
                                    </ul>
                                </li>
                                <li class="has-sub" [ngClass]="precificacao ? 'active' : ''">
                                    <a [routerLink]="" (click)="submenu2('precificacao')">
                                        <b class="caret"></b>
                                        <span>Precificação</span>
                                    </a>
                                    <ul class="sub-menu">
                                        <li routerLinkActive="active"><a routerLink="/marketplace/mercadolivre/precificacao/anuncio">Anúncios</a></li>
                                        <li routerLinkActive="active"><a routerLink="/marketplace/mercadolivre/precificacao/performance">Performance</a></li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </li>

                <!-- <li class="has-sub" [ngClass]="pergunta ? 'active' : ''" (click)="menu('pergunta')">
                    <a [routerLink]="">
                        <b class="caret"></b>
                        <i class="fa fa-comments"></i>
                        <span>Perguntas</span>
                    </a>
                    <ul class="sub-menu">
                        <li routerLinkActive="active"><a routerLink="/pergunta/recebida">Recebidas</a></li>
                        <li routerLinkActive="active"><a routerLink="/pergunta/responder">Responder</a></li>
                    </ul>
                </li>
                <li class="has-sub" [ngClass]="precificacao ? 'active' : ''" (click)="menu('precificacao')">
                    <a [routerLink]="">
                        <b class="caret"></b>
                        <i class="fa fa-calculator"></i>
                        <span>Precificação</span>
                    </a>
                    <ul class="sub-menu">
                        <li routerLinkActive="active"><a routerLink="/precificacao/anuncio">Anúncios</a></li>
                        <li routerLinkActive="active"><a routerLink="/precificacao/performance">Performance</a></li>
                    </ul>
                </li> -->

                <!-- <li [ngClass]="concorrente ? 'active' : ''">
                    <a routerLink="/concorrente/home" (click)="menu('concorrente')">
                        <i class="fa fa-users"></i>
                        <span>Concorrentes</span>
                    </a>
                </li> -->
                
                <li [ngClass]="relatorio ? 'active' : ''">
                    <a routerLink="/relatorio/home" (click)="menu('relatorio')">
                        <i class="fa fa-copy"></i>
                        <span>Relatórios</span>
                    </a>
                </li>

                <li [ngClass]="configuracao ? 'active' : ''">
                    <a routerLink="/configuracao/geral" (click)="menu('configuracao')">
                        <i class="fa fa-cog"></i>
                        <span>Configurações</span>
                    </a>
                </li>

                <li [ngClass]="store ? 'active' : ''">
                    <a routerLink="/store" (click)="menu('store')">
                        <i class="fa fa-shopping-bag"></i>
                        <span>BeSales Store</span>
                    </a>
                </li>

                <!-- <li><a href="javascript:;" class="sidebar-minify-btn" data-click="sidebar-minify"><i
                          class="fa fa-angle-double-left"></i></a></li> -->

            </ul>

        </div>

    </div>
    <div class="sidebar-bg"></div>
    <app-page-content></app-page-content>
    <!-- <a href="javascript:;" class="btn btn-icon btn-circle btn-success btn-scroll-to-top fade" data-click="scroll-top">
      <i class="fa fa-angle-up"></i>
  </a> -->
</div>