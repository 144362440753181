export class PagamentoForma {

	public static PIX = new PagamentoForma("pix", "Pix");
    public static DINHEIRO = new PagamentoForma("dinheiro", "Dinheiro");
    public static CARTAO_CREDITO = new PagamentoForma("cc", "Cartão de Crédito");
    public static CARTAO_DEBITO = new PagamentoForma("cd", "Cartão de Débito");
    public static OUTROS = new PagamentoForma("outros", "Outros");

    public code: string;
    public value: string;

    
    constructor(code?: string, value?: string) {
        this.code = code;
        this.value = value;
    }
}
