export class PropostaStatus {

	public static ABERTO: PropostaStatus = new PropostaStatus("aberto", "Aberto");
    public static RASCUNHO: PropostaStatus = new PropostaStatus("rascunho", "Rascunho");
    public static PENDENTE: PropostaStatus = new PropostaStatus("pendente", "Pendente");
    public static AGUARDANDO: PropostaStatus = new PropostaStatus("aguardando", "Aguardando");
    public static APROVADO: PropostaStatus = new PropostaStatus("aprovado", "Aprovado");
    public static REPROVADO: PropostaStatus = new PropostaStatus("reprovado", "Reprovado");
    public static CONCLUIDO: PropostaStatus = new PropostaStatus("concluido", "Concluído");
  

    public code: string;
    public value: string;

    constructor(code: string, value: string) {
        this.code = code;
        this.value = value;
    }
}
