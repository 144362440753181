import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from './_helpers';
import { LayoutPublicComponent } from './suporte/layout-public/layout-public.component';
import { LayoutPrivateComponent } from './suporte/layout-private/layout-private.component';
import { CadastroComponent } from './inicio/cadastro/cadastro.component';
import { ConfiguracaoComponent } from './inicio/configuracao/configuracao.component';
import { LoginComponent } from './inicio/login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ClienteComponent } from './cadastro/cliente/cliente.component';
import { ProdutoComponent } from './cadastro/produto/produto.component';
import { ServicoComponent } from './cadastro/servico/servico.component';
import { AnuncioComponent } from './cadastro/anuncio/anuncio.component';
import { FornecedorComponent } from './cadastro/fornecedor/fornecedor.component';
import { TransportadoraComponent } from './cadastro/transportadora/transportadora.component';
import { VendedorComponent } from './cadastro/vendedor/vendedor.component';
import { FuncionarioComponent } from './cadastro/funcionario/funcionario.component';
import { CrmComponent } from './crm/acao/crm.component';
import { PdvComponent } from './venda/pdv/pdv.component';
import { PedidoComponent } from './venda/pedido/pedido.component';
import { PropostaComponent } from './venda/proposta/proposta.component';
import { SeparacaoComponent } from './venda/separacao/separacao.component';
import { ExpedicaoComponent } from './venda/expedicao/expedicao.component';
import { DevolucaoComponent } from './venda/devolucao/devolucao.component';
import { ContaCorrenteComponent } from './financa/conta-corrente/conta-corrente.component';
import { LancamentoComponent } from './financa/lancamento/lancamento.component';
import { TituloPagarComponent } from './financa/titulo-pagar/titulo-pagar.component';
import { TituloReceberComponent } from './financa/titulo-receber/titulo-receber.component';
import { CentroCustoComponent } from './financa/centro-custo/centro-custo.component';
import { GeralComponent } from './configuracao/geral/geral.component';
import { UsuarioComponent } from './configuracao/usuario/usuario.component';
import { IntegracaoComponent } from './configuracao/integracao/integracao.component';
import { AuditoriaComponent } from './configuracao/auditoria/auditoria.component';
import { RecuperarSenhaComponent } from './inicio/recuperar-senha/recuperar-senha.component';
import { CategoriaComponent } from './cadastro/categoria/categoria.component';
import { AgendaComponent } from './home/agenda/agenda.component';
import { MinhacontaComponent } from './home/minhaconta/minhaconta.component';
import { UpgradeComponent } from './home/upgrade/upgrade.component';
import { StoreComponent } from './store/store.component';
import { ContratoComponent } from './servico/contrato/contrato.component';
import { OrdemComponent } from './servico/ordem/ordem.component';
import { CobrancaComponent } from './servico/cobranca/cobranca.component';
import { RecebidaComponent } from './pergunta/recebida/recebida.component';
import { ResponderComponent } from './pergunta/responder/responder.component';
import { PrecificacaoAnuncioComponent } from './precificacao/anuncio/anuncio.component';
import { PrecificacaoPerformanceComponent } from './precificacao/performance/performance.component';
import { ConcorrenteHomeComponent } from './concorrente/home/home.component';
import { RelatorioHomeComponent } from './relatorio/home/home.component';
import { MercadoLivreInitializeComponent } from './store/mercadolivre/mercadolivre.initialize.component';
import { AniversarianteComponent } from './crm/aniversariante/aniversariante.component';

const routes: Routes = [
  { path: 'home/dashboard', component: LayoutPrivateComponent, children: [{ path: '', component: DashboardComponent, pathMatch: 'full'}], canActivate: [AuthGuard], data: { title: 'Dashboard' } },
  { path: 'home/agenda', component: LayoutPrivateComponent, children: [{ path: '', component: AgendaComponent, pathMatch: 'full'}], canActivate: [AuthGuard], data: { title: 'Dashboard' } },
  { path: 'home/minha-conta', component: LayoutPrivateComponent, children: [{ path: '', component: MinhacontaComponent, pathMatch: 'full'}], canActivate: [AuthGuard], data: { title: 'Dashboard' } },
  { path: 'home/upgrade-plano', component: LayoutPrivateComponent, children: [{ path: '', component: UpgradeComponent, pathMatch: 'full'}], canActivate: [AuthGuard], data: { title: 'Dashboard' } },
  
  { path: 'cadastro/cliente', component: LayoutPrivateComponent, children: [{ path: '', component: ClienteComponent, pathMatch: 'full'}], canActivate: [AuthGuard], data: { title: 'Cadastro Cliente' } },
  { path: 'cadastro/produto', component: LayoutPrivateComponent, children: [{ path: '', component: ProdutoComponent, pathMatch: 'full'}], canActivate: [AuthGuard], data: { title: 'Cadastro Produto' } },
  { path: 'cadastro/servico', component: LayoutPrivateComponent, children: [{ path: '', component: ServicoComponent, pathMatch: 'full'}], canActivate: [AuthGuard], data: { title: 'Cadastro Serviço' } },
  { path: 'cadastro/categoria', component: LayoutPrivateComponent, children: [{ path: '', component: CategoriaComponent, pathMatch: 'full'}], canActivate: [AuthGuard], data: { title: 'Cadastro Categoria' } },
  { path: 'cadastro/anuncio', component: LayoutPrivateComponent, children: [{ path: '', component: AnuncioComponent, pathMatch: 'full'}], canActivate: [AuthGuard], data: { title: 'Cadastro Anuncio' } },
  { path: 'cadastro/fornecedor', component: LayoutPrivateComponent, children: [{ path: '', component: FornecedorComponent, pathMatch: 'full'}], canActivate: [AuthGuard], data: { title: 'Cadastro Fornecedor' } },
  { path: 'cadastro/transportadora', component: LayoutPrivateComponent, children: [{ path: '', component: TransportadoraComponent, pathMatch: 'full'}], canActivate: [AuthGuard], data: { title: 'Cadastro Transportadora' } },
  { path: 'cadastro/vendedor', component: LayoutPrivateComponent, children: [{ path: '', component: VendedorComponent, pathMatch: 'full'}], canActivate: [AuthGuard], data: { title: 'Cadastro Vendedor' } },
  { path: 'cadastro/funcionario', component: LayoutPrivateComponent, children: [{ path: '', component: FuncionarioComponent, pathMatch: 'full'}], canActivate: [AuthGuard], data: { title: 'Cadastro Funcionario' }},
  
  { path: 'crm/acao', component: LayoutPrivateComponent, children: [{ path: '', component: CrmComponent, pathMatch: 'full'}], canActivate: [AuthGuard] },
  { path: 'crm/aniversariante', component: LayoutPrivateComponent, children: [{ path: '', component: AniversarianteComponent, pathMatch: 'full'}], canActivate: [AuthGuard] },

  { path: 'venda/pdv', component: LayoutPrivateComponent, children: [{ path: '', component: PdvComponent, pathMatch: 'full'}], canActivate: [AuthGuard] },
  { path: 'venda/pedido', component: LayoutPrivateComponent, children: [{ path: '', component: PedidoComponent, pathMatch: 'full'}], canActivate: [AuthGuard] },
  { path: 'venda/proposta', component: LayoutPrivateComponent, children: [{ path: '', component: PropostaComponent, pathMatch: 'full'}], canActivate: [AuthGuard] },
  { path: 'venda/separacao', component: LayoutPrivateComponent, children: [{ path: '', component: SeparacaoComponent, pathMatch: 'full'}], canActivate: [AuthGuard] },
  { path: 'venda/expedicao', component: LayoutPrivateComponent, children: [{ path: '', component: ExpedicaoComponent, pathMatch: 'full'}], canActivate: [AuthGuard] },
  { path: 'venda/devolucao', component: LayoutPrivateComponent, children: [{ path: '', component: DevolucaoComponent, pathMatch: 'full'}], canActivate: [AuthGuard] },
  
  { path: 'financa/conta-corrente', component: LayoutPrivateComponent, children: [{ path: '', component: ContaCorrenteComponent, pathMatch: 'full'}], canActivate: [AuthGuard] },
  { path: 'financa/lancamento', component: LayoutPrivateComponent, children: [{ path: '', component: LancamentoComponent, pathMatch: 'full'}], canActivate: [AuthGuard] },
  { path: 'financa/titulo-pagar', component: LayoutPrivateComponent, children: [{ path: '', component: TituloPagarComponent, pathMatch: 'full'}], canActivate: [AuthGuard] },
  { path: 'financa/titulo-receber', component: LayoutPrivateComponent, children: [{ path: '', component: TituloReceberComponent, pathMatch: 'full'}], canActivate: [AuthGuard] },
  { path: 'financa/centro-custo', component: LayoutPrivateComponent, children: [{ path: '', component: CentroCustoComponent, pathMatch: 'full'}], canActivate: [AuthGuard] },
  
  { path: 'servico/contrato', component: LayoutPrivateComponent, children: [{ path: '', component: ContratoComponent, pathMatch: 'full'}], canActivate: [AuthGuard] },
  { path: 'servico/ordem', component: LayoutPrivateComponent, children: [{ path: '', component: OrdemComponent, pathMatch: 'full'}], canActivate: [AuthGuard] },
  { path: 'servico/cobranca', component: LayoutPrivateComponent, children: [{ path: '', component: CobrancaComponent, pathMatch: 'full'}], canActivate: [AuthGuard] },
  
  // { path: 'pergunta/recebida', component: LayoutPrivateComponent, children: [{ path: '', component: RecebidaComponent, pathMatch: 'full'}], canActivate: [AuthGuard] },
  // { path: 'pergunta/responder', component: LayoutPrivateComponent, children: [{ path: '', component: ResponderComponent, pathMatch: 'full'}], canActivate: [AuthGuard] },
  { path: 'marketplace/mercadolivre/pergunta/recebida', component: LayoutPrivateComponent, children: [{ path: '', component: RecebidaComponent, pathMatch: 'full'}], canActivate: [AuthGuard] },
  { path: 'marketplace/mercadolivre/pergunta/responder', component: LayoutPrivateComponent, children: [{ path: '', component: ResponderComponent, pathMatch: 'full'}], canActivate: [AuthGuard] },
  
  // { path: 'precificacao/anuncio', component: LayoutPrivateComponent, children: [{ path: '', component: PrecificacaoAnuncioComponent, pathMatch: 'full'}], canActivate: [AuthGuard] },
  // { path: 'precificacao/performance', component: LayoutPrivateComponent, children: [{ path: '', component: PrecificacaoPerformanceComponent, pathMatch: 'full'}], canActivate: [AuthGuard] },
  { path: 'marketplace/mercadolivre/precificacao/anuncio', component: LayoutPrivateComponent, children: [{ path: '', component: PrecificacaoAnuncioComponent, pathMatch: 'full'}], canActivate: [AuthGuard] },
  { path: 'marketplace/mercadolivre/precificacao/performance', component: LayoutPrivateComponent, children: [{ path: '', component: PrecificacaoPerformanceComponent, pathMatch: 'full'}], canActivate: [AuthGuard] },
  
  { path: 'marketplace/mercadolivre/concorrente', component: LayoutPrivateComponent, children: [{ path: '', component: ConcorrenteHomeComponent, pathMatch: 'full'}], canActivate: [AuthGuard] },
  
  { path: 'relatorio/home', component: LayoutPrivateComponent, children: [{ path: '', component: RelatorioHomeComponent, pathMatch: 'full'}], canActivate: [AuthGuard] },
  
  { path: 'configuracao/geral', component: LayoutPrivateComponent, children: [{ path: '', component: GeralComponent, pathMatch: 'full'}], canActivate: [AuthGuard] },
  { path: 'configuracao/usuario', component: LayoutPrivateComponent, children: [{ path: '', component: UsuarioComponent, pathMatch: 'full'}], canActivate: [AuthGuard] },
  { path: 'configuracao/integracao', component: LayoutPrivateComponent, children: [{ path: '', component: IntegracaoComponent, pathMatch: 'full'}], canActivate: [AuthGuard] },
  { path: 'configuracao/auditoria', component: LayoutPrivateComponent, children: [{ path: '', component: AuditoriaComponent, pathMatch: 'full'}], canActivate: [AuthGuard] },
  
  { path: 'store', component: LayoutPrivateComponent, children: [{ path: '', component: StoreComponent, pathMatch: 'full'}], canActivate: [AuthGuard] },
  { path: 'initialize', component: LayoutPrivateComponent, children: [{ path: '', component: MercadoLivreInitializeComponent, pathMatch: 'full'}], canActivate: [AuthGuard] },
  { path: 'store/mercado-libre', component: LayoutPrivateComponent, children: [{ path: '', component: StoreComponent, children: [{ path: '', component: MercadoLivreInitializeComponent, pathMatch: 'full'}]}], canActivate: [AuthGuard] },
  
  { path: 'configuracao', component: LayoutPrivateComponent, children: [{ path: '', component: ConfiguracaoComponent, pathMatch: 'full'}], canActivate: [AuthGuard] },
  { path: 'login', component: LayoutPublicComponent, children: [{ path: '', component: LoginComponent, pathMatch: 'full'}], },
  { path: 'recuperar-senha', component: LayoutPublicComponent, children: [{ path: '', component: RecuperarSenhaComponent, pathMatch: 'full'}], },
  { path: 'cadastro', component: LayoutPublicComponent, children: [{ path: '', component: CadastroComponent, pathMatch: 'full'}], },
  
  // otherwise redirect to home
  { path: '**', redirectTo: 'home/dashboard' +
  '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
