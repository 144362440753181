<div class="bs-dialog-header">
  {{title}}
</div>

<div class="bs-dialog-main">
  <div class="bs-dialog-content">

    <p>{{message}}</p>

  </div>
</div>

<div class="bs-dialog-footer">
  <div class="bs-footer-action">
    <button (click)="onDismiss()" class="btn btn-white">Cancelar</button>
    <button (click)="onConfirm()" class="btn btn-primary">Confirmar</button>
  </div>
</div>