<div id="content" class="content content-full-width">

    <h1 class="page-header">Gerenciamento de Concorrentes</h1>

    <div class="vertical-box with-grid inbox bg-light">

        <div class="vertical-box-column width-200">
            <div class="vertical-box">

                <div class="wrapper d-none d-sm-none d-md-none d-lg-block">
                    <div class="d-flex align-items-center justify-content-center">
                        <button (click)="openDialog(null)" class="btn btn-primary p-l-40 p-r-40 btn-sm" pTooltip="Adicionar novo concorrente" tooltipPosition="bottom">
                            Adicionar <i class="pl-1 fa fa-plus-circle"></i>
                        </button>
                    </div>
                </div>

                <div class="vertical-box-row d-lg-table-row collapse">
                    <div class="vertical-box-cell">
                        <div class="vertical-box-inner-cell">
                            <div class="keras-scrollbar">
                                <div class="d-flex align-items-center justify-content-center p-10">
                                    Menu
                                </div>
                                <div class="wrapper p-0">
                                    <ul class="nav nav-inbox">
                                        <li [ngClass]="activeIndex == 0 ? 'active' : ''"><a (click)="activeIndex = 0" href="javascript:;">Concorrentes</a></li>
                                        <li [ngClass]="activeIndex == 1 ? 'active' : ''"><a (click)="activeIndex = 1" href="javascript:;">Performance</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

        <div class="vertical-box-column">
            <div class="vertical-box">


                <div class="wrapper">
                    <div class="input-group">
                        <button class="btn btn-sm btn-white mr-2 d-none d-sm-none d-md-none d-lg-block" (click)="find()" pTooltip="Atualizar" tooltipPosition="bottom">
                            <i class="pi pi-undo"></i>
                        </button>

                        <input type="text" name="name"
                             placeholder="{{'search.placeholder' | translate}}" class="form-control m-r-3" style="height: 29px;">

                        <div class="btn-group ml-auto">
                            <label style="margin: 0px 10px 0px; padding-top: 6px;" *ngIf="!showEmpty && !loading && !sloading">
                                {{data?.paginator?.intervalBegin}}-{{data?.paginator?.intervalEnd}} de {{data?.paginator?.totalResult}}
                            </label>
                        </div>
                        <div class="btn-group ml-0">
                            <button (click)="paging(data?.paginator?.currentPage - 1)" tooltipPosition="bottom" pTooltip="Anteriores" [disabled]="data?.paginator?.currentPage == 1" class="btn btn-white btn-sm">
                                <i class="pi pi-chevron-left"></i>
                            </button>
                            <button (click)="paging(data?.paginator?.currentPage + 1)" tooltipPosition="bottom" pTooltip="Próximas" [disabled]="data?.paginator?.currentPage == data?.paginator?.totalPages" class="btn btn-white btn-sm">
                                <i class="pi pi-chevron-right"></i>
                            </button>
                        </div>
                    </div>
                </div>

                <div class="vertical-box-row">
                    <div class="vertical-box-cell">
                        <div class="vertical-box-inner-cell bg-white">
                            <div class="keras-scrollbar">

                                <div class="p-40 fa-3x" style="position: absolute; left: 44%;" *ngIf="loading || sloading">
                                    <i class="fas fa-spinner fa-pulse"></i>
                                </div>

                                <div class="bs-configuracao bs-dashboard" *ngIf="data?.paginator?.totalResult > 0">
                                    <div class="col-12">
                                        <p-tabView [(activeIndex)]="activeIndex">
                                    
                                            <p-tabPanel>
                                                
                                                <div class="row">
                                                    <div class="col-xl-12 col-md-12" *ngFor="let d of data?.result">
                                                        <div class="widget widget-stats bg-bs-white">
                                                            <div class="row">
                                                                <div class="col-12">
                                                                    <h5 class="m-b-15">{{d?.seller?.nickname}}</h5>
                                                                    <div class="row m-0" style="color: #777;">
                                                                        <i class="fas fa-lg fa-fw m-t-2 m-r-5 fa-medal"></i> <span class="text-capitalize"><b>{{d?.seller?.seller_reputation?.power_seller_status}}</b></span>
                                                                        <i class="fas fa-lg fa-fw m-l-20 m-t-2 m-r-5 fa-ban"></i> <span>Transações canceladas: <b>{{d?.seller?.seller_reputation?.transactions?.canceled}}</b></span>
                                                                        <i class="fas fa-lg fa-fw m-l-20 m-t-2 m-r-5 fa-check-circle"></i> <span>Transações concluídas: <b>{{d?.seller?.seller_reputation?.transactions?.completed}}</b></span>
                                                                        <i class="fas fa-lg fa-fw m-l-20 m-t-2 m-r-5 fa-cubes"></i> <span>Total de Transações: <b>{{d?.seller?.seller_reputation?.transactions?.total}}</b></span>
                                                                      </div>
                                                                </div>
                                                            </div>
                                                            
                                                            <div class="stats-link">
                                                                <a href="javascript:;" (click)="openDialogDetail(d);">Ver Detalhes <i class="fa fa-arrow-alt-circle-right"></i></a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </p-tabPanel>
                                            
                                
                                            <p-tabPanel>
                                                <div class="row">                                                    
                                                    <div class="col-3">
                                                        <div class="widget widget-stats bg-gradient-orange m-b-10">
                                                            <div class="stats-icon stats-icon-lg"><i class="fa fa-file-alt fa-fw"></i>
                                                            </div>
                                                            <div class="stats-content">
                                                                <div class="stats-title">SALDO ATUAL</div>
                                                                <div class="stats-number">7,842,900</div>
                                                                <div class="stats-progress progress">
                                                                    <div class="progress-bar" style="width: 70.1%;"></div>
                                                                </div>
                                                                <div class="stats-desc">Better than last week (70.1%)</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-3">
                                                        <div class="widget widget-stats bg-gradient-blue m-b-10">
                                                            <div class="stats-icon stats-icon-lg"><i class="fa fa-globe fa-fw"></i>
                                                            </div>
                                                            <div class="stats-content">
                                                                <div class="stats-title">CONTAS A PAGAR</div>
                                                                <div class="stats-number">7,842,900</div>
                                                                <div class="stats-progress progress">
                                                                    <div class="progress-bar" style="width: 70.1%;"></div>
                                                                </div>
                                                                <div class="stats-desc">Better than last week (70.1%)</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-3">
                                                        <div class="widget widget-stats bg-gradient-blue m-b-10">
                                                            <div class="stats-icon stats-icon-lg"><i class="fa fa-archive fa-fw"></i>
                                                            </div>
                                                            <div class="stats-content">
                                                                <div class="stats-title">CONTAS A RECEBER</div>
                                                                <div class="stats-number">7,842,900</div>
                                                                <div class="stats-progress progress">
                                                                    <div class="progress-bar" style="width: 0%;"></div>
                                                                </div>
                                                                <div class="stats-desc">Better than last week (70.1%)</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-3">
                                                        <div class="widget widget-stats bg-gradient-purple m-b-10">
                                                            <div class="stats-icon stats-icon-lg"><i class="fa fa-dollar-sign fa-fw"></i>
                                                            </div>
                                                            <div class="stats-content">
                                                                <div class="stats-title">CONTAS VENCENDO HOJE</div>
                                                                <div class="stats-number">7,842,900</div>
                                                                <div class="stats-progress progress">
                                                                    <div class="progress-bar" style="width: 70.1%;"></div>
                                                                </div>
                                                                <div class="stats-desc">Better than last week (70.1%)</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row m-t-20">
                                                    <div class="col-6">
                                                        <p-panel header="Fluxo de caixa">
                                                            <h4 class="m-b-30 m-t-10">R$ 1.454,342,92</h4>
                                                            <p-chart type="bar" [data]="chtTotalVendas" [options]="barChartOptions"></p-chart>
                                                        </p-panel>
                                                    </div>
                                                    <div class="col-6">
                                                        <p-panel header="Contas a Pagar x a Receber ">
                                                            <h4 class="m-b-30 m-t-10">R$ 42,92</h4>
                                                            <p-chart type="bar" [data]="chtTicketMedio" [options]="barChartOptions"></p-chart>
                                                        </p-panel>
                                                    </div>
                                                </div>                                                
                                                
                                            </p-tabPanel>
                                        </p-tabView>
                                    </div>
                                    
                                </div>                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>    
        </div>
    </div>
</div>