import { Component, HostListener, OnInit } from '@angular/core';
import { BaseServiceDialog } from 'src/app/_services/base.service.dialog';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng-lts/dynamicdialog';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-concorrente.dialog',
  templateUrl: './concorrente.dialog.component.html'
})
export class ConcorrenteDialogComponent extends BaseServiceDialog implements OnInit {

  public q: string = "";
  public sellers: any[] = [];

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public dialogService: DialogService,
    public http: HttpClient,
    public toastr: ToastrService,
    public translate: TranslateService) {

      super('integracao/mercadolibre/concorrente', http, toastr, translate, ref, config.data);
  }

  ngOnInit(): void {
    this.loading = true;
    this.http.get<any>(`${environment.apiUrl}/${this.mapping}/list-by-account`).subscribe(
        data => {console.log(data);
          if (data != null) {
            for (let concorrente of data) {
              this.sellers.push(concorrente.seller);
            }
          }
          this.loading = false;
        },
        error => {
          this.loading = false;
          this.toastr.error('Não foi possível carregar o registro');
        }
    );
  }

  public saveClose(): void {
    try {
      this.http.post<any>(`${environment.apiUrl}/${this.mapping}/save-all`, this.sellers).subscribe(
          data => {
            this.datas.reload = true;
            this.recording = false;
            this.close();
          },
          error => {
              this.recording = false;
          }
      );
    }
    catch (e) {
        this.recording = false;
        this.toastr.error('', e.message);
    }
  }

  public remove(seller: any): void {
    this.sellers.splice(this.sellers.indexOf(seller), 1);
  }

  public buscarConcorrente(): void {
    this.loading = true;
    var text = "";

    if(this.q.indexOf("http") > -1 || this.q.indexOf("produto") > -1){
        text = this.q.substring(this.q.indexOf("-") + 1);
        text = "MLB"+text.substring(0, text.indexOf("-"));
    }
    else if (this.q.startsWith("MLB")) {
      text = this.q.replace('-', '');
    }
    else{
        text = this.q;
    }

    if(this.q.indexOf("MLB") === -1){
        text = "MLB"+text;
    }

    const regex: RegExp = /^ML[A-Za-z][0-9]{2,10}$/;

    if (regex.test(text)) {
      this.http.get<any>("https://api.mercadolibre.com/items/" + text, {withCredentials:false}).subscribe(
        data => {
          if (data != null && data.seller_id) {
            this.http.get<any>("https://api.mercadolibre.com/users/" + data.seller_id, {withCredentials:false}).subscribe(
              data => {
                  this.add(data);
                  this.loading = false;
                },
                error => {
                  this.loading = false;
                }
              );
            }
        },
        error => {
          this.loading = false;
          this.toastr.error('Não foi possível encontrar informações do concorrente.');
        }
      );
    }
    else {
      this.loading = false;
      this.toastr.error('Não foi possível encontrar informações do concorrente.');
    }
  }
  
  public add(seller: any): void {
    let exists: boolean = false;

    if (seller) {
      this.sellers.forEach(data => {
        if (data.id == seller.id) {
          exists = true;
        }
      });

      if (!exists) {
        this.sellers.push(seller);
      }
      else {
        this.toastr.error('Concorrente já cadastrado.');
      }
      this.q = '';
    }
  }

  @HostListener('window:keydown.control.s', ['$event'])
  keSaveClose(event: KeyboardEvent) {
  }

  @HostListener('window:keydown.esc', ['$event'])
  keClose(event: KeyboardEvent) {
    this.dialogRef.close();
  }
}