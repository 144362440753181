import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from '@environments/environment';
import { first } from 'rxjs/operators';
import { StoreComponent } from '../store.component';

@Component({
  selector: 'app-mercadolivre.initialize',
  templateUrl: './mercadolivre.initialize.component.html'
})
export class MercadoLivreInitializeComponent {
 
  public code: string;
  public mlUrl: string;

  constructor(
    public app: StoreComponent,
    public http: HttpClient,
    private activatedRoute: ActivatedRoute) {

      this.mlUrl = environment.mlUrl;
  }

  ngOnInit() {
    this.app.activeIndex = -1;

    this.activatedRoute.queryParams
      .subscribe(params => {
        this.code = params.code;
      }
    );

    if(this.code !== null && this.code != undefined) {
      this.http.get(`${environment.apiUrl}/integracao/mercadolibre/conectar/${this.code}`)
        .pipe(first())
        .subscribe(
          data => {
            console.log(data);
          },
          error => {
            console.log(error);
        }
      );
    }
  }
}