import { HttpClient } from '@angular/common/http';
import { Component, HostListener, OnInit } from '@angular/core';
import { environment } from '@environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng-lts/dynamicdialog';
import { ContaCorrente } from 'src/app/_entity/financa/contaCorrente';
import { KeyValue } from 'src/app/_entity/suporte/keyValue';
import { BaseServiceDialog } from 'src/app/_services/base.service.dialog';

@Component({
  selector: 'app-conta-corrente.dialog',
  templateUrl: './conta-corrente.dialog.component.html'
})
export class ContaCorrenteDialogComponent extends BaseServiceDialog implements OnInit {

  public banco: any = null;
  public data: ContaCorrente = null;

  public autocompleteBanco: any[] = [];

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public http: HttpClient,
    public toastr: ToastrService,
    public translate: TranslateService) {

    super('financa/conta-corrente', http, toastr, translate, ref, config.data);

    this.datas = config.data;
    this.data = this.datas.data;
  }

  ngOnInit(): void {
    // Caso seja uma iniciativa ja criada para edicao, busca a ultima
    // versao no banco de dados e apos inicializa os autocompletes
    if (this.data && this.data._id) {
      this.find(this.data._id);
    }
    // Caso seja uma nova apenas inicializa os autocompletes
    else {
      this.createData();
    }
  }

  public afterFind(): void {
      if (this.data.bancos) {
        this.banco = this.data.bancos[0];
      }
  }

  public beforeSave(callback: (valid: boolean) => void): void {
    if (!this.data || !this.data.idBanco) {
      this.toastr.error('Campo Banco deve ser preenchido.');
      callback(false);
    }
    if (!this.data || !this.data.agencia) {
      this.toastr.error('Campo Agência deve ser preenchido.');
      callback(false);
    }
    if (!this.data || !this.data.conta) {
      this.toastr.error('Campo Número Conta deve ser preenchido.');
      callback(false);
    }
    if (!this.data || !this.data.contaDigito) {
      this.toastr.error('Campo Dígito Conta deve ser preenchido.');
      callback(false);
    }
    else {
      callback(true);
    }
  }

  public onSelectBanco(): void {
    if (this.banco) {
      this.data.idBanco = this.banco._id;
    }
  }

  public acFindBanco(event): void {
    this.http.get<any>(`${environment.apiUrl}/support/dominio/banco/${event.query}`).subscribe(
      data => {
          this.autocompleteBanco = data;
      },
      error => {
      }
    );
  }

  public createData(): void {
    if (this.data == null) {
      this.data = new ContaCorrente;
    }
    if (this.data.tipo == null) {
      this.data.tipo = 'corrente';
    }
  }

  @HostListener('window:keydown.control.enter', ['$event'])
  keSave(event: KeyboardEvent) {
    this.save();
  }

  @HostListener('window:keydown.control.s', ['$event'])
  keSaveClose(event: KeyboardEvent) {
    this.saveClose();
  }

  @HostListener('window:keydown.esc', ['$event'])
  keClose(event: KeyboardEvent) {
    this.dialogRef.close();
  }
}