<div class="bs-dialog-header">
  <i class="fa fa-sitemap"></i> CATEGORIAS <small> Gestão de Categorias </small>
</div>

<div class="bs-dialog-main">
  <div class="bs-dialog-content">
  
    <!-- Carregando informações... -->
    <div class="modal-overlay" *ngIf="loading">
      <div class="alert alert-light fade show m-b-0 text-center" style="color: #222;">
        <i class="fas fa-circle-notch fa-spin"></i> 
      </div>
    </div>

    <div class="be-form">

      <div class="row">
        <div class="col-4">
          <label>Origem: </label>
          <select [(ngModel)]="data.origem.code" class="form-control">
            <option value="produto">Produtos</option>
            <option value="servico">Serviços</option>
            <option value="financa">Finanças</option>
          </select>
        </div>
        <div class="col-8">
          <label>Categoria Pai:</label>
          <p-autoComplete [(ngModel)]="data.categoriaPai" (onBlur)="onInputCategoriaPaiBlur()" field="descricao" [suggestions]="categorias" (completeMethod)="autocomplete($event)" autoHighlight="true" forceSelection="true" emptyMessage="Categoria não encontrada" class="be-autocomplete">
            <ng-template let-categoria pTemplate="item">
              {{categoria.arvoreCategoriaTexto ? categoria.arvoreCategoriaTexto + ' > ' : ''}} {{categoria.descricao}}
            </ng-template>
          </p-autoComplete>

        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <label>Descrição:</label>
          <input type="text" [(ngModel)]="data.descricao" class="form-control">
        </div>
      </div>
      <div class="row">
        <div class="col-8">
          <ng-container *ngIf="data.categoriaPai">
            <label>{{data.categoriaPai?.arvoreCategoriaTexto ? data.categoriaPai?.arvoreCategoriaTexto + ' > ' : ''}} {{data.categoriaPai?.descricao}} {{data.descricao ? ' > ' + data.descricao  : ''}}</label>
          </ng-container>
          <ng-container *ngIf="!data.categoriaPai">
            <label>{{arvoreCategoria}} {{data.descricao ? ' > ' + data.descricao  : ''}}</label>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="bs-dialog-footer">
  <div class="bs-footer-action-close">
    <button (click)="close()" class="btn btn-white" pTooltip="Esc" tooltipPosition="bottom">Fechar</button>
  </div>
  <div class="bs-footer-action">
    <button (click)="save()" [disabled]="recording" class="btn btn-white" pTooltip="Ctrl + Enter" tooltipPosition="bottom">Salvar e criar outro</button>
    <button (click)="saveClose()" [disabled]="recording" class="btn btn-primary" pTooltip="Ctrl + S" tooltipPosition="bottom">Salvar</button>
  </div>
</div>