<div class="bs-dialog-header d-flex justify-content-between">
  FATURA #{{ ('0000000' + (data?.numeroPedido)).slice(-7) }}
</div>

<div class="bs-dialog-main">
  <div class="bs-dialog-content">

    <!-- Carregando informações... -->
    <div class="modal-overlay" *ngIf="loading">
      <div class="alert alert-light fade show m-b-0 text-center" style="color: #222;">
        <i class="fas fa-circle-notch fa-spin"></i>
      </div>
    </div>

    <div class="row" *ngIf="!loading">
      <div class="col-12">
        <div class="invoice" id="conteudo-pdf">

          <div class="invoice-company">
              {{org?.nome}}
          </div>


          <div class="invoice-header">
              <div class="invoice-from">
                  <small>de</small>
                  <address class="m-t-5 m-b-5">
                      <strong class="text-inverse">{{org?.nome}}</strong><br>
                      {{org?.endereco?.logradouro}} {{org?.endereco?.numero}} {{org?.endereco?.complemento}}<br>
                      {{org?.endereco?.cidade}} {{org?.endereco?.estado}}<br>
                      {{org?.endereco?.cep}}<br>
                      <!-- Telefone: {{org?.telefone}} -->
                  </address>
              </div>
              <div class="invoice-to">
                  <small>para</small>
                  <address class="m-t-5 m-b-5">
                      <strong class="text-inverse">{{contato?.nome}}</strong><br>
                      {{contato?.endereco?.logradouro}} {{contato?.endereco?.numero}} {{contato?.endereco?.complemento}}<br>
                      {{contato?.endereco?.cidade}} {{contato?.endereco?.estado}}<br>
                      {{contato?.endereco?.cep}}<br>
                      <!-- Telefone: {{contato?.telefone}} -->
                  </address>
              </div>
              <div class="invoice-date">
                  <small>Fatura / Período</small>
                  <div class="date text-inverse m-t-5">{{ data.dtPedido | date: 'dd/MM/yyyy' }}</div>
                  <div class="invoice-detail">
                    #{{ ('0000000' + (data?.numeroPedido)).slice(-7) }}<br>
                      Serviços / Produtos
                  </div>
              </div>
          </div>


          <div class="invoice-content">

              <div class="table-responsive">
                  <table class="table table-invoice">
                      <thead>
                          <tr>
                              <th>DESCRIÇÃO</th>
                              <th class="text-center" width="10%">VALOR</th>
                              <th class="text-center" width="10%">QTE.</th>
                              <th class="text-right" width="20%">SUB TOTAL</th>
                          </tr>
                      </thead>
                      <tbody>
                          <tr *ngFor="let d of data?.pedidoItens; let i = index">
                              <td>
                                  <span class="text-inverse">{{d.nomeProduto}}</span>
                              </td>
                              <td class="text-center">R$ {{d.valorUnitario | number:'1.2-2'}}</td>
                              <td class="text-center">{{d.quantidade | number:'1.2-5'}}</td>
                              <td class="text-right">R$ {{d.valorTotal | number:'1.2-2'}}</td>
                          </tr>
                      </tbody>
                  </table>
              </div>


              <div class="invoice-price">
                  <div class="invoice-price-left">
                      <div class="invoice-price-row">
                          <div class="sub-price">
                              <small>SUB TOTAL</small>
                              <span class="text-inverse">R$ {{data.valorSubTotal | number:'1.2-2'}}</span>
                          </div>
                          <div class="sub-price">
                              <i class="fa fa-minus text-muted"></i>
                          </div>
                          <div class="sub-price">
                              <small>DESCONTO</small>
                              <span class="text-inverse">R$ {{data.valorDesconto | number:'1.2-2'}}</span>
                          </div>
                      </div>
                  </div>
                  <div class="invoice-price-right">
                      <small>TOTAL</small> <span class="f-w-600">R$ {{data.valorTotal | number:'1.2-2'}}</span>
                  </div>
              </div>

          </div>


          <!-- <div class="invoice-note">
              * Make all cheques payable to [Your Company Name]<br>
              * Payment is due within 30 days<br>
              * If you have any questions concerning this invoice, contact [Name, Phone Number, Email]
          </div> -->


          <div class="invoice-footer">
              <p class="text-center m-b-5 f-w-600">
                {{org?.nome}}
              </p>
              <p class="text-center">
                  <!-- <span class="m-r-10"><i class="fa fa-fw fa-lg fa-globe"></i> matiasgallipoli.com</span> -->
                  <span class="m-r-10"><i class="fa fa-fw fa-lg fa-phone-volume"></i> T:{{org?.telefone}}</span>
                  <!-- <span class="m-r-10"><i class="fa fa-fw fa-lg fa-envelope"></i> {{org?.email}}</span> -->
              </p>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>

<div class="bs-dialog-footer">
  <div class="bs-footer-action-close">
    <button (click)="close()" class="btn btn-white" pTooltip="Esc" tooltipPosition="bottom">Fechar</button>
  </div>
  <div class="bs-footer-action">
    <button (click)="gerarPDF()" [disabled]="gerandoPDF" class="btn btn-primary"><i class="fa fa-print t-plus-1 fa-fw fa-lg"></i> Imprimir</button>
  </div>
</div>