<div class="bs-dialog-header">
  <i class="fa fa-address-card"></i> CLIENTES <small> Gestão de Clientes </small>
</div>

<div class="bs-dialog-main">
  <div class="bs-dialog-content">
  
    <!-- Carregando informações... -->
    <div class="modal-overlay" *ngIf="loading">
      <div class="alert alert-light fade show m-b-0 text-center" style="color: #222;">
        <i class="fas fa-circle-notch fa-spin"></i> 
      </div>
    </div>

    <div class="be-form">

      <div class="row">
        <div class="col-sm-3 col-xs-6">
          <label>Tipo de pessoa: </label>
          <select [(ngModel)]="data.documento.tipo" class="form-control">
            <option value="cpf" selected>Física</option>
            <option value="cnpj">Jurídica</option>
            <option value="rne">Estrangeiro</option>
          </select>
        </div>
        <div class="col-sm-3 col-xs-6">
          <label *ngIf="data.documento.tipo == 'rne'">Identificador Estrangeiro:</label>
          <label *ngIf="data.documento.tipo == 'cpf'">CPF:</label>
          <label *ngIf="data.documento.tipo == 'cnpj'">CNPJ:</label>
          
          <p-inputMask *ngIf="data.documento.tipo == 'cpf' || data.documento.tipo == 'cnpj'" [(ngModel)]="data.documento.valor" [mask]="data.documento.tipo == 'cnpj' ? '99.999.999/9999-99' : '999.999.999-99'"></p-inputMask>

          <p-inputNumber type="text" [(ngModel)]="data.documento.valor" [useGrouping]="false" *ngIf="data.documento.tipo == 'rne'"></p-inputNumber>
          <!-- <p-inputNumber type="text" [(ngModel)]="data.documento.valor" [useGrouping]="false" *ngIf="data.documento.tipo == 'cpf'"></p-inputNumber>
          <p-inputNumber type="text" [(ngModel)]="data.documento.valor" [useGrouping]="false" *ngIf="data.documento.tipo == 'cnpj'"></p-inputNumber> -->
        </div>
      </div>
  
      <div class="row">
        <div class="col-sm-6 col-xs-12">
          <label *ngIf="data.documento.tipo == 'cnpj'">Nome fantasia:</label>
          <label *ngIf="data.documento.tipo != 'cnpj'">Nome:</label>
          <input type="text" pInputText [(ngModel)]="data.nome" class="form-control">
        </div>
      </div>
  
      <div class="row">
        <div class="col-sm-6 col-xs-12">
          <label>E-mail:</label>
          <input type="text" pInputText [(ngModel)]="data.email" class="form-control">
        </div>
      </div>
  
      <div class="row">
        <div class="col-sm-3 col-xs-6">
          <label>Telefone:</label>
          <p-inputNumber type="text" [(ngModel)]="data.telefone" [useGrouping]="false"></p-inputNumber>
        </div>
        <div class="col-sm-3 col-xs-6" *ngIf="data.documento.tipo == 'cpf'">
          <label>Gênero:</label>
          <select class="form-control" [(ngModel)]="data.genero">
            <option></option>
            <option value="masculino">Masculino</option>
            <option value="feminino">Feminino</option>
            <option value="ni">Não Informar</option>
          </select>
        </div>
        <div class="col-sm-3 col-xs-6">
          <label *ngIf="data.documento.tipo == 'cnpj'">Abertura da empresa:</label>
          <label *ngIf="data.documento.tipo != 'cnpj'">Data de nascimento:</label>
          <input type="date" [(ngModel)]="data.nascimento" class="form-control" pattern="\d{4}-\d{2}-\d{2}" placeholder="dd/MM/yyyy">
        </div>
      </div>
  
      <div class="row">
        <div class="col-12">
          <label>Observações:</label>
          <textarea [(ngModel)]="data.observacao" rows="2" class="form-control" placeholder="Observações"></textarea>
        </div>
      </div>
  
      <div *ngIf="data.documento.tipo == 'cnpj'">
        <hr />
  
        <h4>Informações fiscais</h4>
  
        <div class="row">
          <div class="col-sm-6 col-xs-12">
            <label>Razão social:</label>
            <input type="text" pInputText [(ngModel)]="data.documentoFiscal.nome" class="form-control">
          </div>
          <div class="col-sm-2 col-xs-4">
            <label>Simples:</label>
            <select [(ngModel)]="data.documentoFiscal.simples" class="form-control">
              <option value="true">Sim</option>
              <option value="false">Não</option>
            </select>
          </div>
          <div class="col-sm-4 col-xs-8">
            <label>Indicador de Inscrição Estadual:</label>
            <select [(ngModel)]="data.documentoFiscal.ieIndicador" class="form-control">
              <option value="nc">Não contribuinte</option>
              <option value="co">Contribuinte</option>
              <option value="ci">Contribuinte isento</option>
            </select>
          </div>
        </div>
  
        <div class="row">
  
          <div class="col-sm-4 col-xs-8">
            <label>Inscrição Estadual:</label>
            <input type="text" pInputText [(ngModel)]="data.documentoFiscal.ie" class="form-control">
          </div>
          <div class="col-sm-4 col-xs-8">
            <label>Inscrição Municipal:</label>
            <input type="text" pInputText [(ngModel)]="data.documentoFiscal.im" class="form-control">
          </div>
          <div class="col-sm-4 col-xs-8">
            <label>Inscrição Suframa:</label>
            <input type="text" pInputText [(ngModel)]="data.documentoFiscal.suframa" class="form-control">
          </div>
        </div>
      </div>
  
      <hr />
  
      <h4 class="m-t-30">Endereço</h4>
      
      <div class="row m-t-20">
        <div class="col-sm-2 col-xs-4">
          <label>CEP:</label>
            <p-inputMask [(ngModel)]="data.endereco.cep" mask="99999-999" (onBlur)="carregarEndereco()"></p-inputMask>
        </div>
        <div class="col-sm-6 col-xs-9">
          <label>Endereço:</label>
          <input type="text" pInputText [(ngModel)]="data.endereco.logradouro" class="form-control" [readonly]="this.loadingAdd">
        </div>
        <div class="col-sm-4 col-xs-3">
          <label>Número:</label>
          <input type="text" pInputText [(ngModel)]="data.endereco.numero" class="form-control">
        </div>
      </div>
  
      <div class="row">
        <div class="col-sm-3 col-xs-6">
          <label>Complemento:</label>
          <input type="text" pInputText [(ngModel)]="data.endereco.complemento" class="form-control">
        </div>
        <div class="col-sm-3 col-xs-6">
          <label>Bairro:</label>
          <input type="text" pInputText [(ngModel)]="data.endereco.bairro" [readonly]="this.loadingAdd" class="form-control">
        </div>
        <div class="col-sm-3 col-xs-6">
          <label>Cidade:</label>
          <input type="text" pInputText [(ngModel)]="data.endereco.cidade" class="form-control" [readonly]="this.loadingAdd">
        </div>
        <div class="col-sm-3 col-xs-6">
          <label>Estado:</label>
          <input type="text" pInputText [(ngModel)]="data.endereco.estado" class="form-control" [readonly]="this.loadingAdd">
        </div>
      </div>
  
    </div>
  </div>
</div>

<div class="bs-dialog-footer">
  <div class="bs-footer-action-close">
    <button (click)="close()" class="btn btn-white" pTooltip="Esc" tooltipPosition="bottom">Fechar</button>
  </div>
  <div class="bs-footer-action">
    <button (click)="save()" [disabled]="recording" class="btn btn-white" pTooltip="Ctrl + Enter" tooltipPosition="bottom">Salvar e criar outro</button>
    <button (click)="saveClose()" [disabled]="recording" class="btn btn-primary" pTooltip="Ctrl + S" tooltipPosition="bottom">Salvar</button>
  </div>
</div>