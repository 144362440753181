export class Filter {
  public att: string;
  public attAlias: string;
  public value: string;
  public value2: string;
  public typeC: string;
  public typeO: string;
  public mask: string;
  public filters: Filter[];

  constructor(typeC?: string, att?: string, value?: string) {
    if (typeC && att && value) {
      this.typeC = typeC;
      this.att = att;
      this.value = value;
    }
  }
}
