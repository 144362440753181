import { BaseEntity } from '../BaseEntity';
import { ProdutoAnexo } from '../suporte/produtoAnexo';
import { ContratoStatus } from '../suporte/contratoStatus';

export class ServicoContrato extends BaseEntity {
  public numero!: number;
  public descricao!: string;
  public contratoStatus!: ContratoStatus;
  public idCliente!: string;
  public nmCliente!: string;
  public idCategoria!: string;
  public pgtoForma!: string;
  public dtContrato!: Date;
  public dtTermino!: Date;
  public dtVencimento!: Date;
  public dtPgto!: Date;
  public carencia!: number;
  public parcelas!: number;
  public valor!: number;
  public observacoes!: string;
  public anexos!: ProdutoAnexo[];
}
