import { BaseEntity } from "../BaseEntity";
import { PagamentoStatus } from "../suporte/pagamentoStatus";

export class TituloPagar extends BaseEntity {

	numeroTitulo!: number;
    identificador!: string;
    idFornecedor!: string;
    nmFornecedor!: string;
    dtVencimento!: Date;
    valorTitulo!: number;
    valorDesconto!: number;
    valorMulta!: number;
    valorTotal!: number;
    statusPagamento!: PagamentoStatus;
    dtPagamento!: Date;
    idCategoria!: string;
    idCentroCusto!: string;
    observacoes!: string;
}
