import { Produto } from "../cadastro/produto";
import { Servico } from "../cadastro/servico";

export class PropostaItem {
    public tipo: string;
    public quantidade: number;
    public valorUnitario: number;
    public valorTotal: number;
    public idItem: string;
    public nomeItem: string;
    public itemSku: string;
    public itemImg: string;
  }
