<div class="bs-dialog-header">
    <i class="fa fa-file-alt"></i> PEDIDO DE VENDA <small> Gestão de Pedidos de Venda </small>
  </div>
  
  <div class="bs-dialog-main">
    <div class="bs-dialog-content">
  
      <!-- Carregando informações... -->
      <div class="modal-overlay" *ngIf="loading">
        <div class="alert alert-light fade show m-b-0 text-center" style="color: #222;">
          <i class="fas fa-circle-notch fa-spin"></i> 
        </div>
      </div>
  
      <div class="be-form">
  
        <div class="row">
          
          <div class="col-md-3 col-sm-6">
            <label>Status:</label>
            <select [(ngModel)]="data.pedidoStatus.code" class="form-control">
              <option value="aguardando_confirmacao">Aguardando Confirmação</option>
              <option value="confirmado">Confirmado</option>
              <option value="em_processamento">Em Processamento</option>
              <option value="pronto_para_envio">Pronto para Envio</option>
              <option value="enviado">Enviado</option>
              <option value="entregue">Entregue</option>
              <option value="cancelado">Cancelado</option>
              <option value="atrasado">Atrasado</option>
              <option value="em_espera">Em Espera</option>
              <option value="em_analise">Em Análise</option>
              <option value="reembolsado">Reembolsado</option>
              <option value="finalizado">Finalizado</option>
            </select>
          </div>
        </div>
  
        <div class="row">
          <div class="col-md-6 col-sm-12">
            <label>Cliente:</label>
            <p-autoComplete [(ngModel)]="contato" (onSelect)="onInputClienteSelect()" field="nome" [suggestions]="autocompleteCliente" (completeMethod)="acFind($event)" autoHighlight="true" forceSelection="true" emptyMessage="Cliente não encontrado" class="be-autocomplete">
              <ng-template let-contato pTemplate="item">
                {{contato.nome}}
              </ng-template>
            </p-autoComplete>
          </div>
        </div>
  
        <div class="row">
          <div class="col-md-6 col-sm-12">
            <label>Vendedor:</label>
            <p-autoComplete [(ngModel)]="vendedor" (onSelect)="onInputVendedorSelect()" field="nome" [suggestions]="autocompleteVendedor" (completeMethod)="acFindVendedor($event)" autoHighlight="true" forceSelection="true" emptyMessage="Vendedor não encontrado" class="be-autocomplete">
              <ng-template let-vendedor pTemplate="item">
                {{vendedor.nome}}
              </ng-template>
            </p-autoComplete>
          </div>
        </div>

        <hr class="m-t-30 m-b-30" />

        <h4>Itens da Venda</h4>

        <div class="m-t-20">
          <table class="table">
            <thead>
              <tr>
                <th>Nº</th>
                <th style="width: 35%;">Item</th>
                <th style="width: 15%;">Código (SKU)</th>
                <th style="width: 10%;">Qte</th>
                <th style="width: 15%;">Preço unitário</th>
                <th style="width: 20%;">Sub Total</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let d of data?.pedidoItens; let i = index">
                <td>{{ i + 1 }}</td>
                <td>
                  <p-autoComplete *ngIf="!d.nomeProduto" [(ngModel)]="pedidoItem" (onSelect)="onInputProdutoSelect(i)" field="nomeProduto" [suggestions]="autocompleteProdutoServico" (completeMethod)="acFindProduto($event)" autoHighlight="true" forceSelection="true" emptyMessage="Produto ou Serviço não encontrado" class="be-autocomplete">
                    <ng-template let-item pTemplate="item">
                      {{item.nomeProduto}}
                    </ng-template>
                  </p-autoComplete>
                  <input type="text" *ngIf="d.nomeProduto" [disabled]="true" pInputText [(ngModel)]="d.nomeProduto" class="form-control">
                </td>
                <td>
                  <input type="text" [disabled]="true" pInputText [(ngModel)]="d.produtoSku" class="form-control">
                </td>
                <td>
                  <p-inputNumber [(ngModel)]="d.quantidade" (onBlur)="calculaSubTotal(i)" mode="decimal" locale="pt-BR" [minFractionDigits]="2" [maxFractionDigits]="2" maxlength="17"></p-inputNumber>
                </td>
                <td>
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">R$</span>
                    <p-inputNumber [disabled]="true" [(ngModel)]="d.valorUnitario" mode="decimal" locale="pt-BR" [minFractionDigits]="2" [maxFractionDigits]="2" maxlength="17"></p-inputNumber>
                  </div>
                </td>
                <td>
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">R$</span>
                    <p-inputNumber [disabled]="true" [(ngModel)]="d.valorTotal" mode="decimal" locale="pt-BR" [minFractionDigits]="2" [maxFractionDigits]="2" maxlength="17"></p-inputNumber>
                  </div>
                </td>
                <td>
                  <a (click)="removeItem(i)" pTooltip="Remover Item" tooltipPosition="bottom" class="btn btn-danger btn-icon btn-circle btn-xs"><i class="fa fa-times"></i></a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <button (click)="addItem()" class="btn btn-primary btn-xs m-l-10">
          <i class="fa fa-plus-circle f-s-14"></i> Adicionar Item
        </button>

     
        <hr class="m-t-30 m-b-30" />

        <h4>Valor final</h4>

        <div class="row m-t-20">
          <div class="col-md-3 col-sm-6 col-xs-6">
            <label>Sub Total:</label>
            <div class="p-inputgroup">
              <span class="p-inputgroup-addon">R$</span>
              <p-inputNumber [disabled]="true" [(ngModel)]="data.valorSubTotal" mode="decimal" locale="pt-BR" [minFractionDigits]="2" [maxFractionDigits]="2" maxlength="17"></p-inputNumber>
            </div>
          </div>
          <div class="col-md-3 col-sm-6 col-xs-6">
            <label>Desconto:</label>
            <div class="p-inputgroup">
              <span class="p-inputgroup-addon">R$</span>
              <p-inputNumber [(ngModel)]="data.valorDesconto" (onBlur)="calculaTotal()" mode="decimal" locale="pt-BR" [minFractionDigits]="2" [maxFractionDigits]="2" maxlength="17"></p-inputNumber>
            </div>
          </div>
          <div class="col-md-3 col-sm-6 col-xs-6">
            <label>Valor total:</label>
            <div class="p-inputgroup">
              <span class="p-inputgroup-addon">R$</span>
              <p-inputNumber [disabled]="true" [(ngModel)]="data.valorTotal" mode="decimal" locale="pt-BR" [minFractionDigits]="2" [maxFractionDigits]="2" maxlength="17"></p-inputNumber>
            </div>
          </div>
        </div>
        
        <hr class="m-t-30 m-b-30" />

        <h4>Forma de Pagamento</h4>
        
        <div class="row m-t-20">
          <div class="col-md-3 col-sm-6">
            <label>Opções:</label>
              <select [(ngModel)]="data.pagamentoForma.code" class="form-control">
                <option value="pix">Pix</option>
                <option value="dinheiro">Dinheiro</option>
                <option value="cc">Cartão de Crédito</option>
                <option value="cd">Cartão de Débito</option>
                <option value="outros">Outros</option>
              </select>
          </div>
          <div class="col-md-3 col-sm-6" *ngIf="data.pagamentoForma.code == 'cc'">
            <label>Parcelas:</label>
            <div class="p-inputgroup">
              <p-inputNumber [(ngModel)]="data.qteParcelas" mode="decimal" locale="pt-BR" [minFractionDigits]="0" [maxFractionDigits]="0" maxlength="2"></p-inputNumber>
            </div>
          </div>
        </div>

        <div class="row" *ngIf="data.pagamentoForma.code == 'dinheiro'">
          <div class="col-md-3 col-xs-6">
            <label>Valor total:</label>
            <div class="p-inputgroup">
              <span class="p-inputgroup-addon">R$</span>
              <p-inputNumber [disabled]="true" [(ngModel)]="data.valorTotal" mode="decimal" locale="pt-BR" [minFractionDigits]="2" [maxFractionDigits]="2" maxlength="17"></p-inputNumber>
            </div>
          </div>
          <div class="col-md-3 col-xs-6">
            <label>Valor Recebido:</label>
            <div class="p-inputgroup">
              <span class="p-inputgroup-addon">R$</span>
              <p-inputNumber [(ngModel)]="data.valorRecebido" (onBlur)="calculaTroco()" mode="decimal" locale="pt-BR" [minFractionDigits]="2" [maxFractionDigits]="2" maxlength="17"></p-inputNumber>
            </div>
          </div>
          <div class="col-md-3 col-xs-6">
            <label>Troco:</label>
            <div class="p-inputgroup">
              <span class="p-inputgroup-addon">R$</span>
              <p-inputNumber [disabled]="true" [(ngModel)]="data.valorTroco" mode="decimal" locale="pt-BR" [minFractionDigits]="2" [maxFractionDigits]="2" maxlength="17"></p-inputNumber>
            </div>
          </div>
        </div>
  
      </div>    
    </div>
  </div>
  
  <div class="bs-dialog-footer">
    <div class="bs-footer-action-close">
      <button (click)="close()" class="btn btn-white" pTooltip="Esc" tooltipPosition="bottom">Fechar</button>
    </div>
    <div class="bs-footer-action">
      <button (click)="save()" [disabled]="recording" class="btn btn-white" pTooltip="Ctrl + Enter" tooltipPosition="bottom">Salvar e criar outro</button>
      <button (click)="saveClose()" [disabled]="recording" class="btn btn-primary" pTooltip="Ctrl + S" tooltipPosition="bottom">Salvar</button>
    </div>
  </div>