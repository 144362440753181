import { BaseEntity } from "../BaseEntity";
import { KeyValue } from "../suporte/keyValue";
import { Categoria } from "./categoria";
import { ProdutoAnexo } from '../suporte/produtoAnexo';
import { ProdutoVariacao } from '../suporte/produtoVariacao';
import { ProdutoVariacaoParametro } from "../suporte/produtoVariacaoParametro";

export class Produto extends BaseEntity {
	nome: string;
	marca: string;
	idCategoria: string;
	sku: string;
	ean: string;
	valorCusto: number;
	valorVenda: number;
	valorPromocional: number;
	unidadeMedida: KeyValue;
	estoque: number;
	estoqueMin: number;
	estoqueMax: number;
	estoqueLocalizacao: string;
	estoquePreparacao: number;
	descricao: string;
	origem: KeyValue;
	ncm: string;
	cest: string;
	altura: number;
	largura: number;
	profundidade: number;
	pesoLiquido: number;
	pesoBruto: number;
	linkVideo: string;
	keywords: string[];
	tituloSeo: string;
	descricaoSeo: string;
	imagens: ProdutoAnexo[];
	anexos: ProdutoAnexo[];
	variacoes: ProdutoVariacao[];
	variacaoParametros: ProdutoVariacaoParametro[];
	categoria: Categoria;
}