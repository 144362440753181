<div class="bs-dialog-header">
    <i class="fa fa-gavel"></i> CONTRATOS <small> Gestão de Contratos </small>
  </div>
  
  <div class="bs-dialog-main">
    <div class="bs-dialog-content">
  
      <!-- Carregando informações... -->
      <div class="modal-overlay" *ngIf="loading">
        <div class="alert alert-light fade show m-b-0 text-center" style="color: #222;">
          <i class="fas fa-circle-notch fa-spin"></i> 
        </div>
      </div>
  
      <div class="be-form">

        <div class="row">
          <div class="col-6">
            <label>Descrição do Contrato:</label>
            <input type="text" pInputText [(ngModel)]="data.descricao" class="form-control">
          </div>
          <div class="col-3">
            <label>Status do Contrato:</label>
            <select [(ngModel)]="data.contratoStatus.code" class="form-control">
              <option value="ativo">Ativo</option>
              <option value="inativo">Inativo</option>
              <option value="baixado">Baixado</option>
              <option value="encerrado">Encerrado</option>
            </select>
          </div>
          <div class="col-3" *ngIf="data.contratoStatus.code != 'ativo'">
            <label>Término:</label>
            <input type="date" [(ngModel)]="data.dtTermino" class="form-control" pattern="\d{4}-\d{2}-\d{2}" placeholder="dd/MM/yyyy">
          </div>
        </div>
  
        <div class="row">
          <div class="col-6">
            <label>Cliente:</label>
            <div class="p-inputgroup">
              <p-autoComplete [(ngModel)]="cliente" (onSelect)="onSelectCliente()" field="nome" [suggestions]="autocompleteCliente" (completeMethod)="acFindCliente($event)" autoHighlight="true" forceSelection="true" emptyMessage="Cliente não encontrado" class="be-autocomplete width-full">
                <ng-template let-cliente pTemplate="item">
                  {{cliente.nome}}
                </ng-template>
              </p-autoComplete>
              <button (click)="addCliente()" type="button" pButton  pTooltip="Adicionar Novo Cliente" tooltipPosition="bottom" icon="pi pi-plus-circle"></button>
            </div>
          </div>
          
          <div class="col-6">
            <label>Categoria</label>
            <div class="p-inputgroup">
              <p-autoComplete [(ngModel)]="categoria" (onSelect)="onSelectCategoria()" field="descricao" [suggestions]="autocompleteCategoria" (completeMethod)="acFindCategoria($event)" autoHighlight="true" forceSelection="true" emptyMessage="Categoria não encontrada" class="be-autocomplete width-full">
                <ng-template let-categoria pTemplate="item">
                  {{categoria.arvoreCategoriaTexto ? categoria.arvoreCategoriaTexto + ' > ' : ''}} {{categoria.descricao}}
                </ng-template>
              </p-autoComplete>
              <button (click)="addCategoria()" type="button" pButton  pTooltip="Adicionar Nova Categoria" tooltipPosition="bottom"icon="pi pi-plus-circle"></button>
            </div>
          </div>
        </div>
  
        <hr class="m-t-30 m-b-30" />

        <h4 class="m-b-20">Prazos e Valores</h4>
          
        <div class="row">
          <div class="col-3">
            <label>Data do Contrato:</label>
            <input type="date" [(ngModel)]="data.dtContrato" class="form-control" pattern="\d{4}-\d{2}-\d{2}" placeholder="dd/MM/yyyy">
          </div>
          <div class="col-3">
            <label>Valor:</label>
            <div class="p-inputgroup">
              <span class="p-inputgroup-addon">R$</span>
              <p-inputNumber [(ngModel)]="data.valor" mode="decimal" locale="pt-BR" [minFractionDigits]="2" [maxFractionDigits]="2" maxlength="17"></p-inputNumber>
            </div>
          </div>
          <div class="col-3">
            <label>Data Vencimento:</label>
            <input type="date" [(ngModel)]="data.dtVencimento" class="form-control" pattern="\d{4}-\d{2}-\d{2}" placeholder="dd/MM/yyyy">
          </div>
          <div class="col-3">
            <label>Carência: <small>(em meses)</small> </label>
            <p-inputNumber [(ngModel)]="data.carencia" mode="decimal" locale="pt-BR" [minFractionDigits]="2" [maxFractionDigits]="2" maxlength="17"></p-inputNumber>
          </div>
        </div>
        
        <div class="row">
          <div class="col-12">
            <label>Observações:</label>
            <textarea [(ngModel)]="data.observacoes" rows="3" class="form-control" placeholder="Observações"></textarea>
          </div>
        </div>

        <hr class="m-t-30 m-b-30" />

        <h4 class="m-b-20">Pagamento</h4>
          
        <div class="row">
          <div class="col-3">
            <label>Forma de Pagamento:</label>
            <select [(ngModel)]="data.pgtoForma" class="form-control">
              <option value="unica">À Vista</option>
              <option value="parcelado">Parcelado</option>
            </select>
          </div>
          <div class="col-2" *ngIf="data.pgtoForma == 'parcelado'">
            <label>Parcelas:</label>
            <p-inputNumber [(ngModel)]="data.parcelas" mode="decimal" locale="pt-BR" [minFractionDigits]="0" [maxFractionDigits]="0" maxlength="3"></p-inputNumber>
          </div>
          <div class="col-3">
            <label *ngIf="data.pgtoForma == 'parcelado'">Vencimento 1º Parcela:</label>
            <label *ngIf="data.pgtoForma != 'parcelado'">Data Vencimento:</label>
            <input type="date" [(ngModel)]="data.dtPgto" class="form-control" pattern="\d{4}-\d{2}-\d{2}" placeholder="dd/MM/yyyy">
          </div>
        </div>

        <hr class="m-t-30 m-b-30" />

        <h4>Anexos</h4>
        
        <div class="alert alert-primary fade show m-b-10 m-t-20">
          Esta opção permite que você adicione arquivos relevantes à sua proposta comercial.
        </div>

        <p-fileUpload 
              name="files" [url]="upload_path_arquivo" (onUpload)="onUploadArquivo($event)" 
              [auto]="true" accept=".pdf,image/*" maxFileSize="1000000" multiple="multiple"
              chooseLabel="Selecionar arquivo">
            
            <ng-template pTemplate="content">
              <label>Arraste seus arquivos aqui.</label>
            </ng-template>

        </p-fileUpload>

        <hr *ngIf="data.anexos?.length" />

        <div class="widget-list widget-list-rounded m-b-30" *ngIf="data.anexos?.length">
          <div class="widget-list-item" *ngFor="let file of data.anexos; let i = index">
            <div class="widget-list-media">
              <i class="far fa-file-pdf"></i>
            </div>
            <div class="widget-list-content">
              <h4 class="widget-list-title">{{file.nome}}</h4>
              <p class="widget-list-desc">{{file.tamanho}} bytes</p>
            </div>
            <div class="widget-list-action">
              <button class="btn btn-link" (click)="removeAnexo(i)"><i class="fa fa-trash-alt f-s-14 m-l-10" pTooltip="Excluir" tooltipPosition="bottom"></i></button>
            </div>
          </div>
        </div>
  
      </div>    
    </div>
  </div>
  
  <div class="bs-dialog-footer">
    <div class="bs-footer-action-close">
      <button (click)="close()" class="btn btn-white" pTooltip="Esc" tooltipPosition="bottom">Fechar</button>
    </div>
    <div class="bs-footer-action">
      <button (click)="save()" [disabled]="recording" class="btn btn-white" pTooltip="Ctrl + Enter" tooltipPosition="bottom">Salvar e criar outro</button>
      <button (click)="saveClose()" [disabled]="recording" class="btn btn-primary" pTooltip="Ctrl + S" tooltipPosition="bottom">Salvar</button>
    </div>
  </div>