<div class="bs-dialog-header">
  <i class="fa fa-calendar-alt"></i> Minha Conta <small> Forma de Pagamento </small>
</div>

<div class="bs-dialog-main">
  <div class="bs-dialog-content">

    <!-- Carregando informações... -->
    <div class="modal-overlay" *ngIf="loading">
      <div class="alert alert-light fade show m-b-0 text-center" style="color: #222;">
        <i class="fas fa-circle-notch fa-spin"></i> 
      </div>
    </div>

    <div class="be-form">

      
      <div class="col-md-12 m-b-20">
        <a [routerLink]="" (click)="pgtoForma('cartao_credito')" class="btn btn-white d-flex" style="height: 46px;">
          <i class="fa fa-credit-card" style="font-size: 24px;color: #00acac;margin-top: 3px;"></i> 
          <span style="margin-top: 6px; margin-left: 8px;">Cartão de Crédito</span>
        </a>
      </div>
      <div class="col-md-12 m-b-20">
        <a [routerLink]="" (click)="pgtoForma('pix')" class="btn btn-white d-flex">
          <img src="../../../assets/images/pix.png" width="30px;">
          <span class="m-t-5 m-l-5">Pix</span>
        </a>
      </div>
      <div class="col-md-12 m-b-20">
        <a [routerLink]="" (click)="pgtoForma('boleto')" class="btn btn-white d-flex" style="height: 46px;">
          <i class="fa fa-barcode" style="font-size: 24px;margin-top: 3px;"></i> 
          <span style="margin-top: 6px; margin-left: 8px;">Boleto</span>
        </a>
      </div>
      <div class="col-md-12" *ngIf="data.formaCobranca">
        <span *ngIf="data.formaCobranca == 'cartao_credito'">Forma de pagamento escolhida: <strong>Cartão de Crédito</strong></span>
        <span *ngIf="data.formaCobranca == 'pix'">Forma de pagamento escolhida: <strong>Pix</strong></span>
        <span *ngIf="data.formaCobranca == 'boleto'">Forma de pagamento escolhida: <strong>Boleto</strong></span>
      </div>


    </div>    
  </div>
</div>

<div class="bs-dialog-footer">
  <div class="bs-footer-action-close">
    <button (click)="close()" class="btn btn-white" pTooltip="Esc" tooltipPosition="bottom">Fechar</button>
  </div>
  <div class="bs-footer-action">
    <button (click)="updatePgto()" [disabled]="recording" class="btn btn-primary" pTooltip="Ctrl + S" tooltipPosition="bottom">Salvar</button>
  </div>
</div>