export class PedidoStatus {

	public static AGUARDANDO_CONFIRMACAO = new PedidoStatus("aguardando_confirmacao", "Aguardando Confirmação");
    public static CONFIRMADO = new PedidoStatus("confirmado", "Confirmado");
    public static EM_PROCESSAMENTO = new PedidoStatus("em_processamento", "Em Processamento");
    public static PRONTO_PARA_ENVIO = new PedidoStatus("pronto_para_envio", "Pronto para Envio");
    public static ENVIADO = new PedidoStatus("enviado", "Enviado");
    public static ENTREGUE = new PedidoStatus("entregue", "Entregue");
    public static CANCELADO = new PedidoStatus("cancelado", "Cancelado");
    public static ATRASADO = new PedidoStatus("atrasado", "Atrasado");
    public static EM_ESPERA = new PedidoStatus("em_espera", "Em Espera");
    public static EM_ANALISE = new PedidoStatus("em_analise", "Em Análise");
    public static REEMBOLSADO = new PedidoStatus("reembolsado", "Reembolsado");
    public static FINALIZADO = new PedidoStatus("finalizado", "Finalizado");

    public code: string;
    public value: string;

    constructor(code: string, value: string) {
        this.code = code;
        this.value = value;
    }
}
