export class CrmAcao {
	acao: string;
    detalhe: string;
    resposta: string;
    dtInclusao: string;
    dtPrevisao: string;
    hrPrevisao: string;
    dtResposta: string;
    idCriador: string;
    nomeCriador: string;
    idResponsavel: string;
    nomeResponsavel: string;
    concluida: boolean;
    editavel: boolean;

    bkResposta: string;
    bkIdResponsavel: string;
    bkNomeResponsavel: string;
    bkDtPrevisao: string;
    bkHrPrevisao: string;
}
