<div id="full">

    <div class="modal-overlay" *ngIf="modal">
        <div class="modal-dialog" style="width: 400px;">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title" *ngIf="modalQte">Quantidade</h4>
                    <h4 class="modal-title" *ngIf="modalCliente">Cliente</h4>
                    <h4 class="modal-title" *ngIf="modalVendedor">Vendedor</h4>
                    <h4 class="modal-title" *ngIf="modalDesconto">Desconto</h4>
                    <h4 class="modal-title" *ngIf="modalCancelarItem">Cancelar Item</h4>
                    <h4 class="modal-title" *ngIf="modalFinalizarVenda || modalFinalizarVendaResumo">Finalizar Venda</h4>
                </div>
                <div class="modal-body" *ngIf="modalQte">
                    <p-inputNumber [(ngModel)]="pedidoItem.quantidade" #qteInput mode="decimal" (keypress)="modalQteOnKeyPress($event)" (onInput)="modalQteSetarValor($event)" [minFractionDigits]="2" [maxFractionDigits]="5" class="form-control form-control-lg ii be-pos-form-inumber"></p-inputNumber>
                </div>
                <div class="modal-body" *ngIf="modalDesconto">
                    <p-inputNumber [(ngModel)]="pedido.valorDesconto" #descontoInput mode="decimal" (keypress)="modalDescontoOnKeyPress($event)" (onInput)="modalDescontoSetarValor($event)" [minFractionDigits]="2" [maxFractionDigits]="5" class="form-control form-control-lg ii be-pos-form-inumber"></p-inputNumber>
                </div>
                <div class="modal-body" *ngIf="modalCancelarItem">
                    <p-inputNumber [(ngModel)]="pedido.itemNumero" #cancelarItemInput mode="decimal" (keypress)="modalCancelarItemOnKeyPress($event)" (onInput)="modalCancelarItemSetarValor($event)" [useGrouping]="false" class="form-control form-control-lg ii be-pos-form-inumber"></p-inputNumber>
                </div>
                <div class="modal-body" *ngIf="modalCliente">
                    <p-autoComplete [(ngModel)]="cliente" #acClienteInput field="nome" (onSelect)="closeModal()" [suggestions]="clientes" (completeMethod)="acCliente($event)" autoHighlight="true" forceSelection="true" emptyMessage="Cliente não encontrado" class="be-autocomplete" placeholder="Pesquise pelo nome, telefone ou documento">
                        <ng-template let-cliente pTemplate="item">
                            {{cliente.nome}}
                        </ng-template>
                    </p-autoComplete>
                </div>
                <div class="modal-body" *ngIf="modalVendedor">
                    <p-autoComplete [(ngModel)]="vendedor" #acVendedorInput field="nome" (onSelect)="closeModal()" [suggestions]="vendedores" (completeMethod)="acVendedor($event)" autoHighlight="true" forceSelection="true" emptyMessage="Vendedor não encontrado" class="be-autocomplete" placeholder="Pesquise pelo nome ou documento">
                        <ng-template let-vendedor pTemplate="item">
                            {{vendedor.nome}}
                        </ng-template>
                    </p-autoComplete>
                </div>
                <div class="modal-body" *ngIf="modalFinalizarVenda">
                    
                    <div *ngIf="pedido.pagamentoForma?.code == 'dinheiro'">
                        <label class="m-t-10">Valor total:</label>
                        <p-inputNumber [(ngModel)]="pedido.valorTotal" readonly mode="decimal" [minFractionDigits]="2" [maxFractionDigits]="2" class="form-control form-control-lg ii be-pos-form-inumber"></p-inputNumber>
                        <label class="m-t-10">Valor recebido:</label>
                        <p-inputNumber [(ngModel)]="pedido.valorRecebido" #valorRecebidoInput (keypress)="modalValorRecebidoOnKeyPress($event)" (onInput)="modalValorRecebidoSetarValor($event)"  mode="decimal" [minFractionDigits]="2" [maxFractionDigits]="2" class="form-control form-control-lg ii be-pos-form-inumber"></p-inputNumber>
                        <label class="m-t-10">Troco:</label>
                        <p-inputNumber [(ngModel)]="pedido.valorTroco" readonly mode="decimal" [minFractionDigits]="2" [maxFractionDigits]="2" class="form-control form-control-lg ii be-pos-form-inumber"></p-inputNumber>
                        <button (click)="scFinalizarVendaVoltar()" class="btn btn-default btn-sm m-t-20">Voltar (V)</button>
                    </div>
                    
                    <div *ngIf="pedido.pagamentoForma?.code == 'cc'">
                        <label class="m-t-10">Quantidade de parcelas:</label>
                        <p-inputNumber [(ngModel)]="pedido.qteParcelas" #qteParcelasInput (keypress)="modalValorRecebidoOnKeyPress($event)" (onInput)="modalParcelasSetarValor($event)"  mode="decimal" [useGrouping]="false" [maxlength]="2" [min]="1" class="form-control form-control-lg ii be-pos-form-inumber"></p-inputNumber>
                        <button (click)="scFinalizarVendaVoltar()" class="btn btn-default btn-sm m-t-20">Voltar (V)</button>
                    </div>
                    
                    <div *ngIf="pedido.pagamentoForma == null">
                        <div class="mb-2">
                            <button (click)="scFinalizarVendaPix()" class="btn btn-white col-12">Pix (P)</button>
                        </div>
                        <div class="mb-2">
                            <button (click)="scFinalizarVendaDinheiro()" class="btn btn-white col-12">Dinheiro (M)</button>
                        </div>
                        <div class="mb-2">
                            <button (click)="scFinalizarVendaCd()" class="btn btn-white col-12">Cartão de Débito (D)</button>
                        </div>
                        <div class="mb-2">
                            <button (click)="scFinalizarVendaCc()" class="btn btn-white col-12">Cartão de Crédito (C)</button>
                        </div>
                        <div class="mb-2">
                            <button (click)="scFinalizarVendaOutros()" class="btn btn-white col-12">Outros (O)</button>
                        </div>
                    </div>

                </div>

                <div class="modal-body invoice" *ngIf="modalFinalizarVendaResumo">

                    <div class="invoice-content">
                        <div class="invoice-price">
                            <div class="invoice-price-left">
                                <div class="invoice-price-row">
                                    <div class="sub-price">
                                        <small>SUBTOTAL</small>
                                        <span class="text-inverse">R$ {{pedido.valorSubTotal | number:'1.2-2'}}</span>
                                    </div>
                                </div>
                                <div class="invoice-price-row">
                                    <div class="sub-price">
                                        <small>DESCONTO</small>
                                        <span class="text-inverse">R$ - {{pedido.valorDesconto | number:'1.2-2'}}</span>
                                    </div>
                                </div>
                                <div class="invoice-price-row" *ngIf="pedido.pagamentoForma?.code == 'dinheiro'">
                                    <div class="sub-price">
                                        <small>VALOR RECEBIDO</small>
                                        <span class="text-inverse">R$ {{pedido.valorRecebido | number:'1.2-2'}}</span>
                                    </div>
                                </div>
                                <div class="invoice-price-row" *ngIf="pedido.pagamentoForma?.code == 'dinheiro'">
                                    <div class="sub-price">
                                        <small>TROCO</small>
                                        <span class="text-inverse">R$ {{pedido.valorTroco | number:'1.2-2'}}</span>
                                    </div>
                                </div>
                                <div class="invoice-price-row" *ngIf="pedido.pagamentoForma?.code == 'cc'">
                                    <div class="sub-price">
                                        <small>PARCELAS</small>
                                        <span class="text-inverse">{{pedido.qteParcelas}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="invoice-content">
                        <div class="invoice-price">
                            <div class="invoice-price-right">
                                <small>TOTAL</small> <span class="f-w-600">R$ {{pedido.valorTotal | number:'1.2-2'}}</span>
                            </div>
                        </div>
                    </div>
                    
                    <div class="row">
                        <div class="col-md-4">
                            <button (click)="scFinalizarVendaVoltar()" class="btn btn-default btn-block">Voltar (V)</button>
                        </div>
                        <div class="col-md-8">
                            <button (click)="scConfirmaVendaModal()" class="btn btn-primary btn-block"><i class="fa fa-check fa-fw fa-lg"></i> Confirmar Venda (CTRL + Enter)</button>
                        </div>
                    </div>
                </div>

                <div class="modal-footer" *ngIf="!modalFinalizarVenda && !modalFinalizarVendaResumo">
                    <button (click)="closeModal()" class="btn btn-white">Fechar (Ctrl + F)</button>
                    <button (click)="closeModal()" *ngIf="!modalCancelarItem && !modalDesconto && pedido.pagamentoForma !== null" class="btn btn-primary" pTooltip="(Enter)" tooltipPosition="bottom">Confirmar (Enter)</button>
                    <button (click)="cancelarItem()" *ngIf="modalCancelarItem" class="btn btn-primary" pTooltip="(Enter)" tooltipPosition="bottom">Confirmar (Enter)</button>
                    <button (click)="calcularValorTotal()" *ngIf="modalDesconto" class="btn btn-primary" pTooltip="(Enter)" tooltipPosition="bottom">Confirmar (Enter)</button>
                </div>
                <div class="modal-footer" *ngIf="modalFinalizarVenda && pedido.pagamentoForma !== null">
                    <button (click)="closeModal()" class="btn btn-white">Fechar (Ctrl + F)</button>
                    <button (click)="modalValorRecebidoOnKeyPress()" class="btn btn-primary" pTooltip="(Enter)" tooltipPosition="bottom">Confirmar (Enter)</button>
                </div>
            </div>
        </div>
    </div>

    <div id="content" class="content p-0" [style.margin]="margin">

        <div class="pos pos-customer" id="pos-customer">

            <div class="vertical-box">

                <div class="pos-header">
                    <div class="info">{{currentUser.nome}}</div>
                    <div class="info m-l-auto">{{time | date: 'HH:mm:ss'}}</div>
                </div>


                <div class="vertical-box-row">
                    <div class="vertical-box-cell">

                        <div class="vertical-box-inner-cell be-pos-produto">

                            <div class="row">
                                <div class="col-12 p-0">
                                    <span class="label label-primary m-b-10" style="font-size: 14px;">Quantidade: <strong>{{pedidoItem.quantidade}}</strong></span>
                                    <span class="label label-primary m-b-10 pull-right" style="font-size: 14px;">Vendedor: <strong *ngIf="vendedor == null">Vendedor não informado</strong><strong *ngIf="vendedor != null">{{vendedor.nome}}</strong></span>

                                    <p-autoComplete [(ngModel)]="produto" #autoCompleteInput field="nome" (onSelect)="onSelectProduto()" [suggestions]="produtos" (completeMethod)="autocomplete($event)" autoHighlight="true" forceSelection="true" emptyMessage="Produto não encontrada" class="be-autocomplete be-autocomplet-pos" placeholder="Pesquise pelo nome do produto, código SKU ou GTIN">
                                        <ng-template let-produto pTemplate="item">
                                            {{produto.nome}}
                                        </ng-template>
                                    </p-autoComplete>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-6 bg-white d-flex justify-content-center align-items-center be-pos-produto-foto">
                                    <img *ngIf="pedidoItemApresentacao.produto && pedidoItemApresentacao.produto.imagens && pedidoItemApresentacao.produto.imagens[0]?.url" [src]="pedidoItemApresentacao.produto.imagens[0].url">
                                    <img *ngIf="!pedidoItemApresentacao.produto || !pedidoItemApresentacao.produto.imagens || !pedidoItemApresentacao.produto.imagens[0]?.url" src="../../../assets/images/pos-background.jpeg" class="img-bk">
                                </div>
                                <div class="col-5 be-pos-produto-form">
                                    <label>Item:</label>
                                    <input type="text" [(ngModel)]="pedidoItemApresentacao.produto.nome" readonly class="form-control form-control-lg bg-white ii">

                                    <label class="m-t-10">Quantidade:</label>
                                    <p-inputNumber [(ngModel)]="pedidoItemApresentacao.quantidade" readonly mode="decimal" [minFractionDigits]="2" [maxFractionDigits]="5" class="form-control form-control-lg ii be-pos-form-inumber"></p-inputNumber>
                                    <!-- <input type="text" [(ngModel)]="pedidoItem.qte" #qteInput (keypress)="onKeyPress($event)" (blur)="onSelectPedidoQte()" (input)="onSelectPedidoQte()" class="form-control form-control-lg bg-white ii"> -->

                                    <label class="m-t-10">Valor unitário:</label>
                                    <p-inputNumber [(ngModel)]="pedidoItemApresentacao.produto.valorVenda" readonly mode="decimal" [minFractionDigits]="2" [maxFractionDigits]="2" class="form-control form-control-lg ii be-pos-form-inumber"></p-inputNumber>

                                    <label class="m-t-10">Valor total:</label>
                                    <p-inputNumber [(ngModel)]="pedidoItemApresentacao.valorTotal" readonly mode="decimal" [minFractionDigits]="2" [maxFractionDigits]="2" class="form-control form-control-lg ii be-pos-form-inumber"></p-inputNumber>
                                </div>
                            </div>
                            <div class="row">
                                <span class="label label-light m-t-10">* Imagem ilustrativa</span>
                            </div>
                        </div>
                    </div>
                </div>


                <div class="pos-footer">
                    <a (click)="scCliente()" class="btn btn-white"><i class="fa fa-address-book fa-fw fa-lg"></i> Cliente (F2)</a>
                    <!-- <a href="#" class="btn btn-white m-l-5"><i class="fa fa-search fa-fw fa-lg"></i> Pesquisar Item (F3)</a> -->
                    <a (click)="scCancelarItem()" class="btn btn-white m-l-5"><i class="fa fa-trash-alt fa-fw fa-lg"></i> Cancelar Item (F3)</a>
                    <a (click)="scDesconto()" class="btn btn-white m-l-5"><i class="fa fa-dollar-sign fa-fw fa-lg"></i> Desconto (F4)</a>
                    <a (click)="scVendedor()" class="btn btn-white m-l-5"><i class="fa fa-user fa-fw fa-lg"></i> Vendedor (F5)</a>
                    <a (click)="scInsereQte()" class="btn btn-white m-l-5"><i class="fa fa-cubes fa-fw fa-lg"></i> Informar Qte (F6)</a>
                    <a (click)="openFullscreen();" *ngIf="!isFullScreen" class="btn btn-white m-l-5"><i class="fa fa-desktop fa-fw fa-lg"></i> Tela cheia (F10)</a>
                    <a (click)="closefullscreen();" *ngIf="isFullScreen" class="btn btn-white m-l-5"><i class="fa fa-desktop fa-fw fa-lg"></i> Sair Tela cheia (F10)</a>
                </div>

            </div>

            <div class="pos-sidebar">
                <div class="pos-sidebar-header">
                    <div class="order m-l-auto">Cliente: <b *ngIf="cliente == null">Não informado</b><b *ngIf="cliente != null">{{cliente.nome}}</b></div>
                </div>
                <div class="pos-sidebar-body tab-content keras-scrollbar">


                    <div class="pos-table">
                        <div class="row pos-table-row" *ngFor="let item of pedido.pedidoItens; let i = index">
                            <div class="col-9">
                                <div class="pos-product-thumb">
                                    <div class="cod">{{ ('000' + (i+1)).slice(-3) }}</div>
                                    <div class="info">
                                        <div class="title">{{item.produto.nome}}</div>
                                        <div class="desc">{{item.quantidade}} {{item.produto.unidadeMedida.code}} x R$ {{item.produto.valorVenda | number:'1.2-2'}}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-3 total-price">R$ {{item.valorTotal | number:'1.2-2'}}</div>
                        </div>                        
                    </div>

                    <div class="be-pos-carrinho-vazio" *ngIf="pedido.pedidoItens.length == 0">
                        <i class="fab fa-lg fa-fw fa-opencart"></i> Carrinho vazio
                    </div>
                </div>

                <div class="pos-sidebar-footer">
                    <div class="subtotal">
                        <div class="text">Total Itens</div>
                        <div class="price">{{pedido.totalItens}} ({{pedido.totalItens}}.00)</div>
                    </div>
                    <div class="subtotal">
                        <div class="text">Subtotal</div>
                        <div class="price">R$ {{pedido.valorSubTotal | number:'1.2-2'}}</div>
                    </div>
                    <div class="taxes">
                        <div class="text">Descontos</div>
                        <div class="price">- R$ {{pedido.valorDesconto | number:'1.2-2'}}</div>
                    </div>
                    <div class="total">
                        <div class="text">Total</div>
                        <div class="price">R$ {{pedido.valorTotal | number:'1.2-2'}}</div>
                    </div>
                    <div class="btn-row">
                        <button (click)="scrollDown()" [disabled]="pedido.pedidoItens?.length == 0" class="btn btn-default col-6"><i class="fa fa-trash-alt fa-fw fa-lg"></i> Cancelar Venda (F8)</button>
                        <button (click)="scFinalizarVenda()" [disabled]="pedido.pedidoItens?.length == 0" class="btn btn-primary col-6"><i class="fa fa-check fa-fw fa-lg"></i> Finalizar Venda (F9)</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>