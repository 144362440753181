<div class="bs-dialog-header">
  <i class="fa fa-tags"></i> PRODUTOS <small> Gestão de Produtos </small>
</div>

<div class="bs-dialog-main">
  <div class="bs-dialog-content">

    <!-- Carregando informações... -->
    <div class="modal-overlay" *ngIf="loading">
      <div class="alert alert-light fade show m-b-0 text-center" style="color: #222;">
        <i class="fas fa-circle-notch fa-spin"></i> 
      </div>
    </div>

    <p-tabView>

      <p-tabPanel header="Dados Gerais">
        <div class="be-form">

          <div class="row">
            <div class="col-sm-4 col-xs-12">
              <label>Nome do produto:</label>
              <input type="text" pInputText [(ngModel)]="data.nome" class="form-control">
            </div>
            <div class="col-sm-4 col-xs-12">
              <label>Marca:</label>
              <input type="text" pInputText [(ngModel)]="data.marca" class="form-control">
            </div>
            <div class="col-sm-4 col-xs-12">
              <label>Categoria</label>
              <p-autoComplete [(ngModel)]="data.categoria" field="descricao" (onSelect)="onSelectCategoria()" [suggestions]="categorias" (completeMethod)="autocomplete($event)" autoHighlight="true" forceSelection="true" emptyMessage="Categoria não encontrada" class="be-autocomplete">
                <ng-template let-categoria pTemplate="item">
                  {{categoria.arvoreCategoriaTexto ? categoria.arvoreCategoriaTexto + ' > ' : ''}} {{categoria.descricao}}
                </ng-template>
              </p-autoComplete>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-4 col-xs-10">
              <label>Código (SKU):</label>
              <input type="text" pInputText [(ngModel)]="data.sku" maxlength="50" class="form-control">
            </div>
            <div class="col-sm-4 col-xs-10">
              <label>Código de barras (GTIN/EAN):</label><i class="fas fa-lg fa-fw m-r-10 fa-question-circle form-info" pTooltip="Global Trade Item Number" tooltipPosition="bottom"></i>
              <p-inputNumber [(ngModel)]="data.ean" maxlength="14" [format]="false"></p-inputNumber>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-3 col-xs-8">
              <label>Valor de custo:</label>
              <div class="p-inputgroup">
                <span class="p-inputgroup-addon">R$</span>
                <p-inputNumber [(ngModel)]="data.valorCusto" mode="decimal" locale="pt-BR" [minFractionDigits]="2" [maxFractionDigits]="2" maxlength="17"></p-inputNumber>
              </div>
            </div>
            <div class="col-sm-3 col-xs-8">
              <label>Valor de venda:</label>
              <div class="p-inputgroup">
                <span class="p-inputgroup-addon">R$</span>
                <p-inputNumber [(ngModel)]="data.valorVenda" mode="decimal" locale="pt-BR" [minFractionDigits]="2" [maxFractionDigits]="2" maxlength="17"></p-inputNumber>
              </div>
            </div>
            <div class="col-sm-3 col-xs-8">
              <label>Valor promocional:</label>
              <div class="p-inputgroup">
                <span class="p-inputgroup-addon">R$</span>
                <p-inputNumber [(ngModel)]="data.valorPromocional" mode="decimal" locale="pt-BR" [minFractionDigits]="2" [maxFractionDigits]="2" maxlength="17"></p-inputNumber>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-3 col-xs-8">
              <label>Unidade de medida:</label>
              <p-dropdown [options]="medidas" [(ngModel)]="data.unidadeMedida" placeholder="Selecione um item" optionLabel="value"></p-dropdown>
            </div>
            <div class="col-sm-3 col-xs-8">
              <label>Estoque disponível:</label>
              <div class="p-inputgroup">
                <span class="p-inputgroup-addon">{{data.unidadeMedida.code}}</span>
                <p-inputNumber [(ngModel)]="data.estoque" mode="decimal" [minFractionDigits]="2" [maxFractionDigits]="5" maxlength="15"></p-inputNumber>
              </div>
            </div>
            <div class="col-sm-3 col-xs-8">
              <label>Estoque mínimo:</label>
              <div class="p-inputgroup">
                <span class="p-inputgroup-addon">{{data.unidadeMedida.code}}</span>
                <p-inputNumber [(ngModel)]="data.estoqueMin" mode="decimal" [minFractionDigits]="2" [maxFractionDigits]="5" maxlength="15"></p-inputNumber>
              </div>
            </div>
            <div class="col-sm-3 col-xs-8">
              <label>Estoque máximo:</label>
              <div class="p-inputgroup">
                <span class="p-inputgroup-addon">{{data.unidadeMedida.code}}</span>
                <p-inputNumber [(ngModel)]="data.estoqueMax" mode="decimal" [minFractionDigits]="2" [maxFractionDigits]="5" maxlength="15"></p-inputNumber>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-6 col-xs-12">
              <label>Localização no estoque:</label>
              <input type="text" pInputText [(ngModel)]="data.estoqueLocalizacao" class="form-control" placeholder="Ex. Galpão A - Corredor X">
            </div>
            <div class="col-sm-4 col-xs-10">
              <label>Tempo de preparação:</label>
              <div class="p-inputgroup">
                <span class="p-inputgroup-addon">Dias</span>
                <p-inputNumber [(ngModel)]="data.estoquePreparacao" mode="decimal" [minFractionDigits]="1" [maxFractionDigits]="1" maxlength="5"></p-inputNumber>
              </div>
            </div>

          </div>
          <div class="row">
            <div class="col-12">
              <label>Observações:</label>
              <textarea [(ngModel)]="data.descricao" rows="3" class="form-control" placeholder="Observações"></textarea>
            </div>
          </div>

          <hr class="m-t-30 m-b-30" />

          <h4>Dados para Nota Fiscal</h4>

          <div class="row m-t-20">
            <div class="col-sm-9 col-xs-12">
              <label>Origem do produto:</label>
              <select [(ngModel)]="data.origem.code" name="origem.code" class="form-control">
                <option value="0">0 - Nacional, exceto as indicadas nos códigos 3, 4, 5 e 8</option>
                <option value="1">1 - Estrangeira - Importação direta, exceto a indicada no código 6</option>
                <option value="2">2 - Estrangeira - Adquirida no mercado interno, exceto a indicada no código 7</option>
                <option value="3">3 - Nacional, mercadoria ou bem com Conteúdo de Importação superior a 40% e inferior ou igual a 70%</option>
                <option value="4">4 - Nacional, cuja produção tenha sido feita em conformidade com os processos produtivos básicos de que tratam as legislações citadas nos Ajustes</option>
                <option value="5">5 - Nacional, mercadoria ou bem com Conteúdo de Importação inferior ou igual a 40% </option>
                <option value="6">6 - Estrangeira - Importação direta, sem similar nacional, constante em lista da CAMEX </option>
                <option value="7">7 - Estrangeira - Adquirida no mercado interno, sem similar nacional, constante em lista da CAMEX</option>
                <option value="8">8 - Nacional, mercadoria ou bem com Conteúdo de Importação superior a 70%</option>
              </select>
            </div>
          </div>
          <div class="row">

            <div class="col-sm-3 col-xs-6">
              <label>NCM:</label>
                <p-inputNumber [(ngModel)]="data.ncm" maxlength="10" [format]="false"></p-inputNumber>
            </div>
            <div class="col-sm-3 col-xs-6">
              <label>CEST:</label>
                <p-inputNumber [(ngModel)]="data.cest" maxlength="10" [format]="false"></p-inputNumber>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-3 col-xs-8">
              <label>Altura:</label>
              <div class="p-inputgroup">
                <span class="p-inputgroup-addon">Cm</span>
                <p-inputNumber [(ngModel)]="data.altura" mode="decimal" [minFractionDigits]="2" [maxFractionDigits]="3"></p-inputNumber>
              </div>
            </div>
            <div class="col-sm-3 col-xs-8">
              <label>Largura:</label>
              <div class="p-inputgroup">
                <span class="p-inputgroup-addon">Cm</span>
                <p-inputNumber [(ngModel)]="data.largura" mode="decimal" [minFractionDigits]="2" [maxFractionDigits]="3"></p-inputNumber>
              </div>
            </div>
            <div class="col-sm-3 col-xs-8">
              <label>Profundidade:</label>
              <div class="p-inputgroup">
                <span class="p-inputgroup-addon">Cm</span>
                <p-inputNumber [(ngModel)]="data.profundidade" mode="decimal" [minFractionDigits]="2" [maxFractionDigits]="3"></p-inputNumber>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-3 col-xs-8">
              <label>Peso Liquído:</label>
              <div class="p-inputgroup">
                <span class="p-inputgroup-addon">Kg</span>
                <p-inputNumber [(ngModel)]="data.pesoLiquido" mode="decimal" [minFractionDigits]="2" [maxFractionDigits]="3"></p-inputNumber>
              </div>
            </div>
            <div class="col-sm-3 col-xs-8">
              <label>Peso Bruto:</label>
              <div class="p-inputgroup">
                <span class="p-inputgroup-addon">Kg</span>
                <p-inputNumber [(ngModel)]="data.pesoBruto" mode="decimal" [minFractionDigits]="2" [maxFractionDigits]="3"></p-inputNumber>
              </div>
            </div>
          </div>

        </div>
      </p-tabPanel>

      <p-tabPanel header="Imagens e Anexos">

        <p-fileUpload 
              name="files" [url]="upload_path_image" (onUpload)="onUploadImagem($event)" 
              [auto]="true" accept="image/*" maxFileSize="1000000" multiple="multiple"
              chooseLabel="Selecionar imagens do produto">
            
            <ng-template pTemplate="content">
              <label>Arraste suas imagens aqui.</label>
            </ng-template>

        </p-fileUpload>

        <hr *ngIf="data.imagens?.length" />

        <div class="widget-list widget-list-rounded m-b-30" *ngIf="data.imagens?.length">
          <div class="widget-list-item" *ngFor="let file of data.imagens; let i = index">
            <div class="widget-list-media">
              <img [src]="file.url" class="rounded">
            </div>
            <div class="widget-list-content">
              <h4 class="widget-list-title">{{file.nome}}</h4>
              <p class="widget-list-desc">{{file.tamanho}} bytes</p>
            </div>
            <div class="widget-list-action">
              <button class="btn btn-link" (click)="moveUp(i)" [disabled]="i === 0"><i class="fa fa-arrow-alt-circle-up f-s-14" pTooltip="Mover para cima" tooltipPosition="bottom"></i></button>
              <button class="btn btn-link" (click)="moveDown(i)" [disabled]="i === data.imagens.length - 1"><i class="fa fa-arrow-alt-circle-down f-s-14 m-l-5" pTooltip="Mover para baixo" tooltipPosition="bottom"></i></button>
              <button class="btn btn-link" (click)="removeImage(i)"><i class="fa fa-trash-alt f-s-14 m-l-10" pTooltip="Excluir" tooltipPosition="bottom"></i></button>
            </div>
          </div>
        </div>

        <hr />

        <h4>Campos adicionais para E-commerce</h4>

        <div class="be-form">

          <div class="row">
            <div class="col-sm-8 col-xs-12">
              <label>Link do vídeo:</label>
              <input type="text" pInputText [(ngModel)]="data.linkVideo" class="form-control">
            </div>            
          </div>
          <div class="row">
            <div class="col-sm-8 col-xs-12">
              <label>Keywords:</label>
              <p-chips [(ngModel)]="data.keywords" separator=","></p-chips>
            </div>            
          </div>
          <div class="row">
            <div class="col-sm-8 col-xs-12">
              <label>Título para SEO:</label>
              <input type="text" pInputText [(ngModel)]="data.tituloSeo" class="form-control">
            </div>            
          </div>
          <div class="row">
            <div class="col-sm-8 col-xs-12">
              <label>Descrição para SEO:</label>
              <input type="text" pInputText [(ngModel)]="data.descricaoSeo" class="form-control">
            </div>            
          </div>

        </div>

        <hr />

        <h4>Anexos</h4>

        <div class="alert alert-primary fade show m-b-10">
          Este campo permite anexar o manual do produto e outros documentos relevantes
        </div>

        <p-fileUpload 
              name="files" [url]="upload_path_arquivo" (onUpload)="onUploadArquivo($event)" 
              [auto]="true" accept=".pdf,image/*" maxFileSize="1000000" multiple="multiple"
              chooseLabel="Selecionar arquivo do produto">
            
            <ng-template pTemplate="content">
              <label>Arraste seus arquivos aqui.</label>
            </ng-template>

        </p-fileUpload>

        <hr *ngIf="data.anexos?.length" />

        <div class="widget-list widget-list-rounded m-b-30" *ngIf="data.anexos?.length">
          <div class="widget-list-item" *ngFor="let file of data.anexos; let i = index">
            <div class="widget-list-media">
              <i class="far fa-file-pdf"></i>
            </div>
            <div class="widget-list-content">
              <h4 class="widget-list-title">{{file.nome}}</h4>
              <p class="widget-list-desc">{{file.tamanho}} bytes</p>
            </div>
            <div class="widget-list-action">
              <button class="btn btn-link" (click)="removeAnexo(i)"><i class="fa fa-trash-alt f-s-14 m-l-10" pTooltip="Excluir" tooltipPosition="bottom"></i></button>
            </div>
          </div>
        </div>

      </p-tabPanel>

      <p-tabPanel header="Variações">
        <div class="alert alert-primary fade show m-b-10">
          Inserir variações de produtos em um marketplace aumenta a visibilidade, melhora a experiência do cliente e impulsiona as vendas.
        </div>
        <div class="be-form">

          <div class="row">
            <div class="col-5">
              <label>Descrição:</label>
            </div>
            <div class="col-7">
              <label>Variação:</label>
            </div>
          </div>

          <div class="row" *ngFor="let variacao of data.variacoes; let i = index">
            <div class="col-5">
              <input type="text" pInputText [(ngModel)]="variacao.descricao" placeholder="Descrição da variação (Tamanho, Cor, etc)" class="form-control">
            </div>
            <div class="col-7">
              <p-chips [(ngModel)]="variacao.variacoes" separator="," [allowDuplicate]="false" placeholder="Pressione ENTER ou virgula para inserir a variação" (onAdd)="criarVariacaoParametro()" (onRemove)="criarVariacaoParametro()">
                <ng-template let-chip pTemplate="item">
                  <span>{{ chip }}</span>
                  <span class="p-chips-token-icon pi pi-pencil" (click)="editChip(chip, variacao)"></span>
                </ng-template>
              </p-chips>
            </div>
          </div>

          <button (click)="criarVariacao()" class="btn btn-primary btn-xs m-t-10 m-l-10">
            <i class="fa fa-plus-circle f-s-14"></i> Criar outra variação
          </button>

          <div class="row m-t-20" *ngIf="data.variacaoParametros?.length > 0">
            <div class="col-2">
              <label>Variação:</label>
            </div>
            <div class="col-3">
              <label>Código EAN:</label>
            </div>
            <div class="col-3">
              <label>SKU:</label>
            </div>
            <div class="col-2">
              <label>Estoque disponível:</label>
            </div>
            <div class="col-2">
              <label>Valor venda:</label>
            </div>
          </div>

          
          <div class="row" *ngFor="let parametro of data.variacaoParametros; let iv = index">
            <div class="col-2">
              {{parametro.variacao}}
            </div>
            <div class="col-3">
              <span class="p-input-icon-right width-full">
                <i class="pi pi-copy" (click)="replicarValorParametro('ean', parametro.ean)" pTooltip="Replicar para todos" tooltipPosition="bottom"></i>
                <p-inputNumber [(ngModel)]="parametro.ean" maxlength="14" [format]="false"></p-inputNumber>
              </span>
            </div>
            <div class="col-3">
              <span class="p-input-icon-right width-full">
                <i class="pi pi-copy" (click)="replicarValorParametro('sku', parametro.sku)" pTooltip="Replicar para todos" tooltipPosition="bottom"></i>
                <input type="text" pInputText [(ngModel)]="parametro.sku" maxlength="50" class="form-control">
              </span>
            </div>
            <div class="col-2">
              <span class="p-input-icon-right">
                <i class="pi pi-copy" (click)="replicarValorParametro('estoque', parametro.estoque)" pTooltip="Replicar para todos" tooltipPosition="bottom"></i>
                <p-inputNumber [(ngModel)]="parametro.estoque" mode="decimal" [minFractionDigits]="2" [maxFractionDigits]="5" maxlength="15"></p-inputNumber>
              </span>
            </div>
            <div class="col-2">
              <span class="p-input-icon-right">
                <i class="pi pi-copy" (click)="replicarValorParametro('valorVenda', parametro.valorVenda)" pTooltip="Replicar para todos" tooltipPosition="bottom"></i>
                <p-inputNumber [(ngModel)]="parametro.valorVenda" mode="decimal" locale="pt-BR" [minFractionDigits]="2" [maxFractionDigits]="2" maxlength="15"></p-inputNumber>
              </span>
            </div>
          </div>

        </div>
      </p-tabPanel>

      <!-- <p-tabPanel header="Anúncios"></p-tabPanel> -->
    </p-tabView>

  </div>
</div>

<div class="bs-dialog-footer">
  <div class="bs-footer-action-close">
    <button (click)="close()" class="btn btn-white" pTooltip="Esc" tooltipPosition="bottom">Fechar</button>
  </div>
  <div class="bs-footer-action">
    <button (click)="save()" [disabled]="recording" class="btn btn-white" pTooltip="Ctrl + Enter" tooltipPosition="bottom">Salvar e criar outro</button>
    <button (click)="saveClose()" [disabled]="recording" class="btn btn-primary" pTooltip="Ctrl + S" tooltipPosition="bottom">Salvar</button>
  </div>
</div>