import { HttpClient } from '@angular/common/http';
import { Component, HostListener, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { BaseService } from 'src/app/_services/base.service';
import { BaseServiceDialog } from 'src/app/_services/base.service.dialog';
import { LayoutPrivateComponent } from 'src/app/suporte/layout-private/layout-private.component';
import { Crm } from 'src/app/_entity/venda/crm';
import { CrmEstagio } from 'src/app/_entity/suporte/crmEstagio';
import { CrmAcao } from 'src/app/_entity/suporte/crmAcao';
import { Filters } from 'src/app/_entity/filters';
import { Filter } from 'src/app/_entity/filter';
import { environment } from '@environments/environment';
import { Contato } from 'src/app/_entity/cadastro/contato';
import { Usuario } from 'src/app/_entity/cadastro/usuario';
import { AuthenticationService } from 'src/app/_services';
import { Confirmation } from 'src/app/_entity/confirmation';
import { ConfirmationComponent } from 'src/app/suporte/confirmation/confirmation.component';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng-lts/dynamicdialog';

@Component({
  selector: 'app-crm',
  templateUrl: './crm.component.html',
  styleUrls: ['./crm.component.css'],
  providers: [DialogService]
})
export class CrmComponent extends BaseService implements OnInit {

  constructor(
    public app: LayoutPrivateComponent,
    public http: HttpClient,
    public dialog: DialogService,
    public toastr: ToastrService,
    public translate: TranslateService,
    private authenticationService: AuthenticationService) {

      super('crm', app, http, dialog, toastr, translate);
  }

  ngOnInit(): void {
    this.find();
    this.dialogClass = CrmDialogComponent;
    this.authService = this.authenticationService;
    this.buildFilterItens();
  }

  public buildFilterItens(): void {
    this.filters.filters[0].att = 'nome';
    this.filters.filters[0].attAlias = 'Nome';

    this.filterItens = [{
          label: 'Cliente',
          command: () => {
            this.filterChangeAtt('contatos.nome', 'Cliente')
          }
      },
      {
          label: 'Assunto',
          command: () => {
            this.filterChangeAtt('assunto', 'Assunto')
          }
      },
      {
          label: 'Responsável',
          command: () => {
            this.filterChangeAtt('nomeResponsavel', 'Responsável')
          }
      }];
  }

}

@Component({
  selector: 'app-crm-dialog',
  templateUrl: 'crm.dialog.component.html',
  styleUrls: ['./crm.component.css']
})
export class CrmDialogComponent extends BaseServiceDialog implements OnInit {

  public iniciativaExiste: boolean = false;

  public datas: any;
  public autocompleteClienteDisabled = false;
  public autocompleteCliente: any[] = [];
  public autocompleteRA: any[] = [];
  public autocompleteRI: any[] = [];
  
  public crmAcao: CrmAcao = new CrmAcao;

  constructor(
    public ref: DynamicDialogRef, 
    public config: DynamicDialogConfig,
    public http: HttpClient,
    public toastr: ToastrService,
    public translate: TranslateService,
    public dialog: DialogService,
    private authenticationService: AuthenticationService) {

      super('crm', http, toastr, translate, ref, config.data);

      this.datas = config.data;

      // Caso seja uma edicao de iniciativa vindo da lista de iniciatias
      if (this.datas && this.datas.data) {
        this.data = this.datas.data;
      }
      // Caso seja a inclusão de uma ação vinda de outra lista. Ex. Cliente
      else if (this.datas && this.datas.contato) {
        this.createData();
        this.data.contato = this.datas.contato;
      }
      // Caso seja o cadastro de uma nova iniciativa
      else {
        this.createData();
      }
  }

  ngOnInit() {
    // Caso seja uma iniciativa ja criada para edicao, busca a ultima
    // versao no banco de dados e apos inicializa os autocompletes
    if (this.data && this.data._id) {
      this.find(this.data._id);
    }
    // Caso seja uma nova apenas inicializa os autocompletes
    else {
      this.afterFind();
    }
  }

  // Apos executar a consulta da inciativa faz uma callback a finalizar
  public afterFind(): void {
    // Caso nao encontre a iniciativa alerta e fecha o dialog
    if (!this.data) {
      this.createData();
      this.datas.reload = true;
      this.toastr.error('Registro não encontrado, pode ser que foi excluído por outro usuário');

      this.close();
    }
    else {
      // Caso seja um cliente vindo de outra tela não permitir alteração
      if (this.data.contato != null && this.data.contato._id != null) {
        this.autocompleteCliente = this.data.contato;
        this.autocompleteClienteDisabled = true;

        this.existeIniciativa(this.data.contato._id);
      }
      // Caso seja um cliente vindo de outra tela não permitir alteração
      if (this.data.nomeResponsavel != null && this.data.idResponsavel != null) {
        let ri: Usuario = new Usuario;
        ri._id = this.data.idResponsavel;
        ri.nome = this.data.nomeResponsavel;

        this.data.responsavel = ri;
      }
    }
  }

  public onInputClienteSelect(): void {
    this.existeIniciativa(this.data.contato._id);
  }

  public onInputRiBlur(): void {
    if (this.data && this.data.responsavel) {
        this.data.idResponsavel = this.data.responsavel.idResponsavel;
        this.data.nomeResponsavel = this.data.responsavel.nomeResponsavel;
      }
  }

  public onInputRaBlur(): void {
    if (this.data && this.data.responsavelAcao && this.data.responsavelAcao._id) {
        this.crmAcao.idResponsavel = this.data.responsavelAcao._id;
        this.crmAcao.nomeResponsavel = this.data.responsavelAcao.nome;
    }      
  }

  public acFind(event): void {
    this.http.post<any>(`${environment.apiUrl}/cliente/lazylist`, this.createFilters(event.query)).subscribe(
      data => {
          this.autocompleteCliente = data.result;
      },
      error => {
      }
    );
  }

  public acFindRA(event): void {
    this.http.post<any>(`${environment.apiUrl}/usuario/lazylist`, this.createFilters(event.query)).subscribe(
      data => {
          this.autocompleteRA = data.result;
      },
      error => {
      }
    );
  }

  public acFindRI(event): void {
    this.http.post<any>(`${environment.apiUrl}/usuario/lazylist`, this.createFilters(event.query)).subscribe(
      data => {
          this.autocompleteRI = data.result;
      },
      error => {
      }
    );
  }

  // Sobscreve  o metodo de criacao de filtro do autocomplete
  public createFilters(nome: string): Filters {
    const filters = new Filters();
    const filter1 = new Filter('lk', 'nome', this.clearSpecialCharacters(nome));
    const filter2 = new Filter('eq', 'status.code', 'active');
  
    filters.offset = 0;
    filters.pageSize = 5;
    filters.orderField = "descricao";
    filters.orderDirection = 'asc';
    filters.filters = [filter1, filter2];
  
    return filters;
  }

  public addAcao(): void {

    if (!this.crmAcao || !this.crmAcao.acao || this.crmAcao.acao.trim().length <= 2) {
      this.toastr.error('Campo Ação deve ser preenchido.');
      return;
    }
    if (!this.crmAcao || !this.crmAcao.detalhe || this.crmAcao.detalhe.trim().length <= 1) {
      this.toastr.error('Campo Detalhes da Ação deve ser preenchido.');
      return;
    }

    let agora = new  Date();
    this.crmAcao.dtInclusao = agora.toISOString();
    this.crmAcao.idCriador = this.authenticationService.currentUserValue._id;
    this.crmAcao.nomeCriador = this.authenticationService.currentUserValue.nome;
    this.data.crmAcoes.push(this.crmAcao);

    this.data.crmAcoes.sort((a, b) => {
      let ad = new Date(a.dtInclusao);
      let bd = new Date(b.dtInclusao)
      
      return bd.getTime() - ad.getTime()
    });

    if (this.data && this.data.responsavelAcao && this.data.responsavelAcao._id) {
      this.data.responsavelAcao = new Usuario;
    }

    this.crmAcao = new CrmAcao;
  }
  
  public removerAcao(linha: Number) {
    const confirmation = this.dialog.open(ConfirmationComponent, {
      width: '400px',
      transitionOptions: '1ms',
      showHeader: false,
      data: new Confirmation('Confirmação', 'Confirma a exclusão da ação?')
    });

    confirmation.onClose.subscribe(dialogResult => {
      if (dialogResult) {
        this.data.crmAcoes.splice(linha, 1);
      }
    });
  }

  public existeIniciativa(cliente: string): void {
    if (this.data._id != null){
      this.iniciativaExiste = false;
    }
    else if (cliente !== null && cliente !== undefined && cliente.trim() !== '') {
      const filters = new Filters();
      const filterName = new Filter('eq', 'id_contato', cliente);
      const filterStatus = new Filter('eq', 'status.code', 'active');

      filters.filters = [filterName, filterStatus];
      this.http.post<any>(`${environment.apiUrl}/${this.mapping}/lazylist`, filters).subscribe(
        data => {
          if (data.result.length === 0) {
            this.iniciativaExiste = false;
          }
          else {
            this.iniciativaExiste = true;
          }
        },
        error => {
          this.toastr.error('Não foi possível verificar se já existe uma iniciativa para este cliente.');
        }
      );
    }
  }

  public editar(index: number): void {
    this.data.crmAcoes[index].bkDtPrevisao = this.data.crmAcoes[index].dtPrevisao;
    this.data.crmAcoes[index].bkHrPrevisao = this.data.crmAcoes[index].hrPrevisao;
    this.data.crmAcoes[index].bkResposta = this.data.crmAcoes[index].resposta;
    this.data.crmAcoes[index].bkIdResponsavel = this.data.crmAcoes[index].idResponsavel;
    this.data.crmAcoes[index].bkNomeResponsavel = this.data.crmAcoes[index].nomeResponsavel;

    this.data.crmAcoes[index].dtResposta = new Date();
    this.data.crmAcoes[index].idResponsavel = this.authenticationService.currentUserValue._id;
    this.data.crmAcoes[index].nomeResponsavel = this.authenticationService.currentUserValue.nome;
    this.data.crmAcoes[index].editavel = true;
  }

  public cancelaEdicao(index: number): void {
    this.data.crmAcoes[index].dtPrevisao = this.data.crmAcoes[index].bkDtPrevisao;
    this.data.crmAcoes[index].hrPrevisao = this.data.crmAcoes[index].bkHrPrevisao;
    this.data.crmAcoes[index].resposta = this.data.crmAcoes[index].bkResposta;
    this.data.crmAcoes[index].idResponsavel = this.data.crmAcoes[index].bkIdResponsavel;
    this.data.crmAcoes[index].nomeResponsavel = this.data.crmAcoes[index].bkNomeResponsavel;

    this.data.crmAcoes[index].editavel = false;
  }

  public atualizaStatusAcao(index: number): void {
    if (this.data.crmAcoes[index].concluida) {
      this.data.crmAcoes[index].concluida = false;
    }
    else {
      this.data.crmAcoes[index].editavel = false;
      this.data.crmAcoes[index].concluida = true;
    }
  }

  public beforeSave(callback: (valid: boolean) => void): void {
    if (!this.data || !this.data.contato || !this.data.contato._id) {
      this.toastr.error('Campo cliente deve ser preenchido.');
      callback(false);
    }
    if (!this.data || !this.data.assunto || this.data.assunto.trim().length <= 2) {
      this.toastr.error('Campo iniciativa deve ser preenchido.');
      callback(false);
    }
    else {
      callback(true);
    }
  }

  public createData(): void {
    if (this.data == null) {
      this.data = new Crm();
      this.data.contato = new Contato();
      this.data.estagio = new CrmEstagio();
      this.data.crmAcoes = [];

      this.data.estagio.code = 'prospecting';
      this.data.estagio.value = 'Prospecção';

      this.iniciativaExiste = false;
      this.autocompleteClienteDisabled = false;
    }
  }

  @HostListener('window:keydown.control.enter', ['$event'])
  keSave(event: KeyboardEvent) {
    this.save();
  }

  @HostListener('window:keydown.control.s', ['$event'])
  keSaveClose(event: KeyboardEvent) {
    this.saveClose();
  }

  @HostListener('window:keydown.esc', ['$event'])
  keClose(event: KeyboardEvent) {
    this.dialogRef.close();
  }
}