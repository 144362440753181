import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, interval } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NotificacaoService {

  constructor(private http: HttpClient) { }

  fetchNotifications(): Observable<any> {
    return interval(60000)
      .pipe(
        switchMap(() => this.http.get(`${environment.apiUrl}/mensagem/count`))
      );
  }
}

