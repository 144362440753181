import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';

import { AuthenticationService } from '../_services';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private toastr: ToastrService,
    private authenticationService: AuthenticationService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError(err => {

      if (err.status === 401) {
        // auto logout if 401 response returned from api
        this.authenticationService.logout();
        location.reload();
      }
      if (err.status === 406) {
        // 406 account not configured
        this.router.navigate(['/configuracao']);
      }

      if (err.status === 0) {
        this.toastr.error('Ops. ocorreu um problema', 'Verifique sua conexão com a internet');
        return throwError('Verifique sua conexão com a internet');
      }
      else if (err.status !== 404) {
        if (err.error.message == null) {
          this.toastr.error('Ops. ocorreu um problema', err.error);
        }
        else {
          this.toastr.error('Ops. ocorreu um problema', err.error.message);
        }

        const error = err.error.message || err.statusText;
        return throwError(error);
      }

      return throwError(err);
    }))
  }
}
